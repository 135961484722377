import Badge from 'react-bootstrap/Badge';
import styled from 'styled-components'
import { Translation } from '../../utils/translation/Translation';







const LevelBadge = ({ levelAuth }: any) => {

  const levelMap: any = {
    1: PrimaryBadge,
    2: SecondaryBadge,
    3: TernaryBadge,
    4: FourthBadge,
    5: FifthBadge,
    6: SixthBadge,
    7: SeventhBadge,
  };


  const BadgeComponent = levelMap[levelAuth];



  return BadgeComponent ? (
    <BadgeComponent>{`${Translation('COL.LEVEL')} ${levelAuth}`}</BadgeComponent>
  ) : null;
}

export default LevelBadge

const PrimaryBadge = styled(Badge)`
  background-color: #009dff !important;
  
`
const SecondaryBadge = styled(Badge)`
  background-color: #0077c2 !important;
  
`
const TernaryBadge = styled(Badge)`
  background-color: #0065a4 !important;
  
  `
const FourthBadge = styled(Badge)`
    background-color: #004d7d !important;
    
  `
const FifthBadge = styled(Badge)`
    background-color: #003150 !important;
    
  `
const SixthBadge = styled(Badge)`
    background-color: #001a2b !important;
    
  `
const SeventhBadge = styled(Badge)`
    background-color :#000000 !important;
    
  `


