import { useMutation, useQuery } from "react-query"
import { fetchGET2, fetchPOST2 } from "utils/https/https"

export const UseQueryListPendingPaymentsCommissions = (data: any) => {

  const username = data?.username ? data?.username : null
  const impulseAmount = data?.impulseAmount ? data?.impulseAmount : null
  const creationDate = data?.creationDate ? data?.creationDate : null
  const cutoffDate = data?.cutoffDate ? data?.cutoffDate : null
  const paymentState = data?.paymentState && data?.paymentState.length > 0 ? data?.paymentState[0] : null
  const paymentId = data?.paymentId && data?.paymentId.length > 0 ? data?.paymentId[0] : null
  const name = data?.name ? data?.name : null
  const paymentAmount = data?.paymentAmount ? data?.paymentAmount : null

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null


  return useQuery(['UseQueryListPendingPaymentsCommissions', username, impulseAmount, paymentId, creationDate, cutoffDate, paymentState, name, paymentAmount, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (username) {
        serchParms.append('username', username)
      }

      if (paymentId) {
        serchParms.append('paymentId', paymentId)
      }

      if (impulseAmount) {
        serchParms.append('impulseAmount', impulseAmount)
      }

      if (creationDate) {
        serchParms.append('creationDate', creationDate)
      }

      if (cutoffDate) {
        serchParms.append('cutoffDate', cutoffDate)
      }

      if (paymentState) {
        serchParms.append('paymentState', paymentState)
      }

      if (name) {
        serchParms.append('name', name)
      }

      if (paymentAmount) {
        serchParms.append('paymentAmount', paymentAmount)
      }


      // Pagination
      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('rowsPerPage', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }

      const response = await fetchGET2(`Payment/ListPendingLiquidations?&paymentState=PENDING&${serchParms.toString()}`)
      return response
    } catch (error) {
      throw error
    }
  })
}


// Request to send the data to approve it at ListPayments

const UseSendApprovedListPaymentsCommissions = async (data: any) => {
  try {
    const response = await fetchPOST2(`Payment/PostLiquidations`, data)
    return response
  } catch (error) {
    throw error
  }
}

export const UsePostApprovedListPaymentsCommission = (callbackSuccess: any) => {
  return useMutation(UseSendApprovedListPaymentsCommissions, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};



// Request to send the data to reject it at ListPayments

const UseSendRejectedListPaymentsCommissions = async (data: any) => {
  try {
    const response = await fetchPOST2(`Payment/PostLiquidations`, data)
    return response
  } catch (error) {
    throw error
  }
}

export const UsePostRejectedListPaymentsCommission = (callbackSuccess: any) => {
  return useMutation(UseSendRejectedListPaymentsCommissions, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};



export const UseQueryListRejectedPaymentsCommissions = (data: any) => {
  const username = data?.username ? data?.username : null
  const paymentUser = data?.paymentUser ? data?.paymentUser : null
  const country = data?.country ? data?.country : null
  const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
  const paymentId = data?.paymentId && data?.paymentId.length > 0 ? data?.paymentId[0] : null
  const paymentYear = data?.paymentYear && data?.paymentYear.length > 0 ? data?.paymentYear[0] : null
  const creationDate = data?.creationDate && data?.creationDate.length > 0 ? data?.creationDate[0] : null
  const cutoffDate = data?.cutoffDate && data?.cutoffDate.length > 0 ? data?.cutoffDate[0] : null
  const paymentState = data?.paymentState && data?.paymentState.length > 0 ? data?.paymentState[0] : null
  const paymentDate = data?.paymentDate && data?.paymentDate.length > 0 ? data?.paymentDate[0] : null
  const userPayment = data?.userPayment && data?.userPayment.length > 0 ? data?.userPayment[0] : null
  // const state = data?.state && data?.state.length > 0 ? data?.state[0] : null
  const company = data?.company && data?.company.length > 0 ? data?.company[0] : null

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null


  return useQuery(['UseQueryListRejectedPaymentsCommissions', username, paymentUser, country, paymentMonth, paymentId, paymentYear, creationDate, cutoffDate, paymentState, paymentDate, company, userPayment, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (username) {
        serchParms.append('username', username)
      }

      if (paymentUser) {
        serchParms.append('paymentUser', paymentUser)
      }

      if (country) {
        serchParms.append('country', country)
      }

      if (paymentId) {
        serchParms.append('paymentId', paymentId)
      }

      if (paymentMonth) {
        serchParms.append('paymentMonth', paymentMonth)
      }

      if (paymentYear) {
        serchParms.append('paymentYear', paymentYear)
      }

      if (creationDate) {
        serchParms.append('creationDate', creationDate)
      }

      if (cutoffDate) {
        serchParms.append('cutoffDate', cutoffDate)
      }

      if (paymentState) {
        serchParms.append('paymentState', paymentState)
      }

      if (paymentDate) {
        serchParms.append('paymentDate', paymentDate)
      }

      if (userPayment) {
        serchParms.append('userPayment', userPayment)
      }

      if (company) {
        serchParms.append('company', company)
      }


      // Pagination

      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('rowsPerPage', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }

      const response = await fetchGET2(`Payment/ListPendingLiquidations?&paymentState=REJECTED&${serchParms.toString()}`)
      return response
    } catch (error) {
      throw error
    }
  })
}

// To get the data at liquidate commission
export const UseQueryListPaidCommissions = (data: any) => {

  const username = data?.username ? data?.username : null
  const paymentUser = data?.paymentUser ? data?.paymentUser : null
  const country = data?.country ? data?.country : null
  const paymentAmount = data?.paymentAmount ? data?.paymentAmount : null
  const creationDate = data?.creationDate ? data?.creationDate : null
  const cutoffDate = data?.cutoffDate ? data?.cutoffDate : null
  const paymentState = data?.paymentState && data?.paymentState.length > 0 ? data?.paymentState[0] : null
  const paymentId = data?.paymentId && data?.paymentId.length > 0 ? data?.paymentId[0] : null
  const approvedDate = data?.approvedDate ? data?.approvedDate : null
  const company = data?.company ? data?.company : null

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null


  return useQuery(['UseQueryListPaidCommissions', username, paymentUser, country, creationDate, paymentId, cutoffDate, paymentState, approvedDate, company, paymentAmount, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (username) {
        serchParms.append('username', username)
      }

      if (paymentUser) {
        serchParms.append('paymentUser', paymentUser)
      }

      if (country) {
        serchParms.append('country', country)
      }

      if (paymentId) {
        serchParms.append('paymentId', paymentId)
      }

      if (creationDate) {
        serchParms.append('creationDate', creationDate)
      }

      if (cutoffDate) {
        serchParms.append('cutoffDate', cutoffDate)
      }

      if (paymentState) {
        serchParms.append('paymentState', paymentState)
      }

      if (paymentAmount) {
        serchParms.append('paymentAmount', paymentAmount)
      }

      if (approvedDate) {
        serchParms.append('approvedDate', approvedDate)
      }

      if (company) {
        serchParms.append('company', company)
      }

      // Pagination

      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('rowsPerPage', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }


      const response = await fetchGET2(`Payment/ListPendingLiquidations?&paymentState=APPROVED&${serchParms.toString()}`)
      return response
    } catch (error) {
      throw error
    }
  })
}

const UseyGetFileListPaymentsCommissions = async (data: any) => {
  const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
  const month = data?.month && data?.month.length > 0 ? data?.month[0] : null
  const creationDate = data?.creationDate && data?.creationDate.length > 0 ? data?.creationDate[0] : null
  const cutoffDate = data?.cutoffDate && data?.cutoffDate.length > 0 ? data?.cutoffDate[0] : null
  const paymentState = data?.paymentState && data?.paymentState.length > 0 ? data?.paymentState[0] : null
  const paymentDate = data?.paymentDate && data?.paymentDate.length > 0 ? data?.paymentDate[0] : null
  const userPayment = data?.userPayment && data?.userPayment.length > 0 ? data?.userPayment[0] : null
  const company = data?.company && data?.company.length > 0 ? data?.company[0] : null

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null


  try {
    const serchParms = new URLSearchParams();

    if (paymentMonth) {
      serchParms.append('paymentMonth', paymentMonth)
    }

    if (month) {
      serchParms.append('month', month)
    }

    if (creationDate) {
      serchParms.append('creationDate', creationDate)
    }

    if (cutoffDate) {
      serchParms.append('cutoffDate', cutoffDate)
    }

    if (paymentState) {
      serchParms.append('paymentState', paymentState)
    }

    if (paymentDate) {
      serchParms.append('paymentDate', paymentDate)
    }

    if (userPayment) {
      serchParms.append('userPayment', userPayment)
    }

    if (company) {
      serchParms.append('company', company)
    }
    if (pageNumber) {
      serchParms.append('pageNumber', pageNumber)
    }

    if (pageSize) {
      serchParms.append('rowsPerPage', pageSize)
    }

    if (sortOrder) {
      serchParms.append('sortOrder', sortOrder)
    }

    if (sortField) {
      serchParms.append('sortField', sortField)
    }
    // Payment/DownloadPayments

    const response = await fetchGET2(`Payment/DownloadPendingLiquidations?&paymentState=APPROVED&${serchParms.toString()}`, { responseType: 'blob' })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'liquidatePaidFile.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);


    return response
  } catch (error) {
    throw error
  }
}

export const UseDownloadListPaymentsCommission = (callbackSuccess: any) => {
  return useMutation(UseyGetFileListPaymentsCommissions, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};

const getFileLiquidatePending = async (data: any) => {

  const username = data?.username ? data?.username : null
  const impulseAmount = data?.impulseAmount ? data?.impulseAmount : null
  const creationDate = data?.creationDate ? data?.creationDate : null
  const cutoffDate = data?.cutoffDate ? data?.cutoffDate : null
  const paymentState = data?.paymentState && data?.paymentState.length > 0 ? data?.paymentState[0] : null
  const paymentId = data?.paymentId && data?.paymentId.length > 0 ? data?.paymentId[0] : null
  const name = data?.name ? data?.name : null
  const paymentAmount = data?.paymentAmount ? data?.paymentAmount : null

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null


  try {
    const serchParms = new URLSearchParams();

    if (username) {
      serchParms.append('username', username)
    }

    if (paymentId) {
      serchParms.append('paymentId', paymentId)
    }

    if (impulseAmount) {
      serchParms.append('impulseAmount', impulseAmount)
    }

    if (creationDate) {
      serchParms.append('creationDate', creationDate)
    }

    if (cutoffDate) {
      serchParms.append('cutoffDate', cutoffDate)
    }

    if (paymentState) {
      serchParms.append('paymentState', paymentState)
    }

    if (name) {
      serchParms.append('name', name)
    }

    if (paymentAmount) {
      serchParms.append('paymentAmount', paymentAmount)
    }


    // Pagination
    if (pageNumber) {
      serchParms.append('pageNumber', pageNumber)
    }

    if (pageSize) {
      serchParms.append('rowsPerPage', pageSize)
    }

    if (sortOrder) {
      serchParms.append('sortOrder', sortOrder)
    }

    if (sortField) {
      serchParms.append('sortField', sortField)
    }

    const response = await fetchGET2(`Payment/DownloadPendingLiquidations?&paymentState=PENDING&${serchParms.toString()}`, { responseType: 'blob' })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'liquidatePendingDocument.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);


    return response
  } catch (error) {
    throw error
  }
}

export const UseDownloadLiquidatePending = (callbackSuccess: any) => {
  return useMutation(getFileLiquidatePending, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};


const getFileLiquidateRejected = async (data: any) => {

  const username = data?.username ? data?.username : null
  const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
  const paymentId = data?.paymentId && data?.paymentId.length > 0 ? data?.paymentId[0] : null
  const paymentYear = data?.paymentYear && data?.paymentYear.length > 0 ? data?.paymentYear[0] : null
  const creationDate = data?.creationDate && data?.creationDate.length > 0 ? data?.creationDate[0] : null
  const cutoffDate = data?.cutoffDate && data?.cutoffDate.length > 0 ? data?.cutoffDate[0] : null
  const paymentState = data?.paymentState && data?.paymentState.length > 0 ? data?.paymentState[0] : null
  const paymentDate = data?.paymentDate && data?.paymentDate.length > 0 ? data?.paymentDate[0] : null
  const userPayment = data?.userPayment && data?.userPayment.length > 0 ? data?.userPayment[0] : null
  // const state = data?.state && data?.state.length > 0 ? data?.state[0] : null
  const company = data?.company && data?.company.length > 0 ? data?.company[0] : null

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null


  try {
    const serchParms = new URLSearchParams();

    if (username) {
      serchParms.append('username', username)
    }

    if (paymentId) {
      serchParms.append('paymentId', paymentId)
    }

    if (paymentMonth) {
      serchParms.append('paymentMonth', paymentMonth)
    }

    if (paymentYear) {
      serchParms.append('paymentYear', paymentYear)
    }

    if (creationDate) {
      serchParms.append('creationDate', creationDate)
    }

    if (cutoffDate) {
      serchParms.append('cutoffDate', cutoffDate)
    }

    if (paymentState) {
      serchParms.append('paymentState', paymentState)
    }

    if (paymentDate) {
      serchParms.append('paymentDate', paymentDate)
    }

    if (userPayment) {
      serchParms.append('userPayment', userPayment)
    }

    if (company) {
      serchParms.append('company', company)
    }


    // Pagination

    if (pageNumber) {
      serchParms.append('pageNumber', pageNumber)
    }

    if (pageSize) {
      serchParms.append('rowsPerPage', pageSize)
    }

    if (sortOrder) {
      serchParms.append('sortOrder', sortOrder)
    }

    if (sortField) {
      serchParms.append('sortField', sortField)
    }

    const response = await fetchGET2(`Payment/DownloadPendingLiquidations?&paymentState=REJECTED&${serchParms.toString()}`, { responseType: 'blob' })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'liquidateRejectedDocument.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);


    return response
  } catch (error) {
    throw error
  }
}

export const UseDownloadLiquidateRejected = (callbackSuccess: any) => {
  return useMutation(getFileLiquidateRejected, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};
