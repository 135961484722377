import axios from 'axios'
import { useMutation, useQuery } from 'react-query'
import { fetchGET, fetchGET2, fetchPATCH, fetchPOST2 } from 'utils/https/https'


// This is the query to get the users that is showing in the select
const UseQueryGetUsers = () => {
  return useQuery(['UseQueryGetUsers'], async () => {
    try {
      const response = await fetchGET2(`User/ListUsers`)
      return response.data
    } catch (error) {
      throw error
    }
  })
}

// This is the query to get the goals at page Month sales
const UseQueryGetData = (data: any) => {
  const year = data.year
  const username = data.username
  return useQuery(['UseQueryGetData', data], async () => {
    try {

      const searchParams = new URLSearchParams()

      if (year !== '') {
        searchParams.append('goalYear', year)
      }

      if (username !== '') {
        searchParams.append('email', username)
      }

      const response = await fetchGET2(`Goal/ListUserGoals?${searchParams.toString()}`)
      return response.data
    } catch (error) {
      throw error
    }
  },
    {
      enabled: !!year && !!username
    }
  )
}

// This is the request to send the goals
const UsePostGoals = async (data: any) => {
  try {
    const response = await fetchPATCH(`/Goal/Update`, [data])
    return response
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const customErr = {
        errorCode: error.response.data.code,
        message: error.response.data.message,
      }
      throw customErr
    }
  }
}

// This is the request to send the goals
const UsePatchGoals = (callbackSuccess: any) => {
  return useMutation(UsePostGoals, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};

//To get the template for bulk load
const UseQueryGetTemplateBulkLoadGoals = async (data: any) => {

  try {
    const response = await fetchGET2(`/Goal/GetUserGoalTemplate?managerEmail=${data}`, { responseType: 'blob' })

    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'template.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    return response
  } catch (error) {
    throw error
  }

}

const UseGetBulkLoadTemplate = (callbackSuccess: any) => {
  return useMutation(UseQueryGetTemplateBulkLoadGoals, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};


// To send the file from MonthSales to the endpoint
const UsePostBulkLoadGoals = async (data: any) => {

  try {
    const formdata = new FormData()
    formdata.append('file', data)
    const response = await fetchPOST2(`/Goal/UploadUserGoalTemplate`, formdata)
    return response

  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const customError = {
        errorCode: error.response.data.code,
        message: error.response.data.message,
      };
      throw customError
    }

  }

}

const UsePostBulkLoadFile = (callbackSuccess: any) => {
  return useMutation(UsePostBulkLoadGoals, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};



// To send the file from Config-global to endpoint
const UsePosGlobaltBulkLoadGoals = async (data: any) => {

  try {
    const formdata = new FormData()
    formdata.append('file', data)
    const response = await fetchPOST2(`ConfigCommissionPercentage/UploadCommissionPercentageTemplate`, formdata)

    return response
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const customErr = {
        errorCode: error.response.data.code,
        message: error.response.data.message,
      }
      throw customErr
    }
  }

}

const UsePostGlobalBulkLoadFile = (callbackSuccess: any) => {
  return useMutation(UsePosGlobaltBulkLoadGoals, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};



// to get the data at the Page Authorization /Authorizator/ListCommissions


const UseQueryGetAuthorizationData = (data: any) => {

  const commissionType = data?.commissionType && data?.commissionType.length > 0 ? data?.commissionType[0] : null
  const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
  const serviceId = data?.global ? data?.global : null
  const authorizationState = data?.authorizationState && data?.authorizationState.length > 0 ? data?.authorizationState[0] : null

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize

  return useQuery(['UseQueryGetAuthorizationData', commissionType, saleMonth, serviceId, authorizationState, pageNumber, pageSize], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (commissionType) {
        serchParms.append('commissionType', commissionType)
      }

      if (saleMonth) {
        serchParms.append('saleMonth', saleMonth)
      }

      if (serviceId) {
        serchParms.append('serviceId', serviceId)
      }

      if (authorizationState) {
        serchParms.append('authorizationState', authorizationState)
      }

      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('pageSize', pageSize)
      }

      const response = await fetchGET2(`Authorizator/ListCommissions?${serchParms.toString()}`)
      return response
    } catch (error) {
      throw error
    }
  }
  )
}

// to send the request to approve the commission Authorizator/AuthorizeCommissions






export {
  UseQueryGetUsers,
  UseQueryGetData,
  UsePatchGoals,
  UseQueryGetTemplateBulkLoadGoals,
  UseGetBulkLoadTemplate,
  UsePostBulkLoadFile,
  UsePostGlobalBulkLoadFile,
  UseQueryGetAuthorizationData,
}
