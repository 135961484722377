import { useTranslation } from "utils/translation/Translation"

interface Props {
  locked: boolean
}
export const Locked = ({ locked }: Props) => {

  const translate = useTranslation()


  if (locked === null) return <></>

  return (
    <>{locked ? <div className="text-danger fw-bolder">{`${translate('SHOW.NO.ISACTIVE')}`}</div> : <div className="">{`${translate('SHOW.ISACTIVE')}`}</div>}</>
  )
}
