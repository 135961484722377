import React, { useState, useEffect, useMemo, useContext } from 'react'
import { useIntl } from 'react-intl'
import { Table, UfinetBox, UfinetButton, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection } from 'ufinet-web-components'
import { AuthContext, Authority, } from 'ufinet-web-functions'
import { colsInProcessCommission } from './InProcessColData'
import { UseQueryInProcessSystems, UseDownloadFileCommissionInProcessSys } from 'request'
import { Translation } from 'utils/translation/Translation'
import { DataTablePageEvent } from 'primereact/datatable'
import { CommissionSystemElement } from 'types/commissionSystemTypes'
import { faDownload } from '@fortawesome/free-solid-svg-icons'



const InProcessPage = () => {
  const intl = useIntl()

  const colsInProcessPage = useMemo(() => colsInProcessCommission(intl), [intl])

  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  const permissions = Authority.getCommissionCmSystemPermissions(roles)



  const [paging, setPaging] = useState<any>()
  const [filterData, setFilterData] = useState<any>()
  const { data: inProcessCommission, isLoading: isLoadingInProcessCommission } = UseQueryInProcessSystems(filterData)

  const cbSuccessInProcessFile = (data: any) => {

  }

  const { mutate: downloadFile, isLoading: isLoadingCommissionInProcessSys } = UseDownloadFileCommissionInProcessSys(cbSuccessInProcessFile)

  useEffect(() => {
    if (inProcessCommission) {
      const parsePagination = JSON.parse(inProcessCommission?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [inProcessCommission])

  const onFilterClear = (): void => {
    setFilterData({})
  }

  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)
    setFilterData({
      ...filterData,
      ...formattedFilters
    })
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({ ...filterData, pageNumber: e.page, pageSize: e.rows })

  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })

  }

  const downloadFileNextPaymentSys = () => {
    downloadFile(filterData)
  }


  const getHeadersButtons = () => (
    <>
      {permissions.canWrite && (
        <UfinetButton
          className="me-3"
          icon={faDownload}
          onClick={() => downloadFileNextPaymentSys()}
          content={Translation('DOWNLOAD.FILE')}
        />
      )}
    </>
  )

  return (
    <>
      <UfinetBox>

        <UfinetSectionBox>

          <Table
            // selectedValues={selectedValues}
            // onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            content={inProcessCommission?.data?.commissions?.map((item: CommissionSystemElement) => ({
              ...item,
              username: item.user.username,
              idService: item.service.idService,
              saleMonth: item.service.saleMonth,
              saleYear: item.service.saleYear,
              commissionType: item.commission.commissionType,
              exchangeRate: item.commission.exchangeRate,
              commissionDescription: item.commission.commissionDescription,
              backlog: item.service.backlog,
              percentageCommission: item.commission.percentageCommission,
              baseAmount: item.commission.baseAmount,
              paymentYear: item.commission.paymentYear,
              paymentMonth: item.commission.paymentMonth,
              commissionTypePercentage: item.commission.commissionTypePercentage,
              idServiceCRM: item.service.idServiceCRM,
              serviceName: item.service.serviceName,
              serviceClient: item.service.serviceClient,
            }))}
            actionBodyTemplate={<></>}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            emptyMessage={isLoadingInProcessCommission || isLoadingCommissionInProcessSys ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            cols={colsInProcessPage}
            onFilterClear={onFilterClear}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingInProcessCommission || isLoadingCommissionInProcessSys,
                onFilter,
                onPage,
                onSort,
              }
            }
            headerButtons={getHeadersButtons()}
          // emptyMessage={loadingRecords ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
          />
          {/* <UfinetModal
          size="lg"
          show={newModal}
          handleClose={hideNewModal}
          title={intl.formatMessage({ id: 'MODAL.NEW.REGISTER.TITLE' })}
        >
          <NewCompanyModalBody search={fetchAndStore} hideModal={hideNewModal} />
        </UfinetModal> */}
        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}

export default InProcessPage


