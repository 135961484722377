import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons"
import { colsAuthorizersColData } from "./AuthorizersColData"
import { UseQueryGetAuthorizersData, useMutationDeleteAuthorizerConfig } from "request"
import { AuthorizersTypes } from "types/configuration"
import { Table, UfinetBox, UfinetButton, UfinetModal, UfinetSectionBox, formatTableFilters, getEditPencilBodyTemplate, tableSortValueToSortDirection } from "ufinet-web-components"
import { DataTablePageEvent } from "primereact/datatable"
import { AuthContext, Authority, useModal } from "ufinet-web-functions"
import { useTranslation } from "utils/translation/Translation"
import { CreateModalForm, UpdateModalForm } from "./modals"
import { toast } from "react-toastify"
import { ErrorResponse } from "types/typesAndValues"

export const AuthorizersPage = () => {
  // TO GET PERMISSIONS
  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  const permissions = Authority.getCommissionConfigPermissions(roles)


  const translate = useTranslation()

  const intl = useIntl()
  const colsAuthorizersConfig = useMemo(() => colsAuthorizersColData(intl), [intl])
  const [selectedValues, setSelectedValues] = useState<AuthorizersTypes[]>([])
  const onSelectionChange = useCallback((values: any) => setSelectedValues(values), [])

  const [authorizerInfo, setAuthorizerInfo] = useState<AuthorizersTypes>()


  const [paging, setPaging] = useState<any>()

  const [filterData, setFilterData] = useState<any>()
  const [createModal, showCreateModal, hideCreateModal] = useModal()
  const [updateModal, showUpdateModal, hideUpdateModal] = useModal()



  const { data: authorizersData, isLoading: isLoadingAuthorizers, refetch } = UseQueryGetAuthorizersData(filterData)


  const cbSuccessDeleteAuthorizerConfig = ({ data }: any) => {
    toast.success(data.message)
    refetch()
  }
  const { mutate: deleteAuthorizer, isError, error: errorMessage } = useMutationDeleteAuthorizerConfig(cbSuccessDeleteAuthorizerConfig)


  const showToastErrMessage = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse;
      toast.error(`${translate("ERROR.MESSAGE")} ${errorResponse.errorCode}`, { toastId: 'Error5' });
      hideUpdateModal()
    }
  }




  useEffect(() => {
    if (authorizersData) {
      const parsePagination = JSON.parse(authorizersData?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: filterData && filterData.pageSize ? filterData.pageSize : parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [authorizersData])


  const onFilterClear = (): void => {
    setFilterData({})
  }

  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)
    setFilterData({
      ...filterData,
      ...formattedFilters
    })
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({ ...filterData, pageNumber: e.page, pageSize: e.rows })
  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })
  }


  const deleteAutorizerConfig = () => {
    if (selectedValues && selectedValues.length > 0) {
      const firstElement = selectedValues[0].email
      deleteAuthorizer(firstElement)
      setSelectedValues([])
    }
  }



  const getHeadersButtons = () => (
    <>
      {
        permissions.canWrite && (
          <>
            <UfinetButton
              className="me-3"
              icon={faPlus}
              onClick={() => {
                showCreateModal()
              }}
              content={translate('CREATE.MODAL')}
            />
          </>
        )
      }
      {
        permissions.canWrite && selectedValues && selectedValues.length > 0 && (
          <>
            <UfinetButton
              className="me-3"
              icon={faTrash}
              onClick={() => {
                deleteAutorizerConfig()
              }}
              content={translate('DELETE.BUTTON')}
            />
          </>
        )
      }
    </>
  )





  return (
    <>
      {isError && showToastErrMessage()}
      <UfinetBox>
        <UfinetSectionBox>

          <Table
            dataKey="id"
            selectedValues={selectedValues}
            onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            content={authorizersData?.data}
            cols={colsAuthorizersConfig}
            headerButtons={getHeadersButtons()}
            onFilterClear={onFilterClear}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            emptyMessage={isLoadingAuthorizers ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            actionBodyTemplate={
              permissions.canUpdate
                ? (auhtorizersConfigData: AuthorizersTypes) =>
                  getEditPencilBodyTemplate(auhtorizersConfigData, () => {
                    setSelectedValues([])
                    setAuthorizerInfo(auhtorizersConfigData)
                    showUpdateModal()
                  })
                : undefined
            }
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingAuthorizers,
                onFilter,
                onPage,
                onSort,
              }
            }
          />

          <UfinetModal
            size="lg"
            show={createModal}
            handleClose={hideCreateModal}
            title={translate('CREATE.MODAL')}
          >
            <CreateModalForm refetch={refetch} hideCreateModal={hideCreateModal} />
          </UfinetModal>

          <UfinetModal
            size="lg"
            show={updateModal}
            handleClose={hideUpdateModal}
            title={translate('UPDATE.MODAL')}
          >
            <UpdateModalForm refetch={refetch} hideUpdateModal={hideUpdateModal} authorizerInfo={authorizerInfo} />
          </UfinetModal>

        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}
