import { convertNumber } from "utils/convertNumber/ConvertNumber"

interface Props {
  value: null | number
  currencyCode: string | null
}


const ShowValue = ({ value, currencyCode }: Props) => {


  return (<>{value === null ? '' : `${convertNumber(value)} ${currencyCode}`} </>)
}

export { ShowValue }
