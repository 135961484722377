import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import { useMutationUpdateAuthorizerConfig } from 'request'
import { ErrorResponse, IsActiveValues, isActiveMappings } from 'types/typesAndValues'
import { IUfinetSelectOption, UfinetActionButton, UfinetActionButtonHandle, UfinetInput, UfinetSelect, emptyUfinetSelectOption } from 'ufinet-web-components'
import { useTranslation } from 'utils/translation/Translation'
import { useCallback, useMemo, useRef } from 'react'
import { onFormikChanges, onFormikNumberChanges, onFormikTextChanges } from 'ufinet-web-functions'
import { AuthorizersTypes, } from 'types/configuration'



interface Props {
  refetch: () => void
  hideUpdateModal: () => void
  authorizerInfo: AuthorizersTypes | undefined
}

type UpdateFormData = {
  email: string
  minPendingApprovals: number
  minPendingAmount: number
  isActive: IUfinetSelectOption
}

export const UpdateModalForm = ({ refetch, hideUpdateModal, authorizerInfo }: Props) => {
  const actionButtonRef = useRef<UfinetActionButtonHandle>(null)
  const translate = useTranslation()
  const intl = useIntl()


  const cbSuccessUpdateAuthorizerConfig = ({ data }: any) => {
    toast.success(data.message)
    refetch()
    hideUpdateModal()
  }

  const { mutate: updateAuhtorizer, isLoading: isLoadingCreate, error: errorMessage, isError } = useMutationUpdateAuthorizerConfig(cbSuccessUpdateAuthorizerConfig)




  const showToastErrMessage = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse;
      toast.error(`${translate("ERROR.MESSAGE")} ${errorResponse.errorCode}`, { toastId: 'Error5' });
      hideUpdateModal()
    }
  }


  const isActiveSelect = [
    {
      label: intl.formatMessage({ id: isActiveMappings.get(IsActiveValues.FALSE) }),
      value: IsActiveValues.FALSE,
    },
    {
      label: intl.formatMessage({ id: isActiveMappings.get(IsActiveValues.TRUE) }),
      value: IsActiveValues.TRUE,
    },
  ]

  const dataFormSchema = Yup.object().shape({
    email: Yup.string().email(intl.formatMessage({ id: 'MUSTBE.EMAIL' })).required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
    minPendingApprovals: Yup.number().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
    minPendingAmount: Yup.number().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
    isActive: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
  })



  const sendData = (values: UpdateFormData) => {
    actionButtonRef.current?.changeActionStatus(true)
    const booleanValue = values.isActive.value === 'true' ? true : false
    updateAuhtorizer({
      email: values.email,
      minPendingApprovals: values.minPendingApprovals,
      minPendingAmount: values.minPendingAmount,
      isActive: booleanValue,
    })
  }

  const onChange = useCallback(onFormikChanges, [])
  const onNumberChange = useCallback(onFormikNumberChanges, [])
  const onTextChange = useCallback(onFormikTextChanges, [])


  const initialValues: UpdateFormData = useMemo<UpdateFormData>(
    () => ({
      email: authorizerInfo && authorizerInfo.email ? authorizerInfo.email : '',
      minPendingApprovals: authorizerInfo && authorizerInfo.minPendingApprovals ? authorizerInfo.minPendingApprovals : 0,
      minPendingAmount: authorizerInfo && authorizerInfo.minPendingAmount ? authorizerInfo.minPendingAmount : 0,
      isActive: authorizerInfo && typeof authorizerInfo.isActive !== 'undefined' ? { label: authorizerInfo.isActive ? translate('SHOW.ISACTIVE') : translate('SHOW.NO.ISACTIVE'), value: authorizerInfo.isActive ? translate('SHOW.ISACTIVE') : translate('SHOW.NO.ISACTIVE') } : emptyUfinetSelectOption,
    }),
    []
  )



  const formik = useFormik<UpdateFormData>({
    initialValues,
    validationSchema: dataFormSchema,
    onSubmit: sendData,
    validateOnChange: false,
    validateOnBlur: false,
  })




  return (
    <>
      {isError && showToastErrMessage()}
      <form onSubmit={formik.handleSubmit}>
        <div className='row'>
          <div className='row'>

            <UfinetInput
              requiredIcon
              error={formik.errors.email}
              type="text"
              labelTitle={translate('COL.CONFIG.EMAIL')}
              className="col-6"
              onChange={onTextChange(formik, 'email')}
              value={formik.values.email}
              solid={false}
            />

            <UfinetInput
              requiredIcon
              error={formik.errors.minPendingApprovals}
              type="decimal"
              labelTitle={translate('COL.CONFIG.MINPENDINGAPPROVALS')}
              className="col-6"
              onChange={onNumberChange(formik, 'minPendingApprovals')}
              value={formik.values.minPendingApprovals}
              solid={false}
            />
            <div className='row mt-5'>


              <UfinetInput
                requiredIcon
                error={formik.errors.minPendingAmount}
                type="decimal"
                labelTitle={translate('COL.CONFIG.MINPENDINGAMOUNT')}
                className="col-6"
                onChange={onNumberChange(formik, 'minPendingAmount')}
                value={formik.values.minPendingAmount}
                solid={false}
              />

              <UfinetSelect
                requiredIcon
                className='col-6'
                value={formik.values.isActive}
                error={formik.errors.isActive?.label}
                labelTitle={translate('COL.CONFIG.ISACTIVE')}
                onChange={onChange(formik, 'isActive')}
                placeholder={translate('COL.CONFIG.ISACTIVE')}
                options={isActiveSelect}
              />

            </div>


            <UfinetActionButton
              isDisabled={isLoadingCreate}
              ref={actionButtonRef}
              className='mt-5'
              content={translate('UPDATE.MODAL')}
            // onClick={downLoadTemplate}
            />
          </div>
        </div>
      </form>
    </>
  )
}
