import { AsideDefault } from 'layout/aside/AsideDefault'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { MasterLayout, WelcomePage, AuthRoute } from 'ufinet-web-components'
import { Authority } from 'ufinet-web-functions'

// COMMISSIONS PAGES
import { InProcessPage } from 'pages/commissions/inProcess/InProcessPage'
import PendingPage from 'pages/commissions/pending/PendingPage'
import ApprovedCommissionPage from 'pages/commissions/approved/ApprovedCommisionPage'
import RejectePage from 'pages/commissions/rejected/RejectedPage'
import { NextPaymentPage } from 'pages/commissions/nextPayment/NextPaymentPage'
import { PaidCommissionKamPage } from 'pages/commissions/paid/PaidCommissionPage'


// COMMISSIONS SYSTEM PAGES
import { NextPaymentCommisionSystemPage } from 'pages/comssionSystem/nextPayment/NextPaymentCommissionSystemPage'
import InProcessPageCommissionSystem from 'pages/comssionSystem/inProcess/InProcessPage'
import PendingCommissionSystemPage from 'pages/comssionSystem/pending/PendingCommissionSystemPage'
import { ApprovedCommissionSystemPage } from 'pages/comssionSystem/approved/ApprovedCommisionSystemPage'
import RejectedCommissionSystemPage from 'pages/comssionSystem/rejected/RejectedCommissionSystemPage'
import { PaidCommissionSystemPage } from 'pages/comssionSystem/paid/PaidCommissionSysPage'

// GOALS PAGE
import { GoalsPage } from 'pages/goals/GoalsPage'

//* CONFIG PAGE
import GlobalPage from 'pages/configuration/global/GlobalPage'
import { PercentageTypeCommssion } from 'pages/configuration/percentangeTypeCommission/PercentangeTypeCommissionPage'
import { MultiplierConfigPage } from 'pages/configuration/mutiplier/ConfigMultiplierPage'
import { AuthorizersPage } from 'pages/configuration/authorizers/AuthorizersPage'
import { AuthorizingChainPage } from 'pages/configuration/authorizing-chain/AuthorizingChainPage'
import { ConfigLiquidatePage } from 'pages/configuration/config-liquidate/ConfigLiquidatePage'
import { UsersRRHHConfigPage } from 'pages/configuration/users-rrhh/UsersRRHHPage'


//* CONFIG-KAM
import { PercentageTypeCommssionKam } from 'pages/config-kam/percentage-type-commission/PercentangeTypeCommissionPage'
import { PercentangeCommissionKamPage } from 'pages/config-kam/percentage-commission/PercentangeCommissionPage'
import { MultiplierConfigKamPage } from 'pages/config-kam/multiplier/MultiplierConfigKamPage'


// AUTHORIZATION PAGES
import { ResumeAuthorizationPage } from 'pages/authorization/resume/ResumeAuthorizationPage'
import { PendingAuthorizationPage } from 'pages/authorization/pending/AuthotizationPendingPage'
import { RejectedAuthorizationPage } from 'pages/authorization/rejected/RejectedAuthorizationPage'
import { AuthorizationManagerApprovedPage } from 'pages/authorization/approved/AuthorizationApproveManagerPage'

// AUTHORIZATION SYSTEM PAGES
import { AuthorizacionApprovedPage } from 'pages/authorizationSystem/approved/AuthorizationApproveSysPage'
import { PendingAuthorizationSystemPage } from 'pages/authorizationSystem/pending/AuthorizationSystemPendingPage'
import { RejectedAuthorizationSystemPage } from 'pages/authorizationSystem/rejected/AuthorizationSystemRejectedPage'
import { ResumeAuthorizationSysPage } from 'pages/authorizationSystem/resume/ResumeAuthorizationSysPage'

// MULTIPLICATOR PAGE
import { MultiplicatorGoalsPage } from 'pages/multiplicator/multiplicator-goals/MultiplicatorGoalsPage'

// MULTIPLICATOR SYSTEM PAGE
import { MultiplicatorSystemPage } from 'pages/multiplicatorSystem/historicals/HistoricalsMultiplicatorSystemPage'

// LIQUIDATEKAM PAGES
import { LiquidateHistoricalsPage } from 'pages/liquidatesKAM/historicals/LiquidateHistoricalsPage'

// LIQUIDATERRHH PAGES
import { LiquidatePaidPage } from 'pages/liquidatesRRHH/paid/LiquidatePaidPage'
import { LiquidatePendingPage } from 'pages/liquidatesRRHH/pending/LiquidatePendingPage'
import { LiquidateRejectedPage } from 'pages/liquidatesRRHH/rejected/LiquidateRejectedPage'

// LIQUIDATE SYSTEM PAGES
import { HistoricalLiquidateSystemPage } from 'pages/liquidatesSystem/historicals/HistoricalsLiquidateSystemPage'





export const PATH_HOME = ''



//* COMMISSIONS PATHS AND MODEL
export const PATH_COMMISSIONS = 'commissions'
export const PATH_PENDING = 'pending'
export const PATH_REJECTED = 'rejected'
export const PATH_APPROVED_PAGE = 'approved'
export const PATH_PAID_PAGE = 'paid'
export const PATH_COMMISSIONS_IN_PROCESS_PAGE = 'in-process'
export const PATH_NEXT_PAYMENT_PAGE = 'next-payments'
export const PATH_COMISSIONS_PENDING_MODEL = `${PATH_COMMISSIONS}/${PATH_PENDING}`
export const PATH_COMISSIONS_IN_PROCESS_MODEL = `${PATH_COMMISSIONS}/${PATH_COMMISSIONS_IN_PROCESS_PAGE}`
export const PATH_COMISSIONS_SETTLED_ACCOUNTS_MODEL = `${PATH_COMMISSIONS}/${PATH_APPROVED_PAGE}`
export const PATH_COMISSIONS_REJECTED_MODEL = `${PATH_COMMISSIONS}/${PATH_REJECTED}`
export const PATH_COMISSIONS_NEXT_PAYMENT_MODEL = `${PATH_COMMISSIONS}/${PATH_NEXT_PAYMENT_PAGE}`
export const PATH_COMISSIONS_PAID_MODEL = `${PATH_COMMISSIONS}/${PATH_PAID_PAGE}`

// COMMISION SYSTEM PATHS AND MODEL
export const PATH_COMMISSIONS_SYSTEM = 'commissions-system'
export const PATH_COMMISSIONS_IN_PROCESS_SYSTEM_PAGE = 'system-in-process'
export const PATH_SYSTEM_PENDING = 'system-pending'
export const PATH_APPROVED_SYSTEM_PAGE = 'system-approved'
export const PATH_PAID_SYSTEM_PAGE = 'system-paid'
export const PATH_REJECTED_SYSTEM = 'system-rejected'
export const PATH_NEXT_PAYMENTS_SYSTEM_PAGE = 'system-next-payments'
export const PATH_COMISSIONS_APPROVED_SYSTEM_MODEL = `${PATH_COMMISSIONS_SYSTEM}/${PATH_APPROVED_SYSTEM_PAGE}`
export const PATH_COMISSIONS_PAID_SYSTEM_MODEL = `${PATH_COMMISSIONS_SYSTEM}/${PATH_PAID_SYSTEM_PAGE}`
export const PATH_COMISSIONS_PENDING_SYSTEM_MODEL = `${PATH_COMMISSIONS_SYSTEM}/${PATH_SYSTEM_PENDING}`
export const PATH_COMISSIONS_MODEL_IN_PROCESS_SYSTEM = `${PATH_COMMISSIONS_SYSTEM}/${PATH_COMMISSIONS_IN_PROCESS_SYSTEM_PAGE}`
export const PATH_COMISSIONS_REJECTED_SYSTEM_MODEL = `${PATH_COMMISSIONS_SYSTEM}/${PATH_REJECTED_SYSTEM}`
export const PATH_COMISSIONS_NEXT_PAYMENTS_SYSTEM_MODEL = `${PATH_COMMISSIONS_SYSTEM}/${PATH_NEXT_PAYMENTS_SYSTEM_PAGE}`

// GOALS PATHS
export const PATH_GOALS = 'goals'

// CONFIG PATHS AND MODEL
export const PATH_CONFIGURATION = 'configuration'
export const PATH_CONFIGURATION_GLOBAL = 'global'
export const PATH_PERCENTAGETYPECOMMMISION_PAGE = 'percentage-type-commission'
export const PATH_MULTIPLIER_CONFIG_PAGE = 'config-multiplier'
export const PATH_AUTHORIZERS_PAGE = 'config-authorizers'
export const PATH_AUTHORIZING_CHAIN_PAGE = 'authorizing-chain'
export const PATH_CONFIG_LIQUIDATE_PAGE = 'config-liquidate'
export const PATH_CONFIG_USERS_RRHH_PAGE = 'users-rrhh'
export const PATH_CONFIGURATION_GLOBAL_MODEL = `${PATH_CONFIGURATION}/${PATH_CONFIGURATION_GLOBAL}`
export const PATH_PERCENTAGETYPECOMMISION_MODEL = `${PATH_CONFIGURATION}/${PATH_PERCENTAGETYPECOMMMISION_PAGE}`
export const PATH_MULTIPLIER_CONFIG_MODEL = `${PATH_CONFIGURATION}/${PATH_MULTIPLIER_CONFIG_PAGE}`
export const PATH_AUTHORIZERS_CONFIG_MODEL = `${PATH_CONFIGURATION}/${PATH_AUTHORIZERS_PAGE}`
export const PATH_AUTHORIZING_CHAIN_CONFIG_MODEL = `${PATH_CONFIGURATION}/${PATH_AUTHORIZING_CHAIN_PAGE}`
export const PATH_CONFIG_LIQUIDATE_MODEL = `${PATH_CONFIGURATION}/${PATH_CONFIG_LIQUIDATE_PAGE}`
export const PATH_CONFIG_USERS_RRHH_MODEL = `${PATH_CONFIGURATION}/${PATH_CONFIG_USERS_RRHH_PAGE}`


//* CONFIG-KAM
export const PATH_CONFIGURATION_KAM = 'configuration-kam'
export const PATH_PERCENTAGETYPECOMMMISION_KAM_PAGE = 'percentage-type-commission-kam'
export const PATH_PERCENTANGE_COMMISSION_KAM_CONFIGURATION_PAGE = 'percentage-commission-kam'
export const PATH_MULTIPLIER_CONFIG_KAM_PAGE = 'config-multiplier-kam'
export const PATH_PERCENTAGETYPECOMMISION_KAM_MODEL = `${PATH_CONFIGURATION_KAM}/${PATH_PERCENTAGETYPECOMMMISION_KAM_PAGE}`
export const PATH_PERCENTAGECOMMISION_KAM_MODEL = `${PATH_CONFIGURATION_KAM}/${PATH_PERCENTANGE_COMMISSION_KAM_CONFIGURATION_PAGE}`
export const PATH_MULTIPLIER_CONFIG_KAM_MODEL = `${PATH_CONFIGURATION_KAM}/${PATH_MULTIPLIER_CONFIG_KAM_PAGE}`

// AUTHORIZATION PATHS AND MODEL
export const PATH_COMMISSION_AUTHORIZATION = 'authorization'
export const PATH_PENDING_AUTHORIZATION_PAGE = 'pending-authorization'
export const PATH_REJECTED_AUTHORIZATION_PAGE = 'rejected-authorization'
export const PATH_RESUME_AUTHORIZATION_PAGE = 'resume-authorization'
export const PATH_APPROVE_AUTHORIZATION_PAGE = 'approve-authorization'
export const PATH_PENDING_AUTHORIZATION_MODEL = `${PATH_COMMISSION_AUTHORIZATION}/${PATH_PENDING_AUTHORIZATION_PAGE}`
export const PATH_RESUME_AUTHORIZATION_MODEL = `${PATH_COMMISSION_AUTHORIZATION}/${PATH_RESUME_AUTHORIZATION_PAGE}`
export const PATH_REJECTED_AUTHORIZATION_MODEL = `${PATH_COMMISSION_AUTHORIZATION}/${PATH_REJECTED_AUTHORIZATION_PAGE}`
export const PATH_APPROVE_AUTHORIZATION_MODEL = `${PATH_COMMISSION_AUTHORIZATION}/${PATH_APPROVE_AUTHORIZATION_PAGE}`

// AUTHORIZATION SYSTEM PATH AND MODEL
export const PATH_AUTHORIZATION_SYSTEM = 'authorization-system'
export const PATH_AUTHORIZATION_SYSTEM_APPROVED_PAGE = 'approved-system'
export const PATH_AUTHORIZATION_SYSTEM_PENDING_PAGE = 'pending-system'
export const PATH_AUTHORIZATION_SYSTEM_REJECTED_PAGE = 'rejected-system'
export const PATH_AUTHORIZATION_SYSTEM_RESUME_PAGE = 'resume-system'
export const PATH_AUTHORIZATION_SYSTEM_APPROVED_MODEL = `${PATH_AUTHORIZATION_SYSTEM}/${PATH_AUTHORIZATION_SYSTEM_APPROVED_PAGE}`
export const PATH_AUTHORIZATION_SYSTEM_PENDING_MODEL = `${PATH_AUTHORIZATION_SYSTEM}/${PATH_AUTHORIZATION_SYSTEM_PENDING_PAGE}`
export const PATH_AUTHORIZATION_SYSTEM_REJECTED_MODEL = `${PATH_AUTHORIZATION_SYSTEM}/${PATH_AUTHORIZATION_SYSTEM_REJECTED_PAGE}`
export const PATH_AUTHORIZATION_SYSTEM_RESUME_MODEL = `${PATH_AUTHORIZATION_SYSTEM}/${PATH_AUTHORIZATION_SYSTEM_RESUME_PAGE}`


// MULTPLICATOR PATHS AND MODEL
export const PATH_MULTIPLICATOR = 'multiplicator'
export const PATH_MULTIPLICATOR_GOALS_PAGE = 'multiplicator-goals'
export const PATH_MULTIPLICATOR_MODEL = `${PATH_MULTIPLICATOR}/${PATH_MULTIPLICATOR_GOALS_PAGE}`

// MULTIPLICATOR SYSTEM PATHS AND MODEL
export const PATH_MULTIPLICATOR_SYSTEM = 'multiplicator-system'
export const PATH_MULTIPLICATOR_HISTORICALS_PAGE = 'multiplicator-historicals'
export const PATH_MULTIPLICATOR_SYSTEM_HISTORICALS_MODEL = `${PATH_MULTIPLICATOR_SYSTEM}/${PATH_MULTIPLICATOR_HISTORICALS_PAGE}`

// LIQUIDATESKAM PATHS AND MODEL
export const PATH_LIQUIDATEKAM = 'liquidate-kam'
export const PATH_LIQUIDATE_HISTORICALS_PAGE = 'historicals'
export const PATH_LIQUIDATE_HISTORICALS_MODEL = `${PATH_LIQUIDATEKAM}/${PATH_LIQUIDATE_HISTORICALS_PAGE}`

// LIQUIDATES PATHS AND MODEL
export const PATH_LIQUIDATE = 'liquidate'
export const PATH_LIQUIDATE_PAID_PAGE = 'paid'
export const PATH_LIQUIDATE_PENDING_PAGE = 'liquidate-pending'
export const PATH_LIQUIDATE_REJECTED_PAGE = 'liquidate-rejected'
export const PATH_LIQUIDATE_ALL_MODEL = `${PATH_LIQUIDATE}/${PATH_LIQUIDATE_PAID_PAGE}`
export const PATH_LIQUIDATE_PENDING_MODEL = `${PATH_LIQUIDATE}/${PATH_LIQUIDATE_PENDING_PAGE}`
export const PATH_LIQUIDATE_REJECTED_MODEL = `${PATH_LIQUIDATE}/${PATH_LIQUIDATE_REJECTED_PAGE}`

// LIQUIDATE SYSTEM PATHS AND MODEL
export const PATH_LIQUIDATE_SYSTEM = 'liquidate-system'
export const PATH_LIQUIDATE_HISTORICALS_SYSTEM_PAGE = 'historicals-system'
export const PATH_LIQUIDATE_SYSTEM_HISTORICALS_MODEL = `${PATH_LIQUIDATE_SYSTEM}/${PATH_LIQUIDATE_HISTORICALS_SYSTEM_PAGE}`






//routerMap: traduccion de cada elemento del la url que se quiere mostrar en el menu de forma individual
//si tenemos un PATH_X="ejemplo/ejemplo2", en nuestro routerMap metemos una clave para ejemplo y otra para ejemplo2
export const routerNameMap = new Map<string, string>([
  [PATH_HOME, 'PATH_HOME'],

  //* COMMISSION KAM
  [PATH_COMMISSIONS, 'MENU.COMMISSIONS'],
  [PATH_NEXT_PAYMENT_PAGE, 'MENU.NEXT.PAYMENTS'],
  [PATH_PENDING, 'MENU.PENDING'],
  [PATH_APPROVED_PAGE, 'MENU.APPROVED'],
  [PATH_PAID_PAGE, 'MENU.PAID.COMMISSION'],
  [PATH_REJECTED, 'MENU.REJECTED'],
  [PATH_COMMISSIONS_IN_PROCESS_PAGE, 'MENU.INPROCESS'],

  //* COMMMISION SYSTEM
  [PATH_COMMISSIONS_SYSTEM, 'MENU.COMMISSIONS'],
  [PATH_NEXT_PAYMENTS_SYSTEM_PAGE, 'MENU.NEXT.PAYMENTS'],
  [PATH_COMMISSIONS_IN_PROCESS_SYSTEM_PAGE, 'MENU.INPROCESS'],
  [PATH_SYSTEM_PENDING, 'MENU.PENDING'],
  [PATH_APPROVED_SYSTEM_PAGE, 'MENU.APPROVED'],
  [PATH_PAID_SYSTEM_PAGE, 'MENU.PAID.COMMISSION'],
  [PATH_REJECTED_SYSTEM, 'MENU.REJECTED'],

  [PATH_GOALS, 'MENU.MONTH.SALES'],

  //* CONFIG 
  [PATH_CONFIGURATION, 'MENU.CONFIGURATION'],
  [PATH_CONFIGURATION_GLOBAL, 'MENU.PERCENTAGE.COMMISION'],
  [PATH_PERCENTAGETYPECOMMMISION_PAGE, 'MENU.PERCENTAGE.TYPE.COMMISION'],
  [PATH_MULTIPLIER_CONFIG_PAGE, 'MENU.MULTIPLIER.CONFIG'],
  [PATH_CONFIG_LIQUIDATE_PAGE, 'MENU.CONFIG.LIQUIDATE'],
  [PATH_AUTHORIZERS_PAGE, 'MENU.AUTHORIZERS.CONFIG'],
  [PATH_AUTHORIZING_CHAIN_PAGE, 'MENU.AUTHORIZING.CHAIN'],
  [PATH_CONFIG_USERS_RRHH_PAGE, 'MENU.USERS.RRHH'],

  //* CONFIG-KAM
  [PATH_CONFIGURATION_KAM, 'MENU.CONFIGURATION'],
  [PATH_PERCENTANGE_COMMISSION_KAM_CONFIGURATION_PAGE, 'MENU.PERCENTAGE.COMMISION'],
  [PATH_PERCENTAGETYPECOMMMISION_KAM_PAGE, 'MENU.PERCENTAGE.TYPE.COMMISION'],
  [PATH_MULTIPLIER_CONFIG_KAM_PAGE, 'MENU.MULTIPLIER.CONFIG'],

  //* AUTHORIZATION
  [PATH_COMMISSION_AUTHORIZATION, 'MENU.AUTHORIZATION'],
  [PATH_PENDING_AUTHORIZATION_PAGE, 'MENU.PENDING'],
  [PATH_REJECTED_AUTHORIZATION_PAGE, 'MENU.REJECTED.AUTHORIZATION'],
  [PATH_RESUME_AUTHORIZATION_PAGE, 'MENU.RESUME.AUTHORIZATION'],
  [PATH_APPROVE_AUTHORIZATION_PAGE, 'MENU.APPROVED.AUHTORIZATION'],

  //* AUTHORIZATION SYS
  [PATH_AUTHORIZATION_SYSTEM, 'MENU.AUTHORIZATION.SYS'],
  [PATH_AUTHORIZATION_SYSTEM_APPROVED_PAGE, 'MENU.APPROVED.AUHTORIZATION'],
  [PATH_AUTHORIZATION_SYSTEM_PENDING_PAGE, 'MENU.PENDING'],
  [PATH_AUTHORIZATION_SYSTEM_REJECTED_PAGE, 'MENU.REJECTED'],
  [PATH_AUTHORIZATION_SYSTEM_RESUME_PAGE, 'MENU.RESUME.AUTHORIZATION'],


  [PATH_MULTIPLICATOR, 'MENU.MULTIPLICATOR'],
  [PATH_MULTIPLICATOR_GOALS_PAGE, 'MENU.MULTIPLICATOR.HISTORIC'],
  [PATH_LIQUIDATEKAM, 'MENU.LIQUIDATE'],
  [PATH_LIQUIDATE_HISTORICALS_PAGE, 'MENU.HISTORICALS'],
  [PATH_LIQUIDATE, 'MENU.LIQUIDATE'],
  [PATH_LIQUIDATE_PAID_PAGE, 'MENU.LIQUIDATE.PAID'],
  [PATH_LIQUIDATE_PENDING_PAGE, 'MENU.LIQUIDATE.PENDING'],
  [PATH_LIQUIDATE_REJECTED_PAGE, 'MENU.LIQUIDATE.REJECTED'],
  [PATH_LIQUIDATE_SYSTEM, 'MENU.LIQUIDATE'],
  [PATH_LIQUIDATE_HISTORICALS_SYSTEM_PAGE, 'MENU.HISTORICALS'],
  [PATH_MULTIPLICATOR_SYSTEM, 'MENU.MULTIPLICATOR'],
  [PATH_MULTIPLICATOR_HISTORICALS_PAGE, 'MENU.HISTORICALS'],
])

export const routerLinkMap = new Map<string, string>([
  [PATH_HOME, `/${PATH_HOME}`],
  // [PATH_COMMISSIONS_PAGE, `/${PATH_COMMISSIONS_PAGE}`],
  // [PATH_PENDING, `/${PATH_PENDING}`],
  // [PATH_COMISSIONS_PENDING_MODEL, `/${PATH_COMISSIONS_PENDING_MODEL}`],
])

export const routePermissionMap = new Map<string, Authority[]>([

  //* COMMISION KAM
  [PATH_COMMISSIONS, [Authority.commissionKamModuleRead]],
  [PATH_PENDING, [Authority.commissionKamModuleRead]],
  [PATH_APPROVED_PAGE, [Authority.commissionKamModuleRead]],
  [PATH_REJECTED, [Authority.commissionKamModuleRead]],
  [PATH_NEXT_PAYMENT_PAGE, [Authority.commissionKamModuleRead]],
  [PATH_PAID_PAGE, [Authority.commissionKamModuleRead]],
  [PATH_COMMISSIONS_IN_PROCESS_PAGE, [Authority.commissionKamModuleRead]],

  //* COMMISION SYSTEM
  [PATH_COMMISSIONS_SYSTEM, [Authority.commissionCmSystemModuleRead]],
  [PATH_SYSTEM_PENDING, [Authority.commissionCmSystemModuleRead]],
  [PATH_APPROVED_SYSTEM_PAGE, [Authority.commissionCmSystemModuleRead]],
  [PATH_PAID_SYSTEM_PAGE, [Authority.commissionCmSystemModuleRead]],
  [PATH_NEXT_PAYMENTS_SYSTEM_PAGE, [Authority.commissionCmSystemModuleRead]],
  [PATH_REJECTED_SYSTEM, [Authority.commissionCmSystemModuleRead]],
  [PATH_COMMISSIONS_IN_PROCESS_SYSTEM_PAGE, [Authority.commissionCmSystemModuleRead]],


  //* GOALS
  [PATH_GOALS, [Authority.commissionGoalsModuleRead]],


  //* CONFIG
  [PATH_CONFIGURATION, [Authority.commissionConfigModuleRead]],
  [PATH_AUTHORIZING_CHAIN_PAGE, [Authority.commissionConfigModuleRead]],
  [PATH_CONFIGURATION_GLOBAL, [Authority.commissionConfigModuleRead]],
  [PATH_PERCENTAGETYPECOMMMISION_PAGE, [Authority.commissionConfigModuleRead]],
  [PATH_MULTIPLIER_CONFIG_PAGE, [Authority.commissionConfigModuleRead]],
  [PATH_AUTHORIZERS_PAGE, [Authority.commissionConfigModuleRead]],
  [PATH_CONFIG_LIQUIDATE_PAGE, [Authority.commissionConfigModuleRead]],
  [PATH_CONFIG_USERS_RRHH_PAGE, [Authority.commissionConfigModuleRead]],

  //* CONFIG-KAM
  [PATH_CONFIGURATION_KAM, [Authority.commissionModuleRead]],
  [PATH_PERCENTANGE_COMMISSION_KAM_CONFIGURATION_PAGE, [Authority.commissionModuleRead]],
  [PATH_PERCENTAGETYPECOMMMISION_KAM_PAGE, [Authority.commissionModuleRead]],
  [PATH_MULTIPLIER_CONFIG_KAM_PAGE, [Authority.commissionModuleRead]],


  //* AUTHORIZATION
  [PATH_PENDING_AUTHORIZATION_PAGE, [Authority.commissionAuthorizationModuleRead]],
  [PATH_REJECTED_AUTHORIZATION_PAGE, [Authority.commissionAuthorizationModuleRead]],
  [PATH_RESUME_AUTHORIZATION_PAGE, [Authority.commissionAuthorizationModuleRead]],
  [PATH_APPROVE_AUTHORIZATION_PAGE, [Authority.commissionAuthorizationModuleRead]],


  //* AUTHORIZATION SYS
  [PATH_AUTHORIZATION_SYSTEM_APPROVED_PAGE, [Authority.commissionAuthorizationSysModuleRead]],
  [PATH_AUTHORIZATION_SYSTEM_PENDING_PAGE, [Authority.commissionAuthorizationSysModuleRead]],
  [PATH_AUTHORIZATION_SYSTEM_REJECTED_PAGE, [Authority.commissionAuthorizationSysModuleRead]],
  [PATH_AUTHORIZATION_SYSTEM_RESUME_PAGE, [Authority.commissionAuthorizationSysModuleRead]],




  [PATH_MULTIPLICATOR, [Authority.commissionMultiplicatorModuleRead]],
  [PATH_MULTIPLICATOR_GOALS_PAGE, [Authority.commissionMultiplicatorModuleRead]],
  [PATH_LIQUIDATEKAM, [Authority.commissionLiquidateModuleRead]],
  [PATH_LIQUIDATE_HISTORICALS_PAGE, [Authority.commissionLiquidateModuleRead]],
  [PATH_LIQUIDATE, [Authority.commissionLiquidateRRHHModuleRead]],
  [PATH_LIQUIDATE_PAID_PAGE, [Authority.commissionLiquidateRRHHModuleRead]],
  [PATH_LIQUIDATE_PENDING_PAGE, [Authority.commissionLiquidateRRHHModuleRead]],
  [PATH_LIQUIDATE_REJECTED_PAGE, [Authority.commissionLiquidateRRHHModuleRead]],
  [PATH_LIQUIDATE_SYSTEM, [Authority.commissionLiquidateSysModuleRead]],
  [PATH_LIQUIDATE_HISTORICALS_SYSTEM_PAGE, [Authority.commissionLiquidateSysModuleRead]],
  [PATH_MULTIPLICATOR_SYSTEM, [Authority.commissionMultiplierSysModuleRead]],
  [PATH_MULTIPLICATOR_HISTORICALS_PAGE, [Authority.commissionMultiplierSysModuleRead]],
  [PATH_AUTHORIZATION_SYSTEM, [Authority.commissionAuthorizationSysModuleRead]],

])

const PrivateRoutes: FC = () => {
  const intl = useIntl()
  const location = useLocation()

  return (
    <Routes>
      <Route
        element={
          <MasterLayout
            asideMobile
            routerLocation={location}
            asideDefault={<AsideDefault />}
            routerNameMap={routerNameMap}
          />
        }
      >
        <Route path={PATH_HOME} element={<WelcomePage appName={intl.formatMessage({ id: 'MENU.TITLE.COMMISSIONS' })} />} />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_COMISSIONS_IN_PROCESS_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_COMMISSIONS)!}>
              <InProcessPage />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_COMISSIONS_NEXT_PAYMENT_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_NEXT_PAYMENT_PAGE)!}>
              <NextPaymentPage />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_COMISSIONS_PENDING_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_PENDING)!}>
              <PendingPage />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_COMISSIONS_PAID_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_PAID_PAGE)!}>
              <PaidCommissionKamPage />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_COMISSIONS_SETTLED_ACCOUNTS_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_APPROVED_PAGE)!}>
              <ApprovedCommissionPage />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_COMISSIONS_REJECTED_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_REJECTED)!}>
              <RejectePage />
            </AuthRoute>
          }
        />

        {/* //! COMMISION SYSTEM */}

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_COMISSIONS_NEXT_PAYMENTS_SYSTEM_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_NEXT_PAYMENTS_SYSTEM_PAGE)!}>
              <NextPaymentCommisionSystemPage />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_COMISSIONS_MODEL_IN_PROCESS_SYSTEM}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_COMMISSIONS_IN_PROCESS_SYSTEM_PAGE)!}>
              <InProcessPageCommissionSystem />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_COMISSIONS_PENDING_SYSTEM_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_SYSTEM_PENDING)!}>
              <PendingCommissionSystemPage />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_COMISSIONS_APPROVED_SYSTEM_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_APPROVED_SYSTEM_PAGE)!}>
              <ApprovedCommissionSystemPage />
            </AuthRoute>
          }
        />
        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_COMISSIONS_PAID_SYSTEM_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_PAID_SYSTEM_PAGE)!}>
              <PaidCommissionSystemPage />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_COMISSIONS_REJECTED_SYSTEM_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_REJECTED_SYSTEM)!}>
              <RejectedCommissionSystemPage />
            </AuthRoute>
          }
        />


        {/* //! GOALS */}
        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_GOALS}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_GOALS)!}>
              <GoalsPage />
            </AuthRoute>
          }
        />



        {/* //! CONFIGURATION */}
        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_CONFIGURATION_GLOBAL_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_CONFIGURATION)!}>
              <GlobalPage />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_PERCENTAGETYPECOMMISION_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_PERCENTAGETYPECOMMMISION_PAGE)!}>
              <PercentageTypeCommssion />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_MULTIPLIER_CONFIG_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_MULTIPLIER_CONFIG_PAGE)!}>
              <MultiplierConfigPage />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_AUTHORIZERS_CONFIG_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_AUTHORIZERS_PAGE)!}>
              <AuthorizersPage />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_AUTHORIZING_CHAIN_CONFIG_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_AUTHORIZING_CHAIN_PAGE)!}>
              <AuthorizingChainPage />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_CONFIG_LIQUIDATE_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_CONFIG_LIQUIDATE_PAGE)!}>
              <ConfigLiquidatePage />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_CONFIG_USERS_RRHH_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_CONFIG_USERS_RRHH_PAGE)!}>
              <UsersRRHHConfigPage />
            </AuthRoute>
          }
        />

        {/* //! CONFIG-KAM */}
        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_PERCENTAGECOMMISION_KAM_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_PERCENTAGETYPECOMMMISION_KAM_PAGE)!}>
              <PercentangeCommissionKamPage />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_PERCENTAGETYPECOMMISION_KAM_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_PERCENTANGE_COMMISSION_KAM_CONFIGURATION_PAGE)!}>
              <PercentageTypeCommssionKam />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_MULTIPLIER_CONFIG_KAM_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_MULTIPLIER_CONFIG_KAM_PAGE)!}>
              <MultiplierConfigKamPage />
            </AuthRoute>
          }
        />


        {/* //! AUTHORIZATION */}
        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_PENDING_AUTHORIZATION_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_PENDING_AUTHORIZATION_PAGE)!}>
              <PendingAuthorizationPage />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_RESUME_AUTHORIZATION_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_RESUME_AUTHORIZATION_PAGE)!}>
              <ResumeAuthorizationPage />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_REJECTED_AUTHORIZATION_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_REJECTED_AUTHORIZATION_PAGE)!}>
              <RejectedAuthorizationPage />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_APPROVE_AUTHORIZATION_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_APPROVE_AUTHORIZATION_PAGE)!}>
              <AuthorizationManagerApprovedPage />
            </AuthRoute>
          }
        />

        {/* //! AUTHORIZATION SYSTEM */}
        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_AUTHORIZATION_SYSTEM_APPROVED_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_AUTHORIZATION_SYSTEM_APPROVED_PAGE)!}>
              <AuthorizacionApprovedPage />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_AUTHORIZATION_SYSTEM_PENDING_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_AUTHORIZATION_SYSTEM_APPROVED_PAGE)!}>
              <PendingAuthorizationSystemPage />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_AUTHORIZATION_SYSTEM_REJECTED_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_AUTHORIZATION_SYSTEM_REJECTED_PAGE)!}>
              <RejectedAuthorizationSystemPage />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_AUTHORIZATION_SYSTEM_RESUME_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_AUTHORIZATION_SYSTEM_RESUME_PAGE)!}>
              <ResumeAuthorizationSysPage />
            </AuthRoute>
          }
        />

        {/* //! MULTIPLICATOR */}
        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_MULTIPLICATOR_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_MULTIPLICATOR_GOALS_PAGE)!}>
              <MultiplicatorGoalsPage />
            </AuthRoute>
          }
        />

        {/* //! MULTIPLICATOR SYSTEM */}
        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_MULTIPLICATOR_SYSTEM_HISTORICALS_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_MULTIPLICATOR_HISTORICALS_PAGE)!}>
              <MultiplicatorSystemPage />
            </AuthRoute>
          }
        />

        {/* //! LIQUIDATE-KAM */}
        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_LIQUIDATE_HISTORICALS_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_LIQUIDATE_HISTORICALS_PAGE)!}>
              <LiquidateHistoricalsPage />
            </AuthRoute>
          }
        />

        {/* //! LIQUIDATE-RRHH */}
        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_LIQUIDATE_ALL_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_LIQUIDATE_PAID_PAGE)!}>
              <LiquidatePaidPage />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_LIQUIDATE_PENDING_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_LIQUIDATE_PENDING_PAGE)!}>
              <LiquidatePendingPage />
            </AuthRoute>
          }
        />

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_LIQUIDATE_REJECTED_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_LIQUIDATE_REJECTED_PAGE)!}>
              <LiquidateRejectedPage />
            </AuthRoute>
          }
        />

        {/* //! LIQUIDATE SYSTEM */}

        <Route
          // the path you can add a model to show at the breadcrumb
          path={PATH_LIQUIDATE_SYSTEM_HISTORICALS_MODEL}
          element={
            <AuthRoute authorizedRoles={routePermissionMap.get(PATH_LIQUIDATE_HISTORICALS_SYSTEM_PAGE)!}>
              <HistoricalLiquidateSystemPage />
            </AuthRoute>
          }
        />

        {/* Default route path */}
        <Route path={'*'} element={<Navigate to={PATH_HOME} replace />} />
      </Route>
    </Routes>

  )
}

export { PrivateRoutes }
