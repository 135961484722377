import styled from "styled-components"
interface Props {
  textToShow: string | null
  redirectLink: string | null
}

export const RedirectLib = ({ textToShow, redirectLink }: Props) => {

  if (textToShow === null) return null
  if (redirectLink === null) return null
  return (
    <AStyled>
      <a href={redirectLink} target="_blank">{textToShow}</a>
    </AStyled>
  )
}

const AStyled = styled.div`
  
  text-decoration: underline;
`
