import axios from "axios"
import { useQuery, useMutation } from "react-query"
import { fetchGET2, fetchPOST2, fetchPOST2Blob } from "utils/https/https"


type RequestBody = {
  username?: string
  serviceClient?: string
}


export const UseQueryResumeAuthorizationCommissions = (data: any) => {

  const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
  const paymentYear = data?.paymentYear && data?.paymentYear.length > 0 ? data?.paymentYear[0] : null
  const creationDate = data?.creationDate && data?.creationDate.length > 0 ? data?.creationDate[0] : null
  const cutoffDate = data?.cutoffDate && data?.cutoffDate.length > 0 ? data?.cutoffDate[0] : null
  const paymentState = data?.state && data?.state.length > 0 ? data?.state[0] : null
  const paymentDate = data?.paymentDate && data?.paymentDate.length > 0 ? data?.paymentDate[0] : null
  const userPayment = data?.userPayment && data?.userPayment.length > 0 ? data?.userPayment[0] : null
  // const state = data?.state && data?.state.length > 0 ? data?.state[0] : null
  const company = data?.company && data?.company.length > 0 ? data?.company[0] : null



  const username = Array.isArray(data?.username) ? data.username : [];

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  return useQuery(['UseQueryResumeAuthorizationCommissions', username, paymentMonth, paymentYear, creationDate, cutoffDate, paymentState, paymentDate, company, userPayment, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();


      if (paymentMonth) {
        serchParms.append('paymentMonth', paymentMonth)
      }

      if (paymentYear) {
        serchParms.append('paymentYear', paymentYear)
      }

      if (creationDate) {
        serchParms.append('creationDate', creationDate)
      }

      if (cutoffDate) {
        serchParms.append('cutoffDate', cutoffDate)
      }

      if (paymentState) {
        serchParms.append('paymentState', paymentState)
      }

      if (paymentDate) {
        serchParms.append('paymentDate', paymentDate)
      }

      if (userPayment) {
        serchParms.append('userPayment', userPayment)
      }

      if (company) {
        serchParms.append('company', company)
      }

      // if (state) {
      //   serchParms.append('state', state)
      // }

      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('rowsPerPage', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }

      const requestBody: RequestBody = {};

      if (username.length > 0) {
        requestBody.username = username.join(',');
      }




      const response = await fetchPOST2(`Authorizator/ListResumeAuthorization?&${serchParms.toString()}`, requestBody)
      return response
    } catch (error) {
      throw error
    }
  })
}




export const UseQueryPendingAuthorizationCommissions = (data: any) => {

  const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
  const type = data?.type && data?.type.length > 0 ? data?.type[0] : null
  const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
  const serviceId = data?.idService ? data?.idService : null
  const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
  const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
  const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null


  const username = Array.isArray(data?.username) ? data.username : [];
  const serviceClient = Array.isArray(data?.serviceClient) ? data.serviceClient : [];

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  return useQuery(['UseQueryPendingAuthorizationCommissions', username, serviceId, type, serviceName, serviceClient, idServiceCRM, saleMonth, paymentMonth, saleYear, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();


      if (serviceName) {
        serchParms.append('serviceName', serviceName)
      }

      if (idServiceCRM) {
        serchParms.append('idServiceCRM', idServiceCRM)
      }

      if (serviceId) {
        serchParms.append('IdService', serviceId)
      }

      if (type) {
        serchParms.append('type', type)
      }

      if (saleMonth) {
        serchParms.append('saleMonth', saleMonth)
      }

      if (paymentMonth) {
        serchParms.append('paymentMonth', paymentMonth)
      }

      if (saleYear) {
        serchParms.append('saleYear', saleYear)
      }


      // PAGINATION
      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('rowsPerPage', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }


      const requestBody: RequestBody = {};

      if (username.length > 0) {
        requestBody.username = username.join(',');
      }
      if (serviceClient.length > 0) {
        requestBody.serviceClient = serviceClient.join(',');
      }

      const response = await fetchPOST2(`Authorizator/ListCommissions?&authorizationState=pending&commissionState=inProcess&${serchParms.toString()}`, requestBody)
      return response
    } catch (error) {
      throw error
    }
  })
}

export const UseQueryRejectedAuthorizationCommissions = (data: any) => {

  const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
  const type = data?.type && data?.type.length > 0 ? data?.type[0] : null
  const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
  const serviceId = data?.idService ? data?.idService : null
  const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
  const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
  const authorizationReason = data?.authorizationReason ? data?.authorizationReason : null
  const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null


  const username = Array.isArray(data?.username) ? data.username : [];
  const serviceClient = Array.isArray(data?.serviceClient) ? data.serviceClient : [];

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  return useQuery(['UseQueryRejectedAuthorizationCommissions', paymentMonth, type, authorizationReason, saleMonth, username, idServiceCRM, serviceClient, serviceName, serviceId, saleYear, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (type) {
        serchParms.append('type', type)
      }



      if (authorizationReason) {
        serchParms.append('authorizationReason', authorizationReason)
      }

      if (serviceName) {
        serchParms.append('serviceName', serviceName)
      }

      if (idServiceCRM) {
        serchParms.append('idServiceCRM', idServiceCRM)
      }

      if (saleMonth) {
        serchParms.append('saleMonth', saleMonth)
      }


      if (serviceId) {
        serchParms.append('IdService', serviceId)
      }

      if (saleYear) {
        serchParms.append('saleYear', saleYear)
      }


      // PAGINATION
      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('rowsPerPage', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }

      const requestBody: RequestBody = {};

      if (username.length > 0) {
        requestBody.username = username.join(',');
      }
      if (serviceClient.length > 0) {
        requestBody.serviceClient = serviceClient.join(',');
      }

      const response = await fetchPOST2(`Authorizator/ListCommissions?&authorizationState=rejected&${serchParms.toString()}`, requestBody)
      return response
    } catch (error) {
      throw error
    }
  })
}

const UseApproveCommissionAuthorization = async (data: any) => {

  try {
    const response = await fetchPOST2(`Authorizator/AuthorizeCommissions`, data)
    return response
  } catch (error) {
    throw error
  }

}

export const UsePostApproveCommissionAuthorization = (callbackSuccess: any) => {
  return useMutation(UseApproveCommissionAuthorization, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};


// to send the request to reject the commission Authorizator/AuthorizeCommissions

const UseRejectCommissionAuthorization = async (data: any) => {

  try {
    const response = await fetchPOST2(`Authorizator/AuthorizeCommissions`, data)
    return response
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const customErr = {
        errorCode: error.response.data.code,
        message: error.response.data.message,
        reason: error.response.data.reason,
      }

      throw customErr
    }
    throw error
  }

}

export const UsePostRejectCommissionAuthorization = (callbackSuccess: any) => {
  return useMutation(UseRejectCommissionAuthorization, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};



const downloadFileAuthorizatorPending = async (data: any) => {

  const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
  const type = data?.type && data?.type.length > 0 ? data?.type[0] : null
  const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
  const serviceId = data?.idService ? data?.idService : null
  const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
  const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
  const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null

  const username = Array.isArray(data?.username) ? data.username : [];
  const serviceClient = Array.isArray(data?.serviceClient) ? data.serviceClient : [];


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  try {

    const serchParms = new URLSearchParams();

    if (username) {
      serchParms.append('username', username)
    }

    if (serviceClient) {
      serchParms.append('serviceClient', serviceClient)
    }

    if (serviceName) {
      serchParms.append('serviceName', serviceName)
    }

    if (idServiceCRM) {
      serchParms.append('idServiceCRM', idServiceCRM)
    }

    if (serviceId) {
      serchParms.append('IdService', serviceId)
    }

    if (type) {
      serchParms.append('type', type)
    }

    if (saleMonth) {
      serchParms.append('saleMonth', saleMonth)
    }

    if (paymentMonth) {
      serchParms.append('paymentMonth', paymentMonth)
    }

    if (saleYear) {
      serchParms.append('saleYear', saleYear)
    }


    //! PAGINATION
    if (pageNumber) {
      serchParms.append('pageNumber', pageNumber)
    }

    if (pageSize) {
      serchParms.append('rowsPerPage', pageSize)
    }

    if (sortOrder) {
      serchParms.append('sortOrder', sortOrder)
    }

    if (sortField) {
      serchParms.append('sortField', sortField)
    }

    const requestBody: RequestBody = {};

    if (username.length > 0) {
      requestBody.username = username.join(',');
    }
    if (serviceClient.length > 0) {
      requestBody.serviceClient = serviceClient.join(',');
    }


    const response = await fetchPOST2Blob(`Authorizator/DownloadCommissions?authorizationState=pending&commissionState=inProcess&${serchParms.toString()}`, requestBody)
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'authorizatorPendingDocument.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);


    return response


  } catch (error) {
    throw error
  }
}


export const UseDownloadFileAuthorizatorPending = (callbackSuccess: any) => {
  return useMutation(downloadFileAuthorizatorPending, {
    onSuccess(data) {
      callbackSuccess(data)
    },
  })
}


const downloadFileAuthorizatorRejected = async (data: any) => {

  const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
  const type = data?.type && data?.type.length > 0 ? data?.type[0] : null
  const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
  const serviceId = data?.idService ? data?.idService : null
  const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
  const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
  const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null

  const username = Array.isArray(data?.username) ? data.username : [];
  const serviceClient = Array.isArray(data?.serviceClient) ? data.serviceClient : [];


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  try {

    const serchParms = new URLSearchParams();

    if (username) {
      serchParms.append('username', username)
    }

    if (serviceClient) {
      serchParms.append('serviceClient', serviceClient)
    }

    if (serviceName) {
      serchParms.append('serviceName', serviceName)
    }

    if (idServiceCRM) {
      serchParms.append('idServiceCRM', idServiceCRM)
    }

    if (serviceId) {
      serchParms.append('IdService', serviceId)
    }

    if (type) {
      serchParms.append('type', type)
    }

    if (saleMonth) {
      serchParms.append('saleMonth', saleMonth)
    }

    if (paymentMonth) {
      serchParms.append('paymentMonth', paymentMonth)
    }

    if (saleYear) {
      serchParms.append('saleYear', saleYear)
    }


    //! PAGINATION
    if (pageNumber) {
      serchParms.append('pageNumber', pageNumber)
    }

    if (pageSize) {
      serchParms.append('rowsPerPage', pageSize)
    }

    if (sortOrder) {
      serchParms.append('sortOrder', sortOrder)
    }

    if (sortField) {
      serchParms.append('sortField', sortField)
    }

    const requestBody: RequestBody = {};

    if (username.length > 0) {
      requestBody.username = username.join(',');
    }
    if (serviceClient.length > 0) {
      requestBody.serviceClient = serviceClient.join(',');
    }


    const response = await fetchPOST2(`Authorizator/DownloadCommissions?&authorizationState=rejected&${serchParms.toString()}`, requestBody)
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'authorizatorRejectedDocument.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);


    return response


  } catch (error) {
    throw error
  }
}


export const UseDownloadFileAuthorizatoRejected = (callbackSuccess: any) => {
  return useMutation(downloadFileAuthorizatorRejected, {
    onSuccess(data) {
      callbackSuccess(data)
    },
  })
}




const getTemplatePendingApproval = async () => {

  try {
    const response = await fetchGET2(`Commission/GetCommissionTemplate`, { responseType: 'blob' })

    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'templatePendingApproval.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    return response
  } catch (error) {
    throw error
  }

}

export const useMutationGetTemplatePendingApproval = (callbackSuccess: any) => {
  return useMutation(getTemplatePendingApproval, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};


const usePostBulkFile = async (data: any) => {

  try {
    const formdata = new FormData()
    formdata.append('file', data)
    const response = await fetchPOST2(`Commission/UploadCommissionTemplate`, formdata)
    return response

  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const customError = {
        errorCode: error.response.data.code,
        message: error.response.data.message,
      };
      throw customError
    }

  }

}

export const usePostBulkFilePendingApproval = (callbackSuccess: any) => {
  return useMutation(usePostBulkFile, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};



export const UseQueryApproveAuthorizationManagerCommissions = (data: any) => {



  const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
  const type = data?.type && data?.type.length > 0 ? data?.type[0] : null
  const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
  const paymentId = data?.paymentId ? data?.paymentId : null
  const authorizatorName = data?.authorizatorName ? data?.authorizatorName : null
  const serviceId = data?.idService ? data?.idService : null
  const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
  const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
  const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null


  const username = Array.isArray(data?.username) ? data.username : [];
  const serviceClient = Array.isArray(data?.serviceClient) ? data.serviceClient : [];


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  return useQuery(['UseQueryApproveAuthorizationManagerCommissions', username, authorizatorName, paymentId, serviceId, serviceClient, serviceName, idServiceCRM, type, saleMonth, paymentMonth, saleYear, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();



      if (paymentId) {
        serchParms.append('paymentId', paymentId)
      }



      if (serviceName) {
        serchParms.append('serviceName', serviceName)
      }

      if (idServiceCRM) {
        serchParms.append('idServiceCRM', idServiceCRM)
      }

      if (authorizatorName) {
        serchParms.append('authorizatorName', authorizatorName)
      }

      if (serviceId) {
        serchParms.append('IdService', serviceId)
      }

      if (type) {
        serchParms.append('type', type)
      }

      if (saleMonth) {
        serchParms.append('saleMonth', saleMonth)
      }

      if (paymentMonth) {
        serchParms.append('paymentMonth', paymentMonth)
      }

      if (saleYear) {
        serchParms.append('saleYear', saleYear)
      }


      // PAGINATION
      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('rowsPerPage', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }

      const requestBody: RequestBody = {};

      if (username.length > 0) {
        requestBody.username = username.join(',');
      }
      if (serviceClient.length > 0) {
        requestBody.serviceClient = serviceClient.join(',');
      }

      const response = await fetchPOST2(`Authorizator/ListCommissions?&authorizationState=approved&${serchParms.toString()}`, requestBody)
      return response
    } catch (error) {
      throw error
    }
  })
}



const downloadFileAuthorizatorApproved = async (data: any) => {


  const authorizatorName = data?.authorizatorName ? data?.authorizatorName : null
  const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
  const type = data?.type && data?.type.length > 0 ? data?.type[0] : null
  const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
  const paymentId = data?.paymentId ? data?.paymentId : null
  const serviceId = data?.idService ? data?.idService : null
  const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
  const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
  const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null

  const username = Array.isArray(data?.username) ? data.username : [];
  const serviceClient = Array.isArray(data?.serviceClient) ? data.serviceClient : [];


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  try {

    const serchParms = new URLSearchParams();



    if (paymentId) {
      serchParms.append('paymentId', paymentId)
    }

    if (authorizatorName) {
      serchParms.append('authorizatorName', authorizatorName)
    }


    if (serviceName) {
      serchParms.append('serviceName', serviceName)
    }

    if (idServiceCRM) {
      serchParms.append('idServiceCRM', idServiceCRM)
    }

    if (serviceId) {
      serchParms.append('IdService', serviceId)
    }

    if (type) {
      serchParms.append('type', type)
    }

    if (saleMonth) {
      serchParms.append('saleMonth', saleMonth)
    }

    if (paymentMonth) {
      serchParms.append('paymentMonth', paymentMonth)
    }

    if (saleYear) {
      serchParms.append('saleYear', saleYear)
    }


    //! PAGINATION
    if (pageNumber) {
      serchParms.append('pageNumber', pageNumber)
    }

    if (pageSize) {
      serchParms.append('rowsPerPage', pageSize)
    }

    if (sortOrder) {
      serchParms.append('sortOrder', sortOrder)
    }

    if (sortField) {
      serchParms.append('sortField', sortField)
    }


    const requestBody: RequestBody = {};

    if (username.length > 0) {
      requestBody.username = username.join(',');
    }
    if (serviceClient.length > 0) {
      requestBody.serviceClient = serviceClient.join(',');
    }

    const response = await fetchPOST2Blob(`Authorizator/DownloadCommissions?&authorizationState=approved&${serchParms.toString()}`, requestBody)
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'authorizatorApprovedDocument.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);


    return response


  } catch (error) {
    throw error
  }
}


export const UseDownloadFileAuthorizatorApproved = (callbackSuccess: any) => {
  return useMutation(downloadFileAuthorizatorApproved, {
    onSuccess(data) {
      callbackSuccess(data)
    },
  })
}


export const UseGetKams = (data: any) => {
  const username = data && data.username ? data.username : null


  return useQuery(['UseGetKams', username], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (username) {
        serchParms.append('username', username)
      }

      const response = await fetchGET2(`User/ListUsers?&${serchParms.toString()}`)
      return response

    } catch (error) {
      throw error
    }

  })
}

export const UseGetClients = (data: any) => {

  const name = data && data.name ? data.name : null


  return useQuery(['UseGetClients', name], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (name) {
        serchParms.append('name', name)
      }

      const response = await fetchGET2(`Client/ListClients?&${serchParms.toString()}`)
      return response

    } catch (error) {
      throw error
    }

  })
}
