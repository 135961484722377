import axios from "axios"
import { useMutation, useQuery } from "react-query"
import { fetchGET, fetchPOST2 } from "utils/https/https"

export const UseQueryHistoricalsLiquidateSystem = (data: any) => {

  const creationDate = data?.creationDate ? data.creationDate : null
  const paymentUser = data?.paymentUser ? data.paymentUser : null
  const company = data?.company ? data.company : null
  const cutoffDate = data?.cutoffDate ? data?.cutoffDate : null
  const impulseAmount = data?.impulseAmount ? data.impulseAmount : null
  const name = data?.name ? data.name : null
  const paymentAmount = data?.paymentAmount ? data.paymentAmount : null
  const paymentId = data?.paymentId && data?.paymentId[0]
  const paymentMonth = data?.paymentMonth && data?.paymentMonth[0]
  const paymentState = data?.paymentState && data?.paymentState.length > 0 && data?.paymentState[0]
  const paymentYear = data?.paymentYear && data?.paymentYear[0]
  const username = data?.username ?? null

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder && data.sortOrder
  const sortField = data?.sortField && data.sortField


  return useQuery(['UseQueryHistoricalsLiquidateSystem', username, paymentUser, company, impulseAmount, creationDate, paymentId, cutoffDate, paymentState, paymentYear, paymentMonth, name, paymentAmount, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (username) {
        serchParms.append('username', username)
      }

      if (company) {
        serchParms.append('company', company)
      }

      if (paymentUser) {
        serchParms.append('paymentUser', paymentUser)
      }

      if (paymentId) {
        serchParms.append('paymentId', paymentId)
      }

      if (impulseAmount) {
        serchParms.append('impulseAmount', impulseAmount)
      }

      if (creationDate) {
        serchParms.append('creationDate', creationDate)
      }

      if (cutoffDate) {
        serchParms.append('cutoffDate', cutoffDate)
      }

      if (paymentState) {
        serchParms.append('paymentState', paymentState)
      }

      if (name) {
        serchParms.append('name', name)
      }

      if (paymentAmount) {
        serchParms.append('paymentAmount', paymentAmount)
      }

      if (paymentYear) {
        serchParms.append('paymentYear', paymentYear)
      }

      if (paymentMonth) {
        serchParms.append('paymentMonth', paymentMonth)
      }


      // Pagination
      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('rowsPerPage', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }

      const response = await fetchGET(`Payment/ListPayments?&${serchParms.toString()}`)
      return response
    } catch (error) {
      throw error
    }
  })
}


const getFileLiquidateHistoricalsSys = async (data: any) => {

  const username = data?.username ? data.username : null
  const paymentYear = data?.paymentYear && data?.paymentYear[0]
  const paymentMonth = data?.paymentMonth && data?.paymentMonth[0]
  const paymentId = data?.paymentId && data?.paymentId[0]
  const impulseAmount = data?.impulseAmount ? data?.impulseAmount : null
  const creationDate = data?.creationDate ? data.creationDate : null
  const cutoffDate = data?.cutoffDate ? data.cutoffDate : null
  const paymentState = data?.state && data?.state.length > 0 && data?.state[0]
  const name = data?.name ? data.name : null
  const paymentAmount = data?.paymentAmount ? data?.paymentAmount : null

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder && data.sortOrder
  const sortField = data?.sortField && data.sortField


  try {
    const serchParms = new URLSearchParams();

    if (username) {
      serchParms.append('username', username)
    }

    if (paymentId) {
      serchParms.append('paymentId', paymentId)
    }

    if (impulseAmount) {
      serchParms.append('impulseAmount', impulseAmount)
    }

    if (creationDate) {
      serchParms.append('creationDate', creationDate)
    }

    if (cutoffDate) {
      serchParms.append('cutoffDate', cutoffDate)
    }

    if (paymentState) {
      serchParms.append('paymentState', paymentState)
    }

    if (name) {
      serchParms.append('name', name)
    }

    if (paymentAmount) {
      serchParms.append('paymentAmount', paymentAmount)
    }

    if (paymentYear) {
      serchParms.append('paymentYear', paymentYear)
    }

    if (paymentMonth) {
      serchParms.append('paymentMonth', paymentMonth)
    }


    // Pagination
    if (pageNumber) {
      serchParms.append('pageNumber', pageNumber)
    }

    if (pageSize) {
      serchParms.append('rowsPerPage', pageSize)
    }

    if (sortOrder) {
      serchParms.append('sortOrder', sortOrder)
    }

    if (sortField) {
      serchParms.append('sortField', sortField)
    }

    const response = await fetchGET(`Payment/DownloadPayments?&${serchParms.toString()}`, { responseType: 'blob' })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'liquidateHistoricalsSysDocument.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);


    return response
  } catch (error) {
    throw error
  }
}

export const UseDownloadLiquidateHistoricalsSys = (callbackSuccess: any) => {
  return useMutation(getFileLiquidateHistoricalsSys, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};



const recalculateLiquidate = async (data: { id: number }[]) => {
  try {

    const response = await fetchPOST2('Support/CalculateLiquidation', data)
    return response

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const customErr = {
        errorCode: error.response.data.code,
        message: error.response.data.message,
      }

      throw customErr
    }

  }
}


export const UseRecalculateLiquidateHistoricalsSys = (callbackSuccess: any) => {
  return useMutation(recalculateLiquidate, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};
