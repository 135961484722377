import { ColData } from 'ufinet-web-components'
import { IntlShape } from 'react-intl'
import LevelBadge from 'lib/badges/LevelBadge';
import MonthBadge from 'lib/badges/MonthBadge'
import AchievementFactorBadge from 'lib/badges/AchievementFactorBadge'
import { Translation } from 'utils/translation/Translation';
import { CommissionType, commissionTypeMappings, commissionMonthMappings, CommissionMonth, CommissionTypeApproved, commissionTypeAprovedMappings, CommissionCalculatingStatus, commissionCalculatingStatusMappings } from 'types/typesAndValues';
import { PendingTypes } from 'types/pendingTypes';
import { ShowValue } from 'lib/showValue/ShowValue';
import { PercentangeCommission } from 'lib/percentangeCommission/PercentangeCommission';
import { RedirectLib } from 'lib/redirectLib/RedirectLib';
import { CommissionSystemElement } from 'types/commissionSystemTypes';


export const colsPendingCommisions = (intl: IntlShape): ColData[] => [
  // header is the id to search the translate based on the data received
  // removing or typing the word false in the property nonFilterable is gonna show in the table header the filter
  // body you can customize the data you want to show, accept react component or just a simple html
  { field: 'username', header: 'COL.COMMISSION.KAM', width: '190px', nonFilterable: false, editable: false, filterType: 'text' },
  { field: 'idService', header: 'COL.COMMISSION.ADMINCODE', width: '190px', nonFilterable: false, editable: false, filterType: 'text' }, {
    field: 'idServiceCRM', header: 'COL.COMMISSION.IDSERVICECRM', width: '190px', nonFilterable: false, editable: false, filterType: 'number',
    body: (row: CommissionSystemElement) => <RedirectLib textToShow={row.service.idServiceCRM} redirectLink={row.service.serviceUrl} />
  },
  {
    field: 'type', header: 'COL.COMMISSION.TYPE', width: '190px', nonFilterable: false, editable: true, nonSortable: false, filterType: 'select',
    filterOptions: [
      {
        label: intl.formatMessage({ id: commissionTypeAprovedMappings.get(CommissionTypeApproved.DECOMMISSION) }),
        value: CommissionTypeApproved.DECOMMISSION,
      },
      {
        label: intl.formatMessage({ id: commissionTypeAprovedMappings.get(CommissionTypeApproved.COMMISSION) }),
        value: CommissionTypeApproved.COMMISSION,
      },
    ],
    body: (row: CommissionSystemElement) => <>{Translation(row.commission.type)}</>
  },
  {
    field: 'totalAmount', header: 'COL.COMMISSION.COMMISSIONTOPAY', width: '190px', nonFilterable: true, nonSortable: false,
    body: (row: CommissionSystemElement) => <ShowValue value={row.commission.totalAmount} currencyCode={row.user.currencyCode} />
  },
  {
    field: 'backlog', header: 'COL.COMMISSION.BACKLOG', width: '190px', nonFilterable: true, editable: true, nonSortable: false,
    body: (row: CommissionSystemElement) => <ShowValue value={row.service.backlog} currencyCode={row.service.currencyCode} />
  },
  {
    field: 'authorizatorLevel', header: 'COL.COMMISSION.AUTH.LEVEL', width: '190px', nonFilterable: true, editable: true, nonSortable: false,
    body: (row: CommissionSystemElement) => <LevelBadge levelAuth={row.authorization.authorizatorLevel} />,
  },
  {
    field: 'authorizatorName', header: 'COL.COMMISSION.AUTHNAME', width: '190px', nonFilterable: false, editable: true, nonSortable: false, filterType: 'text'
  },
  {
    field: 'percentageCommission', header: 'COL.COMMISSION.PERCENTAGECOMMISSION', width: '190px', nonFilterable: true, editable: true, nonSortable: false,
    body: (row: CommissionSystemElement) => <PercentangeCommission percentange={row.commission.percentageCommission} />
  },
  { field: 'exchangeRate', header: 'COL.COMMISSION.EXCHANGERATE', width: '190px', nonFilterable: true, editable: true, nonSortable: false },
  {
    field: 'baseAmount', header: 'COL.COMMISSION.BASEAMOUNT', width: '190px', nonFilterable: true, editable: true, nonSortable: false,
    body: (row: CommissionSystemElement) => <ShowValue value={row.commission.baseAmount} currencyCode={row.user.currencyCode} />
  },
  {
    field: 'achievementFactor',
    header: 'COL.COMMISSION.MULTIPLIER',
    width: '190px',
    nonFilterable: true,
    editable: true,
    nonSortable: false,
    body: (row: CommissionSystemElement) =>
      <AchievementFactorBadge achievementFactor={row.commission.achievementFactor} />

  },
  { field: 'saleYear', header: 'COL.COMMISSION.SALEYEAR', width: '190px', nonFilterable: true, editable: true, nonSortable: false, },
  {
    field: 'saleMonth',
    header: 'COL.COMMISSION.SALEMONTH',
    width: '190px',
    nonFilterable: false,
    nonSortable: false,
    filterOptions: [
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.JANUARY) }),
        value: CommissionMonth.JANUARY,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.FEBRUARY) }),
        value: CommissionMonth.FEBRUARY,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.MARCH) }),
        value: CommissionMonth.MARCH,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.APRIL) }),
        value: CommissionMonth.APRIL,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.MAY) }),
        value: CommissionMonth.MAY,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.JUNE) }),
        value: CommissionMonth.JUNE,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.JULY) }),
        value: CommissionMonth.JULY,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.AUGUST) }),
        value: CommissionMonth.AUGUST,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.SEPTEMBER) }),
        value: CommissionMonth.SEPTEMBER,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.OCTOBER) }),
        value: CommissionMonth.OCTOBER,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.NOVEMBER) }),
        value: CommissionMonth.NOVEMBER,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.DECEMBER) }),
        value: CommissionMonth.DECEMBER,
      },
    ],
    editable: true,
    body: (row: PendingTypes) => <MonthBadge month={row.saleMonth} />
  },
  {
    field: 'firstBillingDate', header: 'COL.COMMISSION.BILLINGDATE', width: '190px', nonFilterable: true, editable: true, nonSortable: false,

  },
  {
    field: 'commissionType', header: 'COL.COMMISSION.COMMISSIONTYPE', width: '190px', nonFilterable: false, nonSortable: false,
    filterOptions: [
      {
        label: intl.formatMessage({ id: commissionTypeMappings.get(CommissionType.ADJUDGE) }),
        value: CommissionType.ADJUDGE,
      },
      {
        label: intl.formatMessage({ id: commissionTypeMappings.get(CommissionType.DELIVERY) }),
        value: CommissionType.DELIVERY,
      },
      {
        label: intl.formatMessage({ id: commissionTypeMappings.get(CommissionType.BILLING) }),
        value: CommissionType.BILLING,
      },
      {
        label: intl.formatMessage({ id: commissionTypeMappings.get(CommissionType.CEASE) }),
        value: CommissionType.CEASE,
      },
      {
        label: intl.formatMessage({ id: commissionTypeMappings.get(CommissionType.MANUAL) }),
        value: CommissionType.MANUAL,
      },
    ],
    editable: true,
    body: (row: PendingTypes) => <>{Translation(row.commissionType)}</>,
    //
  },
  {
    field: 'commissionTypePercentage', header: 'COL.COMMISSION.TYPEPERCENTAGE', width: '190px', nonFilterable: true, editable: true, nonSortable: false,
    body: (row: CommissionSystemElement) => <PercentangeCommission percentange={row.commission.commissionTypePercentage} />,
  },
  {
    field: 'commissionDescription', header: 'COL.COMMISSION.STATUSCALCULATING', width: '190px', nonFilterable: false, nonSortable: false,
    filterOptions: [
      {
        label: intl.formatMessage({ id: commissionCalculatingStatusMappings.get(CommissionCalculatingStatus.MISSING_CONFIGURATION) }),
        value: CommissionCalculatingStatus.MISSING_CONFIGURATION,
      },
      {
        label: intl.formatMessage({ id: commissionCalculatingStatusMappings.get(CommissionCalculatingStatus.WAITING_EXCHANGERATE) }),
        value: CommissionCalculatingStatus.WAITING_EXCHANGERATE,
      },
      {
        label: intl.formatMessage({ id: commissionCalculatingStatusMappings.get(CommissionCalculatingStatus.WAITING_DELIVERY) }),
        value: CommissionCalculatingStatus.WAITING_DELIVERY,
      },
      {
        label: intl.formatMessage({ id: commissionCalculatingStatusMappings.get(CommissionCalculatingStatus.WAITING_BILLING) }),
        value: CommissionCalculatingStatus.WAITING_BILLING,
      },
      {
        label: intl.formatMessage({ id: commissionCalculatingStatusMappings.get(CommissionCalculatingStatus.CALCULATING_MULTIPLIER) }),
        value: CommissionCalculatingStatus.CALCULATING_MULTIPLIER,
      },

      {
        label: intl.formatMessage({ id: commissionCalculatingStatusMappings.get(CommissionCalculatingStatus.WAITING_BACKLOGCEASE) }),
        value: CommissionCalculatingStatus.WAITING_BACKLOGCEASE,
      },
      {
        label: intl.formatMessage({ id: commissionCalculatingStatusMappings.get(CommissionCalculatingStatus.PENDING_AUTHORIZATION) }),
        value: CommissionCalculatingStatus.PENDING_AUTHORIZATION,
      },
    ],
    body: (row: CommissionSystemElement) => <>{Translation(row.commission.commissionDescription)}</>,
    editable: true,
  },

  { field: 'serviceName', header: 'COL.COMMISSION.SERVICENAME', width: '190px', nonFilterable: false, editable: false, filterType: 'text' },
  { field: 'serviceClient', header: 'COL.COMMISSION.SERVICECLIENT', width: '190px', nonFilterable: false, editable: false, filterType: 'text' },
]


