import { ColData } from 'ufinet-web-components'
import { IntlShape } from 'react-intl';
import { AuthorizingChainTypes } from 'types/configuration';
import LevelBadge from 'lib/badges/LevelBadge';
import { ComercialTeamName } from 'types/typesAndValues';



export const colsAuthorizingChainColData = (intl: IntlShape): ColData[] => [
  // header is the id to search the translate based on the data received
  // removing or typing the word false in the property nonFilterable is gonna show in the table header the filter
  // body you can customize the data you want to show, accept react component or just a simple html

  {
    field: 'idAuthorizingChain', header: 'COL.CONFIG.ID', width: '190px', nonFilterable: false, editable: true, nonSortable: false, filterType: 'number',
    // body: (row: AuthorizingChainTypes) => <PercentangeCommission percentange={row.percentage} />
  },
  {
    field: 'authorizer', header: 'COL.CONFIG.AUTHORIZER', width: '190px', nonFilterable: false, editable: true, nonSortable: false, filterType: 'text',
    // body: (row: any) => <PercentangeCommission percentange={row.percentage} />
  },
  {
    field: 'commercialTeam', header: 'COL.CONFIG.TEAM.NAME', width: '190px', nonFilterable: false, editable: true, nonSortable: false, filterType: 'select',
    filterOptions: [
      {
        label: ComercialTeamName.VastComerciales,
        value: ComercialTeamName.VastComerciales,
      },
      {
        label: ComercialTeamName.GuatemalaComerciales,
        value: ComercialTeamName.GuatemalaComerciales,
      },
      {
        label: ComercialTeamName.MexicoComerciales,
        value: ComercialTeamName.MexicoComerciales,
      },
      {
        label: ComercialTeamName.ElSalvadorComerciales,
        value: ComercialTeamName.ElSalvadorComerciales,
      },
      {
        label: ComercialTeamName.HondurasComerciales,
        value: ComercialTeamName.HondurasComerciales,
      },
      {
        label: ComercialTeamName.NicaraguaComerciales,
        value: ComercialTeamName.NicaraguaComerciales,
      },
      {
        label: ComercialTeamName.CostaRicaComerciales,
        value: ComercialTeamName.CostaRicaComerciales,
      },
      {
        label: ComercialTeamName.PanamaComerciales,
        value: ComercialTeamName.PanamaComerciales,
      },
      {
        label: ComercialTeamName.ColombiaComerciales,
        value: ComercialTeamName.ColombiaComerciales,
      },
      {
        label: ComercialTeamName.PeruComerciales,
        value: ComercialTeamName.PeruComerciales,
      },
      {
        label: ComercialTeamName.BrasilComerciales,
        value: ComercialTeamName.BrasilComerciales,
      },
      {
        label: ComercialTeamName.ParaguayComerciales,
        value: ComercialTeamName.ParaguayComerciales,
      },
      {
        label: ComercialTeamName.ArgentinaComerciales,
        value: ComercialTeamName.ArgentinaComerciales,
      },
      {
        label: ComercialTeamName.ChileComerciales,
        value: ComercialTeamName.ChileComerciales,
      },
      {
        label: ComercialTeamName.GlobalKAMComerciales,
        value: ComercialTeamName.GlobalKAMComerciales,
      },
      {
        label: ComercialTeamName.PartnerProgramGlobalKAMComerciales,
        value: ComercialTeamName.PartnerProgramGlobalKAMComerciales,
      },
      {
        label: ComercialTeamName.ToweringComerciales,
        value: ComercialTeamName.ToweringComerciales,
      },
      {
        label: ComercialTeamName.EcuadorComerciales,
        value: ComercialTeamName.EcuadorComerciales,
      },
      {
        label: ComercialTeamName.EnelColombiaComerciales,
        value: ComercialTeamName.EnelColombiaComerciales,
      },
      {
        label: ComercialTeamName.ToweringColombia,
        value: ComercialTeamName.ToweringColombia,
      },
      {
        label: ComercialTeamName.ToweringChile,
        value: ComercialTeamName.ToweringChile,
      },
      {
        label: ComercialTeamName.ToweringCentroamerica,
        value: ComercialTeamName.ToweringCentroamerica,
      },
    ]
    // body: (row: any) => <PercentangeCommission percentange={row.percentage} />
  },
  {
    field: 'minCommissionAmount', header: 'COL.CONFIG.MINCOMMISSIONAMOUNT', width: '190px', nonFilterable: true, editable: true, nonSortable: false, filterType: 'number',
    // body: (row: any) => <PercentangeCommission percentange={row.percentage} />
  },
  {
    field: 'minDecommissionAmount', header: 'COL.CONFIG.MINDECOMMISSIONAMOUNT', width: '190px', nonFilterable: true, editable: true, nonSortable: false, filterType: 'number',
    // body: (row: any) => <PercentangeCommission percentange={row.percentage} />
  },
  {
    field: 'authorizationLevel', header: 'COL.LEVEL', width: '190px', nonFilterable: true, editable: true, nonSortable: false, filterType: 'number',
    body: (row: AuthorizingChainTypes) => <LevelBadge levelAuth={row.authorizationLevel} />
  },

]


