import axios, { ResponseType } from 'axios'
import { getUsername } from './getUsername'

const getAccess = getUsername()

// const https = axios.create({
//   baseURL: process.env.REACT_APP_URL_DUMMY,
//   headers: {
//     'X-API-Key': 'c386c090'
//   }
// })

const https = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
  headers: {
    apikey: process.env.REACT_APP_API_KEY,

  }
})


const fetchGET = async (endpoint: string, configOverride: object | null = null) => {
  const { accessToken } = await getUsername()
  let config = {
    headers: {
      token: accessToken,
    }
  }
  if (configOverride !== null) {
    config = { ...config, ...configOverride };
  }
  const response = await https.get(endpoint, config)
  return response
}


const fetchGET2 = async (endpoint: string, configOverride: object | null = null) => {
  const { username, accessToken } = await getUsername()

  let config = {
    headers: {
      email: username,
      token: accessToken,
    }
  }

  if (configOverride !== null) {
    config = { ...config, ...configOverride };
  }

  // @ts-ignore
  const response = await https.get(endpoint, config)
  return response
}


const fetchPOST2 = async (endpoint: string, data: any) => {
  const { accessToken, username } = await getUsername()
  let config = {
    headers: {
      email: username,
      token: accessToken,
    }
  }

  const response = await https.post(endpoint, data, config)
  return response
}

const fetchPOST2Blob = async (endpoint: string, data: any) => {
  const { accessToken, username } = await getUsername()
  let config = {
    headers: {
      email: username,
      token: accessToken,
    },
    responseType: 'blob' as ResponseType,
  }

  const response = await https.post(endpoint, data, config)
  return response
}

const fetchDelete = async (endpoint: string) => {
  const { accessToken, username } = await getUsername()
  let config = {
    headers: {
      email: username,
      token: accessToken,
    }
  }

  const response = await https.delete(endpoint, config)
  return response
}

const fetchPOST = async (endpoint: string, data: any) => {

  const { accessToken } = await getUsername()
  let config = {
    headers: {
      token: accessToken,
    }
  }
  const response = await https.post(endpoint, data, config)
  return response
}

const fetchPOSTBlob = async (endpoint: string, data: any) => {

  const { accessToken } = await getUsername()
  let config = {
    headers: {
      token: accessToken,
    },
    responseType: 'blob' as ResponseType,
  }
  const response = await https.post(endpoint, data, config)
  return response
}

const fetchPATCH = async (endpoint: string, data: any) => {
  const { accessToken, username } = await getUsername()
  let config = {
    headers: {
      email: username,
      token: accessToken,
    }
  }
  const response = await https.patch(endpoint, data, config)
  return response
}


export {
  fetchGET,
  fetchGET2,
  fetchPOST,
  fetchPOST2,
  fetchPOST2Blob,
  fetchPOSTBlob,
  fetchPATCH,
  fetchDelete
}
