import { ColData } from 'ufinet-web-components'
import { IntlShape } from 'react-intl'
import { commissionTypeMappings, CommissionType, CommissionMonth, commissionMonthMappings, CommissionCalculatingStatus, commissionCalculatingStatusMappings, AuthorizationStatus, authorizationCommissionStatusMappings } from 'types/typesAndValues';
import { convertToLocalTZ, } from 'ufinet-web-functions'
import MonthBadge from 'lib/badges/MonthBadge'
import { Translation } from 'utils/translation/Translation';
import { HistoricalsLiquidateSystemTypes } from 'types/liquidateSystemTypes';
import { ShowValue } from 'lib/showValue/ShowValue';

export const colsHistoricalsLiquidateSystem = (intl: IntlShape): ColData[] => [
  // header is the id to search the translate based on the data received
  // removing or typing the word false in the property nonFilterable is gonna show in the table header the filter
  // body you can customize the data you want to show, accept react component or just a simple html
  {
    field: 'paymentId',
    header: 'COL.COMMISSION.ID',
    width: '240px',
    editable: false,
    nonFilterable: false,
    nonSortable: false,
    filterType: 'number'
  },
  {
    field: 'username',
    header: 'COL.COMMISSION.KAM',
    width: '190px',
    editable: false,
    nonFilterable: false,
    nonSortable: false,
    filterType: 'text'
  },
  {
    field: 'title',
    header: 'COL.COMMISSION.TITLE',
    width: '240px',
    editable: false,
    nonFilterable: true,
    nonSortable: true,
  },
  {
    field: 'paymentMonth',
    header: 'COL.COMMISSION.MONTH',
    width: '190px',
    nonFilterable: false,
    nonSortable: false,
    filterType: "select",
    filterOptions: [
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.JANUARY) }),
        value: CommissionMonth.JANUARY,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.FEBRUARY) }),
        value: CommissionMonth.FEBRUARY,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.MARCH) }),
        value: CommissionMonth.MARCH,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.APRIL) }),
        value: CommissionMonth.APRIL,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.MAY) }),
        value: CommissionMonth.MAY,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.JUNE) }),
        value: CommissionMonth.JUNE,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.JULY) }),
        value: CommissionMonth.JULY,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.AUGUST) }),
        value: CommissionMonth.AUGUST,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.SEPTEMBER) }),
        value: CommissionMonth.SEPTEMBER,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.OCTOBER) }),
        value: CommissionMonth.OCTOBER,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.NOVEMBER) }),
        value: CommissionMonth.NOVEMBER,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.DECEMBER) }),
        value: CommissionMonth.DECEMBER,
      },
    ],
    editable: true,
    body: (row: HistoricalsLiquidateSystemTypes) => <MonthBadge month={row.paymentMonth} />
  },
  { field: 'paymentYear', header: 'COL.COMMISSION.YEAR', width: '190px', nonFilterable: false, nonSortable: false, filterType: 'number', },
  {
    field: 'paymentAmount', header: 'COL.COMMISSION.PAYMENTAMOUNT', width: '190px', nonFilterable: true, nonSortable: false,
    body: (row: HistoricalsLiquidateSystemTypes) => <ShowValue value={row.paymentAmount} currencyCode={row.currencyCode} />
  },
  {
    field: 'boostAmount', header: 'COL.COMMISSION.IMPULSE', width: '190px', nonFilterable: true, nonSortable: false,
    body: (row: HistoricalsLiquidateSystemTypes) => <ShowValue value={row.boostAmount} currencyCode={row.currencyCode} />
  },
  {
    field: 'paymentState',
    header: 'COL.COMMISSION.STATUS',
    width: '190px',
    nonFilterable: false,
    editable: true,
    nonSortable: false,
    filterType: "select",
    filterOptions: [
      {
        label: intl.formatMessage({ id: authorizationCommissionStatusMappings.get(AuthorizationStatus.PENDING) }),
        value: AuthorizationStatus.PENDING,
      },
      {
        label: intl.formatMessage({ id: authorizationCommissionStatusMappings.get(AuthorizationStatus.APPROVED) }),
        value: AuthorizationStatus.APPROVED,
      },
      {
        label: intl.formatMessage({ id: authorizationCommissionStatusMappings.get(AuthorizationStatus.REJECTED) }),
        value: AuthorizationStatus.REJECTED,
      },
    ],
    body: (row: HistoricalsLiquidateSystemTypes) => <>{Translation(row.paymentState)}</>
  },
  { field: 'paymentUser', header: 'COL.COMMISSION.RESPONSABLE', width: '190px', nonFilterable: false, nonSortable: false, filterType: 'text', },
  { field: 'cutoffDate', header: 'COL.COMMISSION.CLOSEDATE', width: '190px', nonFilterable: true, nonSortable: false, filterType: 'text', },
  { field: 'paymentDate', header: 'COL.COMMISSION.STATUSDATE', width: '190px', nonFilterable: true, nonSortable: false, filterType: 'text', },
  { field: 'company', header: 'COL.COMMISSION.ENTERPRISE', width: '190px', nonFilterable: false, nonSortable: false, filterType: 'text', },

]
