import React, { useState, useEffect, useMemo, useContext, useCallback } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { toast } from 'react-toastify'
import { MultipleDeleter, Table, UfinetBox, UfinetButton, UfinetModal, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection } from 'ufinet-web-components'
import { AuthContext, Authority, useAsync, useModal, } from 'ufinet-web-functions'
import { colsLiquidatePending } from './LiquidatePendingColData'
import { UseQueryListPendingPaymentsCommissions, UsePostApprovedListPaymentsCommission, UsePostRejectedListPaymentsCommission, UseDownloadLiquidatePending } from 'request'
import { Translation } from 'utils/translation/Translation'
import { CommissionService } from 'types/CommissionEstimatedTypes'
import BoxTotal from 'lib/box/BoxTotal'
import { DataTableFilterMeta, DataTablePageEvent } from 'primereact/datatable'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck'
import { LiquidateRRHHTypes, LiquidateTypesRRHH } from 'types/liquidateRRHH'



const LiquidatePendingPage = () => {
  const intl = useIntl()
  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  const permissions = Authority.getCommissionLiquidateRRHHPermissions(roles)

  const colsPending = useMemo(() => colsLiquidatePending(intl), [intl])


  const [selectedValues, setSelectedValues] = useState<LiquidateTypesRRHH[]>([])
  const [paging, setPaging] = useState<any>()
  const [filterData, setFilterData] = useState<any>()
  const { data: liquidatePending, isLoading: isLoadingLiquidatePending, refetch: liquidatePendingRefect, isFetching: isFetchingLiquidatePending } = UseQueryListPendingPaymentsCommissions(filterData)

  const cbSuccessApprove = (data: any) => {
    liquidatePendingRefect()
  }

  const cbSuccessReject = (data: any) => {
    liquidatePendingRefect()
  }

  const cbSuccessDownloadFile = (data: any) => {
  }

  const { mutate: approveListPayments, isLoading: isLoadingApproveLiquidate } = UsePostApprovedListPaymentsCommission(cbSuccessApprove)
  const { mutate: rejectListPayments, isLoading: isLoadingRejectLiquidate } = UsePostRejectedListPaymentsCommission(cbSuccessReject)
  const { mutate: downloadFile, isLoading: isLoadingDowloadFilePendig } = UseDownloadLiquidatePending(cbSuccessDownloadFile)

  useEffect(() => {
    if (liquidatePending) {
      const parsePagination = JSON.parse(liquidatePending?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageNumber: parsePagination.currentPage,
        pageSize: filterData && filterData.pageSize ? filterData.pageSize : parsePagination.pageSize
      }
      setPaging(formattedPagination)
    }
  }, [liquidatePending])

  const onFilterClear = (): void => {
    setFilterData({})
  }

  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)
    setFilterData(formattedFilters)
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({ ...filterData, pageNumber: e.page, pageSize: e.rows })
    // if (e.page === userRequestData.page && e.rows === userRequestData.size) return
    // setUserRequestData({ ...userRequestData, pageNumber: e.page, pageSize: e.rows })
  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })
    // const finalField = UserPropertyMap.get(e.sortField)
    // setUserRequestData({
    // 	...userRequestData,
    // 	sortFields: finalField && [finalField],
    // 	sortOrders: finalField && finalOrder && [finalOrder],
    // 	// Return to first page
    // 	pageNumber: 0,
    // })
  }

  const onSelectionChange = useCallback((values: any[]) => {
    setSelectedValues(values)
  }, [])

  const approvePayments = () => {
    if (selectedValues?.length > 0) {
      const newData = selectedValues?.map((item: LiquidateTypesRRHH) => ({
        id: item.paymentId,
        state: 'approved'
      }))
      approveListPayments(newData)


    }
  }

  const rejectPayments = () => {

    if (selectedValues?.length > 0) {
      const newData = selectedValues?.map((item: LiquidateTypesRRHH) => ({
        id: item.paymentId,
        state: 'rejected'
      }))
      rejectListPayments(newData)
    }
  }

  const downloadFileLiquidatePending = () => {
    downloadFile(filterData)
  }



  const getHeadersButtons = () => (
    <>
      {permissions.canWrite && (
        <UfinetButton
          className="me-3"
          icon={faDownload}
          onClick={() => downloadFileLiquidatePending()}
          content={Translation('DOWNLOAD.FILE')}
        />
      )}
      {permissions.canWrite && (
        <UfinetButton
          className="me-3"
          icon={faCircleCheck}
          onClick={() => approvePayments()}
          content={Translation('BUTTON.APRROVED')}
        />
      )}

      {permissions.canWrite && (
        <UfinetButton
          className="me-3"
          icon={faCircleXmark}
          onClick={() => rejectPayments()}
          content={Translation('BUTTON.REJECTED')}
        />
      )}
    </>
  )

  return (
    <>
      <UfinetBox>

        <UfinetSectionBox>

          {/* Liquidación Noviembre 2023 - Cierre a Octubre 2023 */}

          <Table
            dataKey='paymentId'
            selectedValues={selectedValues}
            onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            content={liquidatePending?.data && liquidatePending?.data?.map((item: LiquidateTypesRRHH) => (
              {
                ...item,
                name: `${item.paymentMonth && item.paymentYear ? `${Translation('LIQUIDATE.COL')} ${Translation(item.paymentMonth)} -` : ''} ${item.cutoffMonth && item.cutoffYear ? `${Translation('CLOSEDATE')} ${Translation(item.cutoffMonth)} ${item.cutoffYear}` : ''} `,
                username: item.username,
                paymentId: item.paymentId,
                // paymentYear: item.paymentYear ? item.paymentYear.toString() : ''
              }
            ))}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            cols={colsPending}
            onFilterClear={onFilterClear}
            emptyMessage={isLoadingLiquidatePending || isFetchingLiquidatePending || isLoadingDowloadFilePendig ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingLiquidatePending || isFetchingLiquidatePending || isLoadingDowloadFilePendig,
                onFilter,
                onPage,
                onSort,
              }
            }
            headerButtons={getHeadersButtons()}
            actionBodyTemplate={<></>}
          />
          {/* <UfinetModal
          size="lg"
          show={newModal}
          handleClose={hideNewModal}
          title={intl.formatMessage({ id: 'MODAL.NEW.REGISTER.TITLE' })}
        >
          <NewCompanyModalBody search={fetchAndStore} hideModal={hideNewModal} />
        </UfinetModal> */}
        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}

export { LiquidatePendingPage }

const ContainerCommissionAmount = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;    
    
    h2 {
      margin: 0;
    }
`


const BoxShadow = styled.div`
box-shadow: 0px 0px 2px 1px rgba(189,173,173,0.75);
-webkit-box-shadow: 0px 0px 2px 1px rgba(189,173,173,0.75);
-moz-box-shadow: 0px 0px 2px 1px rgba(189,173,173,0.75);
padding: 5px;
`

const TotalAmount = styled.div`
  display: flex;
  justify-content: flex-end;
`
const CurrencyCode = styled.div`
  display: flex;
  justify-content: flex-end;
`
