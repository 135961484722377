import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Table, UfinetBox, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection, } from 'ufinet-web-components'

import { UseQueryGetPercentageTypeCommision, } from 'request'
import { DataTablePageEvent } from 'primereact/datatable'
import { colsPercentageTypeCommissionKamColData } from './PercentangeTypeCommissionColData'

const PercentageTypeCommssionKam = () => {
  const intl = useIntl()
  const colsPercentangeTypeCommissionKam = useMemo(() => colsPercentageTypeCommissionKamColData(intl), [intl])


  const [paging, setPaging] = useState<any>()

  const [filterData, setFilterData] = useState<any>()



  const { data: percentangeTypeCommisionKam, isLoading: isLoadingPercentageTypeCommissionKam, refetch } = UseQueryGetPercentageTypeCommision(filterData)


  useEffect(() => {
    if (percentangeTypeCommisionKam) {
      const parsePagination = JSON.parse(percentangeTypeCommisionKam?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: filterData && filterData.pageSize ? filterData.pageSize : parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [percentangeTypeCommisionKam])

  const onFilterClear = (): void => {
    setFilterData({})
  }


  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)
    setFilterData({
      ...filterData,
      ...formattedFilters
    })
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({ ...filterData, pageNumber: e.page, pageSize: e.rows })
  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })
  }




  return (
    <>
      <UfinetBox>

        <UfinetSectionBox>

          <Table
            dataKey="id"
            // selectedValues={selectedValues}
            // onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            content={percentangeTypeCommisionKam?.data}
            cols={colsPercentangeTypeCommissionKam}
            onFilterClear={onFilterClear}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            emptyMessage={isLoadingPercentageTypeCommissionKam ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            actionBodyTemplate={<></>}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingPercentageTypeCommissionKam,
                onFilter,
                onPage,
                onSort,
              }
            }
          />


        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}

export { PercentageTypeCommssionKam }





