import { FC } from 'react'
import { I18nProvider, Ufineti18nProvider } from 'ufinet-web-components'

import { commissionMessages } from 'modules/i18n/domain/messages'

const CommissionProvider: FC = ({ children }) => {
  return (
    <Ufineti18nProvider>
      <I18nProvider messagesByLanguage={commissionMessages} >{children}</I18nProvider>
    </Ufineti18nProvider>
  )

}

export { CommissionProvider }
