import React, { useState, useEffect, useMemo, useContext, useCallback } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { toast } from 'react-toastify'
import { MultipleDeleter, Table, UfinetBox, UfinetButton, UfinetModal, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection } from 'ufinet-web-components'
import { AuthContext, Authority, useAsync, useModal, } from 'ufinet-web-functions'
import { colslMultiplicatorSystemCommission } from './HistoricalsMultiplicatorColData'
import { UseQueryMultplicatorSystemCommissions, UseDownloadMultiplicatorSystemCommission, UseRecalculateMultiplierSys } from 'request'
import { Translation, useTranslation } from 'utils/translation/Translation'
import BoxTotal from 'lib/box/BoxTotal'
import { DataTableFilterMeta, DataTablePageEvent } from 'primereact/datatable'
import { MultiplicatorTypes } from 'types/multiplicatorKam'
import { faCalculator } from '@fortawesome/free-solid-svg-icons'
import { ErrorResponse } from 'types/typesAndValues'



const MultiplicatorSystemPage = () => {
  const intl = useIntl()
  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  const permissions = Authority.getCommissionMultiplierSysPermissions(roles)
  const translate = useTranslation()

  const colsMultiplicatorSystem = useMemo(() => colslMultiplicatorSystemCommission(intl), [intl])


  const [selectedValues, setSelectedValues] = useState<MultiplicatorTypes[]>([])
  const [paging, setPaging] = useState<any>()
  const [filterData, setFilterData] = useState<any>()

  const isSelectedValues = selectedValues && selectedValues.length > 0

  const { data: multiplicatorSystemCommission, isLoading: isLoadingMultiplicatorGoals, refetch } = UseQueryMultplicatorSystemCommissions(filterData)

  const cbSuccessDownloadFile = (data: any) => {
  }

  const cbSuccessRecalculate = ({ data }: any) => {
    toast.success(data.message)
    setSelectedValues([])
    filterData({})
    refetch()
  }

  const { mutate: downloadFileMultitpler, isLoading: isLoadingDownloadFileMultitpler } = UseDownloadMultiplicatorSystemCommission(cbSuccessDownloadFile)
  const { mutate: recalculateMultiplier, isLoading: isLoadingRecalculateMultiplier, isError: isErrorRecalculate, error: errorMessage } = UseRecalculateMultiplierSys(cbSuccessRecalculate)



  const showToastErrMessage = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse;
      toast.error(`${translate("ERROR.MESSAGE")} ${errorResponse.errorCode}`, { toastId: 'Error5' });

    }
  }


  useEffect(() => {
    if (multiplicatorSystemCommission) {
      const parsePagination = JSON.parse(multiplicatorSystemCommission?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageNumber: parsePagination.currentPage,
        pageSize: filterData && filterData.pageSize ? filterData.pageSize : parsePagination.pageSize
      }
      setPaging(formattedPagination)
    }
  }, [multiplicatorSystemCommission])

  const onFilterClear = (): void => {
    setFilterData({})
  }

  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)
    setFilterData({
      ...filterData,
      ...formattedFilters
    })
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({ ...filterData, pageNumber: e.page, pageSize: e.rows })
  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })
  }

  const downloadFile = () => {
    downloadFileMultitpler(filterData)
  }

  const recalculate = () => {
    if (isSelectedValues) {
      const newArr = selectedValues.map((item: MultiplicatorTypes) => {
        return {
          email: item.email,
          period: item.period,
        }
      })

      recalculateMultiplier(newArr)

    }


  }


  const getHeadersButtons = () => (
    <>

      {permissions.canWrite && (
        <UfinetButton
          className="me-3"
          icon={faDownload}
          isDisabled={isLoadingDownloadFileMultitpler}
          onClick={() => downloadFile()}
          content={Translation('DOWNLOAD.FILE')}
        />
      )}
      {permissions.canWrite && isSelectedValues && (
        <UfinetButton
          className="me-3"
          icon={faCalculator}
          isDisabled={isLoadingRecalculateMultiplier}
          onClick={() => recalculate()}
          content={Translation('RECALCULATE')}
        />
      )}
    </>
  )


  const onSelectionChange = useCallback((values: any[]) => {
    setSelectedValues(values)
  }, [])

  return (
    <>
      {isErrorRecalculate && showToastErrMessage()}
      <UfinetBox>

        <UfinetSectionBox>

          <Table
            dataKey='id'
            enableSearchFilter={false}
            selectedValues={selectedValues}
            onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            content={multiplicatorSystemCommission?.data}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            cols={colsMultiplicatorSystem}
            onFilterClear={onFilterClear}
            emptyMessage={isLoadingMultiplicatorGoals || isLoadingDownloadFileMultitpler ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingMultiplicatorGoals || isLoadingDownloadFileMultitpler,
                onFilter,
                onPage,
                onSort,
              }
            }
            headerButtons={getHeadersButtons()}
          />
          {/* <UfinetModal
          size="lg"
          show={newModal}
          handleClose={hideNewModal}
          title={intl.formatMessage({ id: 'MODAL.NEW.REGISTER.TITLE' })}
        >
          <NewCompanyModalBody search={fetchAndStore} hideModal={hideNewModal} />
        </UfinetModal> */}
        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}

export { MultiplicatorSystemPage }

const ContainerCommissionAmount = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;    
    
    h2 {
      margin: 0;
    }
`


const BoxShadow = styled.div`
box-shadow: 0px 0px 2px 1px rgba(189,173,173,0.75);
-webkit-box-shadow: 0px 0px 2px 1px rgba(189,173,173,0.75);
-moz-box-shadow: 0px 0px 2px 1px rgba(189,173,173,0.75);
padding: 5px;
`

const TotalAmount = styled.div`
  display: flex;
  justify-content: flex-end;
`
const CurrencyCode = styled.div`
  display: flex;
  justify-content: flex-end;
`
