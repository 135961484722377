


import React from 'react'
import { useIntl } from 'react-intl'

const Translation = (message: string) => {
  const intl = useIntl()
  if (message === null) {
    message = "NO.DATA"
    return intl.formatMessage({ id: message })
  }
  return intl.formatMessage({ id: message })
}

export { Translation }




export const useTranslation = () => {
  const intl = useIntl();

  const translate = (message: string | null) => {
    const messageId = message === null ? "NO.DATA" : message;
    return intl.formatMessage({ id: messageId });
  };

  return translate;
};

