import { useFormik } from 'formik'
import * as Yup from 'yup'
import { IUfinetSelectOption, UfinetActionButton, UfinetActionButtonHandle, UfinetInput, UfinetSelect, emptyUfinetSelectOption } from 'ufinet-web-components'
import { useCallback, useMemo, useRef } from 'react'
import { onFormikChanges, onFormikNumberChanges } from 'ufinet-web-functions'
import { useTranslation } from 'utils/translation/Translation'
import { useIntl } from 'react-intl'
import { ErrorResponse, } from 'types/typesAndValues'
import { useMutationCreateAuthorizingChain, UseGetAuthorizatorSelect, UseGetCommercialTeamSelect } from 'request'
import { toast } from 'react-toastify'
import { AuthorizingChainSelectTypes, CommercialTeamTypes } from '../../../../../types/configuration/ConfigurationTypes';





interface Props {
  refetch: () => void
  hideCreateModal: () => void
}

type CreateFormData = {
  minCommissionAmount: number | string
  minDecommissionAmount: number | string
  authorizationLevel: number | string
  idCommercialTeam: IUfinetSelectOption
  idAuthorizer: IUfinetSelectOption
}

export const CreateAuthorizingChainModalForm = ({ refetch, hideCreateModal }: Props) => {


  const actionButtonRef = useRef<UfinetActionButtonHandle>(null)
  const translate = useTranslation()
  const intl = useIntl()


  //* Request to get the data select
  const { data: authorizatorData, } = UseGetAuthorizatorSelect()
  const { data: commercialTeamData, } = UseGetCommercialTeamSelect()

  const authorizatorSelect = authorizatorData && authorizatorData.data.map((item: AuthorizingChainSelectTypes) => (
    { label: item.name, value: item.id }
  ))

  const commercialTeamSelect = commercialTeamData && commercialTeamData.data.map((item: CommercialTeamTypes) => (
    { label: item.commercialTeamName, value: item.commercialTeamId }
  ))









  const cbSuccessCreateAuthorizingChain = ({ data }: any) => {
    toast.success(data.message)
    refetch()
    hideCreateModal()
  }

  const { mutate: createAuhtorizingChain, isLoading: isLoadingCreate, error: errorMessage, isError } = useMutationCreateAuthorizingChain(cbSuccessCreateAuthorizingChain)




  const showToastErrMessage = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse;
      toast.error(`${translate("ERROR.MESSAGE")} ${errorResponse.errorCode}`, { toastId: 'Error5' });
      hideCreateModal()
    }
  }


  const dataFormSchema = Yup.object().shape({
    minCommissionAmount: Yup.number().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
    minDecommissionAmount: Yup.number().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
    authorizationLevel: Yup.number().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
    idAuthorizer: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
    idCommercialTeam: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
  })



  const sendData = (values: CreateFormData) => {
    actionButtonRef.current?.changeActionStatus(true)
    createAuhtorizingChain({
      minCommissionAmount: +values.minCommissionAmount,
      minDecommissionAmount: +values.minDecommissionAmount,
      authorizationLevel: +values.authorizationLevel,
      idAuthorizer: values.idAuthorizer.value,
      idCommercialTeam: values.idCommercialTeam.value,
    })
  }

  const onChange = useCallback(onFormikChanges, [])
  const onNumberChange = useCallback(onFormikNumberChanges, [])



  const initialValues: CreateFormData = useMemo<CreateFormData>(
    () => ({
      minCommissionAmount: '',
      minDecommissionAmount: '',
      authorizationLevel: '',
      idAuthorizer: emptyUfinetSelectOption,
      idCommercialTeam: emptyUfinetSelectOption,
    }),
    []
  )



  const formik = useFormik<CreateFormData>({
    initialValues,
    validationSchema: dataFormSchema,
    onSubmit: sendData,
    validateOnChange: false,
    validateOnBlur: false,
  })




  return (
    <>
      {isError && showToastErrMessage()}
      <form onSubmit={formik.handleSubmit}>
        <div className='row'>
          <div className='row'>

            <UfinetInput
              requiredIcon
              error={formik.errors.authorizationLevel}
              type="decimal"
              labelTitle={translate('COL.LEVEL')}
              className="col-6"
              onChange={onNumberChange(formik, 'authorizationLevel')}
              value={formik.values.authorizationLevel}
              solid={false}
            />

            <UfinetInput
              requiredIcon
              error={formik.errors.minDecommissionAmount}
              type="decimal"
              labelTitle={translate('COL.CONFIG.MINCOMMISSIONAMOUNT')}
              className="col-6"
              onChange={onNumberChange(formik, 'minDecommissionAmount')}
              value={formik.values.minDecommissionAmount}
              solid={false}
            />

            <UfinetInput
              requiredIcon
              error={formik.errors.minCommissionAmount}
              type="decimal"
              labelTitle={translate('COL.CONFIG.MINDECOMMISSIONAMOUNT')}
              className="col-6"
              onChange={onNumberChange(formik, 'minCommissionAmount')}
              value={formik.values.minCommissionAmount}
              solid={false}
            />
            <UfinetSelect
              requiredIcon
              className='col-6'
              value={formik.values.idAuthorizer}
              error={formik.errors.idAuthorizer?.label}
              labelTitle={translate('COL.CONFIG.AUTHORIZER')}
              onChange={onChange(formik, 'idAuthorizer')}
              placeholder={translate('COL.CONFIG.AUTHORIZER')}
              options={authorizatorSelect}
            />

            <div className='row mt-5'>



              <UfinetSelect
                requiredIcon
                className='col-6'
                value={formik.values.idCommercialTeam}
                error={formik.errors.idCommercialTeam?.label}
                labelTitle={translate('COL.CONFIG.TEAM.NAME')}
                onChange={onChange(formik, 'idCommercialTeam')}
                placeholder={translate('COL.CONFIG.TEAM.NAME')}
                options={commercialTeamSelect}
              />

            </div>


            <UfinetActionButton
              isDisabled={isLoadingCreate}
              ref={actionButtonRef}
              className='mt-5'
              content={translate('CREATE.MODAL')}
            // onClick={downLoadTemplate}
            />
          </div>
        </div>
      </form>
    </>
  )
}
