import React, { useRef, useCallback, useMemo } from 'react'
import { useFormik } from 'formik'
import { UfinetActionButton, UfinetActionButtonHandle, UfinetSelect, IUfinetSelectOption, emptyUfinetSelectOption, UfinetInput } from 'ufinet-web-components';
import { onFormikChanges, IFileUploadData, onFormikNumberChanges } from 'ufinet-web-functions';
import { UseCreateConfigLiquidate, UseGetCompanies } from 'request';
import * as Yup from 'yup'
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { ErrorResponse, } from 'types/typesAndValues';
import { useTranslation } from 'utils/translation/Translation';
import { CompaniesSelectTypes } from 'types/configuration';

interface Props {
  hideModal: () => void;
  refetch: () => void
}

interface File {
  file: IFileUploadData
}



type CreateFormData = {
  executionDay: number | string
  companyId: IUfinetSelectOption
}

const CreateConfigLiquidateForm = ({ hideModal, refetch }: Props) => {


  const { data: companies } = UseGetCompanies()


  const companiesSelect = companies && companies?.data.map((item: CompaniesSelectTypes) => (
    { label: item.name, value: item.id }
  ))



  const translate = useTranslation()
  const intl = useIntl()





  const actionButtonRef = useRef<UfinetActionButtonHandle>(null)




  const cbSuccessCreateConfigLiquidate = ({ data }: any) => {
    actionButtonRef.current?.changeActionStatus(false)
    toast.success(data.message)
    refetch()
    hideModal()
  }

  const { mutate: createConfigLiquidate, isError, error: errorMessage } = UseCreateConfigLiquidate(cbSuccessCreateConfigLiquidate)


  const showErrMessage = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse;
      toast.error(`${intl.formatMessage({ id: "ERROR.MESSAGE" },)} ${errorResponse.message}`, { toastId: 'Error2' });
      hideModal()
    }
  }

  const dataFormSchema = Yup.object().shape({
    executionDay: Yup.number().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
    companyId: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
  })



  const sendData = (values: any) => {
    actionButtonRef.current?.changeActionStatus(true)
    createConfigLiquidate({
      executionDay: +values.executionDay,
      companyId: values.companyId.value,
    })
  }

  const onChange = useCallback(onFormikChanges, [])
  const onNumberChange = useCallback(onFormikNumberChanges, [])


  const initialValues: CreateFormData = useMemo<CreateFormData>(
    () => ({
      executionDay: '',
      companyId: emptyUfinetSelectOption,
    }),
    []
  )



  const formik = useFormik<CreateFormData>({
    initialValues,
    validationSchema: dataFormSchema,
    onSubmit: sendData,
    validateOnChange: false,
    validateOnBlur: false,
  })


  return (
    <>
      {isError && showErrMessage()}
      <form onSubmit={formik.handleSubmit}>
        <div className='row'>
          <div className='row'>

            <UfinetInput
              error={formik.errors.executionDay}
              type="decimal"
              labelTitle={translate('COL.EXECUTIONDAY')}
              className="col-6"
              onChange={onNumberChange(formik, 'executionDay')}
              value={formik.values.executionDay}
              solid={false}
            />

            <UfinetSelect
              requiredIcon
              className='col-6'
              value={formik.values.companyId}
              error={formik.errors.companyId?.label}
              labelTitle={translate('COL.COMMISSION.ENTERPRISE')}
              onChange={onChange(formik, 'companyId')}
              placeholder={translate('COL.COMMISSION.ENTERPRISE')}
              options={companiesSelect}
            />


            <UfinetActionButton
              ref={actionButtonRef}
              className='mt-5'
              content={translate('CREATE.MULTIPLIER.CONFIG')}
            />
          </div>
        </div>
      </form>
    </>
  )
}

export { CreateConfigLiquidateForm }


