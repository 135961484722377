import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { toast } from 'react-toastify'
import { MultipleDeleter, Table, UfinetBox, UfinetButton, UfinetModal, UfinetSectionBox, formatTableFilters, getEditPencilBodyTemplate, tableSortValueToSortDirection, } from 'ufinet-web-components'
import { AuthContext, Authority, useAsync, useModal, } from 'ufinet-web-functions'
import { colsPercentageTypeCommissionColData } from './PercentangetTypeColData'
import { UseQueryGetPercentageTypeCommision, UseGetPercentangeTypeCommissionTemplate, UseDeletePercentangeTypeCommmision } from 'request'
import { useTranslation } from 'utils/translation/Translation'
import { CommissionService } from 'types/CommissionEstimatedTypes'
import BoxTotal from 'lib/box/BoxTotal'
import { DataTableFilterMeta, DataTablePageEvent } from 'primereact/datatable'
import { PercentangeTypeCommissionBulkLoadForm } from '../bulk-load/PercentangeTypeCommissionBulkLoadForm'
import { CreateTypeCommissionForm } from './modals/create/CreateTypeCommissionForm'
import { UpdatePercentangeTypeCommissionForm } from './modals/update/UpdatePercentangeTypeCommssionForm'
import { PercentageTypeCommision } from '../../../types/configuration/ConfigurationTypes';


const PercentageTypeCommssion = () => {
  const intl = useIntl()
  const colsPercentangeTypeCommission = useMemo(() => colsPercentageTypeCommissionColData(intl), [intl])
  const [selectedValues, setSelectedValues] = useState<PercentageTypeCommision[]>([])
  const [percentageTypeId, sePercentangeId] = useState<number>()
  const [percentageTypeInfo, sePercentangeInfo] = useState<PercentageTypeCommision>()



  //* Modals
  const [newModal, showNewModal, hideNewModal] = useModal()
  const [createModal, showCreateModal, hideCreateModal] = useModal()
  const [updateModal, showUpdateModal, hideUpdateModal] = useModal()

  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  // Go to see the repo ufinet-web-functions at the file typeAndValues branch develop
  // In the function Authority to get the function to get the roles
  // In this case getCommissionsPermissions is gonna get the roles
  const permissions = Authority.getCommissionConfigPermissions(roles)

  const [paging, setPaging] = useState<any>()
  const [loadingRecords, setLoadingRecords] = useState<boolean>(false)
  const [filterData, setFilterData] = useState<any>()
  const [tablePFSEvent, setTablePFSEvent] = useState<DataTablePageEvent>()


  const { data: percentangeTypeCommision, isLoading: isLoadingConfigGlobal, refetch } = UseQueryGetPercentageTypeCommision(filterData)

  const translate = useTranslation()


  const onSelectionChange = useCallback((values: any) => setSelectedValues(values), [])


  const cbSuccessPercentangeTypeCommissionTemplate = ({ data }: any) => { }
  const cbSuccessDeletePercentangeTypeCommission = ({ data }: any) => {
    toast.success(translate('DELETED.PERCENTANGE.TYPE.COMMISSION'))
    refetch()
  }

  const { mutate: downloadTemplate } = UseGetPercentangeTypeCommissionTemplate(cbSuccessPercentangeTypeCommissionTemplate)
  const { mutate: deletePercentangeTypeCommission } = UseDeletePercentangeTypeCommmision(cbSuccessDeletePercentangeTypeCommission)


  useEffect(() => {
    if (percentangeTypeCommision) {
      const parsePagination = JSON.parse(percentangeTypeCommision?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: filterData && filterData.pageSize ? filterData.pageSize : parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [percentangeTypeCommision])

  const onFilterClear = (): void => {
    setFilterData({})
  }

  // export type PagingData = {
  //   totalElements: number - totalCount
  //   totalPages: number - totalPages
  //   pageSize: number - pageSize
  //   pageNumber: number - currentPage
  // }



  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)
    setFilterData(formattedFilters)
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({ ...filterData, pageNumber: e.page, pageSize: e.rows })
    // if (e.page === userRequestData.page && e.rows === userRequestData.size) return
    // setUserRequestData({ ...userRequestData, pageNumber: e.page, pageSize: e.rows })
  }

  const onSort = (e: DataTablePageEvent): void => {
    // const finalField = UserPropertyMap.get(e.sortField)
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })
    // setUserRequestData({
    // 	...userRequestData,
    // 	sortFields: finalField && [finalField],
    // 	sortOrders: finalField && finalOrder && [finalOrder],
    // 	// Return to first page
    // 	pageNumber: 0,
    // })
  }


  const deletePercentange = () => {
    if (selectedValues && selectedValues.length > 0) {
      const firstElement = selectedValues[0]
      if (firstElement !== undefined) {
        deletePercentangeTypeCommission(firstElement.id);
      }
    }
  }



  const getHeadersButtons = () => (
    <>

      {permissions.canDelete && selectedValues && selectedValues?.length > 0 && (

        <>
          <UfinetButton
            className="me-3"
            icon={faTrash}
            onClick={() => deletePercentange()}
            content={translate('DELETE.COMMISSION.TYPE')}
          />

        </>

      )}
      {permissions.canWrite && (
        <>
          <UfinetButton
            className="me-3"
            icon={faPlus}
            onClick={() => {
              showCreateModal()
            }}
            content={translate('CREATE.COMMISSION.TYPE')}
          />

          <UfinetButton
            className="me-3"
            icon={faUpload}
            onClick={() => {
              showNewModal()
            }}
            content={translate('BULK_LOAD')}
          />


        </>
      )}
    </>
  )

  return (
    <>
      <UfinetBox>

        <UfinetSectionBox>

          <Table
            dataKey="id"
            selectedValues={selectedValues}
            onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            content={percentangeTypeCommision?.data}
            cols={colsPercentangeTypeCommission}
            headerButtons={getHeadersButtons()}
            onFilterClear={onFilterClear}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            emptyMessage={isLoadingConfigGlobal ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            actionBodyTemplate={
              permissions.canUpdate
                ? (percentageTypeCommission: PercentageTypeCommision) =>
                  getEditPencilBodyTemplate(percentageTypeCommission, () => {
                    setSelectedValues([])
                    sePercentangeInfo(percentageTypeCommission)
                    sePercentangeId(percentageTypeCommission.id)
                    showUpdateModal()
                  })
                : undefined
            }
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingConfigGlobal,
                onFilter,
                onPage,
                onSort,
              }
            }
          />

          <UfinetModal
            size="lg"
            show={newModal}
            handleClose={hideNewModal}
            title={translate('LOAD.TEMPLATE')}
          >
            <PercentangeTypeCommissionBulkLoadForm downLoadTemplate={downloadTemplate} hideLoadModal={hideNewModal} refetch={refetch} />
          </UfinetModal>

          <UfinetModal
            size="lg"
            show={createModal}
            handleClose={hideCreateModal}
            title={translate('CREATE.COMMISSION.TYPE')}
          >
            <CreateTypeCommissionForm hideModal={hideCreateModal} refetch={refetch} />
          </UfinetModal>

          <UfinetModal
            size="lg"
            show={updateModal}
            handleClose={hideUpdateModal}
            title={translate('UPDATE.COMMISSION.TYPE')}
          >
            <UpdatePercentangeTypeCommissionForm updatedData={percentageTypeInfo} hideModal={hideUpdateModal} refetch={refetch} />
          </UfinetModal>
        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}

export { PercentageTypeCommssion }

const ContainerCommissionAmount = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;    
    
    h2 {
      margin: 0;
    }
`


const BoxShadow = styled.div`
box-shadow: 0px 0px 2px 1px rgba(189,173,173,0.75);
-webkit-box-shadow: 0px 0px 2px 1px rgba(189,173,173,0.75);
-moz-box-shadow: 0px 0px 2px 1px rgba(189,173,173,0.75);
padding: 5px;
`

const TotalAmount = styled.div`
  display: flex;
  justify-content: flex-end;
`
const CurrencyCode = styled.div`
  display: flex;
  justify-content: flex-end;
`
