import { useTranslation } from "utils/translation/Translation"


interface Props {
  isActive: boolean
}

export const IsActive = ({ isActive }: Props) => {
  const translate = useTranslation()
  if (isActive === null) return <></>

  const showMessage = isActive ? translate('SHOW.ISACTIVE') : translate('SHOW.NO.ISACTIVE')

  return (
    <>{showMessage}</>
  )
}
