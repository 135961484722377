import { ColData } from 'ufinet-web-components'
import { PercentangeCommission } from 'lib/percentangeCommission/PercentangeCommission';
import { TermName, ComercialTeamName, ServiceTypeName, commissionServiceTypeNameServiceMappings, ClientTypeName, commissionClientTypeNameMappings, EconomicModelName, commissionEconomicModelNameMappings, CommissionType, commissionTypeMappings } from 'types/typesAndValues';
import { IntlShape } from 'react-intl';
import { Translation } from 'utils/translation/Translation';

export const colsPercentageTypeCommissionColData = (intl: IntlShape): ColData[] => [
  // header is the id to search the translate based on the data received
  // removing or typing the word false in the property nonFilterable is gonna show in the table header the filter
  // body you can customize the data you want to show, accept react component or just a simple html
  {
    field: 'id', header: 'COL.CONFIG.ID', width: '20%', nonFilterable: false, editable: true, nonSortable: false, filterType: 'number',
    // body: (row: any) => <PercentangeCommission percentange={row.percentage} />
  },
  {
    field: 'commissionType', header: 'COL.COMMISSION.COMMISSIONTYPE', width: '20%', nonFilterable: false, editable: true,
    filterOptions: [
      {
        label: intl.formatMessage({ id: commissionTypeMappings.get(CommissionType.ADJUDGE) }),
        value: CommissionType.ADJUDGE,
      },
      {
        label: intl.formatMessage({ id: commissionTypeMappings.get(CommissionType.DELIVERY) }),
        value: CommissionType.DELIVERY,
      },
      {
        label: intl.formatMessage({ id: commissionTypeMappings.get(CommissionType.BILLING) }),
        value: CommissionType.BILLING,
      },
      {
        label: intl.formatMessage({ id: commissionTypeMappings.get(CommissionType.CEASE) }),
        value: CommissionType.CEASE,
      },
    ],
    body: (row: any) => <>{Translation(row.commissionType)}</>,
  },

  {
    field: 'serviceType', header: 'COL.GLOBAL.SERVICE.TYPE.NAME', width: '20%', nonFilterable: false, editable: true,
    filterOptions: [
      {
        label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.NOSERVICE) }),
        value: ServiceTypeName.NOSERVICE,
      },
      {
        label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.ETHCAPACITYTRANSPORT) }),
        value: ServiceTypeName.ETHCAPACITYTRANSPORT,
      },
      {
        label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.INTERNET) }),
        value: ServiceTypeName.INTERNET,
      },
      {
        label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.DARKFIBER) }),
        value: ServiceTypeName.DARKFIBER,
      },
      {
        label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.COLOCATION) }),
        value: ServiceTypeName.NOSERVICE,
      },
      {
        label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.INFRASTRUCTURE) }),
        value: ServiceTypeName.INFRASTRUCTURE,
      },
      {
        label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.TDMCAPACITYTRANSPORT) }),
        value: ServiceTypeName.TDMCAPACITYTRANSPORT,
      },
      {
        label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.DWDMMCAPACITYTRANSPORT) }),
        value: ServiceTypeName.DWDMMCAPACITYTRANSPORT,
      },
      {
        label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.CAPACITY) }),
        value: ServiceTypeName.CAPACITY,
      },
      {
        label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.TIMELY) }),
        value: ServiceTypeName.TIMELY,
      },
      {
        label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.VSAT) }),
        value: ServiceTypeName.VSAT,
      },
      {
        label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.EQUIPMENT) }),
        value: ServiceTypeName.EQUIPMENT,
      },
      {
        label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.ENERGY) }),
        value: ServiceTypeName.ENERGY,
      },
      {
        label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.CROSSCONNECTION) }),
        value: ServiceTypeName.CROSSCONNECTION,
      },
      {
        label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.FTTH) }),
        value: ServiceTypeName.FTTH,
      },
      {
        label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.TOWERING) }),
        value: ServiceTypeName.TOWERING,
      },
      {
        label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.CLOUD) }),
        value: ServiceTypeName.CLOUD,
      },
      {
        label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.SECURITY) }),
        value: ServiceTypeName.SECURITY,
      },
      {
        label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.GPON_FTTH) }),
        value: ServiceTypeName.GPON_FTTH,
      },
      {
        label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.REMOTEHANDS) }),
        value: ServiceTypeName.REMOTEHANDS,
      },
      {
        label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.OUTSOURCING) }),
        value: ServiceTypeName.OUTSOURCING,
      },
      {
        label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.OTHERS) }),
        value: ServiceTypeName.OTHERS,
      },
      {
        label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.UNDERGROUNDCHAMBER) }),
        value: ServiceTypeName.UNDERGROUNDCHAMBER,
      },
      {
        label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.NOTREPORTED) }),
        value: ServiceTypeName.NOTREPORTED,
      },
    ]
    // body: (row: any) => <PercentangeCommission percentange={row.percentage} />
  },
  {
    field: 'clientType', header: 'COL.GLOBAL.TYPE.NAME', width: '20%', nonFilterable: false, editable: true,
    filterOptions: [
      {
        label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.GOVERNMENT) }),
        value: ClientTypeName.GOVERNMENT,
      },
      {
        label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.OPERATOR) }),
        value: ClientTypeName.OPERATOR,
      },
      {
        label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.OTHERS) }),
        value: ClientTypeName.OTHERS,
      },
      {
        label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.PROVIDER) }),
        value: ClientTypeName.PROVIDER,
      },
      {
        label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.OTT) }),
        value: ClientTypeName.OTT,
      },
      {
        label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.BPO) }),
        value: ClientTypeName.BPO,
      },
      {
        label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.ELECTRIC) }),
        value: ClientTypeName.ELECTRIC,
      },
      {
        label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.CORPORATE) }),
        value: ClientTypeName.CORPORATE,
      },
      {
        label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.DATACENTER) }),
        value: ClientTypeName.DATACENTER,
      },
      {
        label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.INTEGRATOR) }),
        value: ClientTypeName.INTEGRATOR,
      },
      {
        label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.LOCALOPERATOR) }),
        value: ClientTypeName.LOCALOPERATOR,
      },
      {
        label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.INDIRECTCLIENT) }),
        value: ClientTypeName.INDIRECTCLIENT,
      },
      {
        label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.UFINETGROUP) }),
        value: ClientTypeName.UFINETGROUP,
      },
      {
        label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.GLOBALOPERATOR) }),
        value: ClientTypeName.GLOBALOPERATOR,
      },
      {
        label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.REST) }),
        value: ClientTypeName.REST,
      },
      {
        label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.EXTERNAL) }),
        value: ClientTypeName.EXTERNAL,
      },
      {
        label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.POLEINFRASTRUCTURE) }),
        value: ClientTypeName.POLEINFRASTRUCTURE,
      },
      {
        label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.ISP) }),
        value: ClientTypeName.ISP,
      },
    ]
    // body: (row: any) => <PercentangeCommission percentange={row.percentage} />
  },
  {
    field: 'commissionPercentage', header: 'COL.GLOBAL.PERCENTAGE', width: '20%', nonFilterable: true, editable: true,
    body: (row: any) => <PercentangeCommission percentange={row.commissionPercentage} />
  },
]


