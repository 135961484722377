import { DataTablePageEvent } from "primereact/datatable"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"

import { useIntl } from "react-intl"
import { UseQueryGetAuthorizingChainData, useMutationDeleteAuthorizingChain } from "request"
import { Table, UfinetBox, UfinetButton, UfinetModal, UfinetSectionBox, formatTableFilters, getEditPencilBodyTemplate, tableSortValueToSortDirection } from "ufinet-web-components"
import { AuthContext, Authority, useModal } from "ufinet-web-functions"
import { useTranslation } from "utils/translation/Translation"
import { colsAuthorizingChainColData } from "./AuthorizingChainColData"
import { AuthorizingChainTypes, } from '../../../types/configuration/ConfigurationTypes';
import { CreateAuthorizingChainModalForm, UpdateAuthorizingChainModalForm } from "./modals"

import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons"
import { toast } from "react-toastify"
import { ErrorResponse } from "types/typesAndValues"




export const AuthorizingChainPage = () => {
  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  const permissions = Authority.getCommissionConfigPermissions(roles)

  const translate = useTranslation()
  const intl = useIntl()

  const colsAuthorizincgChain = useMemo(() => colsAuthorizingChainColData(intl), [intl])


  const [selectedValues, setSelectedValues] = useState<AuthorizingChainTypes[]>([])
  const [paging, setPaging] = useState<any>()
  const [filterData, setFilterData] = useState<any>()
  const [createModal, showCreateModal, hideCreateModal] = useModal()
  const [updateModal, showUpdateModal, hideUpdateModal] = useModal()

  const onSelectionChange = useCallback((values: any) => setSelectedValues(values), [])



  const [authorizingChainInfo, setAuthorizingChainInfo] = useState<AuthorizingChainTypes>()

  const { data: authorizingChainData, isLoading: isLoadingAuthorizingChain, refetch } = UseQueryGetAuthorizingChainData(filterData)


  const cbSuccessAuthoringChain = ({ data }: any) => {
    toast.success(data.message)
    refetch()
  }

  const { mutate: deleteAuthorizingChain, isLoading: isLoadingDeleteAuthorizingChain, isError, error: errorMessage } = useMutationDeleteAuthorizingChain(cbSuccessAuthoringChain)



  const showToastErrMessage = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse;
      toast.error(`${translate("ERROR.MESSAGE")} ${errorResponse.errorCode}`, { toastId: 'Error5' });
      hideUpdateModal()
    }
  }

  useEffect(() => {
    if (authorizingChainData) {
      const parsePagination = JSON.parse(authorizingChainData?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: filterData && filterData.pageSize ? filterData.pageSize : parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [authorizingChainData])






  const onFilterClear = (): void => {
    setFilterData({})
  }

  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)
    setFilterData(formattedFilters)
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({ ...filterData, pageNumber: e.page, pageSize: e.rows })
  }

  const onSort = (e: DataTablePageEvent): void => {
    // const finalField = UserPropertyMap.get(e.sortField)
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })
  }


  const deleteAuthorizingChainConfig = () => {
    if (selectedValues && selectedValues.length > 0) {
      const firstElement = selectedValues[0].idAuthorizingChain
      deleteAuthorizingChain(firstElement)
      setSelectedValues([])
    }
  }




  const getHeadersButtons = () => (
    <>
      {
        permissions.canWrite && (
          <>
            <UfinetButton
              className="me-3"
              icon={faPlus}
              onClick={() => {
                showCreateModal()
              }}
              content={translate('CREATE.MODAL')}
            />
          </>
        )
      }
      {
        permissions.canWrite && selectedValues && selectedValues.length > 0 && (
          <>
            <UfinetButton
              isDisabled={isLoadingDeleteAuthorizingChain}
              className="me-3"
              icon={faTrash}
              onClick={() => {
                deleteAuthorizingChainConfig()
              }}
              content={translate('DELETE.BUTTON')}
            />
          </>
        )
      }
    </>
  )


  return (
    <UfinetBox>
      {isError && showToastErrMessage()}

      <UfinetSectionBox>

        <Table
          dataKey="idAuthorizingChain"
          selectedValues={selectedValues}
          onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
          // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
          content={authorizingChainData?.data}
          cols={colsAuthorizincgChain}
          headerButtons={getHeadersButtons()}
          onFilterClear={onFilterClear}
          rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
          emptyMessage={isLoadingAuthorizingChain || isLoadingDeleteAuthorizingChain ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
          actionBodyTemplate={
            permissions.canUpdate
              ? (auhtorizersConfigData: AuthorizingChainTypes) =>
                getEditPencilBodyTemplate(auhtorizersConfigData, () => {
                  setSelectedValues([])
                  setAuthorizingChainInfo(auhtorizersConfigData)
                  // setAuthorizerId(auhtorizersConfigData.id)
                  showUpdateModal()
                })
              : undefined
          }
          lazySettings={
            paging && {
              ...paging,
              loading: isLoadingAuthorizingChain || isLoadingDeleteAuthorizingChain,
              onFilter,
              onPage,
              onSort,
            }
          }
        />

        <UfinetModal
          size="lg"
          show={createModal}
          handleClose={hideCreateModal}
          title={translate('CREATE.MODAL')}
        >
          <CreateAuthorizingChainModalForm refetch={refetch} hideCreateModal={hideCreateModal} />
        </UfinetModal>

        <UfinetModal
          size="lg"
          show={updateModal}
          handleClose={hideUpdateModal}
          title={translate('UPDATE.MODAL')}
        >
          <UpdateAuthorizingChainModalForm refetch={refetch} hideUpdateModal={hideUpdateModal} authorizingChainInfo={authorizingChainInfo} />
        </UfinetModal>

      </UfinetSectionBox>
    </UfinetBox>
  )
}
