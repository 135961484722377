import { ColData } from 'ufinet-web-components'
import { IsActiveValues, isActiveMappings } from 'types/typesAndValues';
import { IntlShape } from 'react-intl';
import { IsActive } from 'lib/isActive/IsActive';
import { AuthorizersTypes } from 'types/configuration';



export const colsAuthorizersColData = (intl: IntlShape): ColData[] => [
  // header is the id to search the translate based on the data received
  // removing or typing the word false in the property nonFilterable is gonna show in the table header the filter
  // body you can customize the data you want to show, accept react component or just a simple html

  {
    field: 'name', header: 'COL.CONFIG.AUTHORIZERS', width: '190px', nonFilterable: false, editable: true, nonSortable: false, filterType: 'text',
    // body: (row: any) => <PercentangeCommission percentange={row.percentage} />
  },
  {
    field: 'email', header: 'COL.CONFIG.EMAIL', width: '190px', nonFilterable: false, editable: true, nonSortable: false, filterType: 'text',
    // body: (row: any) => <PercentangeCommission percentange={row.percentage} />
  },
  {
    field: 'minPendingApprovals', header: 'COL.CONFIG.MINPENDINGAPPROVALS', width: '190px', nonFilterable: true, editable: true, nonSortable: false, filterType: 'number',
    // body: (row: any) => <PercentangeCommission percentange={row.percentage} />
  },
  {
    field: 'minPendingAmount', header: 'COL.CONFIG.MINPENDINGAMOUNT', width: '190px', nonFilterable: true, editable: true, nonSortable: false, filterType: 'number',
    // body: (row: any) => <PercentangeCommission percentange={row.percentage} />
  },
  {
    field: 'isActive', header: 'COL.CONFIG.ISACTIVE', width: '190px', nonFilterable: false, editable: true, nonSortable: false, filterType: 'select',
    filterOptions: [
      {
        label: intl.formatMessage({ id: isActiveMappings.get(IsActiveValues.FALSE) }),
        value: IsActiveValues.FALSE,
      },
      {
        label: intl.formatMessage({ id: isActiveMappings.get(IsActiveValues.TRUE) }),
        value: IsActiveValues.TRUE,
      }
    ],
    body: (row: AuthorizersTypes) => <IsActive isActive={row.isActive} />

  },
]


