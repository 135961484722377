import React, { useRef, useCallback, useMemo } from 'react'
import { useFormik } from 'formik'
import { UfinetActionButton, UfinetActionButtonHandle, UfinetInput } from 'ufinet-web-components';
import { onFormikChanges, IFileUploadData, onFormikNumberChanges } from 'ufinet-web-functions';
import { UseCreateMultiplierConfig } from 'request';
import * as Yup from 'yup'
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { ErrorResponse, } from 'types/typesAndValues';
import { useTranslation } from 'utils/translation/Translation';

interface Props {
  hideModal: () => void;
  refetch: () => void
}

interface File {
  file: IFileUploadData
}



type CreateFormData = {
  compliancePercentageMin: number | string
  compliancePercentageMax: number | string
  multiplierFactor: number | string
}

const CreateMultiplierConfigForm = ({ hideModal, refetch }: Props) => {





  const translate = useTranslation()
  const intl = useIntl()





  const actionButtonRef = useRef<UfinetActionButtonHandle>(null)




  const cbSuccessCreateMultiplierConfig = (data: any) => {
    actionButtonRef.current?.changeActionStatus(false)
    toast.success(intl.formatMessage({ id: 'SENDED' }))
    refetch()
    hideModal()
  }

  const { mutate: createMultiplierConfig, isError, error: errorMessage } = UseCreateMultiplierConfig(cbSuccessCreateMultiplierConfig)


  const showErrMessage = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse;
      toast.error(`${intl.formatMessage({ id: "ERROR.MESSAGE" },)} ${errorResponse.message}`, { toastId: 'Error2' });
      hideModal()
    }
  }

  const dataFormSchema = Yup.object().shape({
    compliancePercentageMin: Yup.number().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
    compliancePercentageMax: Yup.number().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
    multiplierFactor: Yup.number().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
  })






  const sendData = (values: any) => {
    actionButtonRef.current?.changeActionStatus(true)
    createMultiplierConfig({
      id: +values.id,
      compliancePercentageMin: +values.compliancePercentageMin,
      compliancePercentageMax: +values.compliancePercentageMax,
      multiplierFactor: +values.multiplierFactor,
    })
  }

  const onChange = useCallback(onFormikChanges, [])
  const onNumberChange = useCallback(onFormikNumberChanges, [])


  const initialValues: CreateFormData = useMemo<CreateFormData>(
    () => ({
      compliancePercentageMin: '',
      compliancePercentageMax: '',
      multiplierFactor: '',
    }),
    []
  )



  const formik = useFormik<CreateFormData>({
    initialValues,
    validationSchema: dataFormSchema,
    onSubmit: sendData,
    validateOnChange: false,
    validateOnBlur: false,
  })


  return (
    <>
      {isError && showErrMessage()}
      <form onSubmit={formik.handleSubmit}>
        <div className='row'>
          <div className='row'>

            <UfinetInput
              error={formik.errors.compliancePercentageMin}
              type="number"
              labelTitle={translate('COL.CONFIG.COMPLIANCEPERCENTAGEMIN')}
              className="col-6"
              onChange={onNumberChange(formik, 'compliancePercentageMin')}
              value={formik.values.compliancePercentageMin}
              solid={false}
            />

            <UfinetInput
              error={formik.errors.compliancePercentageMax}
              type="decimal"
              labelTitle={translate('COL.CONFIG.COMPLIANCEPERCENTAGEMAX')}
              className="col-6"
              onChange={onNumberChange(formik, 'compliancePercentageMax')}
              value={formik.values.compliancePercentageMax}
              solid={false}
            />


            <div className='row mt-5'>

              <UfinetInput
                error={formik.errors.multiplierFactor}
                type="decimal"
                labelTitle={translate('COL.CONFIG.MULTIPLIERFACTOR')}
                className="col-6"
                onChange={onNumberChange(formik, 'multiplierFactor')}
                value={formik.values.multiplierFactor}
                solid={false}
              />


            </div>

            <UfinetActionButton
              ref={actionButtonRef}
              className='mt-5'
              content={translate('CREATE.MULTIPLIER.CONFIG')}
            />
          </div>
        </div>
      </form>
    </>
  )
}

export { CreateMultiplierConfigForm }


