/* eslint-disable react/jsx-no-target-blank */
import { FC, useContext, useState } from 'react'
import { useIntl } from 'react-intl'

import {
  PATH_COMMISSIONS_IN_PROCESS_PAGE,
  PATH_COMISSIONS_MODEL_IN_PROCESS_SYSTEM,
  PATH_COMMISSIONS_IN_PROCESS_SYSTEM_PAGE,
  PATH_COMISSIONS_PENDING_SYSTEM_MODEL,
  PATH_SYSTEM_PENDING,
  PATH_COMISSIONS_APPROVED_SYSTEM_MODEL,
  PATH_APPROVED_SYSTEM_PAGE,
  PATH_COMISSIONS_REJECTED_SYSTEM_MODEL,
  PATH_REJECTED_SYSTEM,
  PATH_COMISSIONS_IN_PROCESS_MODEL,
  PATH_PENDING,
  PATH_COMISSIONS_PENDING_MODEL,
  PATH_APPROVED_PAGE,
  PATH_COMISSIONS_SETTLED_ACCOUNTS_MODEL,
  PATH_REJECTED,
  PATH_COMISSIONS_REJECTED_MODEL,
  PATH_GOALS,
  PATH_CONFIGURATION_GLOBAL,
  PATH_CONFIGURATION_GLOBAL_MODEL,
  PATH_PERCENTAGETYPECOMMMISION_PAGE,
  PATH_PERCENTAGETYPECOMMISION_MODEL,
  PATH_PENDING_AUTHORIZATION_MODEL,
  PATH_PENDING_AUTHORIZATION_PAGE,
  PATH_MULTIPLICATOR_MODEL,
  PATH_MULTIPLICATOR_GOALS_PAGE,
  PATH_LIQUIDATE_ALL_MODEL,
  PATH_LIQUIDATE_PAID_PAGE,
  PATH_NEXT_PAYMENT_PAGE,
  PATH_COMISSIONS_NEXT_PAYMENT_MODEL,
  PATH_LIQUIDATE_PENDING_PAGE,
  PATH_LIQUIDATE_PENDING_MODEL,
  PATH_LIQUIDATE_REJECTED_PAGE,
  PATH_LIQUIDATE_REJECTED_MODEL,
  PATH_RESUME_AUTHORIZATION_PAGE,
  PATH_RESUME_AUTHORIZATION_MODEL,
  PATH_REJECTED_AUTHORIZATION_PAGE,
  PATH_REJECTED_AUTHORIZATION_MODEL,
  PATH_LIQUIDATE_HISTORICALS_PAGE,
  PATH_LIQUIDATE_HISTORICALS_MODEL,
  PATH_NEXT_PAYMENTS_SYSTEM_PAGE,
  PATH_COMISSIONS_NEXT_PAYMENTS_SYSTEM_MODEL,
  PATH_LIQUIDATE_SYSTEM_HISTORICALS_MODEL,
  PATH_LIQUIDATE_HISTORICALS_SYSTEM_PAGE,
  PATH_MULTIPLICATOR_HISTORICALS_PAGE,
  PATH_MULTIPLICATOR_SYSTEM_HISTORICALS_MODEL,
  PATH_AUTHORIZATION_SYSTEM_APPROVED_PAGE,
  PATH_AUTHORIZATION_SYSTEM_APPROVED_MODEL,
  PATH_AUTHORIZATION_SYSTEM_PENDING_PAGE,
  PATH_AUTHORIZATION_SYSTEM_PENDING_MODEL,
  PATH_AUTHORIZATION_SYSTEM_REJECTED_PAGE,
  PATH_AUTHORIZATION_SYSTEM_REJECTED_MODEL,
  PATH_MULTIPLIER_CONFIG_PAGE,
  PATH_MULTIPLIER_CONFIG_MODEL,
  PATH_AUTHORIZERS_CONFIG_MODEL,
  PATH_AUTHORIZERS_PAGE,
  PATH_AUTHORIZING_CHAIN_PAGE,
  PATH_AUTHORIZING_CHAIN_CONFIG_MODEL,
  PATH_AUTHORIZATION_SYSTEM_RESUME_PAGE,
  PATH_AUTHORIZATION_SYSTEM_RESUME_MODEL,
  PATH_PERCENTAGETYPECOMMMISION_KAM_PAGE,
  PATH_PERCENTAGETYPECOMMISION_KAM_MODEL,
  PATH_PERCENTANGE_COMMISSION_KAM_CONFIGURATION_PAGE,
  PATH_PERCENTAGECOMMISION_KAM_MODEL,
  PATH_MULTIPLIER_CONFIG_KAM_PAGE,
  PATH_MULTIPLIER_CONFIG_KAM_MODEL,
  PATH_PAID_PAGE,
  PATH_COMISSIONS_PAID_MODEL,
  PATH_PAID_SYSTEM_PAGE,
  PATH_COMISSIONS_PAID_SYSTEM_MODEL,
  PATH_CONFIG_LIQUIDATE_PAGE,
  PATH_CONFIG_LIQUIDATE_MODEL,
  PATH_CONFIG_USERS_RRHH_PAGE,
  PATH_CONFIG_USERS_RRHH_MODEL,
  PATH_APPROVE_AUTHORIZATION_PAGE,
  PATH_APPROVE_AUTHORIZATION_MODEL,
  routerNameMap
} from '../../routing/protected/PrivateRoutes'

import {
  faCircle,
  faArrowsToCircle,
  faCommentsDollar,
  faStamp,
  faMoneyBillTransfer,
  faMoneyCheckDollar,
  faList,
  faSackDollar,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from 'react-router-dom'
import { AsideMenuItem, AsideMenuItemWithSub, IMenuItem } from 'ufinet-web-components'
import { AuthContext, Authority } from 'ufinet-web-functions'

enum AsideMenu {
  NONE,
  TEMPLATE,
  COMMISSIONS,
  COMMISSIONS_SYSTEM,
  GOALS,
  CONFIGURATION,
  CONFIGURATION_KAM,
  AUTHORIZATION,
  AUTHORIZATION_SYS,
  MULTIPLICATOR,
  MULTIPLICATORSYS,
  LIQUIDATEKAM,
  LIQUIDATERRHH,
  LIQUIDATESYS,
}

const AsideMenuMain: FC = () => {
  const intl = useIntl()
  const location = useLocation()

  const { userData } = useContext(AuthContext)

  const [active, setActive] = useState(AsideMenu.NONE)

  const activeMenu = (menu: AsideMenu) => () => setActive(menu)
  const isCommissionsActive = () => active === AsideMenu.COMMISSIONS
  const isCommissionsSystemActive = () => active === AsideMenu.COMMISSIONS_SYSTEM
  const isMonthSalesActive = () => active === AsideMenu.GOALS
  const isConfigurationActive = () => active === AsideMenu.CONFIGURATION
  const isConfigurationKamActive = () => active === AsideMenu.CONFIGURATION_KAM
  const isAuthorizationActive = () => active === AsideMenu.AUTHORIZATION
  const isAuthorizationSysActive = () => active === AsideMenu.AUTHORIZATION_SYS
  const isMultiplicatorActive = () => active === AsideMenu.MULTIPLICATOR
  const isMultiplicatorSysActive = () => active === AsideMenu.MULTIPLICATORSYS
  const isLiquidateKAMActive = () => active === AsideMenu.LIQUIDATEKAM
  const isLiquidateRRHHActive = () => active === AsideMenu.LIQUIDATERRHH
  const isLiquidateSysActive = () => active === AsideMenu.LIQUIDATESYS


  const roles = userData?.authorities || []
  const commissionSystemPermission = Authority.getCommissionCmSystemPermissions(roles)
  const kamCommissionPermission = Authority.getCommissionKamPermissions(roles)
  const authorizationCommissionPermission = Authority.getCommissionAuthorizationPermissions(roles)
  const authorizationSysCommissionPermission = Authority.getCommissionAuthorizationSysPermissions(roles)
  const goalsCommissionPermission = Authority.getCommissionGoalsPermissions(roles)
  const configCommissionPermission = Authority.getCommissionConfigPermissions(roles)
  const configCommissionKamPermission = Authority.getCommissionsPermissions(roles)
  const multiplicatorCommissionPermission = Authority.getCommissionMultiplicatorPermissions(roles)
  const multiplicatorSystemCommissionPermission = Authority.getCommissionMultiplierSysPermissions(roles)
  const liquidateRRHHCommissionPermission = Authority.getCommissionLiquidateRRHHPermissions(roles)
  const liquidateKamCommissionPermission = Authority.getCommissionLiquidatePermissions(roles)
  const liquidateSystemCommissionPermission = Authority.getCommissionLiquidateSysPermissions(roles)

  const menuCommissionSystemItems: IMenuItem[] = [
    {
      // to: equals to path at privateRoutes to render the properly component
      to: PATH_COMISSIONS_NEXT_PAYMENTS_SYSTEM_MODEL,
      // title is the text you show at the asideMenu
      title: routerNameMap.get(PATH_NEXT_PAYMENTS_SYSTEM_PAGE),
      icon: faCircle
    },
    {
      // to: equals to path at privateRoutes to render the properly component
      to: PATH_COMISSIONS_APPROVED_SYSTEM_MODEL,
      // title is the text you show at the asideMenu
      title: routerNameMap.get(PATH_APPROVED_SYSTEM_PAGE),
      icon: faCircle
    },
    {
      // to: equals to path at privateRoutes to render the properly component
      to: PATH_COMISSIONS_PAID_SYSTEM_MODEL,
      // title is the text you show at the asideMenu
      title: routerNameMap.get(PATH_PAID_SYSTEM_PAGE),
      icon: faCircle
    },
    {
      // to: equals to path at privateRoutes to render the properly component
      to: PATH_COMISSIONS_REJECTED_SYSTEM_MODEL,
      // title is the text you show at the asideMenu
      title: routerNameMap.get(PATH_REJECTED_SYSTEM),
      icon: faCircle
    },
    {
      // to: equals to path at privateRoutes to render the properly component
      to: PATH_COMISSIONS_PENDING_SYSTEM_MODEL,
      // title is the text you show at the asideMenu
      title: routerNameMap.get(PATH_SYSTEM_PENDING),
      icon: faCircle
    },
    {
      // to: equals to path at privateRoutes to render the properly component
      to: PATH_COMISSIONS_MODEL_IN_PROCESS_SYSTEM,
      // title is the text you show at the asideMenu
      title: routerNameMap.get(PATH_COMMISSIONS_IN_PROCESS_SYSTEM_PAGE),
      icon: faCircle
    },

  ].map((it) => ({ ...it, hidden: !commissionSystemPermission.canRead }))

  const menuCommissionKamItems: IMenuItem[] = [
    {
      to: PATH_COMISSIONS_NEXT_PAYMENT_MODEL,
      title: routerNameMap.get(PATH_NEXT_PAYMENT_PAGE),
      icon: faCircle,
    },
    {
      // to: equals to path at privateRoutes to render the properly component
      to: PATH_COMISSIONS_SETTLED_ACCOUNTS_MODEL,
      // title is the text you show at the asideMenu
      title: routerNameMap.get(PATH_APPROVED_PAGE),
      icon: faCircle
    },

    {
      // to: equals to path at privateRoutes to render the properly component
      to: PATH_COMISSIONS_PAID_MODEL,
      // title is the text you show at the asideMenu
      title: routerNameMap.get(PATH_PAID_PAGE),
      icon: faCircle
    },



    {
      // to: equals to path at privateRoutes to render the properly component
      to: PATH_COMISSIONS_REJECTED_MODEL,
      // title is the text you show at the asideMenu
      title: routerNameMap.get(PATH_REJECTED),
      icon: faCircle
    },
    {
      // to: equals to path at privateRoutes to render the properly component
      to: PATH_COMISSIONS_PENDING_MODEL,
      // title is the text you show at the asideMenu
      title: routerNameMap.get(PATH_PENDING),
      icon: faCircle
    },
    {
      // to: equals to path at privateRoutes to render the properly component
      to: PATH_COMISSIONS_IN_PROCESS_MODEL,
      // title is the text you show at the asideMenu
      title: routerNameMap.get(PATH_COMMISSIONS_IN_PROCESS_PAGE),
      icon: faCircle
    },

  ].map((it) => ({ ...it, hidden: !kamCommissionPermission.canRead }))

  const menuGoalsItems: IMenuItem[] = [
    {// to: equals to path at privateRoutes to render the properly component
      to: PATH_GOALS,
      // title is the text you show at the asideMenu
      title: routerNameMap.get(PATH_GOALS),
      icon: faCircle
    },

  ].map((it) => ({ ...it, hidden: !goalsCommissionPermission.canRead }))

  const menuConfigurationItems: IMenuItem[] = [
    {// to: equals to path at privateRoutes to render the properly component
      to: PATH_CONFIGURATION_GLOBAL_MODEL,
      // title is the text you show at the asideMenu
      title: routerNameMap.get(PATH_CONFIGURATION_GLOBAL),
      icon: faCircle
    },
    {// to: equals to path at privateRoutes to render the properly component
      to: PATH_PERCENTAGETYPECOMMISION_MODEL,
      // title is the text you show at the asideMenu
      title: routerNameMap.get(PATH_PERCENTAGETYPECOMMMISION_PAGE),
      icon: faCircle
    },
    {// to: equals to path at privateRoutes to render the properly component
      to: PATH_MULTIPLIER_CONFIG_MODEL,
      // title is the text you show at the asideMenu
      title: routerNameMap.get(PATH_MULTIPLIER_CONFIG_PAGE),
      icon: faCircle
    },
    {// to: equals to path at privateRoutes to render the properly component
      to: PATH_AUTHORIZERS_CONFIG_MODEL,
      // title is the text you show at the asideMenu
      title: routerNameMap.get(PATH_AUTHORIZERS_PAGE),
      icon: faCircle
    },
    {// to: equals to path at privateRoutes to render the properly component
      to: PATH_AUTHORIZING_CHAIN_CONFIG_MODEL,
      // title is the text you show at the asideMenu
      title: routerNameMap.get(PATH_AUTHORIZING_CHAIN_PAGE),
      icon: faCircle
    },
    {// to: equals to path at privateRoutes to render the properly component
      to: PATH_CONFIG_LIQUIDATE_MODEL,
      // title is the text you show at the asideMenu
      title: routerNameMap.get(PATH_CONFIG_LIQUIDATE_PAGE),
      icon: faCircle
    },
    {// to: equals to path at privateRoutes to render the properly component
      to: PATH_CONFIG_USERS_RRHH_MODEL,
      // title is the text you show at the asideMenu
      title: routerNameMap.get(PATH_CONFIG_USERS_RRHH_PAGE),
      icon: faCircle
    },

  ].map((it) => ({ ...it, hidden: !configCommissionPermission.canRead }))

  const menuConfigurationKamItems: IMenuItem[] = [
    {// to: equals to path at privateRoutes to render the properly component
      to: PATH_PERCENTAGECOMMISION_KAM_MODEL,
      // title is the text you show at the asideMenu
      title: routerNameMap.get(PATH_PERCENTANGE_COMMISSION_KAM_CONFIGURATION_PAGE),
      icon: faCircle
    },
    {// to: equals to path at privateRoutes to render the properly component
      to: PATH_PERCENTAGETYPECOMMISION_KAM_MODEL,
      // title is the text you show at the asideMenu
      title: routerNameMap.get(PATH_PERCENTAGETYPECOMMMISION_KAM_PAGE),
      icon: faCircle
    },
    {// to: equals to path at privateRoutes to render the properly component
      to: PATH_MULTIPLIER_CONFIG_KAM_MODEL,
      // title is the text you show at the asideMenu
      title: routerNameMap.get(PATH_MULTIPLIER_CONFIG_KAM_PAGE),
      icon: faCircle
    },

  ].map((it) => ({ ...it, hidden: !configCommissionKamPermission.canRead }))

  const menuAuthorizationItems: IMenuItem[] = [
    {
      to: PATH_RESUME_AUTHORIZATION_MODEL,
      title: routerNameMap.get(PATH_RESUME_AUTHORIZATION_PAGE),
      icon: faCircle
    },
    {
      to: PATH_APPROVE_AUTHORIZATION_MODEL,
      title: routerNameMap.get(PATH_APPROVE_AUTHORIZATION_PAGE),
      icon: faCircle
    },
    {
      to: PATH_PENDING_AUTHORIZATION_MODEL,
      title: routerNameMap.get(PATH_PENDING_AUTHORIZATION_PAGE),
      icon: faCircle
    },
    {
      to: PATH_REJECTED_AUTHORIZATION_MODEL,
      title: routerNameMap.get(PATH_REJECTED_AUTHORIZATION_PAGE),
      icon: faCircle
    },

  ].map((it) => ({ ...it, hidden: !authorizationCommissionPermission.canRead }))

  const menuAuthorizationSysItems: IMenuItem[] = [
    {
      to: PATH_AUTHORIZATION_SYSTEM_RESUME_MODEL,
      title: routerNameMap.get(PATH_AUTHORIZATION_SYSTEM_RESUME_PAGE),
      icon: faCircle
    },
    {
      to: PATH_AUTHORIZATION_SYSTEM_APPROVED_MODEL,
      title: routerNameMap.get(PATH_AUTHORIZATION_SYSTEM_APPROVED_PAGE),
      icon: faCircle
    },
    {
      to: PATH_AUTHORIZATION_SYSTEM_PENDING_MODEL,
      title: routerNameMap.get(PATH_AUTHORIZATION_SYSTEM_PENDING_PAGE),
      icon: faCircle
    },
    {
      to: PATH_AUTHORIZATION_SYSTEM_REJECTED_MODEL,
      title: routerNameMap.get(PATH_AUTHORIZATION_SYSTEM_REJECTED_PAGE),
      icon: faCircle
    },


  ].map((it) => ({ ...it, hidden: !authorizationSysCommissionPermission.canRead }))

  const menuMultplicatorItems: IMenuItem[] = [
    {// to: equals to path at privateRoutes to render the properly component
      to: PATH_MULTIPLICATOR_MODEL,
      // title is the text you show at the asideMenu
      title: routerNameMap.get(PATH_MULTIPLICATOR_GOALS_PAGE),
      icon: faCircle
    }

  ].map((it) => ({ ...it, hidden: !multiplicatorCommissionPermission.canRead }))

  const menuMultplicatorSystemItems: IMenuItem[] = [
    {// to: equals to path at privateRoutes to render the properly component
      to: PATH_MULTIPLICATOR_SYSTEM_HISTORICALS_MODEL,
      // title is the text you show at the asideMenu
      title: routerNameMap.get(PATH_MULTIPLICATOR_HISTORICALS_PAGE),
      icon: faCircle
    }

  ].map((it) => ({ ...it, hidden: !multiplicatorSystemCommissionPermission.canRead }))

  const menuLiquidatesKamItems: IMenuItem[] = [
    {
      to: PATH_LIQUIDATE_HISTORICALS_MODEL,
      title: routerNameMap.get(PATH_LIQUIDATE_HISTORICALS_PAGE),
      icon: faCircle
    },

  ].map((it) => ({ ...it, hidden: !liquidateKamCommissionPermission.canRead }))


  const menuLiquidatesRRHHItems: IMenuItem[] = [
    {
      to: PATH_LIQUIDATE_PENDING_MODEL,
      title: routerNameMap.get(PATH_LIQUIDATE_PENDING_PAGE),
      icon: faCircle
    },
    {
      to: PATH_LIQUIDATE_ALL_MODEL,
      title: routerNameMap.get(PATH_LIQUIDATE_PAID_PAGE),
      icon: faCircle
    },
    {
      to: PATH_LIQUIDATE_REJECTED_MODEL,
      title: routerNameMap.get(PATH_LIQUIDATE_REJECTED_PAGE),
      icon: faCircle
    },

  ].map((it) => ({ ...it, hidden: !liquidateRRHHCommissionPermission.canRead }))

  const menuLiquidatesSysItems: IMenuItem[] = [
    {
      to: PATH_LIQUIDATE_SYSTEM_HISTORICALS_MODEL,
      title: routerNameMap.get(PATH_LIQUIDATE_HISTORICALS_SYSTEM_PAGE),
      icon: faCircle
    },

  ].map((it) => ({ ...it, hidden: !liquidateSystemCommissionPermission.canRead }))





  return (
    <>

      {
        commissionSystemPermission.canRead && (
          <AsideMenuItemWithSub
            icon={<FontAwesomeIcon icon={faCommentsDollar} className="fs-2x" />}
            title={intl.formatMessage({
              id: 'MENU.COMMISIONS.SYSTEM'
            })}
            active={isCommissionsSystemActive()}
          >
            {
              menuCommissionSystemItems
                .filter((item) => !item.hidden)
                .map((item, index) => (
                  <AsideMenuItem
                    routerLocation={location}
                    key={item.to}
                    to={item.to}
                    icon={item.icon}
                    title={intl.formatMessage({ id: item.title })}
                    onClick={activeMenu(AsideMenu.COMMISSIONS_SYSTEM)}
                  />
                ))
            }
          </AsideMenuItemWithSub>
        )
      }

      {
        kamCommissionPermission.canRead && (
          <AsideMenuItemWithSub
            icon={<FontAwesomeIcon icon={faSackDollar} className="fs-2x" />}
            title={intl.formatMessage({
              id: 'MENU.COMMISSIONS'
            })}
            active={isCommissionsActive()}
          >
            {
              menuCommissionKamItems
                .filter((item) => !item.hidden)
                .map((item, index) => (
                  <AsideMenuItem
                    routerLocation={location}
                    key={item.to}
                    to={item.to}
                    icon={item.icon}
                    title={intl.formatMessage({ id: item.title })}
                    onClick={activeMenu(AsideMenu.COMMISSIONS)}
                  />
                ))
            }
          </AsideMenuItemWithSub>
        )
      }

      {
        goalsCommissionPermission.canRead && (
          <AsideMenuItemWithSub
            icon={<FontAwesomeIcon icon={faList} className="fs-2x" />}
            title={intl.formatMessage({
              id: 'MENU.MONTH.SALES'
            })}
            active={isMonthSalesActive()}
          >
            {
              menuGoalsItems
                .filter((item) => !item.hidden)
                .map((item, index) => (
                  <AsideMenuItem
                    routerLocation={location}
                    key={item.to}
                    to={item.to}
                    icon={item.icon}
                    title={intl.formatMessage({ id: item.title })}
                    onClick={activeMenu(AsideMenu.GOALS)}
                  />
                ))
            }
          </AsideMenuItemWithSub>
        )
      }

      {
        configCommissionPermission.canRead && (
          <AsideMenuItemWithSub
            icon={<FontAwesomeIcon icon={faList} className="fs-2x" />}
            title={intl.formatMessage({
              id: 'MENU.CONFIGURATION'
            })}
            active={isConfigurationActive()}
          >
            {
              menuConfigurationItems
                .filter((item) => !item.hidden)
                .map((item, index) => (
                  <AsideMenuItem
                    routerLocation={location}
                    key={item.to}
                    to={item.to}
                    icon={item.icon}
                    title={intl.formatMessage({ id: item.title })}
                    onClick={activeMenu(AsideMenu.CONFIGURATION)}
                  />
                ))
            }
          </AsideMenuItemWithSub>
        )
      }

      {
        configCommissionKamPermission.canRead && (
          <AsideMenuItemWithSub
            icon={<FontAwesomeIcon icon={faList} className="fs-2x" />}
            title={intl.formatMessage({
              id: 'MENU.CONFIGURATION'
            })}
            active={isConfigurationKamActive()}
          >
            {
              menuConfigurationKamItems
                .filter((item) => !item.hidden)
                .map((item, index) => (
                  <AsideMenuItem
                    routerLocation={location}
                    key={item.to}
                    to={item.to}
                    icon={item.icon}
                    title={intl.formatMessage({ id: item.title })}
                    onClick={activeMenu(AsideMenu.CONFIGURATION_KAM)}
                  />
                ))
            }
          </AsideMenuItemWithSub>
        )
      }

      {
        authorizationCommissionPermission.canRead && (
          <AsideMenuItemWithSub
            icon={<FontAwesomeIcon icon={faStamp} className="fs-2x" />}
            title={intl.formatMessage({
              id: 'MENU.AUTHORIZATION'
            })}
            active={isAuthorizationActive()}
          >
            {
              menuAuthorizationItems
                .filter((item) => !item.hidden)
                .map((item, index) => (
                  <AsideMenuItem
                    routerLocation={location}
                    key={item.to}
                    to={item.to}
                    icon={item.icon}
                    title={intl.formatMessage({ id: item.title })}
                    onClick={activeMenu(AsideMenu.AUTHORIZATION)}
                  />
                ))
            }
          </AsideMenuItemWithSub>
        )
      }

      {
        authorizationSysCommissionPermission.canRead && (
          <AsideMenuItemWithSub
            icon={<FontAwesomeIcon icon={faStamp} className="fs-2x" />}
            title={intl.formatMessage({
              id: 'MENU.AUTHORIZATION.SYS'
            })}
            active={isAuthorizationSysActive()}
          >
            {
              menuAuthorizationSysItems
                .filter((item) => !item.hidden)
                .map((item, index) => (
                  <AsideMenuItem
                    routerLocation={location}
                    key={item.to}
                    to={item.to}
                    icon={item.icon}
                    title={intl.formatMessage({ id: item.title })}
                    onClick={activeMenu(AsideMenu.AUTHORIZATION_SYS)}
                  />
                ))
            }
          </AsideMenuItemWithSub>
        )
      }

      {
        multiplicatorCommissionPermission.canRead && (
          <AsideMenuItemWithSub
            icon={<FontAwesomeIcon icon={faArrowsToCircle} className="fs-2x" />}
            title={intl.formatMessage({
              id: 'MENU.MULTIPLICATOR'
            })}
            active={isMultiplicatorActive()}
          >
            {
              menuMultplicatorItems
                .filter((item) => !item.hidden)
                .map((item, index) => (
                  <AsideMenuItem
                    routerLocation={location}
                    key={item.to}
                    to={item.to}
                    icon={item.icon}
                    title={intl.formatMessage({ id: item.title })}
                    onClick={activeMenu(AsideMenu.MULTIPLICATOR)}
                  />
                ))
            }
          </AsideMenuItemWithSub>
        )
      }

      {
        multiplicatorSystemCommissionPermission.canRead && (
          <AsideMenuItemWithSub
            icon={<FontAwesomeIcon icon={faArrowsToCircle} className="fs-2x" />}
            title={intl.formatMessage({
              id: 'MENU.MULTIPLIER.SYSTEM'
            })}
            active={isMultiplicatorSysActive()}
          >
            {
              menuMultplicatorSystemItems
                .filter((item) => !item.hidden)
                .map((item, index) => (
                  <AsideMenuItem
                    routerLocation={location}
                    key={item.to}
                    to={item.to}
                    icon={item.icon}
                    title={intl.formatMessage({ id: item.title })}
                    onClick={activeMenu(AsideMenu.MULTIPLICATORSYS)}
                  />
                ))
            }
          </AsideMenuItemWithSub>
        )
      }


      {
        liquidateKamCommissionPermission.canRead && (
          <AsideMenuItemWithSub
            icon={<FontAwesomeIcon icon={faMoneyBillTransfer} className="fs-2x" />}
            title={intl.formatMessage({
              id: 'MENU.LIQUIDATE'
            })}
            active={isLiquidateKAMActive()}
          >
            {
              menuLiquidatesKamItems
                .filter((item) => !item.hidden)
                .map((item, index) => (
                  <AsideMenuItem
                    routerLocation={location}
                    key={item.to}
                    to={item.to}
                    icon={item.icon}
                    title={intl.formatMessage({ id: item.title })}
                    onClick={activeMenu(AsideMenu.LIQUIDATEKAM)}
                  />
                ))
            }
          </AsideMenuItemWithSub>
        )
      }

      {
        liquidateRRHHCommissionPermission.canRead && (
          <AsideMenuItemWithSub
            icon={<FontAwesomeIcon icon={faMoneyCheckDollar} className="fs-2x" />}
            title={intl.formatMessage({
              id: 'MENU.LIQUIDATE'
            })}
            active={isLiquidateRRHHActive()}
          >
            {
              menuLiquidatesRRHHItems
                .filter((item) => !item.hidden)
                .map((item, index) => (
                  <AsideMenuItem
                    routerLocation={location}
                    key={item.to}
                    to={item.to}
                    icon={item.icon}
                    title={intl.formatMessage({ id: item.title })}
                    onClick={activeMenu(AsideMenu.LIQUIDATERRHH)}
                  />
                ))
            }
          </AsideMenuItemWithSub>
        )
      }

      {
        liquidateSystemCommissionPermission.canRead && (
          <AsideMenuItemWithSub
            icon={<FontAwesomeIcon icon={faMoneyBillTransfer} className="fs-2x" />}
            title={intl.formatMessage({
              id: 'MENU.LIQUIDATE.SYSTEM'
            })}
            active={isLiquidateSysActive()}
          >
            {
              menuLiquidatesSysItems
                .filter((item) => !item.hidden)
                .map((item, index) => (
                  <AsideMenuItem
                    routerLocation={location}
                    key={item.to}
                    to={item.to}
                    icon={item.icon}
                    title={intl.formatMessage({ id: item.title })}
                    onClick={activeMenu(AsideMenu.LIQUIDATESYS)}
                  />
                ))
            }
          </AsideMenuItemWithSub>
        )
      }

    </>
  )
}

export { AsideMenuMain }
