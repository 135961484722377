import React, { useRef, useCallback, useContext, useState, useMemo } from 'react'
import { useFormik } from 'formik'
import { UfinetButton, UfinetDropzone, UfinetActionButton, UfinetActionButtonHandle, UfinetSelect, IUfinetSelect, UfinetSelectOptionValue, IUfinetSelectOption, ISelect, emptyUfinetSelectOption, UfinetInput } from 'ufinet-web-components';
import { onFormikChanges, IFileUploadData, AuthContext, onFormikNumberChanges } from 'ufinet-web-functions';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { Translation } from 'utils/translation/Translation';
import { UsePostCreateUserRRHH, UseGetCompanies, UseGetUsers } from 'request';
import * as Yup from 'yup'
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { ClientTypeName, CommissionType, ErrorResponse, IsActiveValues, ServiceTypeName, commissionClientTypeNameMappings, commissionServiceTypeNameServiceMappings, commissionTypeMappings, isActiveMappings } from 'types/typesAndValues';
import { useTranslation } from 'utils/translation/Translation';
import { CompaniesSelectTypes, ListUsersTypes } from 'types/configuration';

interface Props {
  downLoadTemplate?: () => void;
  hideModal: () => void;
  refetch: () => void
}

type CreateFormData = {
  idCompany: IUfinetSelectOption
  idUser: IUfinetSelectOption
  active: IUfinetSelectOption
}

const CreateUsersRRHHForm = ({ downLoadTemplate, hideModal, refetch }: Props) => {


  const { data: dataCompanies } = UseGetCompanies()
  const { data: dataUsers } = UseGetUsers()

  const intl = useIntl()
  const translate = useTranslation()


  const companies = dataCompanies && dataCompanies?.data.map((item: CompaniesSelectTypes) => ({
    label: item.name, value: item.id,
  }))

  const listUsers = dataUsers && dataUsers?.data.map((item: ListUsersTypes) => ({
    label: item.name, value: item.idUser,
  }))

  const isActive = [
    {
      label: intl.formatMessage({ id: isActiveMappings.get(IsActiveValues.FALSE) }),
      value: IsActiveValues.FALSE,
    },
    {
      label: intl.formatMessage({ id: isActiveMappings.get(IsActiveValues.TRUE) }),
      value: IsActiveValues.TRUE,
    }
  ]



  const authData = useContext(AuthContext)
  const userEmail = authData.userData?.username

  const actionButtonRef = useRef<UfinetActionButtonHandle>(null)




  const cbSuccessCreateUserRRHH = ({ data }: any) => {
    toast.success(data.message)
    actionButtonRef.current?.changeActionStatus(false)
    refetch()
    hideModal()
  }

  const { mutate: createUserRRHH, isError, error: errorMessage } = UsePostCreateUserRRHH(cbSuccessCreateUserRRHH)


  const showErrMessage = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse;
      toast.error(`${intl.formatMessage({ id: "ERROR.MESSAGE" },)} ${errorResponse.errorCode}`, { toastId: 'Error2' });
      hideModal()
    }
  }

  const dataFormSchema = Yup.object().shape({
    idCompany: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
    idUser: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
    active: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
  })


  const stringToBoolean = (stringValue: string) => {
    if (stringValue === "true") {
      return true;
    } else if (stringValue === "false") {
      return false;
    }
  }


  const sendData = (values: CreateFormData) => {
    actionButtonRef.current?.changeActionStatus(true)
    createUserRRHH({
      idCompany: values.idCompany.value,
      idUser: values.idUser.value,
      active: stringToBoolean(values.active.value),
    })
  }

  const onChange = useCallback(onFormikChanges, [])
  const onNumberChange = useCallback(onFormikNumberChanges, [])


  const initialValues: CreateFormData = useMemo<CreateFormData>(
    () => ({
      idCompany: emptyUfinetSelectOption,
      idUser: emptyUfinetSelectOption,
      active: emptyUfinetSelectOption,
    }),
    []
  )



  const formik = useFormik<CreateFormData>({
    initialValues,
    validationSchema: dataFormSchema,
    onSubmit: sendData,
    validateOnChange: false,
    validateOnBlur: false,
  })


  return (
    <>
      {isError && showErrMessage()}

      <form onSubmit={formik.handleSubmit}>
        <div className='row'>
          <div className='row'>

            <UfinetSelect
              className='col-6'
              requiredIcon
              value={formik.values.idCompany}
              error={formik.errors.idCompany?.label}
              labelTitle={translate('COL.COMMISSION.ENTERPRISE')}
              onChange={onChange(formik, 'idCompany')}
              placeholder={translate('COL.COMMISSION.ENTERPRISE')}
              options={companies}
            />

            <UfinetSelect
              className='col-6'
              requiredIcon
              value={formik.values.idUser}
              error={formik.errors.idUser?.label}
              labelTitle={translate('COL.CONFIG.NAME')}
              onChange={onChange(formik, 'idUser')}
              placeholder={translate('COL.CONFIG.NAME')}
              options={listUsers}
            />
            <div className='row mt-5'>
              <UfinetSelect
                requiredIcon
                className='col-6'
                value={formik.values.active}
                error={formik.errors.active?.label}
                labelTitle={translate('COL.CONFIG.ISACTIVE')}
                onChange={onChange(formik, 'active')}
                placeholder={translate('COL.CONFIG.ISACTIVE')}
                options={isActive}
              />


            </div>


            <UfinetActionButton
              ref={actionButtonRef}
              className='mt-5'
              content={translate('CREATE.COMMISSION.TYPE')}
            // onClick={downLoadTemplate}
            />
          </div>
        </div>
      </form>
    </>
  )
}

export { CreateUsersRRHHForm }


