import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { toast } from 'react-toastify'
import { Table, UfinetBox, UfinetButton, UfinetModal, UfinetSectionBox, formatTableFilters, getEditPencilBodyTemplate, tableSortValueToSortDirection, } from 'ufinet-web-components'
import { AuthContext, Authority, useAsync, useModal, } from 'ufinet-web-functions'
import { colsConfigLiquidateColData } from './ConfigLiquidateColData'
import { UseGetPercentangeTypeCommissionTemplate, UseDeleteConfigLiquidate, UseQueryGetConfigLiquidate } from 'request'
import { useTranslation } from 'utils/translation/Translation'
import { DataTablePageEvent } from 'primereact/datatable'
import { UpdateConfigLiquidateForm, CreateConfigLiquidateForm } from './modals'

import { ConfigLiquidateTypes } from '../../../types/configuration/ConfigurationTypes';
import { ErrorResponse } from 'types/typesAndValues'


const ConfigLiquidatePage = () => {
  const intl = useIntl()
  const colsPercentangeTypeCommission = useMemo(() => colsConfigLiquidateColData(intl), [intl])
  const [selectedValues, setSelectedValues] = useState<ConfigLiquidateTypes[]>([])

  const [configLiquidateInfo, sePercentangeInfo] = useState<ConfigLiquidateTypes>()


  const isSelectedValues = selectedValues && selectedValues?.length > 0



  //* Modals
  const [newModal, showNewModal, hideNewModal] = useModal()
  const [createModal, showCreateModal, hideCreateModal] = useModal()
  const [updateModal, showUpdateModal, hideUpdateModal] = useModal()

  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  // Go to see the repo ufinet-web-functions at the file typeAndValues branch develop
  // In the function Authority to get the function to get the roles
  // In this case getCommissionsPermissions is gonna get the roles
  const permissions = Authority.getCommissionConfigPermissions(roles)

  const [paging, setPaging] = useState<any>()
  const [loadingRecords, setLoadingRecords] = useState<boolean>(false)
  const [filterData, setFilterData] = useState<any>()
  const [tablePFSEvent, setTablePFSEvent] = useState<DataTablePageEvent>()


  const { data: configLiquidate, isLoading: isLoadingConfigLiquidate, refetch } = UseQueryGetConfigLiquidate(filterData)


  const translate = useTranslation()


  const onSelectionChange = useCallback((values: any) => setSelectedValues(values), [])


  const cbSuccessPercentangeTypeCommissionTemplate = ({ data }: any) => { }
  const cbSuccessDeleteConfigLiquidate = ({ data }: any) => {
    toast.success(data.message)
    setSelectedValues([])
    refetch()
  }

  const { mutate: downloadTemplate } = UseGetPercentangeTypeCommissionTemplate(cbSuccessPercentangeTypeCommissionTemplate)
  const { mutate: deleteConfigLiquidate, isError, error: errorMessage } = UseDeleteConfigLiquidate(cbSuccessDeleteConfigLiquidate)


  const showToastErrMessage = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse;
      toast.error(`${intl.formatMessage({ id: "ERROR.MESSAGE" },)} ${errorResponse.errorCode}`, { toastId: 'Error1' });
    }
  }


  useEffect(() => {
    if (configLiquidate) {
      const parsePagination = JSON.parse(configLiquidate?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: filterData && filterData.pageSize ? filterData.pageSize : parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [configLiquidate])

  const onFilterClear = (): void => {
    setFilterData({})
  }



  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)
    setFilterData({
      ...filterData,
      ...formattedFilters
    })
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({ ...filterData, pageNumber: e.page, pageSize: e.rows })
  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })

  }


  const deleteItem = () => {
    if (selectedValues && selectedValues.length > 0) {
      const firstElement = selectedValues[0]
      if (firstElement !== undefined) {
        deleteConfigLiquidate(firstElement.id);
      }
    }
  }



  const getHeadersButtons = () => (
    <>
      {isError && showToastErrMessage()}

      {permissions.canDelete && isSelectedValues && (

        <>
          <UfinetButton
            className="me-3"
            icon={faTrash}
            onClick={() => deleteItem()}
            content={translate('DELETE.COMMISSION.TYPE')}
          />

        </>

      )}
      {permissions.canWrite && (
        <>
          <UfinetButton
            className="me-3"
            icon={faPlus}
            onClick={() => {
              showCreateModal()
            }}
            content={translate('CREATE.COMMISSION.TYPE')}
          />

          <UfinetButton
            className="me-3"
            icon={faUpload}
            onClick={() => {
              showNewModal()
            }}
            content={translate('BULK_LOAD')}
          />


        </>
      )}
    </>
  )

  return (
    <>
      <UfinetBox>

        <UfinetSectionBox>

          <Table
            dataKey="id"
            selectedValues={selectedValues}
            onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            content={configLiquidate?.data}
            cols={colsPercentangeTypeCommission}
            headerButtons={getHeadersButtons()}
            onFilterClear={onFilterClear}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            emptyMessage={isLoadingConfigLiquidate ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            actionBodyTemplate={
              permissions.canUpdate
                ? (configLiquidateItem: ConfigLiquidateTypes) =>
                  getEditPencilBodyTemplate(configLiquidateItem, () => {
                    setSelectedValues([])
                    sePercentangeInfo(configLiquidateItem)
                    showUpdateModal()
                  })
                : undefined
            }
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingConfigLiquidate,
                onFilter,
                onPage,
                onSort,
              }
            }
          />

          {/* <UfinetModal
            size="lg"
            show={newModal}
            handleClose={hideNewModal}
            title={translate('LOAD.TEMPLATE')}
          >
            <PercentangeTypeCommissionBulkLoadForm downLoadTemplate={downloadTemplate} hideLoadModal={hideNewModal} refetch={refetch} />
          </UfinetModal> */}

          <UfinetModal
            size="lg"
            show={createModal}
            handleClose={hideCreateModal}
            title={translate('CREATE.COMMISSION.TYPE')}
          >
            <CreateConfigLiquidateForm hideModal={hideCreateModal} refetch={refetch} />
          </UfinetModal>

          <UfinetModal
            size="lg"
            show={updateModal}
            handleClose={hideUpdateModal}
            title={translate('UPDATE.COMMISSION.TYPE')}
          >
            <UpdateConfigLiquidateForm updatedData={configLiquidateInfo} hideModal={hideUpdateModal} refetch={refetch} />
          </UfinetModal>
        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}

export { ConfigLiquidatePage }


