import { ColData } from 'ufinet-web-components'
import { IntlShape } from 'react-intl';
import { Goal } from 'types/goals/goalsTypes';
import { Locked } from 'lib/locked/Locked';

export const colsGoalsData = (intl: IntlShape): ColData[] => [
  {
    field: 'goalMonth',
    header: 'COL.COMMISSION.MONTH',
    width: '120px',
    nonFilterable: false,
    nonSortable: true,
    editable: false,
    body: (row: Goal) => <>{intl.formatMessage({ id: row.goalMonth })}</>,
  },
  {
    field: 'netArc',
    header: 'ARC.NET',
    width: '120px',
    nonFilterable: true,
    nonSortable: true,
    editable: true,
  },
  {
    field: 'arc',
    header: 'ARC',
    width: '120px',
    nonFilterable: true,
    nonSortable: true,
    editable: true,
  },
  {
    field: 'churnBreakage',
    header: 'CHURNBREAKAGE',
    width: '120px',
    nonSortable: true,
    nonFilterable: true,
    editable: true,
  },
  {
    field: 'locked',
    header: 'EDITABLE',
    width: '120px',
    nonSortable: true,
    nonFilterable: true,
    editable: false,
    body: (row: Goal) => <Locked locked={row.locked} />,
  },

]
