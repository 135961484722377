import React, { useState, useEffect, useMemo, useCallback, } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { Table, UfinetBox, UfinetSectionBox, UfinetSelect, formatTableFilters, tableSortValueToSortDirection } from 'ufinet-web-components'
import { UseQueryResumeAuthorizationSystemCommissions, UseGetAuthorizatorSelect } from 'request'
import { useTranslation } from 'utils/translation/Translation'
import { DataTablePageEvent } from 'primereact/datatable'
import { AuthorizationRejectedColDataTypes } from 'types/authorization'
import { colsResumeColData } from './ResumeColData'
import { AuthorizingChainSelectTypes } from 'types/configuration'
import { useFormik } from 'formik'
import { BoxTotalAuthorization } from 'lib/box/BoxTotalAuthorization'
import { useFiltersAuthorizationSys } from 'hooks/useFiltersAuthorizationSys'
import { MultiSelectFiltersHeaders } from '../filterHeaders/MultiSelectFiltersHeaders'

const ResumeAuthorizationSysPage = () => {
  const translate = useTranslation()
  const intl = useIntl()

  const colsResumeAuthorizationSys = useMemo(() => colsResumeColData(intl), [intl])


  const [input, setInput] = useState('')



  const [paging, setPaging] = useState<any>()
  const [filterData, setFilterData] = useState<any>()

  const {
    kamsSelect,
    isLoadingDataAuthorizator,
    clientsSelect,
    isLoadingDataClients,
    handleMultiSelect,
    multiSelectKam,
    multiSelectClient,
    cleanSelectKamAndClient,
    username,
    serviceClient,
    selectAllClient,
    selectAllKam,
    onSelectAll,
  } = useFiltersAuthorizationSys({ filterData, setFilterData })


  const { data: resumeAuthoriationSysData, isLoading: isLoadingResumeAuthorizationSystem } = UseQueryResumeAuthorizationSystemCommissions(filterData)
  const { data: authorizatorSelect } = UseGetAuthorizatorSelect()

  const authorizatorNamesSelect = authorizatorSelect && authorizatorSelect?.data.map((item: AuthorizingChainSelectTypes) => (
    { label: item.name, value: item.email }
  ))

  const showBoxTotal = resumeAuthoriationSysData?.data ? resumeAuthoriationSysData?.data : null













  useEffect(() => {
    if (resumeAuthoriationSysData) {
      const parsePagination = JSON.parse(resumeAuthoriationSysData?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [resumeAuthoriationSysData])

  const onFilterClear = (): void => {
    cleanSelectKamAndClient()
    setFilterData({})
  }

  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)

    setFilterData({
      ...filterData,
      ...formattedFilters,
      username,
      serviceClient,
    })
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({
      ...filterData,
      username,
      serviceClient,
      pageNumber: e.page,
      pageSize: e.rows
    })
  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      username,
      serviceClient,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })
  }







  const handleChange = (event: any) => {
    setInput(event.value)




    setFilterData({
      ...filterData,
      authorizator: event.value
    })
  }


  const formik = useFormik({
    initialValues: {
      idAuthorizer: '',
    },
    onSubmit: (values) => {

    }
  })

  const filterButtons = () => {
    return (
      <>
        <MultiSelectFiltersHeaders
          clientFilter={false}

          onSelectAll={onSelectAll}
          selectAllClient={selectAllClient}
          selectAllKam={selectAllKam}
          multiSelectClient={multiSelectClient}
          multiSelectKam={multiSelectKam}
          clientsSelect={clientsSelect}
          kamsSelect={kamsSelect}
          onChangeHandle={handleMultiSelect}
          isLoadingDataAuthorizator={isLoadingDataAuthorizator}
          isLoadingDataClients={isLoadingDataClients}
        />
      </>
    )

  }




  return (
    <>
      <UfinetBox>
        <UfinetSectionBox>

          {showBoxTotal && (
            <ContainerCommissionAmount>
              <BoxTotalAuthorization
                title="TOTAL.AMOUNT.TOAUTHORIZE"
                totalCommissionAmount={resumeAuthoriationSysData?.data?.totalCommissions}
              />
              <BoxTotalAuthorization
                title="TOTAL.DECOMMISIONS.TOAUTHORIZE"
                totalCommissionAmount={resumeAuthoriationSysData?.data?.totalDecommissions}
              />
              <BoxTotalAuthorization
                title="TOTALAMOUNT.AUTHORIZE"
                totalCommissionAmount={resumeAuthoriationSysData?.data?.totalAmount}
              />
            </ContainerCommissionAmount>

          )}

          <UfinetSelect
            requiredIcon
            className='col-2 mt-5 mb-5'
            value={formik.values.idAuthorizer}
            // error={formik.errors.idAuthorizer?.label}
            labelTitle={translate('COL.CONFIG.AUTHORIZER')}
            // onChange={onChange(formik, 'idAuthorizer')}

            onChange={handleChange}
            placeholder={translate('COL.CONFIG.AUTHORIZER')}
            options={authorizatorNamesSelect}
          />



        </UfinetSectionBox>

        <UfinetSectionBox className='mt-5 mh-100'>

          <Table
            filterButtons={filterButtons()}
            enableSearchFilter={false}
            // headerButtons={getHeadersButtons()}
            dataKey="id"
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            cols={colsResumeAuthorizationSys}
            content={resumeAuthoriationSysData?.data?.users}
            actionBodyTemplate={<></>}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            emptyMessage={isLoadingResumeAuthorizationSystem ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            onFilterClear={onFilterClear}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingResumeAuthorizationSystem,
                onFilter,
                onPage,
                onSort,
              }
            }
          />
        </UfinetSectionBox>






      </UfinetBox>

    </>
  )
}

export { ResumeAuthorizationSysPage }

const ContainerCommissionAmount = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;  
    justify-content: space-between;
    
    
    h2 {
      margin: 0;
    }
`

const TotalAmount = styled.div`
  display: flex;
  justify-content: flex-end;
`

const CurrencyCode = styled.div`
  display: flex;
  justify-content: flex-end;
`
