export enum CommissionType {
  ADJUDGE = "Adjudge",
  DELIVERY = "Delivery",
  BILLING = "Billing",
  CEASE = "Cease",
  MANUAL = 'Manual',
}
export enum TermName {
  ONE = '0 - 12',
  TWO = '13 - 24',
  THREE = '25 - 36',
  FOUR = '37 - 60',
  FIVE = '0 - 120',
  SIX = '61 - 120',
  SEVEN = '121 - 500'
}

export enum EconomicModelName {
  IRU = 'IRU',
  LEASED = 'Leased',
}

export const commissionEconomicModelNameMappings = new Map<EconomicModelName, string>([
  [EconomicModelName.IRU, 'IRU'],
  [EconomicModelName.LEASED, 'LEASED'],
])

export enum ClientTypeName {
  GOVERNMENT = "Gobierno",
  OPERATOR = "Operador",
  OTHERS = "Otros",
  PROVIDER = "Proveedor",
  OTT = "OTT (Over The Top)",
  BPO = "BPO",
  ELECTRIC = "Eléctrica",
  CORPORATE = "Corporativo",
  DATACENTER = "Data Center",
  INTEGRATOR = "Integrador",
  LOCALOPERATOR = "Operador Local",
  INDIRECTCLIENT = "Cliente Indirecto",
  UFINETGROUP = "Grupo Ufinet",
  GLOBALOPERATOR = "Operador Global",
  REST = "Resto",
  EXTERNAL = "Externo",
  POLEINFRASTRUCTURE = "Postería",
  ISP = "ISP"
}

export const commissionClientTypeNameMappings = new Map<ClientTypeName, string>([
  [ClientTypeName.GOVERNMENT, 'GOVERNMENT'],
  [ClientTypeName.OPERATOR, 'OPERATOR'],
  [ClientTypeName.OTHERS, 'OTHERS'],
  [ClientTypeName.PROVIDER, 'PROVIDER'],
  [ClientTypeName.OTT, 'OTT'],
  [ClientTypeName.BPO, 'BPO'],
  [ClientTypeName.ELECTRIC, 'ELECTRIC'],
  [ClientTypeName.CORPORATE, 'CORPORATE'],
  [ClientTypeName.DATACENTER, 'DATACENTER'],
  [ClientTypeName.INTEGRATOR, 'INTEGRATOR'],
  [ClientTypeName.LOCALOPERATOR, 'LOCALOPERATOR'],
  [ClientTypeName.INDIRECTCLIENT, 'INDIRECTCLIENT'],
  [ClientTypeName.UFINETGROUP, 'UFINETGROUP'],
  [ClientTypeName.GLOBALOPERATOR, 'GLOBALOPERATOR'],
  [ClientTypeName.REST, 'REST'],
  [ClientTypeName.EXTERNAL, 'EXTERNAL'],
  [ClientTypeName.POLEINFRASTRUCTURE, 'POLEINFRASTRUCTURE'],
  [ClientTypeName.ISP, 'ISP'],
])


export enum ServiceTypeName {
  NOSERVICE = "Sin Servicio",
  ETHCAPACITYTRANSPORT = "Transporte Capacidad ETH",
  INTERNET = "Internet",
  DARKFIBER = "Fibra Oscura",
  COLOCATION = "Colocation",
  INFRASTRUCTURE = "Infraestructura",
  TDMCAPACITYTRANSPORT = "Transporte Capacidad TDM",
  DWDMMCAPACITYTRANSPORT = "Transporte Capacidad DWDM",
  CAPACITY = "Capacidad",
  TIMELY = "Puntual",
  VSAT = "VSAT",
  EQUIPMENT = "Equipo",
  ENERGY = "Energía",
  CROSSCONNECTION = "Cross Conexion",
  FTTH = "FTTH",
  TOWERING = "Towering",
  CLOUD = "Cloud",
  SECURITY = "Seguridad",
  GPON_FTTH = "GPON/FTTH",
  REMOTEHANDS = "Manos remotas",
  OUTSOURCING = "Outsourcing",
  OTHERS = "Otros",
  UNDERGROUNDCHAMBER = "Cámara Subterránea",
  NOTREPORTED = "No Informado"
}

export const commissionServiceTypeNameServiceMappings = new Map<ServiceTypeName, string>([
  [ServiceTypeName.NOSERVICE, 'NOSERVICE'],
  [ServiceTypeName.ETHCAPACITYTRANSPORT, 'ETHCAPACITYTRANSPORT'],
  [ServiceTypeName.INTERNET, 'INTERNET'],
  [ServiceTypeName.DARKFIBER, 'DARKFIBER'],
  [ServiceTypeName.COLOCATION, 'COLOCATION'],
  [ServiceTypeName.INFRASTRUCTURE, 'INFRASTRUCTURE'],
  [ServiceTypeName.TDMCAPACITYTRANSPORT, 'TDMCAPACITYTRANSPORT'],
  [ServiceTypeName.DWDMMCAPACITYTRANSPORT, 'DWDMMCAPACITYTRANSPORT'],
  [ServiceTypeName.CAPACITY, 'CAPACITY'],
  [ServiceTypeName.TIMELY, 'TIMELY'],
  [ServiceTypeName.VSAT, 'VSAT'],
  [ServiceTypeName.EQUIPMENT, 'EQUIPMENT'],
  [ServiceTypeName.ENERGY, 'ENERGY'],
  [ServiceTypeName.CROSSCONNECTION, 'CROSSCONNECTION'],
  [ServiceTypeName.FTTH, 'FTTH'],
  [ServiceTypeName.TOWERING, 'TOWERING'],
  [ServiceTypeName.CLOUD, 'CLOUD'],
  [ServiceTypeName.SECURITY, 'SECURITY'],
  [ServiceTypeName.GPON_FTTH, 'GPON_FTTH'],
  [ServiceTypeName.REMOTEHANDS, 'REMOTEHANDS'],
  [ServiceTypeName.OUTSOURCING, 'OUTSOURCING'],
  [ServiceTypeName.OTHERS, 'OTHERS'],
  [ServiceTypeName.UNDERGROUNDCHAMBER, 'UNDERGROUNDCHAMBER'],
  [ServiceTypeName.NOTREPORTED, 'NOTREPORTED'],
])

export enum ComercialTeamName {
  VastComerciales = 'VSAT Comerciales',
  GuatemalaComerciales = 'Guatemala Comerciales',
  MexicoComerciales = 'México Comerciales',
  ElSalvadorComerciales = 'El Salvador Comerciales',
  HondurasComerciales = 'Honduras Comerciales',
  NicaraguaComerciales = 'Nicaragua Comerciales',
  CostaRicaComerciales = 'Costa Rica Comerciales',
  PanamaComerciales = 'Panamá Comerciales',
  ColombiaComerciales = 'Colombia Comerciales',
  PeruComerciales = 'Perú Comerciales',
  BrasilComerciales = 'Brasil Comerciales',
  ParaguayComerciales = 'Paraguay Comerciales',
  ArgentinaComerciales = 'Argentina Comerciales',
  ChileComerciales = 'Chile Comerciales',
  GlobalKAMComerciales = 'Global KAM Comerciales',
  PartnerProgramGlobalKAMComerciales = 'Partner Program Global KAM Comerciales',
  ToweringComerciales = 'Towering Comerciales',
  EcuadorComerciales = 'Ecuador Comerciales',
  EnelColombiaComerciales = 'Enel Colombia Comerciales',
  ToweringColombia = 'Towering Colombia',
  ToweringChile = 'Towering Chile',
  ToweringCentroamerica = 'Towering Centroamérica'
}

export enum CommissionMonth {
  JANUARY = "January",
  FEBRUARY = "February",
  MARCH = "March",
  APRIL = "April",
  MAY = "May",
  JUNE = "June",
  JULY = "July",
  AUGUST = "August",
  SEPTEMBER = "September",
  OCTOBER = "October",
  NOVEMBER = "November",
  DECEMBER = "December",
}

export enum AuthorizationStatus {
  PENDING = "Pending",
  APPROVED = "Approved",
  REJECTED = "Rejected",
}

export enum CommissionTypeApproved {
  DECOMMISSION = "DECOMMISSION",
  COMMISSION = "COMMISSION",
}

export enum CommissionCalculatingStatus {
  MISSING_CONFIGURATION = "MISSING.CONFIGURATION",
  WAITING_EXCHANGERATE = "WAITING.EXCHANGERATE",
  WAITING_DELIVERY = "WAITING.DELIVERY",
  WAITING_BILLING = "WAITING.BILLING",
  CALCULATING_MULTIPLIER = "CALCULATING.MULTIPLIER",
  WAITING_BACKLOGCEASE = "WAITING.BACKLOGCEASE",
  PENDING_AUTHORIZATION = "PENDING.AUTHORIZATION",


}

export const commissionTypeMappings = new Map<CommissionType, string>([
  [CommissionType.ADJUDGE, 'ADJUDGE'],
  [CommissionType.DELIVERY, 'DELIVERY'],
  [CommissionType.BILLING, 'BILLING'],
  [CommissionType.CEASE, 'CEASE'],
  [CommissionType.MANUAL, 'MANUAL'],
])



export const commissionTypeAprovedMappings = new Map<CommissionTypeApproved, string>([
  [CommissionTypeApproved.DECOMMISSION, 'DECOMMISSION'],
  [CommissionTypeApproved.COMMISSION, 'COMMISSION'],
])

export const commissionMonthMappings = new Map<CommissionMonth, string>([
  [CommissionMonth.JANUARY, 'JANUARY'],
  [CommissionMonth.FEBRUARY, 'FEBRUARY'],
  [CommissionMonth.MARCH, 'MARCH'],
  [CommissionMonth.APRIL, 'APRIL'],
  [CommissionMonth.MAY, 'MAY'],
  [CommissionMonth.JUNE, 'JUNE'],
  [CommissionMonth.JULY, 'JULY'],
  [CommissionMonth.AUGUST, 'AUGUST'],
  [CommissionMonth.SEPTEMBER, 'SEPTEMBER'],
  [CommissionMonth.OCTOBER, 'OCTOBER'],
  [CommissionMonth.NOVEMBER, 'NOVEMBER'],
  [CommissionMonth.DECEMBER, 'DECEMBER'],
])

export const commissionCalculatingStatusMappings = new Map<CommissionCalculatingStatus, string>([
  [CommissionCalculatingStatus.MISSING_CONFIGURATION, 'MISSING.CONFIGURATION'],
  [CommissionCalculatingStatus.WAITING_EXCHANGERATE, 'WAITING.EXCHANGERATE'],
  [CommissionCalculatingStatus.WAITING_DELIVERY, 'WAITING.DELIVERY'],
  [CommissionCalculatingStatus.WAITING_BILLING, 'WAITING.BILLING'],
  [CommissionCalculatingStatus.CALCULATING_MULTIPLIER, 'CALCULATING.MULTIPLIER'],
  [CommissionCalculatingStatus.WAITING_BACKLOGCEASE, 'WAITING.BACKLOGCEASE'],
  [CommissionCalculatingStatus.PENDING_AUTHORIZATION, 'PENDING.AUTHORIZATION'],
])

export const authorizationCommissionStatusMappings = new Map<AuthorizationStatus, string>([
  [AuthorizationStatus.PENDING, 'PENDING'],
  [AuthorizationStatus.APPROVED, 'APPROVED'],
  [AuthorizationStatus.REJECTED, 'REJECTED'],
])


export interface ErrorResponse {
  errorCode: string;
  message: string;
  reason?: string;
}


export enum IsActiveValues {
  FALSE = 'false',
  TRUE = 'true'
}


export const isActiveMappings = new Map<IsActiveValues, string>([
  [IsActiveValues.FALSE, 'SHOW.NO.ISACTIVE'],
  [IsActiveValues.TRUE, 'SHOW.ISACTIVE'],
])
