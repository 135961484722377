import axios from "axios"
import { useQuery, useMutation } from "react-query"
import { fetchDelete, fetchGET2, fetchPATCH, fetchPOST2 } from "utils/https/https"
import { AuthorizingChainSelectTypes } from '../../types/configuration/ConfigurationTypes';

// This is the query to get the Config global data
export const UseQueryGetConfigGlobal = (data: any) => {

  const clientTypeName = data?.clientTypeName && data?.clientTypeName.length > 0 ? data?.clientTypeName[0] : null
  const serviceTypeName = data?.serviceTypeName && data?.serviceTypeName.length > 0 ? data?.serviceTypeName[0] : null
  const comercialTeamName = data?.comercialTeamName && data?.comercialTeamName.length > 0 ? data?.comercialTeamName[0] : null
  const economicModelName = data?.economicModelName && data?.economicModelName.length > 0 ? data?.economicModelName[0] : null
  const termName = data?.termName && data?.termName.length > 0 ? data?.termName[0] : null

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  return useQuery(['UseQueryGetConfigGlobal', clientTypeName, serviceTypeName, comercialTeamName, economicModelName, termName, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (clientTypeName) {
        serchParms.append('clientTypeName', clientTypeName)
      }

      if (serviceTypeName) {
        serchParms.append('serviceTypeName', serviceTypeName)
      }

      if (comercialTeamName) {
        serchParms.append('comercialTeamName', comercialTeamName)
      }

      if (economicModelName) {
        serchParms.append('economicModelName', economicModelName)
      }

      if (termName) {
        serchParms.append('termName', termName)
      }


      //Pagination

      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('pageSize', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }


      const response = await fetchGET2(`ConfigCommissionPercentage/ListConfigCommissionPercentage?${serchParms.toString()}`)
      return response
    } catch (error) {
      throw error
    }
  }
  )
}


//To get the template for bulk load at Config-global
const UseQueryGetTemplateGlobalBulkLoad = async () => {

  try {
    const response = await fetchGET2(`ConfigCommissionPercentage/GetCommissionPercentageTemplate`, { responseType: 'blob' })

    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'globalTemplate.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    return response
  } catch (error) {
    throw error
  }

}

export const UseGetGlobalBulkLoadTemplate = (callbackSuccess: any) => {
  return useMutation(UseQueryGetTemplateGlobalBulkLoad, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};


export const UseQueryGetPercentageTypeCommision = (data: any) => {

  const commissionType = data?.commissionType && data?.commissionType.length > 0 ? data?.commissionType[0] : null
  const commissionPercentage = data?.commissionPercentage && data?.commissionPercentage.length > 0 ? data?.commissionPercentage[0] : null
  const clientType = data?.clientType && data?.clientType.length > 0 ? data?.clientType[0] : null
  const id = data?.id && data?.id.length > 0 ? data?.id[0] : null
  const serviceType = data?.serviceType && data?.serviceType.length > 0 ? data?.serviceType[0] : null


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  return useQuery(['UseQueryGetPercentageTypeCommision', commissionType, id, clientType, serviceType, commissionPercentage, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (id) {
        serchParms.append('id', id)
      }

      if (clientType) {
        serchParms.append('clientType', clientType)
      }

      if (commissionPercentage) {
        serchParms.append('commissionPercentage', commissionPercentage)
      }

      if (commissionType) {
        serchParms.append('commissionType', commissionType)
      }

      if (serviceType) {
        serchParms.append('serviceType', serviceType)
      }




      //Pagination

      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('pageSize', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }


      const response = await fetchGET2(`ConfigCommissionType/ListConfigCommissionTypeService?&${serchParms.toString()}`)
      return response
    } catch (error) {
      throw error
    }
  }
  )
}

export const UseQueryGetConfigLiquidate = (data: any) => {

  const id = data?.id && data?.id.length > 0 ? data?.id[0] : null
  const companyName = data?.companyName ? data?.companyName[0] : null
  const executionDay = data?.executionDay ? data?.executionDay[0] : null


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  return useQuery(['UseQueryGetPercentageTypeCommision', id, companyName, executionDay, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (id) {
        serchParms.append('id', id)
      }

      if (companyName) {
        serchParms.append('companyName', companyName)
      }

      if (executionDay) {
        serchParms.append('executionDay', executionDay)
      }



      //Pagination

      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('pageSize', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }


      const response = await fetchGET2(`ConfigCompany/ListConfigCompany?&${serchParms.toString()}`)
      return response
    } catch (error) {
      throw error
    }
  }
  )
}

export const UseQueryGetMultiplierConfig = (data: any) => {

  const id = data?.id && data?.id.length > 0 ? data?.id[0] : null
  const compliancePercentageMin = data?.compliancePercentageMin && data?.compliancePercentageMin.length > 0 ? data?.compliancePercentageMin[0] : null
  const compliancePercentageMax = data?.compliancePercentageMax && data?.compliancePercentageMax.length > 0 ? data?.compliancePercentageMax[0] : null
  const multiplierFactor = data?.multiplierFactor && data?.multiplierFactor.length > 0 ? data?.multiplierFactor[0] : null


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  return useQuery(['UseQueryGetMultiplierConfig', id, compliancePercentageMin, compliancePercentageMax, multiplierFactor, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (id) {
        serchParms.append('id', id)
      }

      if (compliancePercentageMin) {
        serchParms.append('compliancePercentageMin', compliancePercentageMin)
      }

      if (compliancePercentageMax) {
        serchParms.append('compliancePercentageMax', compliancePercentageMax)
      }

      if (multiplierFactor) {
        serchParms.append('multiplierFactor', multiplierFactor)
      }


      //Pagination

      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('pageSize', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }


      const response = await fetchGET2(`ConfigMultiplier/ListConfigMultiplier?&${serchParms.toString()}`)
      return response
    } catch (error) {
      throw error
    }
  }
  )
}


const UseQueryGetTemplatePercentangeTypeBulkLoad = async () => {

  try {
    const response = await fetchGET2(`ConfigCommissionType/GetCommissionServiceTypeTemplate`, { responseType: 'blob' })

    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'percentageTypeCommissionTemplate.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    return response
  } catch (error) {
    throw error
  }

}

export const UseGetPercentangeTypeCommissionTemplate = (callbackSuccess: any) => {
  return useMutation(UseQueryGetTemplatePercentangeTypeBulkLoad, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};



const UsePosPercentangeTypeCommissionBulkLoad = async (data: any) => {

  try {
    const formdata = new FormData()
    formdata.append('file', data)
    const response = await fetchPOST2(`ConfigCommissionType/UploadCommissionServiceTypeTemplate`, formdata)

    return response
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const customErr = {
        errorCode: error.response.data.code,
        message: error.response.data.message,
      }
      throw customErr
    }
  }

}

export const UsePostPercentangeTypeCommissionFile = (callbackSuccess: any) => {
  return useMutation(UsePosPercentangeTypeCommissionBulkLoad, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};



const createPercentangeTypeCommission = async (create: any) => {

  try {
    const response = await fetchPOST2('ConfigCommissionType/CreateConfigCommissionTypeService', create)
    return response

  } catch (error) {
    throw error

  }
}


export const UsePostCreatePercentangeTypeCommission = (callbackSuccess: any) => {
  return useMutation(createPercentangeTypeCommission, {
    onSuccess: (data) => {
      callbackSuccess(data)
    },
  })
}


const deletePercentageTypeCommission = async (id: number) => {
  try {
    const response = await fetchDelete(`ConfigCommissionType/DeleteConfigCommissionTypeService/${id}`)
    return response

  } catch (error) {
    throw error
  }
}


export const UseDeletePercentangeTypeCommmision = (callbackSuccess: any) => {
  return useMutation(deletePercentageTypeCommission, {
    onSuccess: (data) => {
      callbackSuccess(data)
    }
  })
}

const deleteConfigLiquidate = async (id: number) => {
  try {
    const response = await fetchDelete(`ConfigCompany/DeleteConfigCompany/${id}`)
    return response

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const customError = {
        errorCode: error.response.data.code,
        message: error.response.data.message,
      };
      throw customError
    }
  }
}


export const UseDeleteConfigLiquidate = (callbackSuccess: any) => {
  return useMutation(deleteConfigLiquidate, {
    onSuccess: (data) => {
      callbackSuccess(data)
    }
  })
}


const updatePercentangeTypeCommission = async (updatedData: any) => {
  try {
    const response = await fetchPATCH('ConfigCommissionType/UpdateConfigCommissionTypeService', updatedData)
    return response
  } catch (error) {
    throw error
  }
}


export const UseUpdatePercentageTypeCommission = (callbackSuccess: any) => {
  return useMutation(updatePercentangeTypeCommission, {
    onSuccess: (data) => {
      callbackSuccess(data)
    }
  })
}


const deleteMultiplierConfig = async (id: number) => {
  try {
    const response = await fetchDelete(`ConfigMultiplier/DeleteConfigMultiplier/${id}`)
    return response

  } catch (error) {
    throw error
  }
}


export const UseDeleteMultiplierConfig = (callbackSuccess: any) => {
  return useMutation(deleteMultiplierConfig, {
    onSuccess: (data) => {
      callbackSuccess(data)
    }
  })
}


const updateMultiplierConfig = async (updatedData: any) => {
  try {
    const response = await fetchPATCH('ConfigMultiplier/UpdateConfigMultiplier', updatedData)
    return response
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const customErr = {
        errorCode: error.response.data.code,
        message: error.response.data.message,
      }
      throw customErr
    }
  }
}


export const UseUpdateMultiplierConfig = (callbackSuccess: any) => {
  return useMutation(updateMultiplierConfig, {
    onSuccess: (data) => {
      callbackSuccess(data)
    }
  })
}

const updateConfigLiquidate = async (updatedData: any) => {
  try {
    const response = await fetchPATCH('ConfigCompany/UpdateConfigCompany', updatedData)
    return response
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const customErr = {
        errorCode: error.response.data.code,
        message: error.response.data.message,
      }
      throw customErr
    }
  }
}


export const UseUpdateConfigLiquidate = (callbackSuccess: any) => {
  return useMutation(updateConfigLiquidate, {
    onSuccess: (data) => {
      callbackSuccess(data)
    }
  })
}


const createMultiplierConfig = async (create: any) => {

  try {
    const response = await fetchPOST2('ConfigMultiplier/CreateConfigMultiplier', create)
    return response

  } catch (error) {
    throw error

  }
}


export const UseCreateMultiplierConfig = (callbackSuccess: any) => {
  return useMutation(createMultiplierConfig, {
    onSuccess: (data) => {
      callbackSuccess(data)
    },
  })
}


export const UseQueryGetAuthorizersData = (data: any) => {


  const name = data?.name ? data?.name : null
  const email = data?.email ? data?.email : null
  const minPendingApprovals = data?.minPendingApprovals && data?.minPendingApprovals.length > 0 ? data?.minPendingApprovals[0] : null
  const minPendingAmount = data?.minPendingAmount && data?.minPendingAmount.length > 0 ? data?.minPendingAmount[0] : null
  const isActive = data?.isActive && data?.isActive.length > 0 ? data?.isActive[0] : null


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  return useQuery(['UseQueryGetAuthorizersData', name, email, pageNumber, minPendingApprovals, minPendingAmount, isActive, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();



      if (name) {
        serchParms.append('name', name)
      }

      if (email) {
        serchParms.append('userEmail', email)
      }

      if (minPendingApprovals) {
        serchParms.append('minPendingApprovals', minPendingApprovals)
      }

      if (minPendingAmount) {
        serchParms.append('minPendingAmount', minPendingAmount)
      }

      if (isActive) {
        serchParms.append('isActive', isActive)
      }



      // PAGINATION
      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('pageSize', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }

      const response = await fetchGET2(`Authorizator/ListAuthorizators?&${serchParms.toString()}`)
      return response

    } catch (error) {
      throw error

    }

  })
}



const createAuthorizerConfig = async (data: any) => {
  try {
    const response = await fetchPOST2('Authorizator/CreateAuthorizator', data)
    return response

  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const customErr = {
        errorCode: error.response.data.code,
        message: error.response.data.message,
        reason: error.response.data.reason,
      }

      throw customErr
    }
    throw error
  }

}


export const useMutationCreateAuthorizerConfig = (cbSuccess: any) => {
  return useMutation(createAuthorizerConfig, {
    onSuccess: (data) => {
      cbSuccess(data)
    }
  })
}

const updateAuthorizerConfig = async (data: any) => {
  try {
    const response = await fetchPATCH('Authorizator/UpdateAuthorizator', data)
    return response

  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const customErr = {
        errorCode: error.response.data.code,
        message: error.response.data.message,
        reason: error.response.data.reason,
      }

      throw customErr
    }
    throw error
  }

}


export const useMutationUpdateAuthorizerConfig = (cbSuccess: any) => {
  return useMutation(updateAuthorizerConfig, {
    onSuccess: (data) => {
      cbSuccess(data)
    }
  })
}

const deleteAuthorizerConfig = async (email: string) => {
  try {
    const response = await fetchDelete(`Authorizator/DeleteAuthorizator/${email}`)
    return response

  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const customErr = {
        errorCode: error.response.data.code,
        message: error.response.data.message,
        reason: error.response.data.reason,
      }

      throw customErr
    }
    throw error
  }

}


export const useMutationDeleteAuthorizerConfig = (cbSuccess: any) => {
  return useMutation(deleteAuthorizerConfig, {
    onSuccess: (data) => {
      cbSuccess(data)
    }
  })
}



export const UseQueryGetAuthorizingChainData = (data: any) => {


  const authorizer = data?.authorizer ? data?.authorizer : null
  const commercialTeam = data?.commercialTeam && data?.commercialTeam.length > 0 ? data?.commercialTeam[0] : null
  const idAuthorizingChain = data?.idAuthorizingChain && data?.idAuthorizingChain.length > 0 ? data?.idAuthorizingChain[0] : null



  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  return useQuery(['UseQueryGetAuthorizingChainData', authorizer, commercialTeam, pageNumber, idAuthorizingChain, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();



      if (authorizer) {
        serchParms.append('authorizer', authorizer)
      }

      if (commercialTeam) {
        serchParms.append('commercialTeam', commercialTeam)
      }

      if (idAuthorizingChain) {
        serchParms.append('idAuthorizingChain', idAuthorizingChain)
      }



      // PAGINATION
      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('pageSize', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }

      const response = await fetchGET2(`AuthorizingChain/ListAuthorizingChain?&${serchParms.toString()}`)
      return response

    } catch (error) {
      throw error

    }

  })
}


export const UseGetAuthorizatorSelect = () => {

  return useQuery(['UseGetAuthorizatorSelect'], async () => {

    try {
      const response = await fetchGET2(`Authorizator/ListAuthorizators`)
      return response

    } catch (error) {
      throw error

    }

  })
}

export const UseGetAuthorizatorSelectWithValue = (data: any) => {
  const name = data.name ? data.name : null

  return useQuery(['UseGetAuthorizatorSelectWithValue', name], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (name) {
        serchParms.append('name', name)
      }

      const response = await fetchGET2(`Authorizator/ListAuthorizators?&${serchParms.toString()}`)
      return response

    } catch (error) {
      throw error

    }

  })
}

export const UseGetCommercialTeamSelect = () => {

  return useQuery(['UseGetCommercialTeamSelect'], async () => {

    try {
      const response = await fetchGET2(`CommercialTeam/ListCommercialTeams`)
      return response

    } catch (error) {
      throw error

    }

  })
}


const createAuthorizingChain = async (data: any) => {
  try {
    const response = await fetchPOST2('AuthorizingChain/CreateAuthorizingChain', data)
    return response

  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const customErr = {
        errorCode: error.response.data.code,
        message: error.response.data.message,
        reason: error.response.data.reason,
      }

      throw customErr
    }
    throw error
  }

}


export const useMutationCreateAuthorizingChain = (cbSuccess: any) => {
  return useMutation(createAuthorizingChain, {
    onSuccess: (data) => {
      cbSuccess(data)
    }
  })
}

const updateAuthorizingChain = async (data: any) => {
  try {
    const response = await fetchPATCH('AuthorizingChain/UpdateAuthorizingChain', data)
    return response

  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const customErr = {
        errorCode: error.response.data.code,
        message: error.response.data.message,
        reason: error.response.data.reason,
      }

      throw customErr
    }
    throw error
  }

}


export const useMutationUpdateAuthorizingChain = (cbSuccess: any) => {
  return useMutation(updateAuthorizingChain, {
    onSuccess: (data) => {
      cbSuccess(data)
    }
  })
}


// AuthorizingChain/DeleteAuthorizingChain



const deleteAuthorizingChain = async (idAuthorizingChain: number) => {
  try {
    const response = await fetchDelete(`AuthorizingChain/DeleteAuthorizingChain/${idAuthorizingChain}`)
    return response

  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const customErr = {
        errorCode: error.response.data.code,
        message: error.response.data.message,
        reason: error.response.data.reason,
      }

      throw customErr
    }
    throw error
  }

}


export const useMutationDeleteAuthorizingChain = (cbSuccess: any) => {
  return useMutation(deleteAuthorizingChain, {
    onSuccess: (data) => {
      cbSuccess(data)
    }
  })
}

export const UseGetCompanies = () => {
  return useQuery(['getCompanies'], async () => {

    try {

      const response = await fetchGET2('HumanResources/ListCompanies')
      return response

    } catch (error) {
      throw error

    }
  })
}

export const UseGetUsers = () => {
  return useQuery(['UseGetUsers'], async () => {

    try {

      const response = await fetchGET2('HumanResources/ListUsers')
      return response

    } catch (error) {
      throw error

    }
  })
}


const createConfigLiquidate = async (create: any) => {

  try {
    const response = await fetchPOST2('ConfigCompany/CreateConfigCompany', create)
    return response

  } catch (error) {

    if (axios.isAxiosError(error) && error.response) {
      const customErr = {
        errorCode: error.response.data.code,
        message: error.response.data.message,
        reason: error.response.data.reason,
      }
      throw customErr
    }

  }
}


export const UseCreateConfigLiquidate = (callbackSuccess: any) => {
  return useMutation(createConfigLiquidate, {
    onSuccess: (data) => {
      callbackSuccess(data)
    },
  })
}


export const UseQueryGetUsersRRHHConfig = (data: any) => {




  const active = data?.active && data?.active.length > 0 ? data?.active[0] : null
  const company = data?.company ? data?.company : null
  const name = data?.name ? data?.name : null
  const userEmail = data?.userEmail ? data?.userEmail : null


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  return useQuery(['UseQueryGetUsersRRHHConfig', company, name, userEmail, active, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();


      if (company) {
        serchParms.append('company', company)
      }

      if (name) {
        serchParms.append('name', name)
      }

      if (userEmail) {
        serchParms.append('userEmail', userEmail)
      }

      if (active) {
        serchParms.append('active', active)
      }



      //Pagination

      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('pageSize', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }


      const response = await fetchGET2(`HumanResources/ListUserRRHH?&${serchParms.toString()}`)
      return response
    } catch (error) {
      throw error
    }
  }
  )
}



const createUserRRHH = async (create: any) => {

  try {
    const response = await fetchPOST2('HumanResources/CreateUserRRHH', create)
    return response

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const customErr = {
        errorCode: error.response.data.code,
        message: error.response.data.message,
        reason: error.response.data.reason,
      }

      throw customErr
    }

  }
}


export const UsePostCreateUserRRHH = (callbackSuccess: any) => {
  return useMutation(createUserRRHH, {
    onSuccess: (data) => {
      callbackSuccess(data)
    },
  })
}


const updateUserRRHH = async (updatedData: any) => {
  try {
    const response = await fetchPATCH('HumanResources/UpdateUserRRHH', updatedData)
    return response
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const customErr = {
        errorCode: error.response.data.code,
        message: error.response.data.message,
        reason: error.response.data.reason,
      }

      throw customErr
    }
  }
}


export const UseUpdateUserRRHH = (callbackSuccess: any) => {
  return useMutation(updateUserRRHH, {
    onSuccess: (data) => {
      callbackSuccess(data)
    }
  })
}
