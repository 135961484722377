import { useQuery, useMutation } from "react-query"
import { fetchGET, fetchPOST, fetchPOST2 } from "utils/https/https"

// To get the data at Multiplicator system

export const UseQueryMultplicatorSystemCommissions = (data: any) => {
  const username = data?.username ? data?.username : null
  const year = data?.year && data?.year.length > 0 ? data?.year[0] : null
  const month = data?.month && data?.month.length > 0 ? data?.month[0] : null
  const initialDate = data?.initialDatePeriod && data?.initialDatePeriod.length > 0 ? data?.initialDatePeriod[0] : null
  const endDate = data?.endDatePeriod && data?.endDatePeriod.length > 0 ? data?.endDatePeriod[0] : null

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  return useQuery(['UseQueryMultplicatorSystemCommissions', username, year, month, initialDate, endDate, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (username) {
        serchParms.append('username', username)
      }

      if (year) {
        serchParms.append('year', year)
      }

      if (month) {
        serchParms.append('month', month)
      }

      if (initialDate) {
        serchParms.append('initialDate', initialDate)
      }

      if (endDate) {
        serchParms.append('endDate', endDate)
      }

      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('rowsPerPage', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }

      const response = await fetchGET(`Multiplier/ListMultipliers?&${serchParms.toString()}`)
      return response
    } catch (error) {
      throw error
    }
  })
}


// This is the request to download the file according to the params at multiplicator system
const UseDownloadMultplicatorSystemCommission = async (data: any) => {
  const username = data?.username ? data?.username : null
  const year = data?.year && data?.year.length > 0 ? data?.year[0] : null
  const month = data?.month && data?.month.length > 0 ? data?.month[0] : null
  const initialDate = data?.initialDatePeriod && data?.initialDatePeriod.length > 0 ? data?.initialDatePeriod[0] : null
  const endDate = data?.endDatePeriod && data?.endDatePeriod.length > 0 ? data?.endDatePeriod[0] : null

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  try {
    const serchParms = new URLSearchParams();

    if (username) {
      serchParms.append('username', username)
    }

    if (year) {
      serchParms.append('year', year)
    }

    if (month) {
      serchParms.append('month', month)
    }

    if (initialDate) {
      serchParms.append('initialDate', initialDate)
    }

    if (endDate) {
      serchParms.append('endDate', endDate)
    }

    if (pageNumber) {
      serchParms.append('pageNumber', pageNumber)
    }

    if (pageSize) {
      serchParms.append('rowsPerPage', pageSize)
    }

    if (sortOrder) {
      serchParms.append('sortOrder', sortOrder)
    }

    if (sortField) {
      serchParms.append('sortField', sortField)
    }

    const response = await fetchGET(`Multiplier/DownloadMultipliers?&${serchParms.toString()}`, { responseType: 'blob' })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'multiplierSystem.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);


    return response
  } catch (error) {
    throw error
  }

}


export const UseDownloadMultiplicatorSystemCommission = (callbackSuccess: any) => {
  return useMutation(UseDownloadMultplicatorSystemCommission, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};



const recalculateMultiplier = async (data: { email: string, period: number }[]) => {
  try {
    const response = await fetchPOST2('Support/CalculateMultiplier', data)
    return response

  } catch (error) {
    throw error
  }
}


export const UseRecalculateMultiplierSys = (callbackSuccess: any) => {
  return useMutation(recalculateMultiplier, {
    onSuccess(data) {
      callbackSuccess(data)
    },
  })
}
