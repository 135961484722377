import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons/faCircleXmark'
import { Table, UfinetBox, UfinetButton, UfinetModal, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection } from 'ufinet-web-components'
import { AuthContext, Authority, useModal, } from 'ufinet-web-functions'
import { colsPendingAuthorization } from './AuthorizatonPendingColData'
import { UseQueryPendingAuthorizationCommissions, UsePostApproveCommissionAuthorization, UseDownloadFileAuthorizatorPending } from 'request'
import { Translation, useTranslation } from 'utils/translation/Translation'
import { DataTablePageEvent } from 'primereact/datatable'
import { CommissionElement, AuthorizationPendingColDataTypes } from 'types/authorization'
import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons'
import { RejectModalForm, BulkLoadPendingApprovalForm } from './modals'
import { useFiltersAuthorization } from 'hooks/useFiltersAuthorization'
import { MultiSelectFiltersHeaders } from '../filterHeaders/MultiSelectFiltersHeaders'





const PendingAuthorizationPage = () => {
  const translate = useTranslation()
  const intl = useIntl()



  //* MODALS
  const [rejecteModal, showRejectModal, hideRejectModal] = useModal()
  const [uploadModal, showUploadModal, hideUploadModal] = useModal()


  const colsPendingPage = useMemo(() => colsPendingAuthorization(intl), [intl])

  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  const permissions = Authority.getCommissionAuthorizationPermissions(roles)


  const [selectedValues, setSelectedValues] = useState<AuthorizationPendingColDataTypes[]>([])
  const [paging, setPaging] = useState<any>()
  const [filterData, setFilterData] = useState<any>()



  const {
    kamsSelect,
    isLoadingDataAuthorizator,
    clientsSelect,
    isLoadingDataClients,
    handleMultiSelect,
    multiSelectKam,
    multiSelectClient,
    cleanSelectKamAndClient,
    username,
    serviceClient,
    selectAllClient,
    selectAllKam,
    onSelectAll,
  } = useFiltersAuthorization({ filterData, setFilterData, })


  const isSelectValues = selectedValues && selectedValues.length > 0

  const { data: pendingAuthorization, isLoading: isLoadingPendingAuthorization, refetch: refetchPendingAuthotization, isFetching: isFetchingPendingAuthorization } = UseQueryPendingAuthorizationCommissions(filterData)

  const cbSuccessApproveCommission = ({ data }: any) => {
    setSelectedValues([])
    refetchPendingAuthotization()
  }


  const cbSuccessDownloadFile = ({ data }: any) => {

  }

  const { mutate: approveCommission, isLoading: isLoadingApproveCommission } = UsePostApproveCommissionAuthorization(cbSuccessApproveCommission)
  const { mutate: downloadFile, isLoading: isLoadingDownloadFile } = UseDownloadFileAuthorizatorPending(cbSuccessDownloadFile)





  useEffect(() => {
    if (pendingAuthorization) {
      const parsePagination = JSON.parse(pendingAuthorization?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [pendingAuthorization])

  const onFilterClear = (): void => {
    cleanSelectKamAndClient()
    setFilterData({})
  }

  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)

    setFilterData({
      ...filterData,
      ...formattedFilters,
      username,
      serviceClient,
    })
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({
      ...filterData,
      username,
      serviceClient,
      pageNumber: e.page,
      pageSize: e.rows
    })
  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      username,
      serviceClient,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })
  }

  const onSelectionChange = useCallback((values: any[]) => {
    setSelectedValues(values)
  }, [])


  const approveCommisionFn = () => {
    if (selectedValues.length > 0) {
      const newArray = selectedValues.map((item: any) => ({
        id: item.id,
        state: 'approved'
      }))
      approveCommission(newArray)
    }
  }



  const downloadFilePending = () => {
    downloadFile(filterData)
  }


  const getHeadersButtons = () => (
    <>

      {
        permissions.canRead && (
          <UfinetButton
            className="me-3"
            icon={faUpload}
            onClick={showUploadModal}
            content={Translation('BULK_LOAD')}
          />
        )
      }
      {permissions.canWrite && (
        <UfinetButton
          className="me-3"
          isDisabled={isLoadingDownloadFile}
          icon={faDownload}
          onClick={() => downloadFilePending()}
          content={Translation('DOWNLOAD.FILE')}
        />
      )}

      {permissions.canWrite && isSelectValues && (
        <UfinetButton
          className="me-3"
          icon={faCircleCheck}
          onClick={approveCommisionFn}
          isDisabled={isLoadingApproveCommission}
          content={Translation('BUTTON.APRROVED')}
        />
      )}

      {permissions.canWrite && isSelectValues && (
        <UfinetButton
          className="me-3"
          icon={faCircleXmark}
          onClick={showRejectModal}
          content={Translation('BUTTON.REJECTED')}
        />
      )}
    </>
  )



  const filterButtons = () => {
    return (
      <>
        <MultiSelectFiltersHeaders

          onSelectAll={onSelectAll}
          selectAllClient={selectAllClient}
          selectAllKam={selectAllKam}
          multiSelectClient={multiSelectClient}
          multiSelectKam={multiSelectKam}
          clientsSelect={clientsSelect}
          kamsSelect={kamsSelect}
          onChangeHandle={handleMultiSelect}
          isLoadingDataAuthorizator={isLoadingDataAuthorizator}
          isLoadingDataClients={isLoadingDataClients}
        />
      </>
    )

  }


  return (
    <>
      <UfinetBox>

        <UfinetSectionBox>
          <Table
            filterButtons={filterButtons()}
            enableSearchFilter={false}
            dataKey="id"
            selectedValues={selectedValues}
            onSelectionChange={permissions.canUpdate ? onSelectionChange : undefined}
            headerButtons={getHeadersButtons()}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            cols={colsPendingPage}
            content={pendingAuthorization?.data?.commissions?.map((item: CommissionElement) => ({
              ...item,
              username: item.user.username,
              idService: item.service.idService,
              saleMonth: item.service.saleMonth,
              commissionType: item.commission.commissionType,
              exchangeRate: item.commission.exchangeRate,
              saleYear: item.service.saleYear,
              authorizationState: item.authorization.authorizationState,
              id: item.commission.id,
              achievementFactor: item.commission.achievementFactor,
              baseAmount: item.commission.baseAmount,
              percentageCommission: item.commission.percentageCommission,
              backlog: item.service.backlog,
              totalAmount: item.commission.totalAmount,
              commissionTypePercentage: item.commission.commissionTypePercentage,
              idServiceCRM: item.service.idServiceCRM,
              serviceName: item.service.serviceName,
              serviceClient: item.service.serviceClient,
            }))}
            // customClassFilters="row col-xl-6  col-md-12 col-sm-12"
            actionBodyTemplate={<></>}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            emptyMessage={isLoadingPendingAuthorization || isFetchingPendingAuthorization ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            onFilterClear={onFilterClear}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingPendingAuthorization || isFetchingPendingAuthorization,
                onFilter,
                onPage,
                onSort,
              }
            }
          />
          <UfinetModal
            size="lg"
            show={rejecteModal}
            handleClose={hideRejectModal}
            title={translate('BUTTON.REJECTED')}
          >
            <RejectModalForm refetch={refetchPendingAuthotization} hideRejectModal={hideRejectModal} selectedValues={selectedValues} setSelectedValues={setSelectedValues} />
          </UfinetModal>

          <UfinetModal
            handleClose={hideUploadModal}
            size="lg"
            show={uploadModal}
            title={translate('BULK_LOAD')}

          >
            < BulkLoadPendingApprovalForm hideUploadModal={hideUploadModal} />
          </UfinetModal>
        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}

export { PendingAuthorizationPage }

const ContainerCommissionAmount = styled.div`
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      h2 {
        margin: 0;
    }
      `

const TotalAmount = styled.div`
      display: flex;
      justify-content: flex-end;
      `

const CurrencyCode = styled.div`
      display: flex;
      justify-content: flex-end;
      `
