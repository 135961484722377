import { useMutation, useQuery } from "react-query";
import { fetchGET2 } from "utils/https/https";


export const UseQueryLiquidateHistoricals = (data: any) => {

  // <param name="paymentState">Mes de pago</param>
  // <param name="paymentState">Estado del pago</param>
  // <param name="userPayment">Usuario que realizo pago</param>
  // <param name="creationDate">Fecha de creación</param>
  // <param name="cutoffDate">Fecha de corte</param>
  // <param name="paymentDate">Fecha de pago</param>
  // <param name="company">Empresa pagadora</param>

  const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
  const paymentId = data?.paymentId && data?.paymentId.length > 0 ? data?.paymentId[0] : null
  const paymentState = data?.paymentState && data?.paymentState.length > 0 ? data?.paymentState[0] : null
  const userPayment = data?.userPayment && data?.userPayment.length > 0 ? data?.userPayment[0] : null
  const creationDate = data?.creationDate && data?.creationDate.length > 0 ? data?.creationDate[0] : null
  const cutoffDate = data?.cutoffDate && data?.cutoffDate.length > 0 ? data?.cutoffDate[0] : null
  const paymentDate = data?.paymentDate && data?.paymentDate.length > 0 ? data?.paymentDate[0] : null
  const company = data?.company && data?.company.length > 0 ? data?.company[0] : null
  const paymentYear = data?.paymentYear ? data?.paymentYear : null

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null



  return useQuery(['UseQuerLiquidateHistoricals', paymentMonth, paymentState, paymentId, userPayment, creationDate, cutoffDate, paymentDate, company, paymentYear, pageNumber, pageSize, sortField, sortOrder], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (paymentMonth) {
        serchParms.append('paymentMonth', paymentMonth)
      }

      if (paymentId) {
        serchParms.append('paymentId', paymentId)
      }

      if (paymentState) {
        serchParms.append('paymentState', paymentState)
      }

      if (userPayment) {
        serchParms.append('userPayment', userPayment)
      }

      if (creationDate) {
        serchParms.append('creationDate', creationDate)
      }

      if (cutoffDate) {
        serchParms.append('cutoffDate', cutoffDate)
      }

      if (paymentDate) {
        serchParms.append('paymentDate', paymentDate)
      }

      if (company) {
        serchParms.append('company', company)
      }

      if (paymentYear) {
        serchParms.append('paymentYear', paymentYear)
      }


      // Pagination
      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('pageSize', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }

      const response = await fetchGET2(`Payment/ListPayments?&${serchParms.toString()}`)
      return response
    } catch (error) {
      throw error
    }
  })
}


const downloadFileLiquidateHistoricals = async (data: any) => {

  const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
  const paymentId = data?.paymentId && data?.paymentId.length > 0 ? data?.paymentId[0] : null
  const paymentState = data?.state && data?.state.length > 0 ? data?.state[0] : null
  const userPayment = data?.userPayment && data?.userPayment.length > 0 ? data?.userPayment[0] : null
  const creationDate = data?.creationDate && data?.creationDate.length > 0 ? data?.creationDate[0] : null
  const cutoffDate = data?.cutoffDate && data?.cutoffDate.length > 0 ? data?.cutoffDate[0] : null
  const paymentDate = data?.paymentDate && data?.paymentDate.length > 0 ? data?.paymentDate[0] : null
  const company = data?.company && data?.company.length > 0 ? data?.company[0] : null
  const paymentYear = data?.paymentYear ? data?.paymentYear : null

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  try {

    const serchParms = new URLSearchParams();

    if (paymentMonth) {
      serchParms.append('paymentMonth', paymentMonth)
    }

    if (paymentId) {
      serchParms.append('paymentId', paymentId)
    }

    if (paymentState) {
      serchParms.append('paymentState', paymentState)
    }

    if (userPayment) {
      serchParms.append('userPayment', userPayment)
    }

    if (creationDate) {
      serchParms.append('creationDate', creationDate)
    }

    if (cutoffDate) {
      serchParms.append('cutoffDate', cutoffDate)
    }

    if (paymentDate) {
      serchParms.append('paymentDate', paymentDate)
    }

    if (company) {
      serchParms.append('company', company)
    }

    if (paymentYear) {
      serchParms.append('paymentYear', paymentYear)
    }


    // Pagination
    if (pageNumber) {
      serchParms.append('pageNumber', pageNumber)
    }

    if (pageSize) {
      serchParms.append('pageSize', pageSize)
    }

    if (sortOrder) {
      serchParms.append('sortOrder', sortOrder)
    }

    if (sortField) {
      serchParms.append('sortField', sortField)
    }

    const response = await fetchGET2(`Payment/DownloadPayments?&${serchParms.toString()}`, { responseType: 'blob' })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'liquidateHistoricalsDocument.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);

  } catch (error) {
    throw error
  }
}



export const UseDownloadFileLiquidateHistoricals = (callbackSuccess: any) => {
  return useMutation(downloadFileLiquidateHistoricals, {
    onSuccess: (data) => {
      callbackSuccess(data)
    }
  })
}
