import React, { useCallback, useContext } from 'react'
import { useFormik } from 'formik'
import { UfinetButton, UfinetDropzone, } from 'ufinet-web-components';
import { onFormikChanges, IFileUploadData, AuthContext } from 'ufinet-web-functions';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { UseGetTemplateCommissionSysPending, UsePostBulkLoadFileCommissionSysPending } from 'request';
import * as Yup from 'yup'
import { ErrorResponse } from 'types/typesAndValues';
import { useTranslation } from 'utils/translation/Translation';
import { toast } from 'react-toastify'

interface Props {
  downLoadTemplate?: () => void;
  hideLoadModal: () => void;
  refetch: () => void
}

interface File {
  file: IFileUploadData
}


const BulkLoadCommissionPendingForm = ({ hideLoadModal, refetch }: Props) => {

  const translate = useTranslation()



  const authData = useContext(AuthContext)
  const userEmail = authData.userData?.username




  const onChange = useCallback(onFormikChanges, [])

  const cbSuccessDownLoadFile = (data: any) => {
    // hideLoadModal()
  }

  const cbSuccessBulkLoadFile = (data: any) => {
    refetch()
    toast.success(translate('SENDED'))
    hideLoadModal()
  }

  const { mutate: downLoadFileTemplate } = UseGetTemplateCommissionSysPending(cbSuccessDownLoadFile)

  const { mutate: uploadBulkFile, isError, error: errorMessage } = UsePostBulkLoadFileCommissionSysPending(cbSuccessBulkLoadFile)

  const dataFormSchema = Yup.object().shape({
    file: Yup.mixed()
      //@ts-ignore
      .test('file required', translate('ERROR.REQUIRED'), (value) => value.size > 0)
      .test('file type', translate('ERROR.FILE.EXTENSION'), (value) => {
        //@ts-ignore
        const fileExtension = value?.path?.split('.').pop()
        return fileExtension === undefined || fileExtension.includes(['xlsx']) || fileExtension.includes(['xls'])
      }),
  })


  const showToastErrMessage = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse;
      toast.error(`${translate("ERROR.MESSAGE")} ${errorResponse.errorCode}`, { toastId: 'Error5' });
      hideLoadModal()
    }
  }



  const formik = useFormik<File>({
    initialValues: {
      file: {
        path: '',
        size: 0,
      },
    },
    validationSchema: dataFormSchema,
    onSubmit: (values: File) => {

      uploadBulkFile(values.file)
      // afterSubmit && afterSubmit(values)
    },
    validateOnChange: false,
    validateOnBlur: false,
  })


  return (
    <>
      {isError && showToastErrMessage()}


      <form onSubmit={formik.handleSubmit}>
        <div>
          <div className="row py-10">
            <div className="col-12">
              <UfinetButton
                onClick={() => downLoadFileTemplate(userEmail)}
                className="w-100"
                content={translate('DOWNLOAD.TEMPLATE')}
                icon={faDownload}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <UfinetDropzone
                isDisabled={false}
                onChange={onChange(formik, 'file')}
                value={formik.values.file}
                error={formik.errors.file}
                text={translate('DROPZONE.UPLOAD_EXCEL.TITLE')}
                accept={{ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xls'] }}
              />
            </div>
          </div>

          <div className="row py-10">
            <div className="col-12">
              <UfinetButton
                type='submit'
                className="w-100"
                content={translate('SAVE')}
                icon={faSave}
              />
            </div>
          </div>

          {/* <div className="row">
        <UfinetActionButton
          onClick={downLoadTemplate}
          // ref={actionButtonRef}
          className="mt-10"
          content={Translation('SAVE')}
          icon={faSave}
        />
      </div> */}

          {/* <div className="row">
        <div>
          <UfinetButton
            // onClick={downLoadTemplate}
            className="mt-10"
            content={Translation('SAVE')}
            icon={faSave}
          />
        </div>
      </div> */}

          {/* <UfinetButton
        content={Translation('Descargar')}
        onClick={downLoadTemplate}
      /> */}
        </div>
      </form>
    </>
  )
}

export { BulkLoadCommissionPendingForm }


