import { ColData } from 'ufinet-web-components'
import { IntlShape } from 'react-intl';

export const colsMultiplierConfiKamColData = (intl: IntlShape): ColData[] => [
  // header is the id to search the translate based on the data received
  // removing or typing the word false in the property nonFilterable is gonna show in the table header the filter
  // body you can customize the data you want to show, accept react component or just a simple html
  {
    field: 'id', header: 'COL.CONFIG.ID', width: '190px', nonFilterable: false, editable: true, nonSortable: false, filterType: 'number',
    // body: (row: any) => <PercentangeCommission percentange={row.percentage} />
  },
  {
    field: 'compliancePercentageMin', header: 'COL.CONFIG.COMPLIANCEPERCENTAGEMIN', width: '190px', nonFilterable: false, editable: true, nonSortable: false, filterType: 'number',
    // body: (row: any) => <PercentangeCommission percentange={row.percentage} />
  },
  {
    field: 'compliancePercentageMax', header: 'COL.CONFIG.COMPLIANCEPERCENTAGEMAX', width: '190px', nonFilterable: false, editable: true, nonSortable: false, filterType: 'number',
    // body: (row: any) => <PercentangeCommission percentange={row.percentage} />
  },
  {
    field: 'multiplierFactor', header: 'COL.CONFIG.MULTIPLIERFACTOR', width: '190px', nonFilterable: false, editable: true, nonSortable: false, filterType: 'number',
    // body: (row: any) => <PercentangeCommission percentange={row.percentage} />
  },
]

