import { convertNumber } from "utils/convertNumber/ConvertNumber"

interface Props {
  value: null | number
}


const DolarValue = ({ value }: Props) => {


  return (<>{value === null ? '' : `${convertNumber(value)} USD`} </>)
}

export { DolarValue }
