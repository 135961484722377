import * as ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'

import { CommissionProvider } from 'components/common/i18n/CommissionProvider'
import { AppRoutes } from 'routing/AppRoutes'
import { AuthProvider } from 'ufinet-web-components'
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'
import packageJson from '../package.json'

import 'ufinet-web-components/dist/index.css'
import './sass/style.scss'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

// Start the mocking conditionally
if (process.env.REACT_APP_ENV === 'TEST') {
  const { worker } = require('./mocks/browser')
  worker.start()
}

console.info(packageJson.version)

ReactDOM.render(
  <>
    <QueryClientProvider client={queryClient}>

      <AuthProvider>
        <CommissionProvider>
          <AppRoutes />
          <ReactQueryDevtools initialIsOpen={false} />
        </CommissionProvider>
      </AuthProvider>
    </QueryClientProvider>
  </>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
