import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { Table, UfinetBox, UfinetButton, UfinetModal, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection } from 'ufinet-web-components'
import { AuthContext, Authority, useModal, } from 'ufinet-web-functions'
import { colsPendingCommisions } from './PendingColData'
import { UseQueryPendingCommissionsSystems, UseDownloadFileCommissionPendingSys, UseRecalculateCommissionSys } from 'request'
import { Translation, useTranslation } from 'utils/translation/Translation'
import { DataTablePageEvent } from 'primereact/datatable'
import { CommissionSystemElement } from 'types/commissionSystemTypes'
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload'
import { BulkLoadCommissionPendingForm } from './bullkLoad/BulkLoadCommissionPendingForm'
import { faDownload, faCalculator } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import { ErrorResponse } from 'types/typesAndValues'

const PendingPage = () => {
  const intl = useIntl()
  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  const permissions = Authority.getCommissionCmSystemPermissions(roles)

  const translate = useTranslation()

  const [newModal, showNewModal, hideNewModal] = useModal()
  const [paging, setPaging] = useState<any>()
  const colsPendingPage = useMemo(() => colsPendingCommisions(intl), [intl])

  const [selectedValues, setSelectedValues] = useState<CommissionSystemElement[]>([])
  const [filterData, setFilterData] = useState<any>()
  const { data: pendingCommissions, isLoading: isLoadingPendingCommission, refetch, isFetching } = UseQueryPendingCommissionsSystems(filterData)

  const isSelectedValues = selectedValues && selectedValues.length > 0

  const cbSuccessPendingFile = (data: any) => {

  }

  const cbRecalculateCommission = ({ data }: any) => {
    toast.success(data.message)
    setSelectedValues([])
    refetch()
  }

  const { mutate: downloadFile, isLoading: isLoadingCommissionPendingSys } = UseDownloadFileCommissionPendingSys(cbSuccessPendingFile)

  const { mutate: recalculateCommission, isLoading: isLoadingRecalculateCommissionSys, isError: isErrorRecalculate, error: errorMessage } = UseRecalculateCommissionSys(cbRecalculateCommission)

  const showToastErrMessage = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse;
      toast.error(`${translate("ERROR.MESSAGE")} ${errorResponse.errorCode}`, { toastId: 'Error5' });

    }
  }


  useEffect(() => {
    if (pendingCommissions) {
      const parsePagination = JSON.parse(pendingCommissions?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [pendingCommissions])



  const onFilterClear = (): void => {
    setFilterData({})
  }

  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)
    setFilterData({
      ...filterData,
      ...formattedFilters
    })
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({ ...filterData, pageNumber: e.page, pageSize: e.rows })
  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })
  }




  const downloadFileRejectedSys = () => {
    downloadFile(filterData)
  }

  const recalculate = () => {
    if (isSelectedValues) {
      const newArr = selectedValues.map((item: CommissionSystemElement) => {
        return {
          Id: item.commission.id
        }
      })

      recalculateCommission(newArr)

    }

  }

  const getHeadersButtons = () => (
    <>
      {permissions.canWrite && (
        <UfinetButton
          className="me-3"
          icon={faDownload}
          onClick={() => downloadFileRejectedSys()}
          content={Translation('DOWNLOAD.FILE')}
        />
      )}
      {
        permissions.canRead && (
          <UfinetButton
            className="me-3"
            icon={faUpload}
            onClick={showNewModal}
            content={Translation('BULK_LOAD')}
          />
        )
      }
      {permissions.canWrite && isSelectedValues && (
        <UfinetButton
          isDisabled={isLoadingRecalculateCommissionSys}
          className="me-3"
          icon={faCalculator}
          onClick={() => recalculate()}
          content={Translation('RECALCULATE')}
        />
      )}
    </>
  )

  const onSelectionChange = useCallback((values: any[]) => {
    setSelectedValues(values)
  }, [])






  return (
    <>
      {isErrorRecalculate && showToastErrMessage()}
      <UfinetBox>

        <UfinetSectionBox>
          <Table
            dataKey='id'
            enableSearchFilter={false}
            selectedValues={selectedValues}
            onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            cols={colsPendingPage}
            content={pendingCommissions?.data?.commissions?.map((item: CommissionSystemElement) => ({
              ...item,
              username: item.user.username,
              idService: item.service.idService,
              saleMonth: item.service.saleMonth,
              saleYear: item.service.saleYear,
              commissionType: item.commission.commissionType,
              exchangeRate: item.commission.exchangeRate,
              commissionDescription: item.commission.commissionDescription,
              backlog: item.service.backlog,
              authorizatorName: item.authorization.authorizatorName,
              paymentMonth: item.commission.paymentMonth,
              paymentYear: item.commission.paymentYear,
              commissionTypePercentage: item.commission.commissionTypePercentage,
              achievementFactor: item.commission.achievementFactor,
              idServiceCRM: item.service.idServiceCRM,
              serviceName: item.service.serviceName,
              serviceClient: item.service.serviceClient,
              firstBillingDate: item.service.firstBillingDate,
              id: item.commission.id
            }))}
            actionBodyTemplate={<></>}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            emptyMessage={isLoadingPendingCommission || isFetching || isLoadingCommissionPendingSys ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            onFilterClear={onFilterClear}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingPendingCommission || isFetching || isLoadingCommissionPendingSys,
                onFilter,
                onPage,
                onSort,
              }
            }
            headerButtons={getHeadersButtons()}
          />
          <UfinetModal
            size="lg"
            show={newModal}
            handleClose={hideNewModal}
            title={Translation('LOAD.TEMPLATE')}
          >
            <BulkLoadCommissionPendingForm hideLoadModal={hideNewModal} refetch={refetch} />
          </UfinetModal>
        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}

export default PendingPage

const ContainerCommissionAmount = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;    
    
    h2 {
      margin: 0;
    }
`

const TotalAmount = styled.div`
  display: flex;
  justify-content: flex-end;
`

const CurrencyCode = styled.div`
  display: flex;
  justify-content: flex-end;
`
