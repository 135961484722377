import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { toast } from 'react-toastify'
import { MultipleDeleter, Table, UfinetBox, UfinetButton, UfinetModal, UfinetSectionBox, formatTableFilters, getEditPencilBodyTemplate, tableSortValueToSortDirection, } from 'ufinet-web-components'
import { AuthContext, Authority, useAsync, useModal, } from 'ufinet-web-functions'
import { colsUsersRRHHColData } from './UsersRRHHColData'
import { UseQueryGetUsersRRHHConfig, UseGetPercentangeTypeCommissionTemplate, UseDeletePercentangeTypeCommmision } from 'request'
import { useTranslation } from 'utils/translation/Translation'
import { DataTableFilterMeta, DataTablePageEvent } from 'primereact/datatable'
import { CreateUsersRRHHForm, UpdateUsersRRHHForm } from './modals'
import { UsersRRHHTypes } from '../../../types/configuration/ConfigurationTypes';


const UsersRRHHConfigPage = () => {
  const intl = useIntl()
  const colsUsersRRHHConfig = useMemo(() => colsUsersRRHHColData(intl), [intl])
  const [selectedValues, setSelectedValues] = useState<UsersRRHHTypes[]>([])
  const [userRRHHId, setUserRRHHId] = useState<number>()
  const [userRRHHInfo, setUserRRHHInfo] = useState<UsersRRHHTypes>()



  //* Modals
  const [newModal, showNewModal, hideNewModal] = useModal()
  const [createModal, showCreateModal, hideCreateModal] = useModal()
  const [updateModal, showUpdateModal, hideUpdateModal] = useModal()

  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  // Go to see the repo ufinet-web-functions at the file typeAndValues branch develop
  // In the function Authority to get the function to get the roles
  // In this case getCommissionsPermissions is gonna get the roles
  const permissions = Authority.getCommissionConfigPermissions(roles)

  const [paging, setPaging] = useState<any>()
  const [loadingRecords, setLoadingRecords] = useState<boolean>(false)
  const [filterData, setFilterData] = useState<any>()
  const [tablePFSEvent, setTablePFSEvent] = useState<DataTablePageEvent>()


  const { data: usersRRHH, isLoading: isLoadingUsersRRHH, refetch } = UseQueryGetUsersRRHHConfig(filterData)


  const translate = useTranslation()


  const onSelectionChange = useCallback((values: any) => setSelectedValues(values), [])






  useEffect(() => {
    if (usersRRHH) {
      const parsePagination = JSON.parse(usersRRHH?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: filterData && filterData.pageSize ? filterData.pageSize : parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [usersRRHH])

  const onFilterClear = (): void => {
    setFilterData({})
  }



  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)
    setFilterData({
      ...filterData,
      ...formattedFilters
    })
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({ ...filterData, pageNumber: e.page, pageSize: e.rows })
  }

  const onSort = (e: DataTablePageEvent): void => {
    // const finalField = UserPropertyMap.get(e.sortField)
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })
  }






  const getHeadersButtons = () => (
    <>

      {permissions.canWrite && (
        <>
          <UfinetButton
            className="me-3"
            icon={faPlus}
            onClick={() => {
              showCreateModal()
            }}
            content={translate('CREATE.COMMISSION.TYPE')}
          />
        </>
      )}
    </>
  )

  return (
    <>
      <UfinetBox>

        <UfinetSectionBox>

          <Table
            dataKey="id"
            selectedValues={selectedValues}
            onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            content={usersRRHH?.data}
            cols={colsUsersRRHHConfig}
            headerButtons={getHeadersButtons()}
            onFilterClear={onFilterClear}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            emptyMessage={isLoadingUsersRRHH ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            actionBodyTemplate={

              permissions.canUpdate
                ? (userRRRHH: UsersRRHHTypes) =>
                  getEditPencilBodyTemplate(userRRRHH, () => {
                    setSelectedValues([])
                    setUserRRHHInfo(userRRRHH)
                    showUpdateModal()
                  })
                : undefined
            }
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingUsersRRHH,
                onFilter,
                onPage,
                onSort,
              }
            }
          />
          {/* 
          <UfinetModal
            size="lg"
            show={newModal}
            handleClose={hideNewModal}
            title={translate('LOAD.TEMPLATE')}
          >
            <PercentangeTypeCommissionBulkLoadForm downLoadTemplate={downloadTemplate} hideLoadModal={hideNewModal} refetch={refetch} />
          </UfinetModal> */}

          <UfinetModal
            size="lg"
            show={createModal}
            handleClose={hideCreateModal}
            title={translate('CREATE.COMMISSION.TYPE')}
          >
            <CreateUsersRRHHForm hideModal={hideCreateModal} refetch={refetch} />
          </UfinetModal>

          <UfinetModal
            size="lg"
            show={updateModal}
            handleClose={hideUpdateModal}
            title={translate('UPDATE.COMMISSION.TYPE')}
          >
            <UpdateUsersRRHHForm updatedData={userRRHHInfo} hideModal={hideUpdateModal} refetch={refetch} />
          </UfinetModal>
        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}

export { UsersRRHHConfigPage }


