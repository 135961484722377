import React from 'react'
import Badge from 'react-bootstrap/Badge';
import styled from 'styled-components'

interface Props {
  achievementFactor: number
}

const AchievementFactorRow = ({ achievementFactor }: Props) => {
  return (
    <AchievementFactorBadge>{achievementFactor}</AchievementFactorBadge>
  )
}

export default AchievementFactorRow

const AchievementFactorBadge = styled(Badge)`
  background-color: #E6E6E6!important;
  color: #1A1A1A;
  padding: 10px;
`


// Level 1	#009dff
// Level 2	#0077c2
// Level 3	#0065a4
// Level 4	#004d7d
// Level 5	#003150
// Level 6	#001a2b
// Level 7	#000000
// gray color #E6E6E6
