import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload'
import { Table, UfinetBox, UfinetButton, UfinetModal, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection, } from 'ufinet-web-components'
import { AuthContext, Authority, useModal, } from 'ufinet-web-functions'
import { colsGlobalData } from './GlobalColData'
import { UseQueryGetConfigGlobal, UseGetGlobalBulkLoadTemplate } from 'request'
import { Translation } from 'utils/translation/Translation'
import { DataTablePageEvent } from 'primereact/datatable'

import GlobalBulkLoadForm from '../bulk-load/GlobalBulkLoadForm'


const GlobalPage = () => {
  const intl = useIntl()
  const colsGloblaPageCommission = useMemo(() => colsGlobalData(intl), [intl])

  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  // Go to see the repo ufinet-web-functions at the file typeAndValues branch develop
  // In the function Authority to get the function to get the roles
  // In this case getCommissionsPermissions is gonna get the roles
  const permissions = Authority.getCommissionConfigPermissions(roles)
  const [newModal, showNewModal, hideNewModal] = useModal()
  const [paging, setPaging] = useState<any>()
  const [filterData, setFilterData] = useState<any>()



  const { data: configGlobal, isLoading: isLoadingConfigGlobal } = UseQueryGetConfigGlobal(filterData)




  const cbSuccessGlobalBulkLoad = ({ data }: any) => {

  }
  const { mutate: downloadGlobalTemplate } = UseGetGlobalBulkLoadTemplate(cbSuccessGlobalBulkLoad)


  useEffect(() => {
    if (configGlobal) {
      const parsePagination = JSON.parse(configGlobal?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: filterData && filterData.pageSize ? filterData.pageSize : parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [configGlobal])

  const onFilterClear = (): void => {
    setFilterData({})
  }





  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)
    setFilterData({
      ...filterData,
      ...formattedFilters
    })
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({ ...filterData, pageNumber: e.page, pageSize: e.rows })
  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })

  }

  const getHeadersButtons = () => (
    <>

      {permissions.canWrite && (
        <UfinetButton
          className="me-3"
          icon={faUpload}
          onClick={() => {
            showNewModal()
          }}
          content={Translation('BULK_LOAD')}
        />
      )}
    </>
  )

  return (
    <>
      <UfinetBox>

        <UfinetSectionBox>

          <Table
            // selectedValues={selectedValues}
            // onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            content={configGlobal?.data}
            cols={colsGloblaPageCommission}
            headerButtons={getHeadersButtons()}
            onFilterClear={onFilterClear}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            emptyMessage={isLoadingConfigGlobal ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingConfigGlobal,
                onFilter,
                onPage,
                onSort,
              }
            }
          // emptyMessage={loadingRecords ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
          />
          <UfinetModal
            size="lg"
            show={newModal}
            handleClose={hideNewModal}
            title={Translation('LOAD.TEMPLATE')}
          >
            <GlobalBulkLoadForm downLoadTemplate={downloadGlobalTemplate} hideLoadModal={hideNewModal} />
          </UfinetModal>
        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}

export default GlobalPage

