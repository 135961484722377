import React, { useRef, useCallback, useMemo } from 'react'
import { useFormik } from 'formik'
import { UfinetActionButton, UfinetActionButtonHandle, UfinetInput } from 'ufinet-web-components';
import { IFileUploadData, onFormikNumberChanges } from 'ufinet-web-functions';
import { UseUpdateMultiplierConfig } from 'request';
import * as Yup from 'yup'
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { ErrorResponse, } from 'types/typesAndValues';
import { useTranslation } from 'utils/translation/Translation';
import { MultiplierConfigTypes } from 'types/configuration';

interface Props {
  updatedData: MultiplierConfigTypes;
  hideModal: () => void;
  refetch: () => void
}

interface File {
  file: IFileUploadData
}



type CreateFormData = {
  id: number
  compliancePercentageMin: number
  compliancePercentageMax: number
  multiplierFactor: number
}

const UpdateMultiplierConfigForm = ({ updatedData, hideModal, refetch }: Props) => {





  const translate = useTranslation()
  const intl = useIntl()




  const actionButtonRef = useRef<UfinetActionButtonHandle>(null)




  const cbSuccessUpdateMultiplierConfig = (data: any) => {
    toast.success(intl.formatMessage({ id: 'SENDED' }))
    actionButtonRef.current?.changeActionStatus(false)
    refetch()
    hideModal()
  }

  const { mutate: updateMultiplierConfig, isError, error: errorMessage } = UseUpdateMultiplierConfig(cbSuccessUpdateMultiplierConfig)


  const showErrMessage = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse;
      toast.error(`${intl.formatMessage({ id: "ERROR.MESSAGE" },)} ${errorResponse.message}`, { toastId: 'Error2' });
      hideModal()
    }
  }

  const dataFormSchema = Yup.object().shape({
    id: Yup.number(),
    compliancePercentageMin: Yup.number(),
    compliancePercentageMax: Yup.number(),
    multiplierFactor: Yup.number(),
  })






  const sendData = (values: any) => {
    actionButtonRef.current?.changeActionStatus(true)
    updateMultiplierConfig({
      id: +values.id,
      compliancePercentageMin: +values.compliancePercentageMin,
      compliancePercentageMax: +values.compliancePercentageMax,
      multiplierFactor: +values.multiplierFactor,
    })
  }


  const onNumberChange = useCallback(onFormikNumberChanges, [])


  const initialValues: CreateFormData = useMemo<CreateFormData>(
    () => ({
      id: updatedData && updatedData.id ? updatedData.id : 0,
      compliancePercentageMin: updatedData && updatedData.compliancePercentageMin ? updatedData.compliancePercentageMin : 0,
      compliancePercentageMax: updatedData && updatedData.compliancePercentageMax ? updatedData.compliancePercentageMax : 0,
      multiplierFactor: updatedData && updatedData.multiplierFactor ? updatedData.multiplierFactor : 0,
    }),
    []
  )



  const formik = useFormik<CreateFormData>({
    initialValues,
    validationSchema: dataFormSchema,
    onSubmit: sendData,
    validateOnChange: false,
    validateOnBlur: false,
  })


  return (
    <>
      {isError && showErrMessage()}
      <form onSubmit={formik.handleSubmit}>
        <div className='row'>
          <div className='row'>
            <UfinetInput
              error={formik.errors.id}
              type="decimal"
              labelTitle={translate('COL.CONFIG.ID')}
              className="col-6"
              onChange={onNumberChange(formik, 'id')}
              value={formik.values.id}
              solid={false}
            />

            <UfinetInput
              error={formik.errors.compliancePercentageMin}
              type="decimal"
              labelTitle={translate('COL.CONFIG.COMPLIANCEPERCENTAGEMIN')}
              className="col-6"
              onChange={onNumberChange(formik, 'compliancePercentageMin')}
              value={formik.values.compliancePercentageMin}
              solid={false}
            />


            <div className='row mt-5'>
              <UfinetInput
                error={formik.errors.compliancePercentageMax}
                type="decimal"
                labelTitle={translate('COL.CONFIG.COMPLIANCEPERCENTAGEMAX')}
                className="col-6"
                onChange={onNumberChange(formik, 'compliancePercentageMax')}
                value={formik.values.compliancePercentageMax}
                solid={false}
              />
              <UfinetInput
                error={formik.errors.multiplierFactor}
                type="decimal"
                labelTitle={translate('COL.CONFIG.MULTIPLIERFACTOR')}
                className="col-6"
                onChange={onNumberChange(formik, 'multiplierFactor')}
                value={formik.values.multiplierFactor}
                solid={false}
              />


            </div>

            <UfinetActionButton
              ref={actionButtonRef}
              className='mt-5'
              content={translate('UPDATE.MULTIPLIER.CONFIG')}
            />
          </div>
        </div>
      </form>
    </>
  )
}

export { UpdateMultiplierConfigForm }


