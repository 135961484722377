import { useMutation, useQuery } from "react-query"
import { fetchGET, fetchGET2, fetchPOST, fetchPOSTBlob } from "utils/https/https"


type RequestBody = {
  username?: string
  serviceClient?: string
}

export const UseQueryApproveAuthorizationSysCommissions = (data: any) => {

  const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
  const type = data?.type && data?.type.length > 0 ? data?.type[0] : null
  const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
  const authorizatorName = data?.authorizatorName ? data?.authorizatorName : null
  const serviceId = data?.idService ? data?.idService : null
  const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
  const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
  const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null


  const username = Array.isArray(data?.username) ? data.username : [];
  const serviceClient = Array.isArray(data?.serviceClient) ? data.serviceClient : [];

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  return useQuery(['UseQueryApproveAuthorizationSysCommissions', username, authorizatorName, serviceId, serviceClient, serviceName, idServiceCRM, type, saleMonth, paymentMonth, saleYear, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();


      if (serviceName) {
        serchParms.append('serviceName', serviceName)
      }

      if (idServiceCRM) {
        serchParms.append('idServiceCRM', idServiceCRM)
      }

      if (authorizatorName) {
        serchParms.append('authorizatorName', authorizatorName)
      }

      if (serviceId) {
        serchParms.append('IdService', serviceId)
      }

      if (type) {
        serchParms.append('type', type)
      }

      if (saleMonth) {
        serchParms.append('saleMonth', saleMonth)
      }

      if (paymentMonth) {
        serchParms.append('paymentMonth', paymentMonth)
      }

      if (saleYear) {
        serchParms.append('saleYear', saleYear)
      }


      // PAGINATION
      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('rowsPerPage', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }


      const requestBody: RequestBody = {};

      if (username.length > 0) {
        requestBody.username = username.join(',');
      }
      if (serviceClient.length > 0) {
        requestBody.serviceClient = serviceClient.join(',');
      }

      const response = await fetchPOST(`Authorizator/ListCommissions?commissionState=completed&authorizationState=approved&${serchParms.toString()}`, requestBody)
      return response
    } catch (error) {
      throw error
    }
  })
}


export const UseQueryPendingAuthorizationSystemCommissions = (data: any) => {

  const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
  const type = data?.type && data?.type.length > 0 ? data?.type[0] : null
  const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
  const serviceId = data?.idService ? data?.idService : null
  const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
  const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
  const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null
  const authorizatorName = data?.authorizatorName ? data?.authorizatorName : null

  const username = Array.isArray(data?.username) ? data.username : [];
  const serviceClient = Array.isArray(data?.serviceClient) ? data.serviceClient : [];


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  return useQuery(['UseQueryPendingAuthorizationSystemCommissions', username, serviceId, type, authorizatorName, idServiceCRM, serviceClient, serviceName, saleMonth, paymentMonth, saleYear, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();



      if (authorizatorName) {
        serchParms.append('authorizatorName', authorizatorName)
      }


      if (serviceName) {
        serchParms.append('serviceName', serviceName)
      }

      if (idServiceCRM) {
        serchParms.append('idServiceCRM', idServiceCRM)
      }

      if (serviceId) {
        serchParms.append('IdService', serviceId)
      }

      if (type) {
        serchParms.append('type', type)
      }

      if (saleMonth) {
        serchParms.append('saleMonth', saleMonth)
      }

      if (paymentMonth) {
        serchParms.append('paymentMonth', paymentMonth)
      }

      if (saleYear) {
        serchParms.append('saleYear', saleYear)
      }


      // PAGINATION
      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('rowsPerPage', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }


      const requestBody: RequestBody = {};

      if (username.length > 0) {
        requestBody.username = username.join(',');
      }
      if (serviceClient.length > 0) {
        requestBody.serviceClient = serviceClient.join(',');
      }

      const response = await fetchPOST(`Authorizator/ListCommissions?&authorizationState=pending&commissionState=inProcess&${serchParms.toString()}`, requestBody)
      return response
    } catch (error) {
      throw error
    }
  })
}


export const UseQueryRejectedAuthorizationSystemCommissions = (data: any) => {

  const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
  const type = data?.type && data?.type.length > 0 ? data?.type[0] : null
  const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
  const serviceId = data?.idService ? data?.idService : null
  const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
  const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
  const authorizationReason = data?.authorizationReason ? data?.authorizationReason : null
  const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null
  const authorizatorName = data?.authorizatorName ? data?.authorizatorName : null

  const username = Array.isArray(data?.username) ? data.username : [];
  const serviceClient = Array.isArray(data?.serviceClient) ? data.serviceClient : [];


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  return useQuery(['UseQueryRejectedAuthorizationSystemCommissions', paymentMonth, type, authorizationReason, saleMonth, authorizatorName, idServiceCRM, serviceClient, serviceName, username, serviceId, saleYear, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (type) {
        serchParms.append('type', type)
      }

      if (authorizationReason) {
        serchParms.append('authorizationReason', authorizationReason)
      }

      if (authorizatorName) {
        serchParms.append('authorizatorName', authorizatorName)
      }



      if (serviceName) {
        serchParms.append('serviceName', serviceName)
      }

      if (idServiceCRM) {
        serchParms.append('idServiceCRM', idServiceCRM)
      }

      if (saleMonth) {
        serchParms.append('saleMonth', saleMonth)
      }



      if (serviceId) {
        serchParms.append('serviceId', serviceId)
      }

      if (saleYear) {
        serchParms.append('saleYear', saleYear)
      }


      // PAGINATION
      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('rowsPerPage', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }

      const requestBody: RequestBody = {};

      if (username.length > 0) {
        requestBody.username = username.join(',');
      }
      if (serviceClient.length > 0) {
        requestBody.serviceClient = serviceClient.join(',');
      }

      const response = await fetchPOST(`Authorizator/ListCommissions?&authorizationState=rejected&${serchParms.toString()}`, requestBody)
      return response
    } catch (error) {
      throw error
    }
  })
}



const downloadFileAuthorizatorApprovedSys = async (data: any) => {

  const authorizatorName = data?.authorizatorName ? data?.authorizatorName : null
  const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
  const type = data?.type && data?.type.length > 0 ? data?.type[0] : null
  const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
  const serviceId = data?.idService ? data?.idService : null
  const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
  const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
  const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null

  const username = Array.isArray(data?.username) ? data.username : [];
  const serviceClient = Array.isArray(data?.serviceClient) ? data.serviceClient : [];


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  try {

    const serchParms = new URLSearchParams();



    if (authorizatorName) {
      serchParms.append('authorizatorName', authorizatorName)
    }


    if (serviceName) {
      serchParms.append('serviceName', serviceName)
    }

    if (idServiceCRM) {
      serchParms.append('idServiceCRM', idServiceCRM)
    }

    if (serviceId) {
      serchParms.append('IdService', serviceId)
    }

    if (type) {
      serchParms.append('type', type)
    }

    if (saleMonth) {
      serchParms.append('saleMonth', saleMonth)
    }

    if (paymentMonth) {
      serchParms.append('paymentMonth', paymentMonth)
    }

    if (saleYear) {
      serchParms.append('saleYear', saleYear)
    }


    //! PAGINATION
    if (pageNumber) {
      serchParms.append('pageNumber', pageNumber)
    }

    if (pageSize) {
      serchParms.append('rowsPerPage', pageSize)
    }

    if (sortOrder) {
      serchParms.append('sortOrder', sortOrder)
    }

    if (sortField) {
      serchParms.append('sortField', sortField)
    }

    const requestBody: RequestBody = {};

    if (username.length > 0) {
      requestBody.username = username.join(',');
    }
    if (serviceClient.length > 0) {
      requestBody.serviceClient = serviceClient.join(',');
    }


    const response = await fetchPOSTBlob(`Authorizator/DownloadCommissions?&commissionState=completed&authorizationState=approved&${serchParms.toString()}`, requestBody)
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'authorizatorApprovedSysDocument.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);


    return response


  } catch (error) {
    throw error
  }
}


export const UseDownloadFileAuthorizatorApprovedSys = (callbackSuccess: any) => {
  return useMutation(downloadFileAuthorizatorApprovedSys, {
    onSuccess(data) {
      callbackSuccess(data)
    },
  })
}


const downloadFileAuthorizatorPendingSys = async (data: any) => {

  const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
  const type = data?.type && data?.type.length > 0 ? data?.type[0] : null
  const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
  const serviceId = data?.idService ? data?.idService : null
  const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
  const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
  const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null

  const username = Array.isArray(data?.username) ? data.username : [];
  const serviceClient = Array.isArray(data?.serviceClient) ? data.serviceClient : [];


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  try {

    const serchParms = new URLSearchParams();

    if (serviceName) {
      serchParms.append('serviceName', serviceName)
    }

    if (idServiceCRM) {
      serchParms.append('idServiceCRM', idServiceCRM)
    }

    if (serviceId) {
      serchParms.append('IdService', serviceId)
    }

    if (type) {
      serchParms.append('type', type)
    }

    if (saleMonth) {
      serchParms.append('saleMonth', saleMonth)
    }

    if (paymentMonth) {
      serchParms.append('paymentMonth', paymentMonth)
    }

    if (saleYear) {
      serchParms.append('saleYear', saleYear)
    }


    //! PAGINATION
    if (pageNumber) {
      serchParms.append('pageNumber', pageNumber)
    }

    if (pageSize) {
      serchParms.append('rowsPerPage', pageSize)
    }

    if (sortOrder) {
      serchParms.append('sortOrder', sortOrder)
    }

    if (sortField) {
      serchParms.append('sortField', sortField)
    }

    const requestBody: RequestBody = {};

    if (username.length > 0) {
      requestBody.username = username.join(',');
    }
    if (serviceClient.length > 0) {
      requestBody.serviceClient = serviceClient.join(',');
    }


    const response = await fetchPOSTBlob(`Authorizator/DownloadCommissions?&authorizationState=pending&commissionState=inProcess&${serchParms.toString()}`, requestBody)
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'authorizatorPendingSysDocument.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);


    return response


  } catch (error) {
    throw error
  }
}


export const UseDownloadFileAuthorizatorPendingSys = (callbackSuccess: any) => {
  return useMutation(downloadFileAuthorizatorPendingSys, {
    onSuccess(data) {
      callbackSuccess(data)
    },
  })
}


const downloadFileAuthorizatorRejectedSys = async (data: any) => {

  const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
  const type = data?.type && data?.type.length > 0 ? data?.type[0] : null
  const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
  const serviceId = data?.idService ? data?.idService : null
  const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
  const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
  const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null
  const authorizatorName = data?.authorizatorName ? data?.authorizatorName : null

  const username = Array.isArray(data?.username) ? data.username : [];
  const serviceClient = Array.isArray(data?.serviceClient) ? data.serviceClient : [];


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  try {

    const serchParms = new URLSearchParams();


    if (authorizatorName) {
      serchParms.append('authorizatorName', authorizatorName)
    }



    if (serviceName) {
      serchParms.append('serviceName', serviceName)
    }

    if (idServiceCRM) {
      serchParms.append('idServiceCRM', idServiceCRM)
    }

    if (serviceId) {
      serchParms.append('IdService', serviceId)
    }

    if (type) {
      serchParms.append('type', type)
    }

    if (saleMonth) {
      serchParms.append('saleMonth', saleMonth)
    }

    if (paymentMonth) {
      serchParms.append('paymentMonth', paymentMonth)
    }

    if (saleYear) {
      serchParms.append('saleYear', saleYear)
    }


    //! PAGINATION
    if (pageNumber) {
      serchParms.append('pageNumber', pageNumber)
    }

    if (pageSize) {
      serchParms.append('rowsPerPage', pageSize)
    }

    if (sortOrder) {
      serchParms.append('sortOrder', sortOrder)
    }

    if (sortField) {
      serchParms.append('sortField', sortField)
    }


    const requestBody: RequestBody = {};

    if (username.length > 0) {
      requestBody.username = username.join(',');
    }
    if (serviceClient.length > 0) {
      requestBody.serviceClient = serviceClient.join(',');
    }


    const response = await fetchPOSTBlob(`Authorizator/DownloadCommissions?&authorizationState=rejected&${serchParms.toString()}`, requestBody)
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'authorizatorRejectedSysDocument.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);


    return response


  } catch (error) {
    throw error
  }
}


export const UseDownloadFileAuthorizatorRejectedSys = (callbackSuccess: any) => {
  return useMutation(downloadFileAuthorizatorRejectedSys, {
    onSuccess(data) {
      callbackSuccess(data)
    },
  })
}


export const UseQueryResumeAuthorizationSystemCommissions = (data: any) => {

  const authorizator = data?.authorizator ? data?.authorizator : null

  const username = Array.isArray(data?.username) ? data.username : [];





  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  return useQuery(['UseQueryResumeAuthorizationSystemCommissions', authorizator, username, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (authorizator) {
        serchParms.append('authorizator', authorizator)
      }


      // PAGINATION
      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('rowsPerPage', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }

      const requestBody: RequestBody = {};

      if (username.length > 0) {
        requestBody.username = username.join(',');
      }
      const response = await fetchPOST(`Authorizator/ListResumeAuthorization?&${serchParms.toString()}`, requestBody)
      return response
    } catch (error) {
      throw error
    }
  })
}


export const UseGetSysKams = (data: any) => {
  const username = data && data.username ? data.username : null


  return useQuery(['UseGetSysKams', username], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (username) {
        serchParms.append('username', username)
      }

      const response = await fetchGET(`User/ListUsers?&${serchParms.toString()}`)
      return response

    } catch (error) {
      throw error
    }

  })
}

export const UseGetSysClients = (data: any) => {

  const name = data && data.name ? data.name : null


  return useQuery(['UseGetSysClients', name], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (name) {
        serchParms.append('name', name)
      }

      const response = await fetchGET(`Client/ListClients?&${serchParms.toString()}`)
      return response

    } catch (error) {
      throw error
    }

  })
}
