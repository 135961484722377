import React, { useState, useEffect, useMemo, useContext } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { toast } from 'react-toastify'
import { MultipleDeleter, Table, UfinetBox, UfinetButton, UfinetModal, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection } from 'ufinet-web-components'
import { AuthContext, Authority, useAsync, useModal, } from 'ufinet-web-functions'
import { colslMultiplicatorGoalsCommission } from './MultiplicatorGoalsColData'
import { UseQueryMultplicatorGoalsCommissions, UseDownloadMultipliersCommission } from 'request'
import { Translation } from 'utils/translation/Translation'
import BoxTotal from 'lib/box/BoxTotal'
import { DataTableFilterMeta, DataTablePageEvent } from 'primereact/datatable'
import { MultiplicatorTypes } from 'types/multiplicatorKam'



const MultiplicatorGoalsPage = () => {
  const intl = useIntl()
  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  const permissions = Authority.getCommissionMultiplicatorPermissions(roles)

  const colsMultiplicatorGoals = useMemo(() => colslMultiplicatorGoalsCommission(intl), [intl])


  const [selectedValues, setSelectedValues] = useState()
  const [paging, setPaging] = useState<any>()
  const [filterData, setFilterData] = useState<any>()
  const { data: multiplicatoGoalsCommission, isLoading: isLoadingMultiplicatorGoals } = UseQueryMultplicatorGoalsCommissions(filterData)

  const cbSuccessDownloadFile = (data: any) => {
  }

  const { mutate: downloadFileMultitpler, isLoading: isLoadingDownloadFileMultitpler } = UseDownloadMultipliersCommission(cbSuccessDownloadFile)

  useEffect(() => {
    if (multiplicatoGoalsCommission) {
      const parsePagination = JSON.parse(multiplicatoGoalsCommission?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageNumber: parsePagination.currentPage,
        pageSize: filterData && filterData.pageSize ? filterData.pageSize : parsePagination.pageSize
      }
      setPaging(formattedPagination)
    }
  }, [multiplicatoGoalsCommission])

  const onFilterClear = (): void => {
    setFilterData({})
  }

  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)
    setFilterData(formattedFilters)
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({ ...filterData, pageNumber: e.page, pageSize: e.rows })
    // if (e.page === userRequestData.page && e.rows === userRequestData.size) return
    // setUserRequestData({ ...userRequestData, pageNumber: e.page, pageSize: e.rows })
  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })
    // const finalField = UserPropertyMap.get(e.sortField)
    // setUserRequestData({
    // 	...userRequestData,
    // 	sortFields: finalField && [finalField],
    // 	sortOrders: finalField && finalOrder && [finalOrder],
    // 	// Return to first page
    // 	pageNumber: 0,
    // })
  }

  const downloadFile = () => {
    downloadFileMultitpler(filterData)
  }


  const getHeadersButtons = () => (
    <>

      {permissions.canWrite && (
        <UfinetButton
          className="me-3"
          icon={faDownload}
          onClick={() => downloadFile()}
          content={Translation('DOWNLOAD.FILE')}
        />
      )}
    </>
  )

  return (
    <>
      <UfinetBox>

        <UfinetSectionBox>

          <Table
            // selectedValues={selectedValues}
            // onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            content={multiplicatoGoalsCommission?.data}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            cols={colsMultiplicatorGoals}
            onFilterClear={onFilterClear}
            emptyMessage={isLoadingMultiplicatorGoals || isLoadingDownloadFileMultitpler ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingMultiplicatorGoals || isLoadingDownloadFileMultitpler,
                onFilter,
                onPage,
                onSort,
              }
            }
            headerButtons={getHeadersButtons()}
          />
          {/* <UfinetModal
          size="lg"
          show={newModal}
          handleClose={hideNewModal}
          title={intl.formatMessage({ id: 'MODAL.NEW.REGISTER.TITLE' })}
        >
          <NewCompanyModalBody search={fetchAndStore} hideModal={hideNewModal} />
        </UfinetModal> */}
        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}

export { MultiplicatorGoalsPage }

const ContainerCommissionAmount = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;    
    
    h2 {
      margin: 0;
    }
`


const BoxShadow = styled.div`
box-shadow: 0px 0px 2px 1px rgba(189,173,173,0.75);
-webkit-box-shadow: 0px 0px 2px 1px rgba(189,173,173,0.75);
-moz-box-shadow: 0px 0px 2px 1px rgba(189,173,173,0.75);
padding: 5px;
`

const TotalAmount = styled.div`
  display: flex;
  justify-content: flex-end;
`
const CurrencyCode = styled.div`
  display: flex;
  justify-content: flex-end;
`
