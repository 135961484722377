import React from 'react'
import styled from 'styled-components'
import { Translation } from 'utils/translation/Translation'
import { convertNumber } from 'utils/convertNumber/ConvertNumber'


interface Props {
  currencyCode?: string
  totalCommissionAmount: number
  title: string


}

const BoxTotal = ({ currencyCode = '$', totalCommissionAmount, title }: Props) => {


  return (
    <ContainerCommissionAmount>
      <ContainerTotalAmount>
        <TitleContainerAmoount >{Translation(title)}</TitleContainerAmoount>
        <div>
          <TotalContainerAmount>
            <TotalAmount >{` ${convertNumber(totalCommissionAmount)} ${currencyCode === null ? '' : currencyCode}`}</TotalAmount>
          </TotalContainerAmount>
          {/* <CurrencyCode>
            <h2 className="fs-1 fw-bolder text-dark">{data?.currencyCode}</h2>
          </CurrencyCode> */}
        </div>
      </ContainerTotalAmount>
    </ContainerCommissionAmount>
  )
}

export default BoxTotal

const ContainerCommissionAmount = styled.div`
    /* display: flex;
    flex-direction: column;
    align-items: flex-end;     */
    
    h2 {
      margin: 0;
    }
`

const ContainerTotalAmount = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 368px;
  height: 182px;
  padding: 16px;
  box-shadow: 2px 2px 8px 3px rgba(0,0,0,0.04);
-webkit-box-shadow: 2px 2px 8px 3px rgba(0,0,0,0.04);
-moz-box-shadow: 2px 2px 8px 3px rgba(0,0,0,0.04);
  border-radius: 10px;  
`

const TitleContainerAmoount = styled.h2`
font-size: 26px !important;
    color: #2e3540 !important;
`
const TotalContainerAmount = styled.div`
  display: flex;
  justify-content: flex-end;
`

const TotalAmount = styled.h2`
    font-size: 34px !important;
    color:#576780 !important;
`

const CurrencyCode = styled.div`
  display: flex;
  justify-content: flex-end;
`
