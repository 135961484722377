import { ColData } from 'ufinet-web-components'
import { IntlShape } from 'react-intl'
import { commissionTypeMappings, CommissionType, CommissionMonth, commissionMonthMappings, CommissionCalculatingStatus, commissionCalculatingStatusMappings, AuthorizationStatus, authorizationCommissionStatusMappings } from 'types/typesAndValues';
import { Translation } from 'utils/translation/Translation';
import { LiquidateTypesRRHH } from 'types/liquidateRRHH';
import { ShowValue } from 'lib/showValue/ShowValue';

export const colsLiquidatePending = (intl: IntlShape): ColData[] => [
  // header is the id to search the translate based on the data received
  // removing or typing the word false in the property nonFilterable is gonna show in the table header the filter
  // body you can customize the data you want to show, accept react component or just a simple html
  {
    field: 'paymentId',
    header: 'COL.COMMISSION.ID',
    width: '240px',
    editable: false,
    nonFilterable: false,
    nonSortable: false,
    filterType: 'number'
  },
  {
    field: 'username',
    header: 'COL.COMMISSION.KAM',
    width: '190px',
    editable: false,
    nonFilterable: false,
    nonSortable: false,
    filterType: 'text'
  },
  {
    field: 'name',
    header: 'COL.COMMISSION.TITLE',
    width: '190px',
    editable: false,
    nonFilterable: true,
    nonSortable: true,
  },
  {
    field: 'paymentAmount', header: 'COL.COMMISSION.PAYMENTAMOUNT', width: '190px', nonFilterable: true, nonSortable: false,
    body: (row: LiquidateTypesRRHH) => <ShowValue value={row.paymentAmount} currencyCode={row.currencyCode} />
  },
  {
    field: 'boostAmount', header: 'COL.COMMISSION.IMPULSE', width: '190px', nonFilterable: true, nonSortable: false,
    body: (row: LiquidateTypesRRHH) => <ShowValue value={row.boostAmount} currencyCode={row.currencyCode} />
  },
  { field: 'creationDate', header: 'COL.COMMISSION.CREATIONDATE', width: '190px', nonFilterable: true, nonSortable: false, filterType: 'text', },
  { field: 'cutoffDate', header: 'COL.COMMISSION.CUTDATE', width: '190px', nonFilterable: true, nonSortable: false, filterType: 'text', },
  {
    field: 'paymentState',
    header: 'COL.COMMISSION.STATUS',
    width: '190px',
    nonFilterable: false,
    editable: true,
    nonSortable: false,
    filterType: "select",
    filterOptions: [
      {
        label: intl.formatMessage({ id: authorizationCommissionStatusMappings.get(AuthorizationStatus.PENDING) }),
        value: AuthorizationStatus.PENDING,
      },
      {
        label: intl.formatMessage({ id: authorizationCommissionStatusMappings.get(AuthorizationStatus.APPROVED) }),
        value: AuthorizationStatus.APPROVED,
      },
      {
        label: intl.formatMessage({ id: authorizationCommissionStatusMappings.get(AuthorizationStatus.REJECTED) }),
        value: AuthorizationStatus.REJECTED,
      },
    ],
    body: (row: LiquidateTypesRRHH) => <>{Translation(row.paymentState)}</>
  },
]
