import React, { useRef, useCallback, useMemo } from 'react'
import { useFormik } from 'formik'
import { UfinetActionButton, UfinetActionButtonHandle, UfinetInput } from 'ufinet-web-components';
import { IFileUploadData, onFormikNumberChanges } from 'ufinet-web-functions';
import { UseUpdateConfigLiquidate } from 'request';
import * as Yup from 'yup'
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { ErrorResponse, } from 'types/typesAndValues';
import { useTranslation } from 'utils/translation/Translation';
import { ConfigLiquidateTypes } from 'types/configuration';

interface Props {
  updatedData: ConfigLiquidateTypes | undefined;
  hideModal: () => void;
  refetch: () => void
}

interface File {
  file: IFileUploadData
}



type UpdateFormData = {
  id: number;
  executionDay: number;

}

const UpdateConfigLiquidateForm = ({ updatedData, hideModal, refetch }: Props) => {





  const translate = useTranslation()
  const intl = useIntl()





  const actionButtonRef = useRef<UfinetActionButtonHandle>(null)




  const cbSuccessUpdateConfigLiquidate = ({ data }: any) => {
    toast.success(data.message)
    actionButtonRef.current?.changeActionStatus(false)
    refetch()
    hideModal()
  }

  const { mutate: updateConfigLiquidate, isError, error: errorMessage } = UseUpdateConfigLiquidate(cbSuccessUpdateConfigLiquidate)


  const showErrMessage = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse;
      toast.error(`${intl.formatMessage({ id: "ERROR.MESSAGE" },)} ${errorResponse.message}`, { toastId: 'Error2' });
      hideModal()
    }
  }

  const dataFormSchema = Yup.object().shape({
    id: Yup.number(),
    executionDay: Yup.number(),
  })






  const sendData = (values: any) => {
    actionButtonRef.current?.changeActionStatus(true)
    updateConfigLiquidate({
      id: +values.id,
      executionDay: +values.executionDay,
    })
  }


  const onNumberChange = useCallback(onFormikNumberChanges, [])


  const initialValues: UpdateFormData = useMemo<UpdateFormData>(
    () => ({
      id: updatedData && updatedData.id ? updatedData.id : 0,
      executionDay: updatedData && updatedData.executionDay ? updatedData.executionDay : 0,
    }),
    []
  )



  const formik = useFormik<UpdateFormData>({
    initialValues,
    validationSchema: dataFormSchema,
    onSubmit: sendData,
    validateOnChange: false,
    validateOnBlur: false,
  })


  return (
    <>
      {isError && showErrMessage()}
      <form onSubmit={formik.handleSubmit}>
        <div className='row'>
          <div className='row '>
            <UfinetInput
              isDisabled
              error={formik.errors.id}
              type="decimal"
              labelTitle={translate('COL.CONFIG.ID')}
              className="col-6"
              onChange={onNumberChange(formik, 'id')}
              value={formik.values.id}
              solid={false}
            />
            <UfinetInput
              error={formik.errors.executionDay}
              type="decimal"
              labelTitle={translate('COL.EXECUTIONDAY')}
              className="col-6"
              onChange={onNumberChange(formik, 'executionDay')}
              value={formik.values.executionDay}
              solid={false}
            />



            <UfinetActionButton
              ref={actionButtonRef}
              className='mt-5'
              content={translate('UPDATE.MULTIPLIER.CONFIG')}
            />
          </div>
        </div>
      </form>
    </>
  )
}

export { UpdateConfigLiquidateForm }


