import { App } from 'App'

import { FC, useCallback, useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AuthPage, VerificationPage } from 'ufinet-web-components'
import { AuthContext, authService, AuthStatus } from 'ufinet-web-functions'
import { PrivateRoutes } from './protected/PrivateRoutes'

export const PATH_AUTH = 'auth'

const AppRoutes: FC = () => {
  const intl = useIntl()
  const { status, setStatus, token, setToken, logout, clear } = useContext(AuthContext)
  const prevToken = token

  const [isSSOLoggingIn, setIsSSOLoggingIn] = useState<boolean>()

  useEffect(() => {
    authService.setUpBroadcastChannel({ onLogoutReceived: clear })

    setStatus(AuthStatus.VERIFYING)
    beginAuthentication()
  }, [])

  useEffect(() => {
    if (status === AuthStatus.LOGGED_OUT) return

    isSSOLoggingIn === false && checkAuthentication()
  }, [isSSOLoggingIn, token])

  const beginAuthentication = () => {
    setIsSSOLoggingIn(true)
    authService
      .initSSOLogin(setToken)
      .then(() => console.log('SSO login attempt complete'))
      .catch(() => {
        console.warn('SSO login attempt failed')
        status !== AuthStatus.LOGGED_IN && toast.error(intl.formatMessage({ id: 'AUTH.LOGIN.DOWN' }))
      })
      .finally(() => setIsSSOLoggingIn(false))
  }

  const checkAuthentication = useCallback(() => {
    if (!token && !prevToken) {
      logout()
      return
    }

    authService
      .isAuthenticated(setToken, token)
      .then((userData) => {
        setStatus(AuthStatus.LOGGED_IN, userData)
      })
      .catch(() => {
        toast.dismiss()
        toast.error(intl.formatMessage({ id: 'AUTH.SESSION.EXPIRED' }))
        logout()
      })
  }, [setToken, token])

  // Periodically refresh token if user stays in page
  const refreshToken = useCallback(() => {
    if (status === AuthStatus.LOGGED_IN) authService.refreshToken(setToken)
  }, [status, setToken])

  useEffect(() => {
    const refreshInterval = setInterval(refreshToken, 15 * 60 * 1000)

    return () => {
      clearInterval(refreshInterval)
    }
  }, [refreshToken])

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          {status === AuthStatus.LOGGED_IN ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
            </>
          ) : status === AuthStatus.VERIFYING ? (
            <>
              <Route path="/*" element={<VerificationPage />} />
            </>
          ) : (
            <>
              <Route path={`${PATH_AUTH}/*`} element={<AuthPage />} />
              <Route path="*" element={<Navigate to={PATH_AUTH} />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
