import React, { useState, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { Table, UfinetBox, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection } from 'ufinet-web-components'
import { colsResumeAuthorization } from './ResumeColData'
import { UseQueryResumeAuthorizationCommissions } from 'request'
import { DataTablePageEvent } from 'primereact/datatable'
import { BoxTotalAuthorization } from 'lib/box/BoxTotalAuthorization'
import { AuthorizationUserTypes } from 'types/authorization'
import { useFiltersAuthorization } from 'hooks/useFiltersAuthorization'
import { MultiSelectFiltersHeaders } from '../filterHeaders/MultiSelectFiltersHeaders'

const ResumeAuthorizationPage = () => {
  const intl = useIntl()

  const colsResumePage = useMemo(() => colsResumeAuthorization(intl), [intl])




  // const [selectedValues, setSelectedValues] = useState<AuthorizationPendingTypes[]>([])
  const [paging, setPaging] = useState<any>()
  const [filterData, setFilterData] = useState<any>()

  const {
    kamsSelect,
    isLoadingDataAuthorizator,
    clientsSelect,
    isLoadingDataClients,
    handleMultiSelect,
    multiSelectKam,
    multiSelectClient,
    cleanSelectKamAndClient,
    username,
    serviceClient,
    selectAllClient,
    selectAllKam,
    onSelectAll,
  } = useFiltersAuthorization({ filterData, setFilterData, })




  const { data: resumeAuthorization, isLoading: isLoadingResumeAuthorization } = UseQueryResumeAuthorizationCommissions(filterData)



  // To get de currencySymbol to show it at the top of the table
  const showBoxTotal = resumeAuthorization?.data ? resumeAuthorization?.data : null


  useEffect(() => {
    if (resumeAuthorization) {
      const parsePagination = JSON.parse(resumeAuthorization?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [resumeAuthorization])

  const onFilterClear = (): void => {
    cleanSelectKamAndClient()
    setFilterData({})
  }

  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)

    setFilterData({
      ...filterData,
      ...formattedFilters,
      username,
      serviceClient,
    })
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({ ...filterData, pageNumber: e.page, pageSize: e.rows })
  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      username,
      serviceClient,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })
  }


  const filterButtons = () => {
    return (
      <>
        <MultiSelectFiltersHeaders
          clientFilter={false}

          onSelectAll={onSelectAll}
          selectAllClient={selectAllClient}
          selectAllKam={selectAllKam}
          multiSelectClient={multiSelectClient}
          multiSelectKam={multiSelectKam}
          clientsSelect={clientsSelect}
          kamsSelect={kamsSelect}
          onChangeHandle={handleMultiSelect}
          isLoadingDataAuthorizator={isLoadingDataAuthorizator}
          isLoadingDataClients={isLoadingDataClients}
        />
      </>
    )

  }






  return (
    <>
      <UfinetBox>
        <UfinetSectionBox>
          {showBoxTotal && (
            <ContainerCommissionAmount>
              <BoxTotalAuthorization
                title="TOTAL.AMOUNT.TOAUTHORIZE"
                totalCommissionAmount={resumeAuthorization?.data?.totalCommissions}
              />
              <BoxTotalAuthorization
                title="TOTAL.DECOMMISIONS.TOAUTHORIZE"
                totalCommissionAmount={resumeAuthorization?.data?.totalDecommissions}
              />
              <BoxTotalAuthorization
                title="TOTALAMOUNT.AUTHORIZE"
                totalCommissionAmount={resumeAuthorization?.data?.totalAmount}
              />
            </ContainerCommissionAmount>

          )}
        </UfinetSectionBox>
        <UfinetSectionBox className='mt-5 mh-100'>
          <Table
            filterButtons={filterButtons()}
            enableSearchFilter={false}
            // dataKey="id"
            // selectedValues={selectedValues}
            // onSelectionChange={permissions.canUpdate ? onSelectionChange : undefined}
            // headerButtons={getHeadersButtons()}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            cols={colsResumePage}
            content={resumeAuthorization?.data?.users?.map((item: AuthorizationUserTypes) => ({
              ...item,
            }))}
            actionBodyTemplate={<></>}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            emptyMessage={isLoadingResumeAuthorization ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            onFilterClear={onFilterClear}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingResumeAuthorization,
                onFilter,
                onPage,
                onSort,
              }
            }
          // headerButtons={getHeadersButtons()}
          // emptyMessage={loadingRecords ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
          />
        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}

export { ResumeAuthorizationPage }

const ContainerCommissionAmount = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;  
    justify-content: space-between;
    
    h2 {
      margin: 0;
    }
`

const TotalAmount = styled.div`
  display: flex;
  justify-content: flex-end;
`

const CurrencyCode = styled.div`
  display: flex;
  justify-content: flex-end;
`
