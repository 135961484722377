import React, { useState, useEffect, useContext, useMemo, } from 'react'
import { useIntl } from 'react-intl'
import { Table, UfinetBox, UfinetButton, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection } from 'ufinet-web-components'
import { AuthContext, Authority, } from 'ufinet-web-functions'
import { colsRejectedCommisions } from './RejectedColData'
import { UseQueryRejectedCommissions, UseDownloadFileCommissionRejected } from 'request'
import { Translation } from 'utils/translation/Translation'
import { DataTablePageEvent } from 'primereact/datatable'
import BoxTotal from 'lib/box/BoxTotal'
import { CommissionKamElement } from 'types/commissionKamTypes'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

const RejectePage = () => {
  const intl = useIntl()
  const colsRejectPage = useMemo(() => colsRejectedCommisions(intl), [intl])
  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  const permissions = Authority.getCommissionKamPermissions(roles)



  const [paging, setPaging] = useState<any>()
  const [filterData, setFilterData] = useState<any>()
  const { data: rejectedCommissions, isLoading: isLoadingRejectedCommission } = UseQueryRejectedCommissions(filterData)

  const cbSuccessRejectedFile = (data: any) => {

  }

  const { mutate: downloadFile, isLoading: isLoadingCommissionRejected } = UseDownloadFileCommissionRejected(cbSuccessRejectedFile)


  // To get de currencySymbol to show it at the top of the table
  const currencySymbol = rejectedCommissions?.data && rejectedCommissions?.data?.commissions.length > 0 ? rejectedCommissions?.data?.commissions[0]?.user.currencyCode : null


  useEffect(() => {
    if (rejectedCommissions) {
      const parsePagination = JSON.parse(rejectedCommissions?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [rejectedCommissions])

  const onFilterClear = (): void => {
    setFilterData({})
  }

  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)
    setFilterData({
      ...filterData,
      ...formattedFilters
    })
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({ ...filterData, pageNumber: e.page, pageSize: e.rows })

  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })

  }

  const downloadFileRejectedCommission = () => {
    downloadFile(filterData)
  }


  const getHeadersButtons = () => (
    <>
      {permissions.canWrite && (
        <UfinetButton
          className="me-3"
          icon={faDownload}
          onClick={() => downloadFileRejectedCommission()}
          content={Translation('DOWNLOAD.FILE')}
        />
      )}
    </>
  )

  return (
    <>
      <UfinetBox>

        <UfinetSectionBox>
          {rejectedCommissions?.data && rejectedCommissions?.data?.commissions.length > 0 && (
            <BoxTotal
              title="TOTAL.AMOUNT.REJECTED"
              totalCommissionAmount={rejectedCommissions?.data?.totalCommissionAmount}
              currencyCode={currencySymbol}
            />
          )}
        </UfinetSectionBox>

        <UfinetSectionBox className='mt-5'>
          <Table
            // selectedValues={selectedValues}
            // onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            cols={colsRejectPage}
            content={rejectedCommissions?.data?.commissions?.map((item: CommissionKamElement) => ({
              ...item,
              idService: item.service.idService,
              saleMonth: item.service.saleMonth,
              commissionType: item.commission.commissionType,
              exchangeRate: item.commission.exchangeRate,
              type: item.commission.type,
              backlog: item.service.backlog,
              percentageCommission: item.commission.percentageCommission,
              baseAmount: item.commission.baseAmount,
              authorizatorName: item.authorization.authorizatorName,
              totalAmount: item.commission.totalAmount,
              authorizatorLevel: item.authorization.authorizatorLevel,
              rejectedDate: item.authorization.rejectedDate,
              achievementFactor: item.commission.achievementFactor,
              saleYear: item.service.saleYear,
              commissionTypePercentage: item.commission.commissionTypePercentage,
              idServiceCRM: item.service.idServiceCRM,
              serviceName: item.service.serviceName,
              serviceClient: item.service.serviceClient,
              authorizationReason: item.authorization.authorizationReason,
              firstBillingDate: item.service.firstBillingDate,
            }))}
            actionBodyTemplate={<></>}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            emptyMessage={isLoadingRejectedCommission || isLoadingCommissionRejected ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            onFilterClear={onFilterClear}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingRejectedCommission || isLoadingCommissionRejected,
                onFilter,
                onPage,
                onSort,
              }
            }
            headerButtons={getHeadersButtons()}
          // emptyMessage={loadingRecords ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
          />
        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}

export default RejectePage
