import React, { useState, useEffect, useContext, useMemo, } from 'react'
import { useIntl } from 'react-intl'
import { Table, UfinetBox, UfinetButton, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection } from 'ufinet-web-components'
import { AuthContext, Authority, } from 'ufinet-web-functions'
import { colsPaidCommisionSys } from './PaidColData'
import { UseQueryPaidCommissionsSystems, UseDownloadFileCommissionPaidSys } from 'request'
import { DataTablePageEvent } from 'primereact/datatable'
import { Translation } from 'utils/translation/Translation'
import { CommissionSystemElement } from 'types/commissionSystemTypes'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

const PaidCommissionSystemPage = () => {
  const intl = useIntl()
  const colsPaidCommission = useMemo(() => colsPaidCommisionSys(intl), [intl])

  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  const permissions = Authority.getCommissionCmSystemPermissions(roles)


  const [paging, setPaging] = useState<any>()
  const [filterData, setFilterData] = useState<any>()
  const { data: paidCommssionSys, isLoading: isLoadingPaidCommisionSys } = UseQueryPaidCommissionsSystems(filterData)


  const cbSuccessApprovedPaidFile = (data: any) => {

  }

  const { mutate: downloadFile, isLoading: isLoadingCommissionPaidSys } = UseDownloadFileCommissionPaidSys(cbSuccessApprovedPaidFile)


  useEffect(() => {
    if (paidCommssionSys) {
      const parsePagination = JSON.parse(paidCommssionSys?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [paidCommssionSys])

  const onFilterClear = (): void => {
    setFilterData({})
  }

  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)
    setFilterData({
      ...filterData,
      ...formattedFilters
    })
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({ ...filterData, pageNumber: e.page, pageSize: e.rows })

  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })

  }


  const downloadFilePaidSys = () => {
    downloadFile(filterData)
  }


  const getHeadersButtons = () => (
    <>
      {permissions.canWrite && (
        <UfinetButton
          className="me-3"
          icon={faDownload}
          onClick={() => downloadFilePaidSys()}
          content={Translation('DOWNLOAD.FILE')}
        />
      )}
    </>
  )

  return (
    <>
      <UfinetBox>

        <UfinetSectionBox>
          <Table
            // selectedValues={selectedValues}
            // onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            cols={colsPaidCommission}
            content={paidCommssionSys?.data?.commissions?.map((item: CommissionSystemElement) => ({
              ...item,
              name: `${item.commission.paymentMonth && item.commission.paymentYear ? `${Translation('LIQUIDATE.COL')} ${Translation(item.commission.paymentMonth)} -` : ''} ${item.commission.cutoffMonth && item.commission.cutoffYear ? `${Translation('CLOSEDATE')} ${Translation(item.commission.cutoffMonth)} ${item.commission.cutoffYear}` : ''} `,
              username: item.user.username,
              idService: item.service.idService,
              saleMonth: item.service.saleMonth,
              commissionType: item.commission.commissionType,
              exchangeRate: item.commission.exchangeRate,
              saleYear: item.service.saleYear,
              backlog: item.service.backlog,
              percentageCommission: item.commission.percentageCommission,
              baseAmount: item.commission.baseAmount,
              achievementFactor: item.commission.achievementFactor,
              totalAmount: item.commission.totalAmount,
              authorizationDate: item.authorization.authorizationDate,
              authorizatorName: item.authorization.authorizatorName,
              authorizatorLevel: item.authorization.authorizatorLevel,
              paymentState: item.commission.paymentState,
              paymentMonth: item.commission.paymentMonth,
              paymentYear: item.commission.paymentYear,
              commissionTypePercentage: item.commission.commissionTypePercentage,
              paymentId: item.commission.paymentId,
              idServiceCRM: item.service.idServiceCRM,
              serviceName: item.service.serviceName,
              serviceClient: item.service.serviceClient,
              firstBillingDate: item.service.firstBillingDate,
            }))}
            actionBodyTemplate={<></>}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            emptyMessage={isLoadingPaidCommisionSys || isLoadingCommissionPaidSys ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            onFilterClear={onFilterClear}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingPaidCommisionSys || isLoadingCommissionPaidSys,
                onFilter,
                onPage,
                onSort,
              }
            }
            headerButtons={getHeadersButtons()}
          // emptyMessage={loadingRecords ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
          />
        </UfinetSectionBox>
      </UfinetBox >
    </>
  )
}

export { PaidCommissionSystemPage }


