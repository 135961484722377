import React, { useState, useEffect, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Table, UfinetBox, UfinetButton, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection } from 'ufinet-web-components';
import { AuthContext, Authority } from 'ufinet-web-functions'
import { colsApproveAuthorizationSys } from './AuthorizationApproveColData'
import { UseDownloadFileAuthorizatorApproved, UseQueryApproveAuthorizationManagerCommissions } from 'request'
import { Translation } from 'utils/translation/Translation'
import { DataTablePageEvent } from 'primereact/datatable'
import { CommissionElement } from 'types/authorization'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { useFiltersAuthorization } from 'hooks/useFiltersAuthorization'
import { MultiSelectFiltersHeaders } from '../filterHeaders/MultiSelectFiltersHeaders'

const AuthorizationManagerApprovedPage = () => {
  const intl = useIntl()

  const colsApprovedAuthorization = useMemo(() => colsApproveAuthorizationSys(intl), [intl])

  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  const permissions = Authority.getCommissionAuthorizationPermissions(roles)





  const [paging, setPaging] = useState<any>()
  const [filterData, setFilterData] = useState<any>()


  const {
    kamsSelect,
    isLoadingDataAuthorizator,
    clientsSelect,
    isLoadingDataClients,
    handleMultiSelect,
    multiSelectKam,
    multiSelectClient,
    cleanSelectKamAndClient,
    username,
    serviceClient,
    selectAllClient,
    selectAllKam,
    onSelectAll,
  } = useFiltersAuthorization({ filterData, setFilterData, })

  const { data: approvedAuthorization, isLoading: isLoadingPendingAuthorization, isFetching: isFetchingPendingAuthorization } = UseQueryApproveAuthorizationManagerCommissions(filterData)


  const cbSuccessAuthorizationApproved = ({ data }: any) => {

  }



  const { mutate: downloadFile, isLoading: isLoadingDownloadingFile } = UseDownloadFileAuthorizatorApproved(cbSuccessAuthorizationApproved)




  useEffect(() => {
    if (approvedAuthorization) {
      const parsePagination = JSON.parse(approvedAuthorization?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [approvedAuthorization])

  const onFilterClear = (): void => {
    cleanSelectKamAndClient()
    setFilterData({})
  }

  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)

    setFilterData({
      ...filterData,
      ...formattedFilters,
      username,
      serviceClient,
    })
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({
      ...filterData,
      username,
      serviceClient,
      pageNumber: e.page,
      pageSize: e.rows
    })
  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      username,
      serviceClient,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })
  }

  const downloadFileApprovedSys = () => {
    downloadFile(filterData)
  }


  const getHeadersButtons = () => (
    <>
      {permissions.canWrite && (
        <UfinetButton
          className="me-3"
          isDisabled={isLoadingDownloadingFile}
          icon={faDownload}
          onClick={() => downloadFileApprovedSys()}
          content={Translation('DOWNLOAD.FILE')}
        />
      )}
    </>
  )

  const filterButtons = () => {
    return (
      <>
        <MultiSelectFiltersHeaders
          // kamFilter={false}

          onSelectAll={onSelectAll}
          selectAllClient={selectAllClient}
          selectAllKam={selectAllKam}
          multiSelectClient={multiSelectClient}
          multiSelectKam={multiSelectKam}
          clientsSelect={clientsSelect}
          kamsSelect={kamsSelect}
          onChangeHandle={handleMultiSelect}
          isLoadingDataAuthorizator={isLoadingDataAuthorizator}
          isLoadingDataClients={isLoadingDataClients}
        />
      </>
    )

  }




  return (
    <>
      <UfinetBox>

        <UfinetSectionBox>
          <Table
            filterButtons={filterButtons()}
            headerButtons={getHeadersButtons()}
            dataKey="id"
            cols={colsApprovedAuthorization}
            content={approvedAuthorization?.data?.commissions?.map((item: CommissionElement) => ({
              ...item,
              username: item.user.username,
              idService: item.service.idService,
              saleMonth: item.service.saleMonth,
              commissionType: item.commission.commissionType,
              exchangeRate: item.commission.exchangeRate,
              saleYear: item.service.saleYear,
              authorizationState: item.authorization.authorizationState,
              id: item.commission.id,
              achievementFactor: item.commission.achievementFactor,
              baseAmount: item.commission.baseAmount,
              percentageCommission: item.commission.percentageCommission,
              backlog: item.service.backlog,
              totalAmount: item.commission.totalAmount,
              commissionTypePercentage: item.commission.commissionTypePercentage,
              authorizationDate: item.authorization.authorizationDate,
              authorizatorName: item.authorization.authorizatorName,
              authorizatorLevel: item.authorization.authorizatorLevel,
              idServiceCRM: item.service.idServiceCRM,
              serviceName: item.service.serviceName,
              serviceClient: item.service.serviceClient,
              paymentId: item.commission.paymentId,
            }))}
            enableSearchFilter={false}
            actionBodyTemplate={<></>}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            emptyMessage={isLoadingPendingAuthorization || isFetchingPendingAuthorization || isLoadingDownloadingFile ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            onFilterClear={onFilterClear}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingPendingAuthorization || isFetchingPendingAuthorization || isLoadingDownloadingFile,
                onFilter,
                onPage,
                onSort,
              }
            }
          />
        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}

export { AuthorizationManagerApprovedPage }


