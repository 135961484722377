import React, { useCallback, } from 'react'
import { useFormik } from 'formik'
import { UfinetButton, UfinetDropzone, } from 'ufinet-web-components';
import { onFormikChanges, IFileUploadData, } from 'ufinet-web-functions';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { Translation } from 'utils/translation/Translation';
import { UsePostPercentangeTypeCommissionFile } from 'request';
import * as Yup from 'yup'
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { ErrorResponse } from 'types/typesAndValues';

interface Props {
  downLoadTemplate: () => void;
  hideLoadModal: () => void;
  refetch: () => void
}

interface File {
  file: IFileUploadData
}


const PercentangeTypeCommissionBulkLoadForm = ({ downLoadTemplate, hideLoadModal, refetch }: Props) => {

  const intl = useIntl()




  const onChange = useCallback(onFormikChanges, [])

  const cbSuccessUploadBulkFile = (data: any) => {
    refetch()
    toast.success(intl.formatMessage({ id: 'SENDED' }))
    hideLoadModal()
  }

  const { mutate: uploadBulkFile, isError, error: errorMessage } = UsePostPercentangeTypeCommissionFile(cbSuccessUploadBulkFile)


  const showErrMessage = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse;
      toast.error(`${intl.formatMessage({ id: "ERROR.MESSAGE" },)} ${errorResponse.errorCode}`, { toastId: 'Error2' });
      hideLoadModal()
    }
  }

  const dataFormSchema = Yup.object().shape({
    file: Yup.mixed()
      //@ts-ignore
      .test('file required', Translation('ERROR.REQUIRED'), (value) => value.size > 0)
      .test('file type', Translation('ERROR.FILE.EXTENSION'), (value) => {
        //@ts-ignore
        const fileExtension = value?.path?.split('.').pop()
        return fileExtension === undefined || fileExtension.includes(['xlsx']) || fileExtension.includes(['xls'])
      }),
  })



  const formik = useFormik<File>({
    initialValues: {
      file: {
        path: '',
        size: 0,
      },
    },
    validationSchema: dataFormSchema,
    onSubmit: (values: File) => {

      uploadBulkFile(values.file)
      // afterSubmit && afterSubmit(values)
    },
    validateOnChange: false,
    validateOnBlur: false,
  })


  return (
    <>
      {isError && showErrMessage()}
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div className="row py-10">
            <div className="col-12">
              <UfinetButton
                onClick={downLoadTemplate}
                className="w-100"
                content={Translation('DOWNLOAD.TEMPLATE')}
                icon={faDownload}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <UfinetDropzone
                isDisabled={false}
                onChange={onChange(formik, 'file')}
                value={formik.values.file}
                error={formik.errors.file}
                text={Translation('DROPZONE.UPLOAD_EXCEL.TITLE')}
                accept={{ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xls'] }}
              />
            </div>
          </div>

          <div className="row py-10">
            <div className="col-12">
              <UfinetButton
                type='submit'
                className="w-100"
                content={Translation('SAVE')}
                icon={faSave}
              />
            </div>
          </div>


        </div>
      </form>
    </>
  )
}

export { PercentangeTypeCommissionBulkLoadForm }


