import { ColData } from 'ufinet-web-components'
import { PercentangeCommission } from 'lib/percentangeCommission/PercentangeCommission';
import { TermName, ComercialTeamName, ServiceTypeName, commissionServiceTypeNameServiceMappings, ClientTypeName, commissionClientTypeNameMappings, EconomicModelName, commissionEconomicModelNameMappings, CommissionType, commissionTypeMappings, IsActiveValues, isActiveMappings } from 'types/typesAndValues';
import { IntlShape } from 'react-intl';
import { Translation } from 'utils/translation/Translation';
import { IsActive } from 'lib/isActive/IsActive';
import { UsersRRHHTypes } from 'types/configuration';

export const colsUsersRRHHColData = (intl: IntlShape): ColData[] => [
  // header is the id to search the translate based on the data received
  // removing or typing the word false in the property nonFilterable is gonna show in the table header the filter
  // body you can customize the data you want to show, accept react component or just a simple html
  {
    field: 'company', header: 'COL.COMMISSION.ENTERPRISE', width: '20%', nonFilterable: false, editable: true, nonSortable: false, filterType: 'text',

  },
  {
    field: 'name', header: 'COL.CONFIG.NAME', width: '20%', nonFilterable: false, editable: true, nonSortable: false, filterType: 'text',

  },
  {
    field: 'userEmail', header: 'COL.CONFIG.EMAIL', width: '20%', nonFilterable: false, editable: true, nonSortable: false, filterType: 'text',

  },
  {
    field: 'active', header: 'COL.CONFIG.ISACTIVE', width: '20%', nonFilterable: false, editable: true, nonSortable: false, filterType: 'select',

    filterOptions: [
      {
        label: intl.formatMessage({ id: isActiveMappings.get(IsActiveValues.FALSE) }),
        value: IsActiveValues.FALSE,
      },
      {
        label: intl.formatMessage({ id: isActiveMappings.get(IsActiveValues.TRUE) }),
        value: IsActiveValues.TRUE,
      }
    ],
    body: (row: UsersRRHHTypes) => <IsActive isActive={row.active} />

  },
]


