import { useContext, useMemo, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { Table, UfinetBox, UfinetButton, UfinetModal, UfinetSectionBox, UfinetSelect, UfinetSelectHandle } from "ufinet-web-components"
import { colsGoalsData } from "./GoalsColData"
import { UseQueryGetData, UseQueryGetUsers, UsePatchGoals, UseGetBulkLoadTemplate } from "request"
import { useTranslation } from "utils/translation/Translation"
import { ListUsersGoals, Goal } from "types/goals/goalsTypes"
import { AuthContext, Authority, useModal } from "ufinet-web-functions"
import { toast } from "react-toastify"
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload'
import { ErrorResponse } from "types/typesAndValues"
import BoxTotal from "lib/box/BoxTotal"
import BulkLoadForm from "./bulk-load/BulkLoadForm"


export const GoalsPage = () => {
  const intl = useIntl()
  const translate = useTranslation()

  const [filteredData, setFilteredData] = useState({})
  const [loadModal, showLoadModal, hideModal] = useModal()

  const colsGoals = useMemo(() => colsGoalsData(intl), [intl])


  const authData = useContext(AuthContext)
  const userEmail = authData.userData?.username
  const roles = authData.userData?.authorities || []
  const permissions = Authority.getCommissionGoalsPermissions(roles)


  const { data: goalsData, isLoading: isLoadingGoals, refetch, isFetching } = UseQueryGetData(filteredData)
  const { data: dataUsers } = UseQueryGetUsers()



  console.log('goalsData22332321', goalsData?.data)



  const isData = goalsData?.data && goalsData?.data[0]


  const cbSuccessPatchGoals = ({ data }: any) => {
    toast.success(data.message)
    refetch()
  }
  const cbSuccessGetTemplateFile = ({ data }: any) => {

  }
  const { mutate: getFileTemplate, isLoading: isLoadingGetTemplateFile } = UseGetBulkLoadTemplate(cbSuccessGetTemplateFile)


  const { mutate: sendGoals, isError, error: errorMessage } = UsePatchGoals(cbSuccessPatchGoals)

  const showErrMessage = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse;
      toast.error(`${translate("ERROR.MESSAGE")} ${errorResponse.errorCode}`, { toastId: 'Error3' });
    }
  }





  const dataUsersSelect = dataUsers?.map((item: ListUsersGoals) => (
    { label: item.username, value: item.email }
  ))




  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 5 }, (_, index) => (
    { label: String(currentYear - index), value: String(currentYear - index) }
  ))


  const yeartSelectRef = useRef<UfinetSelectHandle>(null)
  const usersSelectRef = useRef<UfinetSelectHandle>(null)


  const getHeaderButtons = () => {
    return (
      <>
        {
          permissions.canWrite &&
          <UfinetButton
            icon={faUpload}
            onClick={showLoadModal}
            content={translate('BULK_LOAD')}
          />
        }
      </>
    )
  }



  const getFilters = () => {
    return (
      <>
        <div className="row">

          <UfinetSelect
            ref={yeartSelectRef}
            requiredIcon
            className='col'
            // error={formik.errors.idAuthorizer?.label}
            labelTitle={translate('SELECT.YEAR')}
            // onChange={onChange(formik, 'idAuthorizer')}

            onChange={(value: any) => setFilteredData({ ...filteredData, year: value.value })}
            placeholder={translate('SELECT.YEAR')}
            options={years}
          />
          <UfinetSelect
            ref={usersSelectRef}
            requiredIcon
            className='col'
            // error={formik.errors.idAuthorizer?.label}
            labelTitle={translate('SELECT.USER')}
            // onChange={onChange(formik, 'idAuthorizer')}

            onChange={(value: any) => setFilteredData({ ...filteredData, username: value.value })}
            placeholder={translate('SELECT.USER')}
            options={dataUsersSelect}
          />
        </div>
      </>
    )
  }

  const onFilterClear = () => {
    if (yeartSelectRef.current !== null && usersSelectRef.current !== null) {
      yeartSelectRef.current.clearSelect()
      usersSelectRef.current.clearSelect()
      setFilteredData({})
    }
  }


  const onRowEditComplete = (data: Goal) => {

    if (data.locked) return toast.error(translate('ERROR.MESSAGE.GOALS'))
    if (data.locked === false) {
      return sendGoals({
        email: isData.email,
        goals: [
          {
            netArc: data.netArc,
            arc: data.arc,
            churnBreakage: data.churnBreakage,
            period: data.period,
          }
        ]
      })

    }

  }


  const getFile = () => {
    getFileTemplate(userEmail)
  }


  return (
    <>
      {isError && showErrMessage()}
      <UfinetBox>
        {isData &&
          <UfinetSectionBox className="mb-2">
            <div>
              <BoxTotal totalCommissionAmount={isData.totalGoalsAmount} currencyCode={isData.currencyCode} title='ANNUAL.GOAL' />
            </div>
          </UfinetSectionBox>
        }
        <UfinetSectionBox className="mh-100">

          <Table
            headerButtons={getHeaderButtons()}
            onFilterClear={onFilterClear}
            onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            cols={colsGoals}
            content={goalsData?.data && goalsData?.data[0]?.goals}
            enableSearchFilter={false}
            filterButtons={getFilters()}
            emptyMessage={isLoadingGoals || isFetching ? translate('LOADING_DOTS') : undefined}
            actionBodyTemplate={<></>}
          />

        </UfinetSectionBox>


        <UfinetModal
          size="xl"
          show={loadModal}
          handleClose={hideModal}
          title={translate('LOAD.TEMPLATE')}
        >
          <BulkLoadForm downLoadTemplate={getFile} hideLoadModal={hideModal} refetch={refetch} />
        </UfinetModal>

      </UfinetBox>
    </>
  )
}
