import React, { useState, useEffect, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Table, UfinetBox, UfinetButton, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection } from 'ufinet-web-components'
import { AuthContext, Authority, } from 'ufinet-web-functions'
import { colsNextPaymentCommissions } from './NextPaymentColData'
import { UseQueryNextPaymentCommissions, UseDownloadFileCommissionNextPayment } from 'request'
import BoxTotal from 'lib/box/BoxTotal'
import { DataTablePageEvent } from 'primereact/datatable'
import { CommissionKamElement } from 'types/commissionKamTypes'
import { Translation } from 'utils/translation/Translation'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

const NextPaymentPage = () => {
  const intl = useIntl()
  const colsNextPaymentCommission = useMemo(() => colsNextPaymentCommissions(intl), [intl])

  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  const permissions = Authority.getCommissionKamPermissions(roles)


  const [paging, setPaging] = useState<any>()
  const [filterData, setFilterData] = useState<any>()
  const { data: nextPaymentCommssion, isLoading: isLoadingNextPaymentCommision } = UseQueryNextPaymentCommissions(filterData)

  const cbSuccessNextPaymentFile = (data: any) => {

  }

  const { mutate: downloadFile, isLoading: isLoadingCommissionNextPayment } = UseDownloadFileCommissionNextPayment(cbSuccessNextPaymentFile)


  // To get de currencySymbol to show it at the top of the table
  const currencySymbol = nextPaymentCommssion?.data && nextPaymentCommssion?.data.commissions.length > 0 ? nextPaymentCommssion?.data?.commissions[0]?.user.currencyCode : null
  const totalAmountBox = nextPaymentCommssion?.data ? nextPaymentCommssion?.data?.totalCommissionAmount : null




  useEffect(() => {
    if (nextPaymentCommssion) {
      const parsePagination = JSON.parse(nextPaymentCommssion?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [nextPaymentCommssion])

  const onFilterClear = (): void => {
    setFilterData({})
  }

  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)
    setFilterData({
      ...filterData,
      ...formattedFilters
    })
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({ ...filterData, pageNumber: e.page, pageSize: e.rows })
  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      sortField: e.sortField,
      sortOrder: finalOrder
    })
  }

  const downloadFileNextPaymentCommission = () => {
    downloadFile(filterData)
  }


  const getHeadersButtons = () => (
    <>
      {permissions.canWrite && (
        <UfinetButton
          className="me-3"
          icon={faDownload}
          onClick={() => downloadFileNextPaymentCommission()}
          content={Translation('DOWNLOAD.FILE')}
        />
      )}
    </>
  )

  return (
    <>
      <UfinetBox>
        <UfinetSectionBox>

          <BoxTotal
            title='TOTAL.TOPAY'
            totalCommissionAmount={totalAmountBox}
            currencyCode={currencySymbol}
          />

        </UfinetSectionBox>
        <UfinetSectionBox className='mt-5'>
          <Table
            // selectedValues={selectedValues}
            // onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            cols={colsNextPaymentCommission}
            content={nextPaymentCommssion?.data?.commissions?.map((item: CommissionKamElement) => ({
              ...item,
              idService: item.service.idService,
              saleMonth: item.service.saleMonth,
              saleYear: item.service.saleYear,
              commissionType: item.commission.commissionType,
              exchangeRate: item.commission.exchangeRate,
              type: item.commission.type,
              backlog: item.service.backlog,
              percentageCommission: item.commission.percentageCommission,
              commissionTypePercentage: item.commission.commissionTypePercentage,
              authorizationDate: item.authorization.authorizationDate,
              achievementFactor: item.commission.achievementFactor,
              baseAmount: item.commission.baseAmount,
              totalAmount: item.commission.totalAmount,
              idServiceCRM: item.service.idServiceCRM,
              serviceName: item.service.serviceName,
              serviceClient: item.service.serviceClient,
              paymenthMonth: item.commission.paymentMonth,
              paymentYear: item.commission.paymentYear,
              firstBillingDate: item.service.firstBillingDate,

            }))}
            actionBodyTemplate={<></>}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            emptyMessage={isLoadingNextPaymentCommision || isLoadingCommissionNextPayment ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            onFilterClear={onFilterClear}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingNextPaymentCommision || isLoadingCommissionNextPayment,
                onFilter,
                onPage,
                onSort,
              }
            }
            headerButtons={getHeadersButtons()}
          // emptyMessage={loadingRecords ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
          />
        </UfinetSectionBox>
      </UfinetBox >
    </>
  )
}

export { NextPaymentPage }
