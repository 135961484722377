import React, { useState, useEffect, useMemo, useContext } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { Table, UfinetBox, UfinetButton, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection } from 'ufinet-web-components'
import { AuthContext, Authority, } from 'ufinet-web-functions'
import { colsInProcessCommission } from './InProcessColData'
import { UseQueryInProcessCommissions, UseDownloadFileCommissionInProccess } from 'request'
import { Translation } from 'utils/translation/Translation'
import { DataTablePageEvent } from 'primereact/datatable'
import { CommissionKamElement } from 'types/commissionKamTypes'
import { faDownload } from '@fortawesome/free-solid-svg-icons'



const InProcessPage = () => {
  const intl = useIntl()

  const colsInProcessPage = useMemo(() => colsInProcessCommission(intl), [intl])
  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  const permissions = Authority.getCommissionKamPermissions(roles)



  const [paging, setPaging] = useState<any>()
  const [filterData, setFilterData] = useState<any>()
  const { data: inProcessCommission, isLoading: isLoadingInProcessCommission } = UseQueryInProcessCommissions(filterData)

  const cbSuccessInProccessFile = (data: any) => {

  }

  const { mutate: downloadFile, isLoading: isLoadingCommissionInProcces } = UseDownloadFileCommissionInProccess(cbSuccessInProccessFile)

  useEffect(() => {
    if (inProcessCommission) {
      const parsePagination = JSON.parse(inProcessCommission?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageNumber: parsePagination.currentPage,
        pageSize: filterData && filterData.pageSize ? filterData.pageSize : parsePagination.pageSize
      }
      setPaging(formattedPagination)
    }
  }, [inProcessCommission])

  const onFilterClear = (): void => {
    setFilterData({})
  }

  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)
    setFilterData({
      ...filterData,
      ...formattedFilters
    })
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({ ...filterData, pageNumber: e.page, pageSize: e.rows })

  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })

  }

  const downloadFileInProccessCommission = () => {
    downloadFile(filterData)
  }


  const getHeadersButtons = () => (
    <>
      {permissions.canWrite && (
        <UfinetButton
          className="me-3"
          icon={faDownload}
          onClick={() => downloadFileInProccessCommission()}
          content={Translation('DOWNLOAD.FILE')}
        />
      )}
    </>
  )

  return (
    <>
      <UfinetBox>

        <UfinetSectionBox>

          <Table
            // selectedValues={selectedValues}
            // onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            content={inProcessCommission?.data?.commissions?.map((item: CommissionKamElement) => ({
              ...item,
              idService: item.service.idService,
              saleMonth: item.service.saleMonth,
              saleYear: item.service.saleYear,
              commissionType: item.commission.commissionType,
              exchangeRate: item.commission.exchangeRate,
              commissionDescription: item.commission.commissionDescription,
              type: item.commission.type,
              backlog: item.service.backlog,
              percentageCommission: item.commission.percentageCommission,
              baseAmount: item.commission.baseAmount,
              commissionTypePercentage: item.commission.commissionTypePercentage,
              idServiceCRM: item.service.idServiceCRM,
              serviceName: item.service.serviceName,
              serviceClient: item.service.serviceClient,
            }))}
            actionBodyTemplate={<></>}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            cols={colsInProcessPage}
            onFilterClear={onFilterClear}
            emptyMessage={isLoadingInProcessCommission || isLoadingCommissionInProcces ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingInProcessCommission || isLoadingCommissionInProcces,
                onFilter,
                onPage,
                onSort,
              }
            }
            headerButtons={getHeadersButtons()}
          // emptyMessage={loadingRecords ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
          />
          {/* <UfinetModal
          size="lg"
          show={newModal}
          handleClose={hideNewModal}
          title={intl.formatMessage({ id: 'MODAL.NEW.REGISTER.TITLE' })}
        >
          <NewCompanyModalBody search={fetchAndStore} hideModal={hideNewModal} />
        </UfinetModal> */}
        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}

export { InProcessPage }

const ContainerCommissionAmount = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;    
    
    h2 {
      margin: 0;
    }
`


const BoxShadow = styled.div`
box-shadow: 0px 0px 2px 1px rgba(189,173,173,0.75);
-webkit-box-shadow: 0px 0px 2px 1px rgba(189,173,173,0.75);
-moz-box-shadow: 0px 0px 2px 1px rgba(189,173,173,0.75);
padding: 5px;
`

const TotalAmount = styled.div`
  display: flex;
  justify-content: flex-end;
`
const CurrencyCode = styled.div`
  display: flex;
  justify-content: flex-end;
`
