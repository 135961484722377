import { i18nMessages } from 'ufinet-web-components'

import commisionEnMessages from './en.json'
import CommissionEsMessages from './es.json'
import CommmisionPtMessages from './pt.json'

const commissionMessages: i18nMessages = {
  en: {
    ...commisionEnMessages,
  },
  es: {
    ...CommissionEsMessages,
  },
  pt: {
    ...CommmisionPtMessages,
  }
}

export { commissionMessages }
