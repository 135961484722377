import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Table, UfinetBox, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection, } from 'ufinet-web-components'


import { UseQueryGetMultiplierConfig, } from 'request'
import { DataTablePageEvent } from 'primereact/datatable'
import { colsMultiplierConfiKamColData } from './MultiplierConfigKamColData'




const MultiplierConfigKamPage = () => {
  const intl = useIntl()
  const colsMultiplierConfigKam = useMemo(() => colsMultiplierConfiKamColData(intl), [intl])


  const [paging, setPaging] = useState<any>()

  const [filterData, setFilterData] = useState<any>()



  const { data: multiplierConfigKam, isLoading: isLoadingMultiplierConfigKam, refetch } = UseQueryGetMultiplierConfig(filterData)

  useEffect(() => {
    if (multiplierConfigKam) {
      const parsePagination = JSON.parse(multiplierConfigKam?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: filterData && filterData.pageSize ? filterData.pageSize : parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [multiplierConfigKam])

  const onFilterClear = (): void => {
    setFilterData({})
  }




  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)
    setFilterData({
      ...filterData,
      ...formattedFilters
    })
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({ ...filterData, pageNumber: e.page, pageSize: e.rows })
  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })
  }







  return (
    <>
      <UfinetBox>

        <UfinetSectionBox>

          <Table
            enableSearchFilter={false}
            dataKey="id"
            // selectedValues={selectedValues}
            // onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            content={multiplierConfigKam?.data}
            cols={colsMultiplierConfigKam}
            // headerButtons={getHeadersButtons()}
            onFilterClear={onFilterClear}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            emptyMessage={isLoadingMultiplierConfigKam ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            actionBodyTemplate={<></>}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingMultiplierConfigKam,
                onFilter,
                onPage,
                onSort,
              }
            }
          />

        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}

export { MultiplierConfigKamPage }


