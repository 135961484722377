import React from 'react'


interface Props {
  percentange: number
}

const PercentangeCommission = ({ percentange }: Props) => {

  if (percentange === null) return <>{''}</>

  return (
    <>{`${percentange}%`}</>
  )
}

export { PercentangeCommission }
