import React, { useCallback, } from 'react'
import { useFormik } from 'formik'
import { UfinetButton, UfinetDropzone, } from 'ufinet-web-components';
import { onFormikChanges, IFileUploadData } from 'ufinet-web-functions';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { UsePostGlobalBulkLoadFile } from 'request/request';
import * as Yup from 'yup'
import { ErrorResponse } from 'types/typesAndValues';
import { useTranslation } from 'utils/translation/Translation';
import { toast } from 'react-toastify';

interface Props {
  downLoadTemplate: () => void;
  hideLoadModal: () => void;
}

interface File {
  file: IFileUploadData
}


const BulkLoadForm = ({ downLoadTemplate, hideLoadModal }: Props) => {


  const translate = useTranslation()






  const onChange = useCallback(onFormikChanges, [])

  const cbSuccessUploadBulkFile = (data: any) => {
    toast.success(translate('SENDED'))
    hideLoadModal()
  }

  const { mutate: uploadBulkFile, isError, error: errorMessage } = UsePostGlobalBulkLoadFile(cbSuccessUploadBulkFile)

  const showErrMessage = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse;
      toast.error(`${translate("ERROR.MESSAGE")} ${errorResponse.errorCode}`, { toastId: 'Error3' });
      hideLoadModal()
    }
  }


  const dataFormSchema = Yup.object().shape({
    file: Yup.mixed()
      //@ts-ignore
      .test('file required', translate('ERROR.REQUIRED'), (value) => value.size > 0)
      .test('file type', translate('ERROR.FILE.EXTENSION'), (value) => {
        //@ts-ignore
        const fileExtension = value?.path?.split('.').pop()
        return fileExtension === undefined || fileExtension.includes(['xlsx']) || fileExtension.includes(['xls'])
      }),
  })



  const formik = useFormik<File>({
    initialValues: {
      file: {
        path: '',
        size: 0,
      },
    },
    validationSchema: dataFormSchema,
    onSubmit: (values: File) => {

      uploadBulkFile(values.file)
      // afterSubmit && afterSubmit(values)
    },
    validateOnChange: false,
    validateOnBlur: false,
  })


  return (
    <>
      {isError && showErrMessage()}
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div className="row py-10">
            <div className="col-12">
              <UfinetButton
                onClick={downLoadTemplate}
                className="w-100"
                content={translate('DOWNLOAD.TEMPLATE')}
                icon={faDownload}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <UfinetDropzone
                isDisabled={false}
                onChange={onChange(formik, 'file')}
                value={formik.values.file}
                error={formik.errors.file}
                text={translate('DROPZONE.UPLOAD_EXCEL.TITLE')}
                accept={{ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xls'] }}
              />
            </div>
          </div>

          <div className="row py-10">
            <div className="col-12">
              <UfinetButton
                type='submit'
                className="w-100"
                content={translate('SAVE')}
                icon={faSave}
              />
            </div>
          </div>


        </div>
      </form>
    </>
  )
}

export default BulkLoadForm


