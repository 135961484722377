import React, { useState, useEffect, useMemo, useContext, useCallback } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { toast } from 'react-toastify'
import { MultipleDeleter, Table, UfinetBox, UfinetButton, UfinetModal, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection } from 'ufinet-web-components'
import { AuthContext, Authority, useAsync, useModal, } from 'ufinet-web-functions'
import { colsLiquidateRejected } from './LiquidateRejectedColData'
import { UseQueryListRejectedPaymentsCommissions, UsePostApprovedListPaymentsCommission, UseDownloadLiquidateRejected } from 'request'
import { Translation } from 'utils/translation/Translation'
import { CommissionService } from 'types/CommissionEstimatedTypes'
import BoxTotal from 'lib/box/BoxTotal'
import { DataTableFilterMeta, DataTablePageEvent } from 'primereact/datatable'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck'
import { LiquidateTypesRRHH } from 'types/liquidateRRHH'



const LiquidateRejectedPage = () => {
  const intl = useIntl()
  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  const permissions = Authority.getCommissionLiquidateRRHHPermissions(roles)

  const colsRejected = useMemo(() => colsLiquidateRejected(intl), [intl])


  const [selectedValues, setSelectedValues] = useState<LiquidateTypesRRHH[]>([])
  const [paging, setPaging] = useState<any>()
  const [filterData, setFilterData] = useState<any>()
  const { data: rejectedPayments, isLoading: isLoadingRejectedPayments, refetch: refetchRejectedPayments, isFetching } = UseQueryListRejectedPaymentsCommissions(filterData)

  const cbSuccessApprove = (data: any) => {
    refetchRejectedPayments()
  }
  const cbSuccessDownloadFile = (data: any) => {
  }

  const { mutate: approvePayments, isLoading: isLoadingApprovePayments } = UsePostApprovedListPaymentsCommission(cbSuccessApprove)
  const { mutate: downloadFile, isLoading: isLoadingDownloadFileRejected } = UseDownloadLiquidateRejected(cbSuccessDownloadFile)

  useEffect(() => {
    if (rejectedPayments) {
      const parsePagination = JSON.parse(rejectedPayments?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageNumber: parsePagination.currentPage,
        pageSize: filterData && filterData.pageSize ? filterData.pageSize : parsePagination.pageSize
      }
      setPaging(formattedPagination)
    }
  }, [rejectedPayments])

  const onFilterClear = (): void => {
    setFilterData({})
  }

  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)
    setFilterData(formattedFilters)
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({ ...filterData, pageNumber: e.page, pageSize: e.rows })
    // if (e.page === userRequestData.page && e.rows === userRequestData.size) return
    // setUserRequestData({ ...userRequestData, pageNumber: e.page, pageSize: e.rows })
  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })
    // const finalField = UserPropertyMap.get(e.sortField)
    // setUserRequestData({
    // 	...userRequestData,
    // 	sortFields: finalField && [finalField],
    // 	sortOrders: finalField && finalOrder && [finalOrder],
    // 	// Return to first page
    // 	pageNumber: 0,
    // })
  }

  const approveListPayments = () => {
    if (selectedValues.length > 0) {
      const newData = selectedValues.map((item: LiquidateTypesRRHH) => ({
        id: item.paymentId,
        state: 'approved'
      }))

      approvePayments(newData)
    }
  }

  const downloadFileLiquidateRejected = () => {
    downloadFile(filterData)
  }


  const getHeadersButtons = () => (
    <>
      {permissions.canWrite && (
        <UfinetButton
          className="me-3"
          icon={faDownload}
          onClick={() => downloadFileLiquidateRejected()}
          content={Translation('DOWNLOAD.FILE')}
        />
      )}

      {permissions.canWrite && (
        <UfinetButton
          className="me-3"
          icon={faCircleCheck}
          onClick={() => approveListPayments()}
          content={Translation('BUTTON.APRROVED')}
        />
      )}
    </>
  )

  const onSelectionChange = useCallback((values: any[]) => {
    setSelectedValues(values)
  }, [])


  return (
    <>
      <UfinetBox>
        <UfinetSectionBox>

          <Table
            dataKey='paymentId'
            selectedValues={selectedValues}
            onSelectionChange={permissions.canUpdate ? onSelectionChange : undefined}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            content={rejectedPayments?.data && rejectedPayments?.data?.map((item: LiquidateTypesRRHH) => (
              {
                ...item,
                name: `${item.paymentMonth && item.paymentYear ? `${Translation('LIQUIDATE.COL')} ${Translation(item.paymentMonth)} -` : ''} ${item.cutoffMonth && item.cutoffYear ? `${Translation('CLOSEDATE')} ${Translation(item.cutoffMonth)} ${item.cutoffYear}` : ''} `,
                username: item.username,
                paymentId: item.paymentId,
              }
            ))}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            cols={colsRejected}
            onFilterClear={onFilterClear}
            emptyMessage={isLoadingRejectedPayments || isFetching || isLoadingDownloadFileRejected ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingRejectedPayments || isFetching || isLoadingDownloadFileRejected,
                onFilter,
                onPage,
                onSort,
              }
            }
            headerButtons={getHeadersButtons()}
            actionBodyTemplate={<></>}
          />
          {/* <UfinetModal
          size="lg"
          show={newModal}
          handleClose={hideNewModal}
          title={intl.formatMessage({ id: 'MODAL.NEW.REGISTER.TITLE' })}
        >
          <NewCompanyModalBody search={fetchAndStore} hideModal={hideNewModal} />
        </UfinetModal> */}
        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}

export { LiquidateRejectedPage }

const ContainerCommissionAmount = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;    
    
    h2 {
      margin: 0;
    }
`


const BoxShadow = styled.div`
box-shadow: 0px 0px 2px 1px rgba(189,173,173,0.75);
-webkit-box-shadow: 0px 0px 2px 1px rgba(189,173,173,0.75);
-moz-box-shadow: 0px 0px 2px 1px rgba(189,173,173,0.75);
padding: 5px;
`

const TotalAmount = styled.div`
  display: flex;
  justify-content: flex-end;
`
const CurrencyCode = styled.div`
  display: flex;
  justify-content: flex-end;
`
