import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck'
import { Table, UfinetBox, UfinetButton, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection } from 'ufinet-web-components'
import { AuthContext, Authority } from 'ufinet-web-functions'
import { colsRejectedAuthorization } from './RejectedAuthorizionColData'
import { UseQueryRejectedAuthorizationCommissions, UsePostApproveCommissionAuthorization, UseDownloadFileAuthorizatoRejected } from 'request'
import { Translation } from 'utils/translation/Translation'
import { DataTablePageEvent } from 'primereact/datatable'
import { CommissionElement, AuthorizationRejectedColDataTypes } from 'types/authorization'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { useFiltersAuthorization } from 'hooks/useFiltersAuthorization'
import { MultiSelectFiltersHeaders } from '../filterHeaders/MultiSelectFiltersHeaders'

const RejectedAuthorizationPage = () => {
  const intl = useIntl()

  const colsRejectedPage = useMemo(() => colsRejectedAuthorization(intl), [intl])


  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  const permissions = Authority.getCommissionAuthorizationPermissions(roles)


  const [selectedValues, setSelectedValues] = useState<AuthorizationRejectedColDataTypes[]>([])
  const [paging, setPaging] = useState<any>()
  const [filterData, setFilterData] = useState<any>()


  const {
    kamsSelect,
    isLoadingDataAuthorizator,
    clientsSelect,
    isLoadingDataClients,
    handleMultiSelect,
    multiSelectKam,
    multiSelectClient,
    cleanSelectKamAndClient,
    username,
    serviceClient,
    selectAllClient,
    selectAllKam,
    onSelectAll,
  } = useFiltersAuthorization({ filterData, setFilterData, })


  const { data: rejectedAuthorization, isLoading: isLoadingRejectedAuthorization, refetch: refetchRejectedAuthorization, isFetching: isFetchingRejectedAuhtorization } = UseQueryRejectedAuthorizationCommissions(filterData)

  const cbSuccessApproveCommission = ({ data }: any) => {
    setSelectedValues([])
    refetchRejectedAuthorization()
  }

  const cbSuccessDownloadFile = ({ data }: any) => { }



  const { mutate: approveCommission, isLoading: isLoadingApproveCommission } = UsePostApproveCommissionAuthorization(cbSuccessApproveCommission)
  const { mutate: downloadFile, isLoading: isLoadingDownloadFile } = UseDownloadFileAuthorizatoRejected(cbSuccessDownloadFile)



  useEffect(() => {
    if (rejectedAuthorization) {
      const parsePagination = JSON.parse(rejectedAuthorization?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [rejectedAuthorization])

  const onFilterClear = (): void => {
    cleanSelectKamAndClient()
    setFilterData({})
  }

  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)

    setFilterData({
      ...filterData,
      ...formattedFilters,
      username,
      serviceClient,
    })
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({
      ...filterData,
      username,
      serviceClient,
      pageNumber: e.page,
      pageSize: e.rows
    })
  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      username,
      serviceClient,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })


  }

  const onSelectionChange = useCallback((values: any[]) => {
    setSelectedValues(values)
  }, [])


  const approveCommisionFn = () => {
    if (selectedValues.length > 0) {
      const newArray = selectedValues.map((item: any) => ({
        id: item.id,
        state: 'approved'
      }))
      approveCommission(newArray)
    }
  }

  const downloadFilePending = () => {
    downloadFile(filterData)
  }


  const getHeadersButtons = () => (
    <>


      {permissions.canWrite && (
        <UfinetButton
          className="me-3"
          icon={faDownload}
          isDisabled={isLoadingDownloadFile}
          onClick={() => downloadFilePending()}
          content={Translation('DOWNLOAD.FILE')}
        />
      )}

      {permissions.canWrite && selectedValues && selectedValues.length > 0 && (
        <UfinetButton
          className="me-3"
          icon={faCircleCheck}
          isDisabled={isLoadingApproveCommission}
          onClick={approveCommisionFn}
          content={Translation('BUTTON.APRROVED')}
        />
      )}
    </>
  )


  const filterButtons = () => {
    return (
      <>
        <MultiSelectFiltersHeaders
          // kamFilter={false}

          onSelectAll={onSelectAll}
          selectAllClient={selectAllClient}
          selectAllKam={selectAllKam}
          multiSelectClient={multiSelectClient}
          multiSelectKam={multiSelectKam}
          clientsSelect={clientsSelect}
          kamsSelect={kamsSelect}
          onChangeHandle={handleMultiSelect}
          isLoadingDataAuthorizator={isLoadingDataAuthorizator}
          isLoadingDataClients={isLoadingDataClients}
        />
      </>
    )

  }



  return (
    <>
      <UfinetBox>

        <UfinetSectionBox>
          <Table
            filterButtons={filterButtons()}
            enableSearchFilter={false}
            dataKey="id"
            selectedValues={selectedValues}
            onSelectionChange={permissions.canUpdate ? onSelectionChange : undefined}
            headerButtons={getHeadersButtons()}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            cols={colsRejectedPage}
            content={rejectedAuthorization?.data?.commissions?.map((item: CommissionElement) => ({
              ...item,
              username: item.user.username,
              idService: item.service.idService,
              saleMonth: item.service.saleMonth,
              commissionType: item.commission.commissionType,
              exchangeRate: item.commission.exchangeRate,
              authorizationState: item.authorization.authorizationState,
              id: item.commission.id,
              totalAmount: item.commission.totalAmount,
              backlog: item.service.backlog,
              percentageCommission: item.commission.percentageCommission,
              baseAmount: item.commission.baseAmount,
              achievementFactor: item.commission.achievementFactor,
              paymentYear: item.commission.paymentYear,
              commissionTypePercentage: item.commission.commissionTypePercentage,
              rejectedDate: item.authorization.rejectedDate,
              saleYear: item.service.saleYear,
              idServiceCRM: item.service.idServiceCRM,
              serviceName: item.service.serviceName,
              serviceClient: item.service.serviceClient,
              authorizationReason: item.authorization.authorizationReason,
            }))}
            actionBodyTemplate={<></>}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            emptyMessage={isLoadingRejectedAuthorization || isFetchingRejectedAuhtorization ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            onFilterClear={onFilterClear}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingRejectedAuthorization || isFetchingRejectedAuhtorization,
                onFilter,
                onPage,
                onSort,
              }
            }
          />


        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}

export { RejectedAuthorizationPage }

const ContainerCommissionAmount = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;    
    
    h2 {
      margin: 0;
    }
`

const TotalAmount = styled.div`
  display: flex;
  justify-content: flex-end;
`

const CurrencyCode = styled.div`
  display: flex;
  justify-content: flex-end;
`
