import axios from "axios"
import { useQuery, useMutation } from "react-query"
import { fetchGET, fetchGET2, fetchPOST2 } from "utils/https/https"



export const UseQueryNextPaymentCommissionSystem = (data: any) => {

  const username = data?.username ?? null;
  const commissionType = data?.commissionType?.[0] ?? null;
  const paymentId = data?.paymentId?.[0] ?? null;
  const saleMonth = data?.saleMonth?.[0] ?? null;
  const saleYear = data?.saleYear?.[0] ?? null;
  const paymentYear = data?.paymentYear?.[0] ?? null;
  const paymentMonth = data?.paymentMonth?.[0] ?? null;
  const serviceId = (data?.idService?.length >= 5) ? data.idService : null;
  const idServiceCRM = data?.idServiceCRM?.[0] ?? null;
  const serviceName = data?.serviceName || null;
  const serviceClient = data?.serviceClient || null;
  const commissionDescription = data?.commissionDescription?.[0] ?? null;
  const typeCommission = data?.type?.[0] ?? null;

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  return useQuery(['UseQueryNextPaymentCommissionSystem', username, commissionType, serviceClient, paymentId, saleMonth, serviceName, idServiceCRM, saleYear, serviceId, paymentYear, paymentMonth, commissionDescription, typeCommission, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (username) {
        serchParms.append('username', username)
      }

      if (commissionType) {
        serchParms.append('commissionType', commissionType)
      }

      if (serviceClient) {
        serchParms.append('serviceClient', serviceClient)
      }

      if (serviceName) {
        serchParms.append('serviceName', serviceName)
      }

      if (idServiceCRM) {
        serchParms.append('idServiceCRM', idServiceCRM)
      }

      if (paymentId) {
        serchParms.append('paymentId', paymentId)
      }

      if (typeCommission) {
        serchParms.append('type', typeCommission)
      }

      if (saleYear) {
        serchParms.append('saleYear', saleYear)
      }

      if (saleMonth) {
        serchParms.append('saleMonth', saleMonth)
      }

      if (paymentYear) {
        serchParms.append('paymentYear', paymentYear)
      }

      if (paymentMonth) {
        serchParms.append('paymentMonth', paymentMonth)
      }

      if (serviceId) {
        serchParms.append('IdService', serviceId)
      }

      if (commissionDescription) {
        serchParms.append('commissionDescription', commissionDescription)
      }

      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('rowsPerPage', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }

      const response = await fetchGET(`Commission/ListCommissions?&authorizationState=approved&commissionState=completed&paymentState=pending&${serchParms.toString()}`)
      return response
    } catch (error) {
      throw error
    }
  })
}

export const UseQueryApprovedCommissionsSystems = (data: any) => {

  const username = data?.username ?? null;
  const commissionType = data?.commissionType?.[0] ?? null;
  const paymentId = data?.paymentId?.[0] ?? null;
  const saleMonth = data?.saleMonth?.[0] ?? null;
  const saleYear = data?.saleYear?.[0] ?? null;
  const paymentMonth = data?.paymentMonth?.[0] ?? null;
  const paymentYear = data?.paymentYear?.[0] ?? null;
  const paymentState = data?.paymentState?.[0] ?? null;
  const serviceId = (data?.idService?.length >= 5) ? data.idService : null;
  const idServiceCRM = data?.idServiceCRM?.[0] ?? null;
  const serviceName = data?.serviceName || null;
  const serviceClient = data?.serviceClient || null;
  const typeCommission = data?.type?.[0] ?? null;
  const authorizatorName = data?.authorizatorName ?? null;


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null
  return useQuery(['UseQueryApprovedCommissionsSystems', username, commissionType, paymentId, idServiceCRM, serviceClient, serviceName, saleMonth, saleYear, paymentMonth, paymentYear, paymentState, serviceId, typeCommission, authorizatorName, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (username) {
        serchParms.append('username', username)
      }

      if (idServiceCRM) {
        serchParms.append('idServiceCRM', idServiceCRM)
      }

      if (serviceName) {
        serchParms.append('serviceName', serviceName)
      }

      if (serviceClient) {
        serchParms.append('serviceClient', serviceClient)
      }

      if (commissionType) {
        serchParms.append('commissionType', commissionType)
      }

      if (paymentId) {
        serchParms.append('paymentId', paymentId)
      }

      if (saleMonth) {
        serchParms.append('saleMonth', saleMonth)
      }

      if (saleYear) {
        serchParms.append('saleYear', saleYear)
      }

      if (paymentState) {
        serchParms.append('paymentState', paymentState)
      }

      if (paymentMonth) {
        serchParms.append('paymentMonth', paymentMonth)
      }

      if (paymentYear) {
        serchParms.append('paymentYear', paymentYear)
      }

      if (serviceId) {
        serchParms.append('IdService', serviceId)
      }

      if (typeCommission) {
        serchParms.append('type', typeCommission)
      }

      if (authorizatorName) {
        serchParms.append('authorizatorName', authorizatorName)
      }



      // Pagination

      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('rowsPerPage', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }

      const response = await fetchGET(`Commission/ListCommissions?&authorizationState=approved&commissionState=completed&${serchParms.toString()}`)
      return response
    } catch (error) {
      throw error
    }
  })
}
export const UseQueryPaidCommissionsSystems = (data: any) => {

  const username = data?.username ?? null;
  const commissionType = data?.commissionType?.[0] ?? null;
  const paymentId = data?.paymentId?.[0] ?? null;
  const saleMonth = data?.saleMonth?.[0] ?? null;
  const saleYear = data?.saleYear?.[0] ?? null;
  const paymentMonth = data?.paymentMonth?.[0] ?? null;
  const paymentYear = data?.paymentYear?.[0] ?? null;
  const paymentState = data?.paymentState?.[0] ?? null;
  const serviceId = data?.idService?.length >= 5 ? data.idService : null;
  const idServiceCRM = data?.idServiceCRM?.[0] ?? null;
  const serviceName = data?.serviceName || null;
  const serviceClient = data?.serviceClient || null;
  const typeCommission = data?.type?.[0] ?? null;
  const authorizatorName = data?.authorizatorName ?? null;


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null
  return useQuery(['UseQueryPaidCommissionsSystems', username, commissionType, paymentId, idServiceCRM, serviceClient, serviceName, saleMonth, saleYear, paymentMonth, paymentYear, paymentState, serviceId, typeCommission, authorizatorName, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (username) {
        serchParms.append('username', username)
      }

      if (idServiceCRM) {
        serchParms.append('idServiceCRM', idServiceCRM)
      }

      if (serviceName) {
        serchParms.append('serviceName', serviceName)
      }

      if (serviceClient) {
        serchParms.append('serviceClient', serviceClient)
      }

      if (commissionType) {
        serchParms.append('commissionType', commissionType)
      }

      if (paymentId) {
        serchParms.append('paymentId', paymentId)
      }

      if (saleMonth) {
        serchParms.append('saleMonth', saleMonth)
      }

      if (saleYear) {
        serchParms.append('saleYear', saleYear)
      }

      if (paymentState) {
        serchParms.append('paymentState', paymentState)
      }

      if (paymentMonth) {
        serchParms.append('paymentMonth', paymentMonth)
      }

      if (paymentYear) {
        serchParms.append('paymentYear', paymentYear)
      }

      if (serviceId) {
        serchParms.append('IdService', serviceId)
      }

      if (typeCommission) {
        serchParms.append('type', typeCommission)
      }

      if (authorizatorName) {
        serchParms.append('authorizatorName', authorizatorName)
      }



      // Pagination

      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('rowsPerPage', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }

      const response = await fetchGET(`Commission/ListCommissions?&authorizationState=approved&commissionState=completed&paymentState=approved&${serchParms.toString()}`)
      return response
    } catch (error) {
      throw error
    }
  })
}

// to get the data pending at view commission system
export const UseQueryPendingCommissionsSystems = (data: any) => {

  const username = data?.username ?? null;
  const commissionType = data?.commissionType?.[0] ?? null;
  const saleMonth = data?.saleMonth?.[0] ?? null;
  const saleYear = data?.saleYear?.[0] ?? null;
  const paymentYear = data?.paymentYear?.[0] ?? null;
  const paymentMonth = data?.paymentMonth?.[0] ?? null;
  const serviceId = data?.idService?.length >= 5 ? data.idService : null;
  const idServiceCRM = data?.idServiceCRM?.[0] ?? null;
  const serviceName = data?.serviceName || null;
  const serviceClient = data?.serviceClient || null;
  const typeCommission = data?.type?.[0] ?? null;
  const authorizatorName = data?.authorizatorName ?? null;

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  return useQuery(['UseQueryPendingCommissionsSystems', username, commissionType, idServiceCRM, serviceName, serviceClient, saleMonth, saleYear, serviceId, paymentYear, paymentMonth, typeCommission, authorizatorName, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (username) {
        serchParms.append('username', username)
      }

      if (commissionType) {
        serchParms.append('commissionType', commissionType)
      }

      if (idServiceCRM) {
        serchParms.append('idServiceCRM', idServiceCRM)
      }

      if (serviceName) {
        serchParms.append('serviceName', serviceName)
      }

      if (serviceClient) {
        serchParms.append('serviceClient', serviceClient)
      }

      if (saleYear) {
        serchParms.append('saleYear', saleYear)
      }

      if (saleMonth) {
        serchParms.append('saleMonth', saleMonth)
      }

      if (paymentYear) {
        serchParms.append('paymentYear', paymentYear)
      }

      if (paymentMonth) {
        serchParms.append('paymentMonth', paymentMonth)
      }

      if (serviceId) {
        serchParms.append('IdService', serviceId)
      }

      if (typeCommission) {
        serchParms.append('type', typeCommission)
      }

      if (authorizatorName) {
        serchParms.append('authorizatorName', authorizatorName)
      }


      // Pagination
      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('rowsPerPage', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }



      const response = await fetchGET(`Commission/ListCommissions?&authorizationState=pending&commissionState=inProcess&${serchParms.toString()}`)
      return response
    } catch (error) {
      throw error
    }
  })
}


const GetFileCommissionSysPending = async (data: any) => {
  try {
    const response = await fetchGET2(`Commission/GetCommissionTemplate?&email=${data}`, { responseType: 'blob' })

    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'templatePendingComSys.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    return response
  } catch (error) {
    throw error
  }
}

export const UseGetTemplateCommissionSysPending = (callbackSuccess: any) => {
  return useMutation(GetFileCommissionSysPending, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};


const UsePostSendFile = async (data: any) => {
  try {
    const formdata = new FormData()
    formdata.append('file', data)
    const response = await fetchPOST2(`Commission/UploadCommissionTemplate`, formdata)

    return response
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const customErr = {
        errorCode: error.response.data.code,
        message: error.response.data.message,
      }

      throw customErr
    }
  }
}

export const UsePostBulkLoadFileCommissionSysPending = (callbackSuccess: any) => {
  return useMutation(UsePostSendFile, {
    onSuccess: (data) => {
      callbackSuccess(data)
    },
  })

}





// to get the data in process at view commission system
export const UseQueryInProcessSystems = (data: any) => {

  const username = data?.username ?? null;
  const commissionType = data?.commissionType?.[0] ?? null;
  const saleMonth = data?.saleMonth?.[0] ?? null;
  const saleYear = data?.saleYear?.[0] ?? null;
  const paymentYear = data?.paymentYear?.[0] ?? null;
  const paymentMonth = data?.paymentMonth?.[0] ?? null;
  const serviceId = data?.idService?.length >= 5 ? data.idService : null;
  const idServiceCRM = data?.idServiceCRM?.[0] ?? null;
  const serviceName = data?.serviceName || null;
  const serviceClient = data?.serviceClient || null;
  const commissionDescription = data?.commissionDescription?.[0] ?? null;
  const typeCommission = data?.type?.[0] ?? null;

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null
  return useQuery(['UseQueryInProcessSystems', username, commissionType, saleMonth, saleYear, idServiceCRM, serviceName, serviceClient, paymentYear, paymentMonth, serviceId, commissionDescription, typeCommission, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (username) {
        serchParms.append('username', username)
      }

      if (idServiceCRM) {
        serchParms.append('idServiceCRM', idServiceCRM)
      }

      if (serviceName) {
        serchParms.append('serviceName', serviceName)
      }

      if (serviceClient) {
        serchParms.append('serviceClient', serviceClient)
      }

      if (commissionType) {
        serchParms.append('commissionType', commissionType)
      }

      if (saleYear) {
        serchParms.append('saleYear', saleYear)
      }

      if (saleMonth) {
        serchParms.append('saleMonth', saleMonth)
      }

      if (paymentYear) {
        serchParms.append('paymentYear', paymentYear)
      }

      if (paymentMonth) {
        serchParms.append('paymentMonth', paymentMonth)
      }

      if (serviceId) {
        serchParms.append('IdService', serviceId)
      }

      if (commissionDescription) {
        serchParms.append('commissionDescription', commissionDescription)
      }

      if (typeCommission) {
        serchParms.append('type', typeCommission)
      }


      //Pagination

      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('rowsPerPage', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }

      const response = await fetchGET(`Commission/ListCommissions?&commissionState=pending&${serchParms.toString()}`)
      return response
    } catch (error) {
      throw error
    }
  })
}


export const UseQueryRejectedCommissionsSystems = (data: any) => {

  const username = data?.username ?? null;
  const commissionType = data?.commissionType?.[0] ?? null;
  const saleMonth = data?.saleMonth?.[0] ?? null;
  const saleYear = data?.saleYear?.[0] ?? null;
  const paymentYear = data?.paymentYear?.[0] ?? null;
  const paymentMonth = data?.paymentMonth?.[0] ?? null;
  const serviceId = data?.idService?.length >= 5 ? data.idService : null;
  const idServiceCRM = data?.idServiceCRM?.[0] ?? null;
  const serviceName = data?.serviceName || null;
  const authorizationReason = data?.authorizationReason ?? null;
  const serviceClient = data?.serviceClient || null;
  const typeCommission = data?.type?.[0] ?? null;
  const authorizatorName = data?.authorizatorName ?? null;

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null
  return useQuery(['UseQueryRejectedCommissionsSystems', username, commissionType, serviceClient, authorizationReason, saleMonth, serviceName, idServiceCRM, saleYear, serviceId, paymentYear, paymentMonth, typeCommission, authorizatorName, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (username) {
        serchParms.append('username', username)
      }

      if (authorizationReason) {
        serchParms.append('authorizationReason', authorizationReason)
      }

      if (commissionType) {
        serchParms.append('commissionType', commissionType)
      }

      if (serviceClient) {
        serchParms.append('serviceClient', serviceClient)
      }

      if (serviceName) {
        serchParms.append('serviceName', serviceName)
      }

      if (idServiceCRM) {
        serchParms.append('idServiceCRM', idServiceCRM)
      }

      if (saleMonth) {
        serchParms.append('saleMonth', saleMonth)
      }

      if (saleYear) {
        serchParms.append('saleYear', saleYear)
      }

      if (serviceId) {
        serchParms.append('IdService', serviceId)
      }

      if (paymentYear) {
        serchParms.append('paymentYear', paymentYear)
      }

      if (paymentMonth) {
        serchParms.append('paymentMonth', paymentMonth)
      }

      if (typeCommission) {
        serchParms.append('type', typeCommission)
      }

      if (authorizatorName) {
        serchParms.append('authorizatorName', authorizatorName)
      }


      // Pagination

      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('rowsPerPage', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }

      const response = await fetchGET(`Commission/ListCommissions?&authorizationState=rejected&commissionState=completed&${serchParms.toString()}`)
      return response
    } catch (error) {
      throw error
    }
  })
}


const downloadFileCommissionNextPaymentSys = async (data: any) => {


  const username = data?.username ?? null;
  const commissionType = data?.commissionType?.[0] ?? null;
  const paymentId = data?.paymentId?.[0] ?? null;
  const saleMonth = data?.saleMonth?.[0] ?? null;
  const saleYear = data?.saleYear?.[0] ?? null;
  const paymentYear = data?.paymentYear?.[0] ?? null;
  const paymentMonth = data?.paymentMonth?.[0] ?? null;
  const serviceId = data?.idService?.length >= 5 ? data.idService : null;
  const idServiceCRM = data?.idServiceCRM?.[0] ?? null;
  const serviceName = data?.serviceName || null;
  const serviceClient = data?.serviceClient || null;
  const commissionDescription = data?.commissionDescription?.[0] ?? null;
  const typeCommission = data?.type?.[0] ?? null;

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  try {

    const serchParms = new URLSearchParams();

    if (username) {
      serchParms.append('username', username)
    }

    if (commissionType) {
      serchParms.append('commissionType', commissionType)
    }

    if (typeCommission) {
      serchParms.append('typeCommission', typeCommission)
    }

    if (commissionDescription) {
      serchParms.append('commissionDescription', commissionDescription)
    }

    if (paymentYear) {
      serchParms.append('paymentYear', paymentYear)
    }

    if (paymentId) {
      serchParms.append('paymentId', paymentId)
    }

    if (serviceClient) {
      serchParms.append('serviceClient', serviceClient)
    }

    if (serviceName) {
      serchParms.append('serviceName', serviceName)
    }

    if (idServiceCRM) {
      serchParms.append('idServiceCRM', idServiceCRM)
    }

    if (serviceId) {
      serchParms.append('IdService', serviceId)
    }


    if (saleMonth) {
      serchParms.append('saleMonth', saleMonth)
    }

    if (paymentMonth) {
      serchParms.append('paymentMonth', paymentMonth)
    }

    if (saleYear) {
      serchParms.append('saleYear', saleYear)
    }


    //! PAGINATION
    if (pageNumber) {
      serchParms.append('pageNumber', pageNumber)
    }

    if (pageSize) {
      serchParms.append('rowsPerPage', pageSize)
    }

    if (sortOrder) {
      serchParms.append('sortOrder', sortOrder)
    }

    if (sortField) {
      serchParms.append('sortField', sortField)
    }


    const response = await fetchGET(`Commission/DownloadCommissions?&authorizationState=approved&commissionState=completed&paymentState=pending&${serchParms.toString()}`, { responseType: 'blob' })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'commissionNextPaymentSysDocument.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);


    return response


  } catch (error) {
    throw error
  }
}


export const UseDownloadFileCommissionNextPaymentSys = (callbackSuccess: any) => {
  return useMutation(downloadFileCommissionNextPaymentSys, {
    onSuccess(data) {
      callbackSuccess(data)
    },
  })
}



const downloadFileCommissionApprovedSys = async (data: any) => {

  const username = data?.username ?? null;
  const commissionType = data?.commissionType?.[0] ?? null;
  const paymentId = data?.paymentId?.[0] ?? null;
  const saleMonth = data?.saleMonth?.[0] ?? null;
  const saleYear = data?.saleYear?.[0] ?? null;
  const paymentMonth = data?.paymentMonth?.[0] ?? null;
  const paymentYear = data?.paymentYear?.[0] ?? null;
  const paymentState = data?.paymentState?.[0] ?? null;
  const serviceId = data?.idService?.length >= 5 ? data.idService : null;
  const idServiceCRM = data?.idServiceCRM?.[0] ?? null;
  const serviceName = data?.serviceName || null;
  const serviceClient = data?.serviceClient || null;
  const typeCommission = data?.type?.[0] ?? null;
  const authorizatorName = data?.authorizatorName ?? null;


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  try {

    const serchParms = new URLSearchParams();

    if (username) {
      serchParms.append('username', username)
    }

    if (commissionType) {
      serchParms.append('commissionType', commissionType)
    }

    if (typeCommission) {
      serchParms.append('typeCommission', typeCommission)
    }

    if (authorizatorName) {
      serchParms.append('authorizatorName', authorizatorName)
    }

    if (paymentState) {
      serchParms.append('paymentState', paymentState)
    }

    if (paymentYear) {
      serchParms.append('paymentYear', paymentYear)
    }

    if (paymentId) {
      serchParms.append('paymentId', paymentId)
    }

    if (serviceClient) {
      serchParms.append('serviceClient', serviceClient)
    }

    if (serviceName) {
      serchParms.append('serviceName', serviceName)
    }

    if (idServiceCRM) {
      serchParms.append('idServiceCRM', idServiceCRM)
    }

    if (serviceId) {
      serchParms.append('IdService', serviceId)
    }


    if (saleMonth) {
      serchParms.append('saleMonth', saleMonth)
    }

    if (paymentMonth) {
      serchParms.append('paymentMonth', paymentMonth)
    }

    if (saleYear) {
      serchParms.append('saleYear', saleYear)
    }


    //! PAGINATION
    if (pageNumber) {
      serchParms.append('pageNumber', pageNumber)
    }

    if (pageSize) {
      serchParms.append('rowsPerPage', pageSize)
    }

    if (sortOrder) {
      serchParms.append('sortOrder', sortOrder)
    }

    if (sortField) {
      serchParms.append('sortField', sortField)
    }


    const response = await fetchGET(`Commission/DownloadCommissions?&authorizationState=approved&commissionState=completed&${serchParms.toString()}`, { responseType: 'blob' })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'commissionApprovedPaidSysDocument.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);


    return response


  } catch (error) {
    throw error
  }
}


export const UseDownloadFileCommissionApprovedSys = (callbackSuccess: any) => {
  return useMutation(downloadFileCommissionApprovedSys, {
    onSuccess(data) {
      callbackSuccess(data)
    },
  })
}

const downloadFileCommissionPaidSys = async (data: any) => {

  const username = data?.username ?? null;
  const commissionType = data?.commissionType?.[0] ?? null;
  const paymentId = data?.paymentId?.[0] ?? null;
  const saleMonth = data?.saleMonth?.[0] ?? null;
  const saleYear = data?.saleYear?.[0] ?? null;
  const paymentMonth = data?.paymentMonth?.[0] ?? null;
  const paymentYear = data?.paymentYear?.[0] ?? null;
  const paymentState = data?.paymentState?.[0] ?? null;
  const serviceId = data?.idService?.length >= 5 ? data.idService : null;
  const idServiceCRM = data?.idServiceCRM?.[0] ?? null;
  const serviceName = data?.serviceName || null;
  const serviceClient = data?.serviceClient || null;
  const typeCommission = data?.type?.[0] ?? null;
  const authorizatorName = data?.authorizatorName ?? null;


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  try {

    const serchParms = new URLSearchParams();

    if (username) {
      serchParms.append('username', username)
    }

    if (commissionType) {
      serchParms.append('commissionType', commissionType)
    }

    if (typeCommission) {
      serchParms.append('typeCommission', typeCommission)
    }

    if (authorizatorName) {
      serchParms.append('authorizatorName', authorizatorName)
    }

    if (paymentState) {
      serchParms.append('paymentState', paymentState)
    }

    if (paymentYear) {
      serchParms.append('paymentYear', paymentYear)
    }

    if (paymentId) {
      serchParms.append('paymentId', paymentId)
    }

    if (serviceClient) {
      serchParms.append('serviceClient', serviceClient)
    }

    if (serviceName) {
      serchParms.append('serviceName', serviceName)
    }

    if (idServiceCRM) {
      serchParms.append('idServiceCRM', idServiceCRM)
    }

    if (serviceId) {
      serchParms.append('IdService', serviceId)
    }


    if (saleMonth) {
      serchParms.append('saleMonth', saleMonth)
    }

    if (paymentMonth) {
      serchParms.append('paymentMonth', paymentMonth)
    }

    if (saleYear) {
      serchParms.append('saleYear', saleYear)
    }


    //! PAGINATION
    if (pageNumber) {
      serchParms.append('pageNumber', pageNumber)
    }

    if (pageSize) {
      serchParms.append('rowsPerPage', pageSize)
    }

    if (sortOrder) {
      serchParms.append('sortOrder', sortOrder)
    }

    if (sortField) {
      serchParms.append('sortField', sortField)
    }


    const response = await fetchGET(`Commission/DownloadCommissions?&authorizationState=approved&commissionState=completed&paymentState=approved&${serchParms.toString()}`, { responseType: 'blob' })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'commissionApprovedPaidSysDocument.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);


    return response


  } catch (error) {
    throw error
  }
}


export const UseDownloadFileCommissionPaidSys = (callbackSuccess: any) => {
  return useMutation(downloadFileCommissionPaidSys, {
    onSuccess(data) {
      callbackSuccess(data)
    },
  })
}

const downloadFileCommissionRejectedSys = async (data: any) => {

  const username = data?.username ?? null;
  const commissionType = data?.commissionType?.[0] ?? null;
  const saleMonth = data?.saleMonth?.[0] ?? null;
  const saleYear = data?.saleYear?.[0] ?? null;
  const paymentYear = data?.paymentYear?.[0] ?? null;
  const paymentMonth = data?.paymentMonth?.[0] ?? null;
  const serviceId = data?.idService?.length >= 5 ? data.idService : null;
  const idServiceCRM = data?.idServiceCRM?.[0] ?? null;
  const serviceName = data?.serviceName || null;
  const serviceClient = data?.serviceClient || null;
  const typeCommission = data?.type?.[0] ?? null;
  const authorizatorName = data?.authorizatorName ?? null;

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  try {

    const serchParms = new URLSearchParams();

    if (username) {
      serchParms.append('username', username)
    }

    if (commissionType) {
      serchParms.append('commissionType', commissionType)
    }

    if (typeCommission) {
      serchParms.append('typeCommission', typeCommission)
    }

    if (authorizatorName) {
      serchParms.append('authorizatorName', authorizatorName)
    }


    if (paymentYear) {
      serchParms.append('paymentYear', paymentYear)
    }



    if (serviceClient) {
      serchParms.append('serviceClient', serviceClient)
    }

    if (serviceName) {
      serchParms.append('serviceName', serviceName)
    }

    if (idServiceCRM) {
      serchParms.append('idServiceCRM', idServiceCRM)
    }

    if (serviceId) {
      serchParms.append('IdService', serviceId)
    }


    if (saleMonth) {
      serchParms.append('saleMonth', saleMonth)
    }

    if (paymentMonth) {
      serchParms.append('paymentMonth', paymentMonth)
    }

    if (saleYear) {
      serchParms.append('saleYear', saleYear)
    }


    //! PAGINATION
    if (pageNumber) {
      serchParms.append('pageNumber', pageNumber)
    }

    if (pageSize) {
      serchParms.append('rowsPerPage', pageSize)
    }

    if (sortOrder) {
      serchParms.append('sortOrder', sortOrder)
    }

    if (sortField) {
      serchParms.append('sortField', sortField)
    }


    const response = await fetchGET(`Commission/DownloadCommissions?&authorizationState=rejected&commissionState=completed&${serchParms.toString()}`, { responseType: 'blob' })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'commissionRejectedSysDocument.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);


    return response


  } catch (error) {
    throw error
  }
}


export const UseDownloadFileCommissionRejectedSys = (callbackSuccess: any) => {
  return useMutation(downloadFileCommissionRejectedSys, {
    onSuccess(data) {
      callbackSuccess(data)
    },
  })
}


const downloadFileCommissionPendingSys = async (data: any) => {

  const username = data?.username ?? null;
  const commissionType = data?.commissionType?.[0] ?? null;
  const saleMonth = data?.saleMonth?.[0] ?? null;
  const saleYear = data?.saleYear?.[0] ?? null;
  const paymentYear = data?.paymentYear?.[0] ?? null;
  const paymentMonth = data?.paymentMonth?.[0] ?? null;
  const serviceId = (data?.idService?.length >= 5 && data.idService !== '') ? data.idService : null;
  const idServiceCRM = data?.idServiceCRM?.[0] ?? null;
  const serviceName = data?.serviceName || null;
  const serviceClient = data?.serviceClient || null;
  const typeCommission = data?.type?.[0] ?? null;
  const authorizatorName = data?.authorizatorName ?? null;


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  try {

    const serchParms = new URLSearchParams();

    if (username) {
      serchParms.append('username', username)
    }

    if (commissionType) {
      serchParms.append('commissionType', commissionType)
    }

    if (typeCommission) {
      serchParms.append('typeCommission', typeCommission)
    }

    if (authorizatorName) {
      serchParms.append('authorizatorName', authorizatorName)
    }


    if (paymentYear) {
      serchParms.append('paymentYear', paymentYear)
    }



    if (serviceClient) {
      serchParms.append('serviceClient', serviceClient)
    }

    if (serviceName) {
      serchParms.append('serviceName', serviceName)
    }

    if (idServiceCRM) {
      serchParms.append('idServiceCRM', idServiceCRM)
    }

    if (serviceId) {
      serchParms.append('IdService', serviceId)
    }


    if (saleMonth) {
      serchParms.append('saleMonth', saleMonth)
    }

    if (paymentMonth) {
      serchParms.append('paymentMonth', paymentMonth)
    }

    if (saleYear) {
      serchParms.append('saleYear', saleYear)
    }


    //! PAGINATION
    if (pageNumber) {
      serchParms.append('pageNumber', pageNumber)
    }

    if (pageSize) {
      serchParms.append('rowsPerPage', pageSize)
    }

    if (sortOrder) {
      serchParms.append('sortOrder', sortOrder)
    }

    if (sortField) {
      serchParms.append('sortField', sortField)
    }


    const response = await fetchGET(`Commission/DownloadCommissions?&authorizationState=pending&commissionState=inProcess&${serchParms.toString()}`, { responseType: 'blob' })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'commissionPendingSysDocument.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);


    return response


  } catch (error) {
    throw error
  }
}


export const UseDownloadFileCommissionPendingSys = (callbackSuccess: any) => {
  return useMutation(downloadFileCommissionPendingSys, {
    onSuccess(data) {
      callbackSuccess(data)
    },
  })
}


const downloadFileCommissionInProcessSys = async (data: any) => {

  const username = data?.username ?? null;
  const commissionType = data?.commissionType?.[0] ?? null;
  const saleMonth = data?.saleMonth?.[0] ?? null;
  const saleYear = data?.saleYear?.[0] ?? null;
  const paymentYear = data?.paymentYear?.[0] ?? null;
  const paymentMonth = data?.paymentMonth?.[0] ?? null;
  const serviceId = (data?.idService?.length >= 5 && data.idService !== '') ? data.idService : null;
  const idServiceCRM = data?.idServiceCRM?.[0] ?? null;
  const serviceName = data?.serviceName || null;
  const serviceClient = data?.serviceClient || null;
  const commissionDescription = data?.commissionDescription?.[0] ?? null;
  const typeCommission = data?.type?.[0] ?? null;


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  try {

    const serchParms = new URLSearchParams();

    if (username) {
      serchParms.append('username', username)
    }

    if (commissionType) {
      serchParms.append('commissionType', commissionType)
    }

    if (typeCommission) {
      serchParms.append('typeCommission', typeCommission)
    }

    if (commissionDescription) {
      serchParms.append('commissionDescription', commissionDescription)
    }


    if (paymentYear) {
      serchParms.append('paymentYear', paymentYear)
    }



    if (serviceClient) {
      serchParms.append('serviceClient', serviceClient)
    }

    if (serviceName) {
      serchParms.append('serviceName', serviceName)
    }

    if (idServiceCRM) {
      serchParms.append('idServiceCRM', idServiceCRM)
    }

    if (serviceId) {
      serchParms.append('IdService', serviceId)
    }


    if (saleMonth) {
      serchParms.append('saleMonth', saleMonth)
    }

    if (paymentMonth) {
      serchParms.append('paymentMonth', paymentMonth)
    }

    if (saleYear) {
      serchParms.append('saleYear', saleYear)
    }


    //! PAGINATION
    if (pageNumber) {
      serchParms.append('pageNumber', pageNumber)
    }

    if (pageSize) {
      serchParms.append('rowsPerPage', pageSize)
    }

    if (sortOrder) {
      serchParms.append('sortOrder', sortOrder)
    }

    if (sortField) {
      serchParms.append('sortField', sortField)
    }


    const response = await fetchGET(`Commission/DownloadCommissions?&commissionState=pending&${serchParms.toString()}`, { responseType: 'blob' })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'commissionInProccessSysDocument.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);


    return response


  } catch (error) {
    throw error
  }
}


export const UseDownloadFileCommissionInProcessSys = (callbackSuccess: any) => {
  return useMutation(downloadFileCommissionInProcessSys, {
    onSuccess(data) {
      callbackSuccess(data)
    },
  })
}



const recalculateCommission = async (data: { Id: number }[]) => {

  try {
    const response = await fetchPOST2('Support/ReCalculateCommissions', data)
    return response

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const customErr = {
        errorCode: error.response.data.code,
        message: error.response.data.message,
      }

      throw customErr
    }

  }
}





export const UseRecalculateCommissionSys = (callbackSuccess: any) => {
  return useMutation(recalculateCommission, {
    onSuccess(data) {
      callbackSuccess(data)
    },
  })
}
