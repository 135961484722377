import React, { useCallback, useState, useEffect, FC } from 'react'
import { useFormik } from 'formik'
import { UfinetButton, UfinetDropzone } from 'ufinet-web-components';
import { onFormikChanges, IFileUploadData } from 'ufinet-web-functions';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'utils/translation/Translation';
import { useMutationGetTemplatePendingApproval, usePostBulkFilePendingApproval } from 'request';
import * as Yup from 'yup'
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl'
import { ErrorResponse } from 'types/typesAndValues';


interface Props {
  hideUploadModal: () => void;
}

interface File {
  file: IFileUploadData
}


const BulkLoadPendingApprovalForm: FC<Props> = ({ hideUploadModal }) => {

  const intl = useIntl()
  const translate = useTranslation()




  const [showMessage, setShowMessage] = useState(false)

  useEffect(() => {

    if (showMessage) setShowMessage(false)

  }, [showMessage])



  const onChange = useCallback(onFormikChanges, [])



  const cbSuccessUploadBulkFile = ({ data }: any) => {
    toast.success(data.message);
    hideUploadModal()
  }

  const cbSuccessTemplateFile = (data: any) => {

  }



  const { mutate: uploadBulkFile, isLoading: isLoadingUploadBulkFile, isError, error: errorMessage } = usePostBulkFilePendingApproval(cbSuccessUploadBulkFile)
  const { mutate: downloadTemplate, isLoading: isLoadingTemplateFile } = useMutationGetTemplatePendingApproval(cbSuccessTemplateFile)




  const showToastErrMessage = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse;
      toast.error(`${intl.formatMessage({ id: "ERROR.MESSAGE" },)} ${errorResponse.errorCode}`, { toastId: 'Error1' });
      hideUploadModal()
    }
  }

  const dataFormSchema = Yup.object().shape({
    file: Yup.mixed()
      //@ts-ignore
      .test('file required', translate('ERROR.REQUIRED'), (value) => value.size > 0)
      .test('file type', translate('ERROR.FILE.EXTENSION'), (value) => {
        //@ts-ignore
        const fileExtension = value?.path?.split('.').pop()
        return fileExtension === undefined || fileExtension.includes(['xlsx']) || fileExtension.includes(['xls'])
      }),
  })



  const formik = useFormik<File>({
    initialValues: {
      file: {
        path: '',
        size: 0,
      },
    },
    validationSchema: dataFormSchema,
    onSubmit: (values: File) => {

      uploadBulkFile(values.file)
      // hideUploadModal && hideUploadModal()
    },
    validateOnChange: false,
    validateOnBlur: false,
  })


  return (
    <>
      {isError && showToastErrMessage()}
      {/* {showMessage && showToasMessage()} */}

      <form onSubmit={formik.handleSubmit}>
        <div>
          <div className="row py-10">
            <div className="col-12">
              <UfinetButton
                isDisabled={isLoadingTemplateFile}
                onClick={() => downloadTemplate()}
                className="w-100"
                content={translate('DOWNLOAD.TEMPLATE')}
                icon={faDownload}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <UfinetDropzone
                isDisabled={false}
                onChange={onChange(formik, 'file')}
                value={formik.values.file}
                error={formik.errors.file}
                text={translate('DROPZONE.UPLOAD_EXCEL.TITLE')}
                accept={{ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xls'] }}
              />
            </div>
          </div>

          <div className="row py-10">
            <div className="col-12">
              <UfinetButton
                isDisabled={isLoadingUploadBulkFile}
                type='submit'
                className="w-100"
                content={translate('SAVE')}
                icon={faSave}
              />
            </div>
          </div>


        </div>
      </form>
    </>
  )
}

export { BulkLoadPendingApprovalForm }


