import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import { Table, UfinetBox, UfinetButton, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection } from 'ufinet-web-components'
import { AuthContext, Authority, } from 'ufinet-web-functions'
import { colsRejectedCommisions } from './RejectedColData'
import { UseQueryRejectedCommissionsSystems, UseDownloadFileCommissionRejectedSys, UseRecalculateCommissionSys } from 'request'
import { Translation, useTranslation } from 'utils/translation/Translation'
import { DataTablePageEvent } from 'primereact/datatable'
import { CommissionSystemElement } from 'types/commissionSystemTypes'
import { faCalculator, faDownload } from '@fortawesome/free-solid-svg-icons'
import { ErrorResponse } from 'types/typesAndValues'

const RejectePage = () => {
  const intl = useIntl()
  const colsRejectPage = useMemo(() => colsRejectedCommisions(intl), [intl])
  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  const permissions = Authority.getCommissionCmSystemPermissions(roles)

  const translate = useTranslation()


  const [selectedValues, setSelectedValues] = useState<CommissionSystemElement[]>([])
  const [paging, setPaging] = useState<any>()
  const [filterData, setFilterData] = useState<any>()
  const { data: rejectedCommissions, isLoading: isLoadingRejectedCommission, refetch } = UseQueryRejectedCommissionsSystems(filterData)

  const isSelectedValues = selectedValues && selectedValues.length > 0


  const cbSuccessRejectedFile = (data: any) => {

  }

  const cbRecalculateCommission = ({ data }: any) => {
    toast.success(data.message)
    setSelectedValues([])
    refetch()
  }

  const { mutate: downloadFile, isLoading: isLoadingCommissionRejectedSys } = UseDownloadFileCommissionRejectedSys(cbSuccessRejectedFile)
  const { mutate: recalculateCommission, isLoading: isLoadingRecalculateCommissionSys, isError: isErrorRecalculate, error: errorMessage } = UseRecalculateCommissionSys(cbRecalculateCommission)

  const showToastErrMessage = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse;
      toast.error(`${translate("ERROR.MESSAGE")} ${errorResponse.errorCode}`, { toastId: 'Error5' });

    }
  }

  // To get de currencySymbol to show it at the top of the table
  const currencySymbol = rejectedCommissions?.data && rejectedCommissions?.data?.commissions.length > 0 ? rejectedCommissions?.data?.commissions[0]?.user.currencySymbol : null


  useEffect(() => {
    if (rejectedCommissions) {
      const parsePagination = JSON.parse(rejectedCommissions?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [rejectedCommissions])

  const onFilterClear = (): void => {
    setFilterData({})
  }

  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)
    setFilterData({
      ...filterData,
      ...formattedFilters
    })
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({ ...filterData, pageNumber: e.page, pageSize: e.rows })
  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })

  }

  const downloadFileNextPaymentSys = () => {
    downloadFile(filterData)
  }

  const recalculate = () => {
    if (isSelectedValues) {
      const newArr = selectedValues.map((item: CommissionSystemElement) => {
        return {
          Id: item.commission.id
        }
      })

      recalculateCommission(newArr)

    }

  }

  const onSelectionChange = useCallback((values: any[]) => {
    setSelectedValues(values)
  }, [])


  const getHeadersButtons = () => (
    <>
      {permissions.canWrite && (
        <UfinetButton
          className="me-3"
          icon={faDownload}
          onClick={() => downloadFileNextPaymentSys()}
          content={Translation('DOWNLOAD.FILE')}
        />
      )}
      {permissions.canWrite && isSelectedValues && (
        <UfinetButton
          isDisabled={isLoadingRecalculateCommissionSys}
          className="me-3"
          icon={faCalculator}
          onClick={() => recalculate()}
          content={Translation('RECALCULATE')}
        />
      )}
    </>
  )

  return (
    <>
      {isErrorRecalculate && showToastErrMessage()}
      <UfinetBox>

        <UfinetSectionBox>
          <Table
            dataKey='id'
            enableSearchFilter={false}
            selectedValues={selectedValues}
            onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            cols={colsRejectPage}
            content={rejectedCommissions?.data?.commissions?.map((item: CommissionSystemElement) => ({
              ...item,
              username: item.user.username,
              idService: item.service.idService,
              saleMonth: item.service.saleMonth,
              saleYear: item.service.saleYear,
              commissionType: item.commission.commissionType,
              exchangeRate: item.commission.exchangeRate,
              backlog: item.service.backlog,
              percentageCommission: item.commission.percentageCommission,
              baseAmount: item.commission.baseAmount,
              authorizatorName: item.authorization.authorizatorName,
              paymentMonth: item.commission.paymentMonth,
              paymentYear: item.commission.paymentYear,
              rejectedDate: item.authorization.rejectedDate,
              idServiceCRM: item.service.idServiceCRM,
              serviceName: item.service.serviceName,
              serviceClient: item.service.serviceClient,
              authorizationReason: item.authorization.authorizationReason,
              firstBillingDate: item.service.firstBillingDate,
              id: item.commission.id

            }))}
            actionBodyTemplate={<></>}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            emptyMessage={isLoadingRejectedCommission || isLoadingCommissionRejectedSys ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            onFilterClear={onFilterClear}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingRejectedCommission || isLoadingCommissionRejectedSys,
                onFilter,
                onPage,
                onSort,
              }
            }
            headerButtons={getHeadersButtons()}
          // emptyMessage={loadingRecords ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
          />
        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}

export default RejectePage


