import { ColData } from 'ufinet-web-components'
import { IntlShape } from 'react-intl'
import { commissionTypeMappings, CommissionType, CommissionMonth, commissionMonthMappings, CommissionCalculatingStatus, commissionCalculatingStatusMappings } from 'types/typesAndValues';
import { convertToLocalTZ, } from 'ufinet-web-functions'
import Badge from 'react-bootstrap/Badge';
import LevelBadge from 'lib/badges/LevelBadge';
import MonthBadge from 'lib/badges/MonthBadge'
import AchievementFactorBadge from 'lib/badges/AchievementFactorBadge'
import FormattedDate from 'lib/badges/FormattedDate';
import { Translation } from 'utils/translation/Translation';
import { InProcessTypes } from 'types/inProcessTypes';
import { MultiplicatorTypes } from 'types/multiplicatorKam';

export const colslMultiplicatorSystemCommission = (intl: IntlShape): ColData[] => [
  // header is the id to search the translate based on the data received
  // removing or typing the word false in the property nonFilterable is gonna show in the table header the filter
  // body you can customize the data you want to show, accept react component or just a simple html
  {
    field: 'username',
    header: 'COL.COMMISSION.KAM',
    width: '190px',
    editable: false,
    nonFilterable: false,
    nonSortable: false,
    filterType: 'text'
  },

  {
    field: 'month',
    header: 'COL.COMMISSION.MONTH',
    width: '190px',
    nonFilterable: false,
    nonSortable: false,
    filterOptions: [
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.JANUARY) }),
        value: CommissionMonth.JANUARY,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.FEBRUARY) }),
        value: CommissionMonth.FEBRUARY,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.MARCH) }),
        value: CommissionMonth.MARCH,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.APRIL) }),
        value: CommissionMonth.APRIL,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.MAY) }),
        value: CommissionMonth.MAY,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.JUNE) }),
        value: CommissionMonth.JUNE,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.JULY) }),
        value: CommissionMonth.JULY,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.AUGUST) }),
        value: CommissionMonth.AUGUST,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.SEPTEMBER) }),
        value: CommissionMonth.SEPTEMBER,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.OCTOBER) }),
        value: CommissionMonth.OCTOBER,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.NOVEMBER) }),
        value: CommissionMonth.NOVEMBER,
      },
      {
        label: intl.formatMessage({ id: commissionMonthMappings.get(CommissionMonth.DECEMBER) }),
        value: CommissionMonth.DECEMBER,
      },
    ],
    body: (row: any) => <>{Translation(row.month)}</>,
    editable: true,
  },
  { field: 'year', header: 'COL.COMMISSION.YEAR', width: '190px', nonFilterable: false, editable: false, nonSortable: false, filterType: 'number' },
  {
    field: 'multiplierFactor',
    header: 'COL.COMMISSION.MULTIPLICATOR',
    width: '190px',
    nonFilterable: true,
    editable: true,
    nonSortable: false,
  },
  {
    field: 'achievementAmount', header: 'COL.COMMISSION.ACHIEVEMENT.AMOUNT', width: '220px', nonFilterable: true, nonSortable: false,
    body: (row: MultiplicatorTypes) => <>{`${row.achievementAmount} ${row.currencyCode}`}</>
  },
  {
    field: 'achievementPeriodicityAmount', header: 'COL.COMMISSION.ACHIEVEMENT.PERIODICITY.AMOUNT', width: '250px', nonFilterable: true, editable: true, nonSortable: false,
    body: (row: MultiplicatorTypes) => <>{`${row.achievementPeriodicityAmount} ${row.currencyCode}`}</>
  },
  {
    field: 'goalsPeriodicityAmount', header: 'COL.COMMISSION.GOALS.PERIODICITY.AMOUNT', width: '220px', nonFilterable: true, editable: true, nonSortable: false,
    body: (row: MultiplicatorTypes) => <>{`${row.goalsPeriodicityAmount} ${row.currencyCode}`}</>
  },
  { field: 'percentageAchievement', header: 'COL.COMMISSION.PERCENTANGE.ACHIEVEMENT', width: '190px', nonFilterable: true, editable: true, nonSortable: false, },

  { field: 'initialDatePeriod', header: 'COL.COMMISSION.INITIAL.DATE', width: '250px', nonFilterable: false, editable: true, nonSortable: false, },
  { field: 'endDatePeriod', header: 'COL.COMMISSION.END.DATE', width: '250px', nonFilterable: false, editable: true, nonSortable: false, },
]
