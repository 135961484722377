import { MultiSelectAllEvent, MultiSelectChangeEvent } from 'primereact/multiselect';
import React, { SetStateAction, useEffect, useState } from 'react'
import { UseGetSysClients, UseGetSysKams } from 'request'
import { ClientList, KamList } from 'types/authorization';
import { useDebounce } from 'use-debounce';


export interface InputProps {
  username: string
  name: string
}

interface Props {
  setFilterData?: SetStateAction<any>
  filterData?: any
}


export enum SelectName {
  KAM = 'kam',
  CLIENT = 'client'
}

export const useFiltersAuthorizationSys = ({ filterData, setFilterData }: Props) => {


  const [input, setInput] = useState<InputProps>({
    username: '',
    name: ''
  })



  const clearInputs = () => {
    setInput({
      username: '',
      name: ''
    })
  }


  const [debouncedKamsInput] = useDebounce(input.username, 500);
  const [debouncedClientInput] = useDebounce(input.name, 500);





  //? Authorization Sys
  const { data: dataAuthorizatorSys, isLoading: isLoadingDataAuthorizator } = UseGetSysKams({ username: debouncedKamsInput })
  const { data: dataClientsSys, isLoading: isLoadingDataClients } = UseGetSysClients({ name: debouncedClientInput })



  const kamsSelect = dataAuthorizatorSys &&
    [...dataAuthorizatorSys?.data.map((item: KamList) => (
      { label: item.username, value: item.id }
    ))]




  const clientsSelect = dataClientsSys &&
    [...dataClientsSys.data.map((item: ClientList) => (
      { label: item.name, value: item.id }
    ))]



  const [selectAllKam, setSelectAllKam] = useState(false)
  const [selectAllClient, setSelectAllClient] = useState(false)
  const [multiSelectKam, setMultiSelectKam] = useState<string[] | undefined>([])
  const [multiSelectClient, setMultiSelectClient] = useState<string[] | undefined>([])


  const username = multiSelectKam
  const serviceClient = multiSelectClient


  useEffect(() => {

    if (clientsSelect && multiSelectClient && clientsSelect.length == multiSelectClient.length) {

      setFilterData({
        ...filterData,
        username,
        serviceClient
      })
    }


    setFilterData({
      ...filterData,
      username,
      serviceClient
    })

  }, [multiSelectClient])

  useEffect(() => {

    if (kamsSelect && multiSelectKam && kamsSelect.length == multiSelectKam.length) {

      setFilterData({
        ...filterData,
        username,
        serviceClient
      })
    }


    setFilterData({
      ...filterData,
      username,
      serviceClient
    })
  }, [multiSelectKam])

  const cleanSelectKamAndClient = () => {
    setSelectAllKam(false)
    setSelectAllClient(false)
    setMultiSelectKam([])
    setMultiSelectClient([])
  }



  const handleMultiSelect = (event: MultiSelectChangeEvent, id: SelectName) => {

    if (id === SelectName.KAM) {
      setMultiSelectKam(event.value)
      setSelectAllKam(event.value.length === kamsSelect?.length)

    }
    if (id === SelectName.CLIENT) {
      setMultiSelectClient(event.value)
      setSelectAllClient(event.value.length === clientsSelect?.length)
    }

  }


  const onSelectAll = (e: MultiSelectAllEvent, id: SelectName) => {

    if (id === SelectName.KAM) {
      setMultiSelectKam(e.checked ? [] : kamsSelect?.map((item) => item.value))
      setSelectAllKam(!e.checked)
    }

    if (id === SelectName.CLIENT) {
      setMultiSelectClient(e.checked ? [] : clientsSelect?.map((item) => item.value))
      setSelectAllClient(!e.checked)

    }

  }



  return {
    kamsSelect,
    clientsSelect,
    input,
    isLoadingDataAuthorizator,
    isLoadingDataClients,
    clearInputs,
    handleMultiSelect,
    multiSelectKam,
    multiSelectClient,
    cleanSelectKamAndClient,
    username,
    serviceClient,
    selectAllKam,
    selectAllClient,
    setMultiSelectKam,
    setMultiSelectClient,
    setSelectAllKam,
    setSelectAllClient,
    onSelectAll,
  }

}
