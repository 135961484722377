import React, { useRef, useCallback, useContext, useState, useMemo } from 'react'
import { useFormik } from 'formik'
import { UfinetButton, UfinetDropzone, UfinetActionButton, UfinetActionButtonHandle, UfinetSelect, IUfinetSelect, UfinetSelectOptionValue, IUfinetSelectOption, ISelect, emptyUfinetSelectOption, UfinetInput } from 'ufinet-web-components';
import { onFormikChanges, IFileUploadData, AuthContext, onFormikNumberChanges } from 'ufinet-web-functions';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { Translation } from 'utils/translation/Translation';
import { UseUpdatePercentageTypeCommission } from 'request';
import * as Yup from 'yup'
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { ClientTypeName, CommissionType, ErrorResponse, ServiceTypeName, commissionClientTypeNameMappings, commissionServiceTypeNameServiceMappings, commissionTypeMappings } from 'types/typesAndValues';
import { useTranslation } from 'utils/translation/Translation';
import { PercentageTypeCommision } from '../../../../../types/configuration/ConfigurationTypes';

interface Props {
  updatedData: PercentageTypeCommision | undefined;
  hideModal: () => void;
  refetch: () => void
}

interface File {
  file: IFileUploadData
}



type CreateFormData = {
  id: string
  serviceType: IUfinetSelectOption
  commissionType: IUfinetSelectOption
  clientType: IUfinetSelectOption
  commissionPercentage: number
}

const UpdatePercentangeTypeCommissionForm = ({ updatedData, hideModal, refetch }: Props) => {




  const translate = useTranslation()
  const intl = useIntl()

  const serviceTypeData = [
    {
      label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.NOSERVICE) }),
      value: ServiceTypeName.NOSERVICE,
    },
    {
      label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.ETHCAPACITYTRANSPORT) }),
      value: ServiceTypeName.ETHCAPACITYTRANSPORT,
    },
    {
      label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.INTERNET) }),
      value: ServiceTypeName.INTERNET,
    },
    {
      label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.DARKFIBER) }),
      value: ServiceTypeName.DARKFIBER,
    },
    {
      label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.COLOCATION) }),
      value: ServiceTypeName.NOSERVICE,
    },
    {
      label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.INFRASTRUCTURE) }),
      value: ServiceTypeName.INFRASTRUCTURE,
    },
    {
      label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.TDMCAPACITYTRANSPORT) }),
      value: ServiceTypeName.TDMCAPACITYTRANSPORT,
    },
    {
      label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.DWDMMCAPACITYTRANSPORT) }),
      value: ServiceTypeName.DWDMMCAPACITYTRANSPORT,
    },
    {
      label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.CAPACITY) }),
      value: ServiceTypeName.CAPACITY,
    },
    {
      label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.TIMELY) }),
      value: ServiceTypeName.TIMELY,
    },
    {
      label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.VSAT) }),
      value: ServiceTypeName.VSAT,
    },
    {
      label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.EQUIPMENT) }),
      value: ServiceTypeName.EQUIPMENT,
    },
    {
      label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.ENERGY) }),
      value: ServiceTypeName.ENERGY,
    },
    {
      label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.CROSSCONNECTION) }),
      value: ServiceTypeName.CROSSCONNECTION,
    },
    {
      label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.FTTH) }),
      value: ServiceTypeName.FTTH,
    },
    {
      label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.TOWERING) }),
      value: ServiceTypeName.TOWERING,
    },
    {
      label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.CLOUD) }),
      value: ServiceTypeName.CLOUD,
    },
    {
      label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.SECURITY) }),
      value: ServiceTypeName.SECURITY,
    },
    {
      label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.GPON_FTTH) }),
      value: ServiceTypeName.GPON_FTTH,
    },
    {
      label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.REMOTEHANDS) }),
      value: ServiceTypeName.REMOTEHANDS,
    },
    {
      label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.OUTSOURCING) }),
      value: ServiceTypeName.OUTSOURCING,
    },
    {
      label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.OTHERS) }),
      value: ServiceTypeName.OTHERS,
    },
    {
      label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.UNDERGROUNDCHAMBER) }),
      value: ServiceTypeName.UNDERGROUNDCHAMBER,
    },
    {
      label: intl.formatMessage({ id: commissionServiceTypeNameServiceMappings.get(ServiceTypeName.NOTREPORTED) }),
      value: ServiceTypeName.NOTREPORTED,
    },
  ]


  const commissionTypeData = [
    {
      label: intl.formatMessage({ id: commissionTypeMappings.get(CommissionType.ADJUDGE) }),
      value: CommissionType.ADJUDGE,
    },
    {
      label: intl.formatMessage({ id: commissionTypeMappings.get(CommissionType.DELIVERY) }),
      value: CommissionType.DELIVERY,
    },
    {
      label: intl.formatMessage({ id: commissionTypeMappings.get(CommissionType.BILLING) }),
      value: CommissionType.BILLING,
    },
    {
      label: intl.formatMessage({ id: commissionTypeMappings.get(CommissionType.CEASE) }),
      value: CommissionType.CEASE,
    },
  ]


  const clientTypeData = [
    {
      label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.GOVERNMENT) }),
      value: ClientTypeName.GOVERNMENT,
    },
    {
      label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.OPERATOR) }),
      value: ClientTypeName.OPERATOR,
    },
    {
      label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.OTHERS) }),
      value: ClientTypeName.OTHERS,
    },
    {
      label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.PROVIDER) }),
      value: ClientTypeName.PROVIDER,
    },
    {
      label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.OTT) }),
      value: ClientTypeName.OTT,
    },
    {
      label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.BPO) }),
      value: ClientTypeName.BPO,
    },
    {
      label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.ELECTRIC) }),
      value: ClientTypeName.ELECTRIC,
    },
    {
      label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.CORPORATE) }),
      value: ClientTypeName.CORPORATE,
    },
    {
      label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.DATACENTER) }),
      value: ClientTypeName.DATACENTER,
    },
    {
      label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.INTEGRATOR) }),
      value: ClientTypeName.INTEGRATOR,
    },
    {
      label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.LOCALOPERATOR) }),
      value: ClientTypeName.LOCALOPERATOR,
    },
    {
      label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.INDIRECTCLIENT) }),
      value: ClientTypeName.INDIRECTCLIENT,
    },
    {
      label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.UFINETGROUP) }),
      value: ClientTypeName.UFINETGROUP,
    },
    {
      label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.GLOBALOPERATOR) }),
      value: ClientTypeName.GLOBALOPERATOR,
    },
    {
      label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.REST) }),
      value: ClientTypeName.REST,
    },
    {
      label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.EXTERNAL) }),
      value: ClientTypeName.EXTERNAL,
    },
    {
      label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.POLEINFRASTRUCTURE) }),
      value: ClientTypeName.POLEINFRASTRUCTURE,
    },
    {
      label: intl.formatMessage({ id: commissionClientTypeNameMappings.get(ClientTypeName.ISP) }),
      value: ClientTypeName.ISP,
    },
  ]



  const [file, setFile] = useState()

  const authData = useContext(AuthContext)
  const userEmail = authData.userData?.username

  const actionButtonRef = useRef<UfinetActionButtonHandle>(null)




  const cbSuccessUpdatePercentageTypeCommission = (data: any) => {
    toast.success(intl.formatMessage({ id: 'SENDED' }))
    actionButtonRef.current?.changeActionStatus(false)
    refetch()
    hideModal()
  }

  const { mutate: updatePercentange, isError, error: errorMessage } = UseUpdatePercentageTypeCommission(cbSuccessUpdatePercentageTypeCommission)


  // const showErrMessage = () => {
  //   if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
  //     const errorResponse = errorMessage as ErrorResponse;
  //     toast.error(`${intl.formatMessage({ id: "ERROR.MESSAGE" },)} ${errorResponse.errorCode}`, { toastId: 'Error2' });
  //     hideLoadModal()
  //   }
  // }

  const dataFormSchema = Yup.object().shape({
    id: Yup.number().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
    serviceType: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
    commissionType: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
    clientType: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
    commissionPercentage: Yup.number().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
  })





  const sendData = (values: any) => {
    actionButtonRef.current?.changeActionStatus(true)
    updatePercentange({
      id: +values.id,
      commissionType: values.commissionType.value,
      serviceType: values.serviceType.value,
      clientType: values.clientType.value,
      commissionPercentage: +values.commissionPercentage,
    })
  }

  const onChange = useCallback(onFormikChanges, [])
  const onNumberChange = useCallback(onFormikNumberChanges, [])


  const initialValues: CreateFormData = useMemo<CreateFormData>(
    () => ({
      id: updatedData && updatedData.id ? updatedData.id.toString() : '',
      serviceType: updatedData && updatedData.serviceType ? { label: updatedData.serviceType, value: updatedData.serviceType } : emptyUfinetSelectOption,
      commissionType: updatedData && updatedData.commissionType ? { label: intl.formatMessage({ id: updatedData.commissionType }), value: updatedData.commissionType } : emptyUfinetSelectOption,
      clientType: updatedData && updatedData.clientType ? { label: updatedData.clientType, value: updatedData.clientType } : emptyUfinetSelectOption,
      commissionPercentage: updatedData && updatedData.commissionPercentage ? updatedData.commissionPercentage : 0,
    }),
    []
  )



  const formik = useFormik<CreateFormData>({
    initialValues,
    validationSchema: dataFormSchema,
    onSubmit: sendData,
    validateOnChange: false,
    validateOnBlur: false,
  })


  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='row'>
        <div className='row'>
          <UfinetInput
            requiredIcon
            error={formik.errors.id}
            type="decimal"
            labelTitle={translate('COL.CONFIG.ID')}
            className="col-6"
            onChange={onNumberChange(formik, 'id')}
            value={formik.values.id}
            solid={false}
          />

          <UfinetSelect
            className='col-6'
            isClearable
            requiredIcon
            value={formik.values.serviceType}
            error={formik.errors.serviceType?.label}
            labelTitle={translate('COL.GLOBAL.SERVICE.TYPE.NAME')}
            onChange={onChange(formik, 'serviceType')}
            placeholder={translate('COL.GLOBAL.SERVICE.TYPE.NAME')}
            options={serviceTypeData}
          />


          <div className='row mt-5'>
            <UfinetSelect
              className='col-6'
              requiredIcon
              value={formik.values.commissionType}
              error={formik.errors.commissionType?.label}
              labelTitle={translate('COL.COMMISSION.COMMISSIONTYPE')}
              onChange={onChange(formik, 'commissionType')}
              placeholder={translate('COL.COMMISSION.COMMISSIONTYPE')}
              options={commissionTypeData}
            />
            <UfinetSelect
              requiredIcon
              className='col-6'
              value={formik.values.clientType}
              error={formik.errors.clientType?.label}
              labelTitle={translate('COL.GLOBAL.TYPE.NAME')}
              onChange={onChange(formik, 'clientType')}
              placeholder={translate('COL.GLOBAL.TYPE.NAME')}
              options={clientTypeData}
            />



          </div>

          <div className='row mt-5'>
            <UfinetInput
              requiredIcon
              error={formik.errors.commissionPercentage}
              type="decimal"
              labelTitle={translate('COL.GLOBAL.PERCENTAGE')}
              className="col-6"
              onChange={onNumberChange(formik, 'commissionPercentage')}
              value={formik.values.commissionPercentage}
              solid={false}
            />

          </div>


          <UfinetActionButton
            ref={actionButtonRef}
            className='mt-5'
            content={translate('UPDATE.COMMISSION.TYPE')}
          // onClick={downLoadTemplate}
          />
        </div>
      </div>
    </form>
  )
}

export { UpdatePercentangeTypeCommissionForm }


