import { ColData } from 'ufinet-web-components'
import { IntlShape } from 'react-intl'
import { AuthorizationUserTypes } from 'types/authorization';

export const colsResumeColData = (intl: IntlShape): ColData[] => [
  // header is the id to search the translate based on the data received
  // removing or typing the word false in the property nonFilterable is gonna show in the table header the filter
  // body you can customize the data you want to show, accept react component or just a simple html
  {
    field: 'username',
    header: 'COL.COMMISSION.KAM',
    width: '190px',
    editable: false,
    nonFilterable: false,
    nonSortable: false,
    filterType: 'text',
  },
  { field: 'pendingApprovals', header: 'COL.COMMISSION.PEDINGAPPROVAL', width: '190px', nonFilterable: true, editable: false, nonSortable: false, },
  {
    field: 'amountCommission', header: 'COL.COMMISSION.AMOUNTCOMMISION', width: '190px', nonFilterable: true, editable: false, nonSortable: false,
    body: (row: AuthorizationUserTypes) => <>{`${row.amountCommission} USD`}</>
  },
  {
    field: 'amountDecommission', header: 'COL.COMMISSION.AMOUNTDECOMMISSION', width: '190px', nonFilterable: true, editable: false, nonSortable: false,
    body: (row: AuthorizationUserTypes) => <>{`${row.amountDecommission} USD`}</>
  },
  {
    field: 'amountPendingAuthorize', header: 'COL.COMMISSION.AMOUNTAUTHORIZE', width: '190px', nonFilterable: true, editable: false, nonSortable: false,
    body: (row: AuthorizationUserTypes) => <>{`${row.amountPendingAuthorize} USD`}</>
  },
]


