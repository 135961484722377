import { useMutation, useQuery } from "react-query"
import { fetchGET2 } from "utils/https/https"

export const UseQueryApprovedCommissions = (data: any) => {

  const commissionType = data?.commissionType && data?.commissionType.length > 0 ? data?.commissionType[0] : null
  const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
  const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null
  const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
  const paymentYear = data?.paymentYear && data?.paymentYear.length > 0 ? data?.paymentYear[0] : null
  const serviceId = data?.idService && data?.idService !== '' && data?.idService.length >= 5 ? data?.idService : null
  const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
  const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
  const serviceClient = data?.serviceClient && data?.serviceClient !== '' ? data?.serviceClient : null
  const typeCommission = data?.type && data?.type.length > 0 ? data?.type[0] : null
  const authorizatorName = data?.authorizatorName && data?.authorizatorName.length > 0 ? data?.authorizatorName[0] : null

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  return useQuery(['UseQueryApprovedCommissions', commissionType, saleMonth, saleYear, idServiceCRM, serviceClient, serviceName, paymentMonth, , paymentYear, serviceId, typeCommission, authorizatorName, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (commissionType) {
        serchParms.append('commissionType', commissionType)
      }

      if (idServiceCRM) {
        serchParms.append('idServiceCRM', idServiceCRM)
      }

      if (serviceName) {
        serchParms.append('serviceName', serviceName)
      }

      if (serviceClient) {
        serchParms.append('serviceClient', serviceClient)
      }

      if (typeCommission) {
        serchParms.append('type', typeCommission)
      }

      if (saleMonth) {
        serchParms.append('saleMonth', saleMonth)
      }

      if (saleYear) {
        serchParms.append('saleYear', saleYear)
      }

      if (paymentMonth) {
        serchParms.append('paymentMonth', paymentMonth)
      }

      if (paymentYear) {
        serchParms.append('paymentYear', paymentYear)
      }

      if (serviceId) {
        serchParms.append('IdService', serviceId)
      }

      if (authorizatorName) {
        serchParms.append('authorizatorName', authorizatorName)
      }

      // Pagination

      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('rowsPerPage', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }

      const response = await fetchGET2(`Commission/ListCommissions?&authorizationState=approved&commissionState=completed&${serchParms.toString()}`)
      return response
    } catch (error) {
      throw error
    }
  })
}


export const UseQueryPaidCommissions = (data: any) => {

  const commissionType = data?.commissionType && data?.commissionType.length > 0 ? data?.commissionType[0] : null
  const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
  const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null
  const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
  const paymentYear = data?.paymentYear && data?.paymentYear.length > 0 ? data?.paymentYear[0] : null
  const serviceId = data?.idService && data?.idService !== '' && data?.idService.length >= 5 ? data?.idService : null
  const paymentId = data?.paymentId && data?.paymentId !== '' ? data?.paymentId : null
  const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
  const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
  const serviceClient = data?.serviceClient && data?.serviceClient !== '' ? data?.serviceClient : null
  const typeCommission = data?.type && data?.type.length > 0 ? data?.type[0] : null
  const authorizatorName = data?.authorizatorName && data?.authorizatorName.length > 0 ? data?.authorizatorName[0] : null

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  return useQuery(['UseQueryPaidCommissions', commissionType, saleMonth, paymentId, saleYear, idServiceCRM, serviceClient, serviceName, paymentMonth, , paymentYear, serviceId, typeCommission, authorizatorName, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (commissionType) {
        serchParms.append('commissionType', commissionType)
      }

      if (paymentId) {
        serchParms.append('paymentId', paymentId)
      }

      if (idServiceCRM) {
        serchParms.append('idServiceCRM', idServiceCRM)
      }

      if (serviceName) {
        serchParms.append('serviceName', serviceName)
      }

      if (serviceClient) {
        serchParms.append('serviceClient', serviceClient)
      }

      if (typeCommission) {
        serchParms.append('type', typeCommission)
      }

      if (saleMonth) {
        serchParms.append('saleMonth', saleMonth)
      }

      if (saleYear) {
        serchParms.append('saleYear', saleYear)
      }

      if (paymentMonth) {
        serchParms.append('paymentMonth', paymentMonth)
      }

      if (paymentYear) {
        serchParms.append('paymentYear', paymentYear)
      }

      if (serviceId) {
        serchParms.append('IdService', serviceId)
      }

      if (authorizatorName) {
        serchParms.append('authorizatorName', authorizatorName)
      }

      // Pagination

      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('rowsPerPage', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }

      // 

      const response = await fetchGET2(`Commission/ListCommissions?&authorizationState=approved&commissionState=completed&paymentState=approved&${serchParms.toString()}`)
      return response
    } catch (error) {
      throw error
    }
  })
}


export const UseQueryInProcessCommissions = (data: any) => {
  const commissionType = data?.commissionType && data?.commissionType.length > 0 ? data?.commissionType[0] : null
  const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
  const serviceId = data?.idService && data?.idService !== '' && data?.idService.length >= 5 ? data?.idService : null
  const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
  const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
  const serviceClient = data?.serviceClient && data?.serviceClient !== '' ? data?.serviceClient : null
  const commissionDescription = data?.commissionDescription && data?.commissionDescription.length > 0 ? data?.commissionDescription[0] : null
  const typeCommission = data?.type && data?.type.length > 0 ? data?.type[0] : null

  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null


  return useQuery(['UseQueryInProcessCommissions', commissionType, saleMonth, serviceId, idServiceCRM, serviceName, serviceClient, commissionDescription, typeCommission, pageNumber, pageSize, sortOrder, sortField], async () => {
    try {
      const serchParms = new URLSearchParams();

      if (commissionType) {
        serchParms.append('commissionType', commissionType)
      }

      if (serviceClient) {
        serchParms.append('serviceClient', serviceClient)
      }

      if (serviceName) {
        serchParms.append('serviceName', serviceName)
      }

      if (idServiceCRM) {
        serchParms.append('idServiceCRM', idServiceCRM)
      }

      if (typeCommission) {
        serchParms.append('type', typeCommission)
      }

      if (saleMonth) {
        serchParms.append('saleMonth', saleMonth)
      }

      if (serviceId) {
        serchParms.append('IdService', serviceId)
      }

      if (commissionDescription) {
        serchParms.append('commissionDescription', commissionDescription)
      }

      if (pageNumber) {
        serchParms.append('pageNumber', pageNumber)
      }

      if (pageSize) {
        serchParms.append('rowsPerPage', pageSize)
      }

      if (sortOrder) {
        serchParms.append('sortOrder', sortOrder)
      }

      if (sortField) {
        serchParms.append('sortField', sortField)
      }

      const response = await fetchGET2(`Commission/ListCommissions?&commissionState=pending&${serchParms.toString()}`)
      return response
    } catch (error) {
      throw error
    }
  })
}


export // To get the data at next payment page
  const UseQueryNextPaymentCommissions = (data: any) => {
    const commissionType = data?.commissionType && data?.commissionType.length > 0 ? data?.commissionType[0] : null
    const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
    const serviceId = data?.idService && data?.idService !== '' && data?.idService.length >= 5 ? data?.idService : null
    const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
    const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
    const serviceClient = data?.serviceClient && data?.serviceClient !== '' ? data?.serviceClient : null
    const commissionDescription = data?.commissionDescription && data?.commissionDescription.length > 0 ? data?.commissionDescription[0] : null
    const typeCommission = data?.type && data?.type.length > 0 ? data?.type[0] : null

    const pageNumber = data?.pageNumber
    const pageSize = data?.pageSize
    const sortOrder = data?.sortOrder ? data.sortOrder : null
    const sortField = data?.sortField ? data.sortField : null

    return useQuery(['UseQueryNextPaymentCommissions', commissionType, saleMonth, serviceId, idServiceCRM, serviceName, serviceClient, commissionDescription, typeCommission, pageNumber, pageSize, sortOrder, sortField], async () => {
      try {
        const serchParms = new URLSearchParams();

        if (commissionType) {
          serchParms.append('commissionType', commissionType)
        }

        if (serviceClient) {
          serchParms.append('serviceClient', serviceClient)
        }

        if (serviceName) {
          serchParms.append('serviceName', serviceName)
        }

        if (idServiceCRM) {
          serchParms.append('idServiceCRM', idServiceCRM)
        }

        if (typeCommission) {
          serchParms.append('type', typeCommission)
        }

        if (saleMonth) {
          serchParms.append('saleMonth', saleMonth)
        }

        if (serviceId) {
          serchParms.append('IdService', serviceId)
        }

        if (commissionDescription) {
          serchParms.append('commissionDescription', commissionDescription)
        }

        if (pageNumber) {
          serchParms.append('pageNumber', pageNumber)
        }

        if (pageSize) {
          serchParms.append('rowsPerPage', pageSize)
        }

        if (sortOrder) {
          serchParms.append('sortOrder', sortOrder)
        }

        if (sortField) {
          serchParms.append('sortField', sortField)
        }

        const response = await fetchGET2(`Commission/ListCommissions?&authorizationState=approved&commissionState=completed&paymentState=pending&${serchParms.toString()}`)
        return response
      } catch (error) {
        throw error
      }
    })
  }

export // This is the request to get the data at pending in commissions
  const UseQueryPendingCommissions = (data: any) => {

    const commissionType = data?.commissionType && data?.commissionType.length > 0 ? data?.commissionType[0] : null
    const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
    const serviceId = data?.idService && data?.idService !== '' && data?.idService.length >= 5 ? data?.idService : null
    const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
    const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
    const serviceClient = data?.serviceClient && data?.serviceClient !== '' ? data?.serviceClient : null
    const typeCommission = data?.type && data?.type.length > 0 ? data?.type[0] : null
    const authorizatorName = data?.authorizatorName && data?.authorizatorName.length > 0 ? data?.authorizatorName[0] : null

    const pageNumber = data?.pageNumber
    const pageSize = data?.pageSize
    const sortOrder = data?.sortOrder ? data.sortOrder : null
    const sortField = data?.sortField ? data.sortField : null

    return useQuery(['UseQueryPendingCommissions', commissionType, saleMonth, serviceId, typeCommission, idServiceCRM, serviceClient, serviceName, authorizatorName, pageNumber, pageSize, sortOrder, sortField], async () => {
      try {
        const serchParms = new URLSearchParams();

        if (commissionType) {
          serchParms.append('commissionType', commissionType)
        }

        if (serviceClient) {
          serchParms.append('serviceClient', serviceClient)
        }

        if (serviceName) {
          serchParms.append('serviceName', serviceName)
        }

        if (idServiceCRM) {
          serchParms.append('idServiceCRM', idServiceCRM)
        }

        if (typeCommission) {
          serchParms.append('type', typeCommission)
        }

        if (saleMonth) {
          serchParms.append('saleMonth', saleMonth)
        }

        if (serviceId) {
          serchParms.append('IdService', serviceId)
        }

        if (authorizatorName) {
          serchParms.append('authorizatorName', authorizatorName)
        }

        // Pagination

        if (pageNumber) {
          serchParms.append('pageNumber', pageNumber)
        }

        if (pageSize) {
          serchParms.append('rowsPerPage', pageSize)
        }

        if (sortOrder) {
          serchParms.append('sortOrder', sortOrder)
        }

        if (sortField) {
          serchParms.append('sortField', sortField)
        }


        const response = await fetchGET2(`Commission/ListCommissions?&authorizationState=pending&commissionState=inProcess&${serchParms.toString()}`)
        return response
      } catch (error) {
        throw error
      }
    })
  }


export // this is the request to get the data of rejected commissions
  const UseQueryRejectedCommissions = (data: any) => {
    const commissionType = data?.commissionType && data?.commissionType.length > 0 ? data?.commissionType[0] : null
    const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
    const serviceId = data?.idService && data?.idService !== '' && data?.idService.length >= 5 ? data?.idService : null
    const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
    const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
    const authorizationReason = data?.authorizationReason ? data?.authorizationReason : null
    const serviceClient = data?.serviceClient && data?.serviceClient !== '' ? data?.serviceClient : null
    const typeCommission = data?.type && data?.type.length > 0 ? data?.type[0] : null
    const authorizatorName = data?.authorizatorName && data?.authorizatorName.length > 0 ? data?.authorizatorName[0] : null

    const pageNumber = data?.pageNumber
    const pageSize = data?.pageSize
    const sortOrder = data?.sortOrder ? data.sortOrder : null
    const sortField = data?.sortField ? data.sortField : null

    return useQuery(['UseQueryRejectedCommissions', commissionType, saleMonth, serviceId, authorizationReason, idServiceCRM, serviceName, serviceClient, typeCommission, authorizatorName, pageNumber, pageSize, sortOrder, sortField], async () => {
      try {
        const serchParms = new URLSearchParams();

        if (commissionType) {
          serchParms.append('commissionType', commissionType)
        }

        if (authorizationReason) {
          serchParms.append('authorizationReason', authorizationReason)
        }

        if (serviceClient) {
          serchParms.append('serviceClient', serviceClient)
        }

        if (serviceName) {
          serchParms.append('serviceName', serviceName)
        }

        if (idServiceCRM) {
          serchParms.append('idServiceCRM', idServiceCRM)
        }

        if (saleMonth) {
          serchParms.append('saleMonth', saleMonth)
        }

        if (serviceId) {
          serchParms.append('IdService', serviceId)
        }

        if (typeCommission) {
          serchParms.append('type', typeCommission)
        }

        if (authorizatorName) {
          serchParms.append('authorizatorName', authorizatorName)
        }

        // Pagination

        if (pageNumber) {
          serchParms.append('pageNumber', pageNumber)
        }

        if (pageSize) {
          serchParms.append('rowsPerPage', pageSize)
        }

        if (sortOrder) {
          serchParms.append('sortOrder', sortOrder)
        }

        if (sortField) {
          serchParms.append('sortField', sortField)
        }

        const response = await fetchGET2(`Commission/ListCommissions?&authorizationState=rejected&commissionState=completed&${serchParms.toString()}`)
        return response
      } catch (error) {
        throw error
      }
    })
  }


const downloadFileCommissionApproved = async (data: any) => {

  const commissionType = data?.commissionType && data?.commissionType.length > 0 ? data?.commissionType[0] : null
  const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
  const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null
  const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
  const paymentYear = data?.paymentYear && data?.paymentYear.length > 0 ? data?.paymentYear[0] : null
  const serviceId = data?.idService && data?.idService !== '' && data?.idService.length >= 5 ? data?.idService : null
  const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
  const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
  const serviceClient = data?.serviceClient && data?.serviceClient !== '' ? data?.serviceClient : null
  const typeCommission = data?.type && data?.type.length > 0 ? data?.type[0] : null
  const authorizatorName = data?.authorizatorName && data?.authorizatorName.length > 0 ? data?.authorizatorName[0] : null


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  try {

    const serchParms = new URLSearchParams();



    if (commissionType) {
      serchParms.append('commissionType', commissionType)
    }

    if (typeCommission) {
      serchParms.append('typeCommission', typeCommission)
    }

    if (authorizatorName) {
      serchParms.append('authorizatorName', authorizatorName)
    }



    if (paymentYear) {
      serchParms.append('paymentYear', paymentYear)
    }


    if (serviceClient) {
      serchParms.append('serviceClient', serviceClient)
    }

    if (serviceName) {
      serchParms.append('serviceName', serviceName)
    }

    if (idServiceCRM) {
      serchParms.append('idServiceCRM', idServiceCRM)
    }

    if (serviceId) {
      serchParms.append('IdService', serviceId)
    }


    if (saleMonth) {
      serchParms.append('saleMonth', saleMonth)
    }

    if (paymentMonth) {
      serchParms.append('paymentMonth', paymentMonth)
    }

    if (saleYear) {
      serchParms.append('saleYear', saleYear)
    }


    //! PAGINATION
    if (pageNumber) {
      serchParms.append('pageNumber', pageNumber)
    }

    if (pageSize) {
      serchParms.append('rowsPerPage', pageSize)
    }

    if (sortOrder) {
      serchParms.append('sortOrder', sortOrder)
    }

    if (sortField) {
      serchParms.append('sortField', sortField)
    }


    const response = await fetchGET2(`Commission/DownloadCommissions?&authorizationState=approved&commissionState=completed&${serchParms.toString()}`, { responseType: 'blob' })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'commissionApprovedDocument.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);


    return response


  } catch (error) {
    throw error
  }
}


export const UseDownloadFileCommissionApproved = (callbackSuccess: any) => {
  return useMutation(downloadFileCommissionApproved, {
    onSuccess(data) {
      callbackSuccess(data)
    },
  })
}


const downloadFileCommissionPaid = async (data: any) => {

  const commissionType = data?.commissionType && data?.commissionType.length > 0 ? data?.commissionType[0] : null
  const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
  const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null
  const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
  const paymentYear = data?.paymentYear && data?.paymentYear.length > 0 ? data?.paymentYear[0] : null
  const serviceId = data?.idService && data?.idService !== '' && data?.idService.length >= 5 ? data?.idService : null
  const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
  const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
  const serviceClient = data?.serviceClient && data?.serviceClient !== '' ? data?.serviceClient : null
  const typeCommission = data?.type && data?.type.length > 0 ? data?.type[0] : null
  const authorizatorName = data?.authorizatorName && data?.authorizatorName.length > 0 ? data?.authorizatorName[0] : null


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  try {

    const serchParms = new URLSearchParams();



    if (commissionType) {
      serchParms.append('commissionType', commissionType)
    }

    if (typeCommission) {
      serchParms.append('typeCommission', typeCommission)
    }

    if (authorizatorName) {
      serchParms.append('authorizatorName', authorizatorName)
    }



    if (paymentYear) {
      serchParms.append('paymentYear', paymentYear)
    }


    if (serviceClient) {
      serchParms.append('serviceClient', serviceClient)
    }

    if (serviceName) {
      serchParms.append('serviceName', serviceName)
    }

    if (idServiceCRM) {
      serchParms.append('idServiceCRM', idServiceCRM)
    }

    if (serviceId) {
      serchParms.append('IdService', serviceId)
    }


    if (saleMonth) {
      serchParms.append('saleMonth', saleMonth)
    }

    if (paymentMonth) {
      serchParms.append('paymentMonth', paymentMonth)
    }

    if (saleYear) {
      serchParms.append('saleYear', saleYear)
    }


    //! PAGINATION
    if (pageNumber) {
      serchParms.append('pageNumber', pageNumber)
    }

    if (pageSize) {
      serchParms.append('rowsPerPage', pageSize)
    }

    if (sortOrder) {
      serchParms.append('sortOrder', sortOrder)
    }

    if (sortField) {
      serchParms.append('sortField', sortField)
    }


    const response = await fetchGET2(`Commission/DownloadCommissions?&authorizationState=approved&commissionState=completed&paymentState=approved?&${serchParms.toString()}`, { responseType: 'blob' })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'commissionApprovedDocument.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);


    return response


  } catch (error) {
    throw error
  }
}


export const UseDownloadFileCommissionPaid = (callbackSuccess: any) => {
  return useMutation(downloadFileCommissionPaid, {
    onSuccess(data) {
      callbackSuccess(data)
    },
  })
}

const downloadFileCommissionInProcess = async (data: any) => {

  const commissionType = data?.commissionType && data?.commissionType.length > 0 ? data?.commissionType[0] : null
  const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
  const serviceId = data?.idService && data?.idService !== '' && data?.idService.length >= 5 ? data?.idService : null
  const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
  const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
  const serviceClient = data?.serviceClient && data?.serviceClient !== '' ? data?.serviceClient : null
  const commissionDescription = data?.commissionDescription && data?.commissionDescription.length > 0 ? data?.commissionDescription[0] : null
  const typeCommission = data?.type && data?.type.length > 0 ? data?.type[0] : null


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  try {

    const serchParms = new URLSearchParams();



    if (commissionType) {
      serchParms.append('commissionType', commissionType)
    }

    if (typeCommission) {
      serchParms.append('typeCommission', typeCommission)
    }

    if (serviceClient) {
      serchParms.append('serviceClient', serviceClient)
    }

    if (serviceName) {
      serchParms.append('serviceName', serviceName)
    }

    if (idServiceCRM) {
      serchParms.append('idServiceCRM', idServiceCRM)
    }

    if (serviceId) {
      serchParms.append('IdService', serviceId)
    }


    if (saleMonth) {
      serchParms.append('saleMonth', saleMonth)
    }



    //! PAGINATION
    if (pageNumber) {
      serchParms.append('pageNumber', pageNumber)
    }

    if (pageSize) {
      serchParms.append('rowsPerPage', pageSize)
    }

    if (sortOrder) {
      serchParms.append('sortOrder', sortOrder)
    }

    if (sortField) {
      serchParms.append('sortField', sortField)
    }


    const response = await fetchGET2(`Commission/DownloadCommissions?&commissionState=pending&${serchParms.toString()}`, { responseType: 'blob' })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'commissionApprovedDocument.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);


    return response


  } catch (error) {
    throw error
  }
}


export const UseDownloadFileCommissionInProccess = (callbackSuccess: any) => {
  return useMutation(downloadFileCommissionInProcess, {
    onSuccess(data) {
      callbackSuccess(data)
    },
  })
}

const downloadFileCommissionNexPayment = async (data: any) => {

  const commissionType = data?.commissionType && data?.commissionType.length > 0 ? data?.commissionType[0] : null
  const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
  const serviceId = data?.idService && data?.idService !== '' && data?.idService.length >= 5 ? data?.idService : null
  const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
  const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
  const serviceClient = data?.serviceClient && data?.serviceClient !== '' ? data?.serviceClient : null
  const commissionDescription = data?.commissionDescription && data?.commissionDescription.length > 0 ? data?.commissionDescription[0] : null
  const typeCommission = data?.type && data?.type.length > 0 ? data?.type[0] : null


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  try {

    const serchParms = new URLSearchParams();



    if (commissionType) {
      serchParms.append('commissionType', commissionType)
    }

    if (commissionDescription) {
      serchParms.append('commissionDescription', commissionDescription)
    }

    if (typeCommission) {
      serchParms.append('typeCommission', typeCommission)
    }

    if (serviceClient) {
      serchParms.append('serviceClient', serviceClient)
    }

    if (serviceName) {
      serchParms.append('serviceName', serviceName)
    }

    if (idServiceCRM) {
      serchParms.append('idServiceCRM', idServiceCRM)
    }

    if (serviceId) {
      serchParms.append('IdService', serviceId)
    }


    if (saleMonth) {
      serchParms.append('saleMonth', saleMonth)
    }



    //! PAGINATION
    if (pageNumber) {
      serchParms.append('pageNumber', pageNumber)
    }

    if (pageSize) {
      serchParms.append('rowsPerPage', pageSize)
    }

    if (sortOrder) {
      serchParms.append('sortOrder', sortOrder)
    }

    if (sortField) {
      serchParms.append('sortField', sortField)
    }


    const response = await fetchGET2(`Commission/DownloadCommissions?&authorizationState=approved&commissionState=completed&paymentState=pending&${serchParms.toString()}`, { responseType: 'blob' })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'commissionApprovedDocument.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);


    return response


  } catch (error) {
    throw error
  }
}


export const UseDownloadFileCommissionNextPayment = (callbackSuccess: any) => {
  return useMutation(downloadFileCommissionNexPayment, {
    onSuccess(data) {
      callbackSuccess(data)
    },
  })
}

const downloadFileCommissionPending = async (data: any) => {

  const commissionType = data?.commissionType && data?.commissionType.length > 0 ? data?.commissionType[0] : null
  const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
  const serviceId = data?.idService && data?.idService !== '' && data?.idService.length >= 5 ? data?.idService : null
  const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
  const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
  const serviceClient = data?.serviceClient && data?.serviceClient !== '' ? data?.serviceClient : null
  const typeCommission = data?.type && data?.type.length > 0 ? data?.type[0] : null
  const authorizatorName = data?.authorizatorName && data?.authorizatorName.length > 0 ? data?.authorizatorName[0] : null


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  try {

    const serchParms = new URLSearchParams();



    if (commissionType) {
      serchParms.append('commissionType', commissionType)
    }

    if (authorizatorName) {
      serchParms.append('authorizatorName', authorizatorName)
    }

    if (typeCommission) {
      serchParms.append('typeCommission', typeCommission)
    }

    if (serviceClient) {
      serchParms.append('serviceClient', serviceClient)
    }

    if (serviceName) {
      serchParms.append('serviceName', serviceName)
    }

    if (idServiceCRM) {
      serchParms.append('idServiceCRM', idServiceCRM)
    }

    if (serviceId) {
      serchParms.append('IdService', serviceId)
    }


    if (saleMonth) {
      serchParms.append('saleMonth', saleMonth)
    }



    //! PAGINATION
    if (pageNumber) {
      serchParms.append('pageNumber', pageNumber)
    }

    if (pageSize) {
      serchParms.append('rowsPerPage', pageSize)
    }

    if (sortOrder) {
      serchParms.append('sortOrder', sortOrder)
    }

    if (sortField) {
      serchParms.append('sortField', sortField)
    }


    const response = await fetchGET2(`Commission/DownloadCommissions?&authorizationState=pending&commissionState=inProcess&${serchParms.toString()}`, { responseType: 'blob' })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'commissionApprovedDocument.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);


    return response


  } catch (error) {
    throw error
  }
}


export const UseDownloadFileCommissionPending = (callbackSuccess: any) => {
  return useMutation(downloadFileCommissionPending, {
    onSuccess(data) {
      callbackSuccess(data)
    },
  })
}


const downloadFileCommissionRejected = async (data: any) => {

  const commissionType = data?.commissionType && data?.commissionType.length > 0 ? data?.commissionType[0] : null
  const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
  const serviceId = data?.idService && data?.idService !== '' && data?.idService.length >= 5 ? data?.idService : null
  const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
  const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
  const serviceClient = data?.serviceClient && data?.serviceClient !== '' ? data?.serviceClient : null
  const typeCommission = data?.type && data?.type.length > 0 ? data?.type[0] : null
  const authorizatorName = data?.authorizatorName && data?.authorizatorName.length > 0 ? data?.authorizatorName[0] : null


  const pageNumber = data?.pageNumber
  const pageSize = data?.pageSize
  const sortOrder = data?.sortOrder ? data.sortOrder : null
  const sortField = data?.sortField ? data.sortField : null

  try {

    const serchParms = new URLSearchParams();



    if (commissionType) {
      serchParms.append('commissionType', commissionType)
    }

    if (authorizatorName) {
      serchParms.append('authorizatorName', authorizatorName)
    }

    if (typeCommission) {
      serchParms.append('typeCommission', typeCommission)
    }

    if (serviceClient) {
      serchParms.append('serviceClient', serviceClient)
    }

    if (serviceName) {
      serchParms.append('serviceName', serviceName)
    }

    if (idServiceCRM) {
      serchParms.append('idServiceCRM', idServiceCRM)
    }

    if (serviceId) {
      serchParms.append('IdService', serviceId)
    }


    if (saleMonth) {
      serchParms.append('saleMonth', saleMonth)
    }



    //! PAGINATION
    if (pageNumber) {
      serchParms.append('pageNumber', pageNumber)
    }

    if (pageSize) {
      serchParms.append('rowsPerPage', pageSize)
    }

    if (sortOrder) {
      serchParms.append('sortOrder', sortOrder)
    }

    if (sortField) {
      serchParms.append('sortField', sortField)
    }


    const response = await fetchGET2(`Commission/DownloadCommissions?&authorizationState=rejected&commissionState=completed&${serchParms.toString()}`, { responseType: 'blob' })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'commissionApprovedDocument.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);


    return response


  } catch (error) {
    throw error
  }
}


export const UseDownloadFileCommissionRejected = (callbackSuccess: any) => {
  return useMutation(downloadFileCommissionRejected, {
    onSuccess(data) {
      callbackSuccess(data)
    },
  })
}
