import React, { useState, useEffect, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Table, UfinetBox, UfinetButton, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection } from 'ufinet-web-components'
import { AuthContext, Authority, } from 'ufinet-web-functions'
import { colsApprovedCommisions } from './ApprovedColData'
import { UseQueryApprovedCommissions, UseDownloadFileCommissionApproved } from 'request'
import BoxTotal from 'lib/box/BoxTotal'
import { DataTablePageEvent } from 'primereact/datatable'
import { Translation } from 'utils/translation/Translation'
import { CommissionKamElement } from 'types/commissionKamTypes'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

const ApprovedCommissionPage = () => {
  const intl = useIntl()
  const colsApprovedCommission = useMemo(() => colsApprovedCommisions(intl), [intl])

  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  const permissions = Authority.getCommissionKamPermissions(roles)


  const [paging, setPaging] = useState<any>()
  const [filterData, setFilterData] = useState<any>()
  const { data: approvedCommssion, isLoading: isLoadingApprovedCommision } = UseQueryApprovedCommissions(filterData)

  const cbSuccessApprovedFile = (data: any) => {

  }

  const { mutate: downloadFile, isLoading: isLoadingCommissionApproved } = UseDownloadFileCommissionApproved(cbSuccessApprovedFile)

  // To get de currencySymbol to show it at the top of the table
  const currencySymbol = approvedCommssion?.data && approvedCommssion?.data.commissions.length > 0 ? approvedCommssion?.data?.commissions[0]?.user.currencyCode : null

  useEffect(() => {
    if (approvedCommssion) {
      const parsePagination = JSON.parse(approvedCommssion?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [approvedCommssion])

  const onFilterClear = (): void => {
    setFilterData({})
  }

  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)
    setFilterData({
      ...filterData,
      ...formattedFilters
    })
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({ ...filterData, pageNumber: e.page, pageSize: e.rows })
  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      sortField: e.sortField,
      sortOrder: finalOrder
    })
  }

  const downloadFileApprovedCommission = () => {
    downloadFile(filterData)
  }


  const getHeadersButtons = () => (
    <>
      {permissions.canWrite && (
        <UfinetButton
          className="me-3"
          icon={faDownload}
          onClick={() => downloadFileApprovedCommission()}
          content={Translation('DOWNLOAD.FILE')}
        />
      )}
    </>
  )

  return (
    <>
      <UfinetBox>
        <UfinetSectionBox>
          {approvedCommssion?.data && approvedCommssion?.data?.commissions.length > 0 && (
            <BoxTotal
              title='TOTAL.AMOUNT.APPROVED'
              totalCommissionAmount={approvedCommssion?.data?.totalCommissionAmount}
              currencyCode={currencySymbol}
            />
          )}
        </UfinetSectionBox>
        <UfinetSectionBox className='mt-5'>
          <Table
            // selectedValues={selectedValues}
            // onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            cols={colsApprovedCommission}
            content={approvedCommssion?.data?.commissions?.map((item: CommissionKamElement) => ({
              ...item,
              idService: item.service.idService,
              saleMonth: item.service.saleMonth,
              commissionType: item.commission.commissionType,
              exchangeRate: item.commission.exchangeRate,
              type: item.commission.type,
              backlog: item.service.backlog,
              percentageCommission: item.commission.percentageCommission,
              baseAmount: item.commission.baseAmount,
              authorizatorName: item.authorization.authorizatorName,
              paymentState: item.commission.paymentState,
              authorizationDate: item.authorization.authorizationDate,
              commissionTypePercentage: item.commission.commissionTypePercentage,
              saleYear: item.service.saleYear,
              achievementFactor: item.commission.achievementFactor,
              authorizatorLevel: item.authorization.authorizatorLevel,
              totalAmount: item.commission.totalAmount,
              paymentMonth: item.commission.paymentMonth,
              paymentYear: item.commission.paymentYear,
              idServiceCRM: item.service.idServiceCRM,
              serviceName: item.service.serviceName,
              serviceClient: item.service.serviceClient,
              firstBillingDate: item.service.firstBillingDate,

            }))}
            actionBodyTemplate={<></>}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            emptyMessage={isLoadingApprovedCommision || isLoadingCommissionApproved ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            onFilterClear={onFilterClear}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingApprovedCommision || isLoadingCommissionApproved,
                onFilter,
                onPage,
                onSort,
              }
            }
            headerButtons={getHeadersButtons()}
          // emptyMessage={loadingRecords ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
          />
        </UfinetSectionBox>
      </UfinetBox >
    </>
  )
}

export default ApprovedCommissionPage
