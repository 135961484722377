import React, { useState, useEffect, useMemo, useContext, useCallback } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { toast } from 'react-toastify'
import { MultipleDeleter, Table, UfinetBox, UfinetButton, UfinetModal, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection } from 'ufinet-web-components'
import { AuthContext, Authority, useAsync, useModal, } from 'ufinet-web-functions'
import { colsHistoricalsLiquidateSystem } from './HistoricalLiquidateSystemColData'
import { Translation, useTranslation } from 'utils/translation/Translation'
import { CommissionService } from 'types/CommissionEstimatedTypes'
import BoxTotal from 'lib/box/BoxTotal'
import { DataTableFilterMeta, DataTablePageEvent } from 'primereact/datatable'
import { faCalculator, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck'
import { UseQueryHistoricalsLiquidateSystem, UseDownloadLiquidateHistoricalsSys, UseRecalculateLiquidateHistoricalsSys } from 'request'
import { HistoricalsLiquidateSystemTypes } from 'types/liquidateSystemTypes'
import { ErrorResponse } from 'types/typesAndValues'



const HistoricalLiquidateSystemPage = () => {
  const intl = useIntl()
  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  const permissions = Authority.getCommissionLiquidateSysPermissions(roles)

  const colsHistoricals = useMemo(() => colsHistoricalsLiquidateSystem(intl), [intl])


  const [selectedValues, setSelectedValues] = useState<HistoricalsLiquidateSystemTypes[]>([])
  const [paging, setPaging] = useState<any>()
  const [filterData, setFilterData] = useState<any>()
  const { data: liquidateHistoricals, isLoading: isLoadingLiquidateHistoricals, refetch, isFetching } = UseQueryHistoricalsLiquidateSystem(filterData)
  const isSelectedValues = selectedValues && selectedValues.length > 0


  const translate = useTranslation()


  const cbSuccessDowloadFile = (data: any) => {

  }


  const cbSuccessRecalculateLiquidate = ({ data }: any) => {
    toast.success(data.message)
    setSelectedValues([])
    setFilterData({})
    refetch()

  }

  const { mutate: downloadFile, isLoading: isLoadingLiquidateHistoricalsSys } = UseDownloadLiquidateHistoricalsSys(cbSuccessDowloadFile)
  const { mutate: recalculateLiquidate, isLoading: isLoadingRecalculateLiquidateHistoricalsSys, isError: isErrorRecalculate, error: errorMessage } = UseRecalculateLiquidateHistoricalsSys(cbSuccessRecalculateLiquidate)


  const showToastErrMessage = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse;
      toast.error(`${translate("ERROR.MESSAGE")} ${errorResponse.errorCode}`, { toastId: 'Error5' });

    }
  }


  useEffect(() => {
    if (liquidateHistoricals) {
      const parsePagination = JSON.parse(liquidateHistoricals?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageNumber: parsePagination.currentPage,
        pageSize: filterData && filterData.pageSize ? filterData.pageSize : parsePagination.pageSize
      }
      setPaging(formattedPagination)
    }
  }, [liquidateHistoricals])

  const onFilterClear = (): void => {
    setFilterData({})
  }

  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)
    setFilterData({
      ...filterData,
      ...formattedFilters
    })
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({ ...filterData, pageNumber: e.page, pageSize: e.rows })
  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })
  }


  const downloadFileLiquidateHistoricalsSys = () => {
    downloadFile(filterData)
  }


  const recalculate = () => {
    if (isSelectedValues) {
      const newArr = selectedValues.map((item: HistoricalsLiquidateSystemTypes) => {
        return {
          id: item.paymentId,
        }
      })


      recalculateLiquidate(newArr)

    }


  }


  const getHeadersButtons = () => (
    <>
      {permissions.canWrite && (
        <UfinetButton
          className="me-3"
          icon={faDownload}
          onClick={() => downloadFileLiquidateHistoricalsSys()}
          content={Translation('DOWNLOAD.FILE')}
        />
      )}
      {permissions.canWrite && isSelectedValues && (
        <UfinetButton
          className="me-3"
          icon={faCalculator}
          isDisabled={isLoadingRecalculateLiquidateHistoricalsSys}
          onClick={() => recalculate()}
          content={Translation('RECALCULATE')}
        />
      )}
    </>
  )

  const onSelectionChange = useCallback((values: any[]) => {
    setSelectedValues(values)
  }, [])


  return (
    <>
      {isErrorRecalculate && showToastErrMessage()}
      <UfinetBox>


        <UfinetSectionBox>

          <Table
            enableSearchFilter={false}
            dataKey='paymentId'
            selectedValues={selectedValues}
            onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            content={liquidateHistoricals?.data && liquidateHistoricals?.data?.map((item: HistoricalsLiquidateSystemTypes) => (
              {
                ...item,
                title: `${item.paymentMonth && item.paymentYear ? `${Translation('LIQUIDATE.COL')} ${Translation(item.paymentMonth)} -` : ''} ${item.cutoffMonth && item.cutoffYear ? `${Translation('CLOSEDATE')} ${Translation(item.cutoffMonth)} ${item.cutoffYear}` : ''} `,
              }
            ))}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            cols={colsHistoricals}
            onFilterClear={onFilterClear}
            emptyMessage={isLoadingLiquidateHistoricals || isLoadingLiquidateHistoricalsSys || isLoadingRecalculateLiquidateHistoricalsSys || isFetching ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingLiquidateHistoricals || isLoadingLiquidateHistoricalsSys || isLoadingRecalculateLiquidateHistoricalsSys || isFetching,
                onFilter,
                onPage,
                onSort,
              }
            }
            headerButtons={getHeadersButtons()}
            actionBodyTemplate={<></>}
          />
          {/* <UfinetModal
          size="lg"
          show={newModal}
          handleClose={hideNewModal}
          title={intl.formatMessage({ id: 'MODAL.NEW.REGISTER.TITLE' })}
        >
          <NewCompanyModalBody search={fetchAndStore} hideModal={hideNewModal} />
        </UfinetModal> */}
        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}

export { HistoricalLiquidateSystemPage }

