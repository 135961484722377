import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { toast } from 'react-toastify'
import { Table, UfinetBox, UfinetButton, UfinetModal, UfinetSectionBox, formatTableFilters, getEditPencilBodyTemplate, tableSortValueToSortDirection, } from 'ufinet-web-components'
import { AuthContext, Authority, useModal, } from 'ufinet-web-functions'
import { colsMultiplierConfigColData } from './ConfigMultiplierColData'
import { UseQueryGetMultiplierConfig, UseDeleteMultiplierConfig } from 'request'
import { useTranslation } from 'utils/translation/Translation'
import { DataTablePageEvent } from 'primereact/datatable'
import { UpdateMultiplierConfigForm } from './modals/update/UpdateMultiplierConfigForm'
import { CreateMultiplierConfigForm } from './modals/create/CreateMultiplierConfigForm'
import { MultiplierConfigTypes } from '../../../types/configuration/ConfigurationTypes';


const MultiplierConfigPage = () => {
  const intl = useIntl()
  const colsMultiplierConfig = useMemo(() => colsMultiplierConfigColData(intl), [intl])
  const [selectedValues, setSelectedValues] = useState<MultiplierConfigTypes[]>([])

  const [multiplierConfigInfo, setMultiplierInfo] = useState<MultiplierConfigTypes>({
    id: 0,
    compliancePercentageMin: 0,
    compliancePercentageMax: 0,
    multiplierFactor: 0,
  })



  //* Modals

  const [createModal, showCreateModal, hideCreateModal] = useModal()
  const [updateModal, showUpdateModal, hideUpdateModal] = useModal()

  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  // Go to see the repo ufinet-web-functions at the file typeAndValues branch develop
  // In the function Authority to get the function to get the roles
  // In this case getCommissionsPermissions is gonna get the roles
  const permissions = Authority.getCommissionConfigPermissions(roles)

  const [paging, setPaging] = useState<any>()
  const [loadingRecords, setLoadingRecords] = useState<boolean>(false)
  const [filterData, setFilterData] = useState<any>()
  const [tablePFSEvent, setTablePFSEvent] = useState<DataTablePageEvent>()


  const { data: multiplierConfig, isLoading: isLoadingMultiplierConfig, refetch } = UseQueryGetMultiplierConfig(filterData)


  const cbSuccessDeleteMultiplierConfig = (data: any) => {
    toast.success(translate('DELETED.MULTIPLIER.CONFIG'))
    refetch()
  }

  const { mutate: deleteMultiplierConfig, isLoading: isLoadingDeleteMultplierConfig } = UseDeleteMultiplierConfig(cbSuccessDeleteMultiplierConfig)


  const translate = useTranslation()


  const onSelectionChange = useCallback((values: any) => setSelectedValues(values), [])







  useEffect(() => {
    if (multiplierConfig) {
      const parsePagination = JSON.parse(multiplierConfig?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: filterData && filterData.pageSize ? filterData.pageSize : parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [multiplierConfig])

  const onFilterClear = (): void => {
    setFilterData({})
  }

  // export type PagingData = {
  //   totalElements: number - totalCount
  //   totalPages: number - totalPages
  //   pageSize: number - pageSize
  //   pageNumber: number - currentPage
  // }



  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)
    setFilterData({
      ...filterData,
      ...formattedFilters
    })
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({ ...filterData, pageNumber: e.page, pageSize: e.rows })

  }

  const onSort = (e: DataTablePageEvent): void => {

    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })

  }


  const deleteMultiplier = () => {
    if (selectedValues && selectedValues.length > 0) {
      const firstElement = selectedValues[0]
      if (firstElement !== undefined) {
        deleteMultiplierConfig(firstElement.id);
      }
    }
  }



  const getHeadersButtons = () => (
    <>

      {permissions.canDelete && selectedValues && selectedValues?.length > 0 && (

        <>
          <UfinetButton
            className="me-3"
            icon={faTrash}
            onClick={() => deleteMultiplier()}
            content={translate('DELETE.COMMISSION.TYPE')}
          />

        </>

      )}

      {permissions.canWrite && (
        <>
          <UfinetButton
            className="me-3"
            icon={faPlus}
            onClick={() => {
              showCreateModal()
            }}
            content={translate('CREATE.MULTIPLIER.CONFIG')}
          />


        </>
      )}
    </>
  )

  return (
    <>
      <UfinetBox>

        <UfinetSectionBox>

          <Table
            dataKey="id"
            selectedValues={selectedValues}
            onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            content={multiplierConfig?.data}
            cols={colsMultiplierConfig}
            headerButtons={getHeadersButtons()}
            onFilterClear={onFilterClear}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            emptyMessage={isLoadingMultiplierConfig || isLoadingDeleteMultplierConfig ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            actionBodyTemplate={
              permissions.canUpdate
                ? (multiplierId: MultiplierConfigTypes) =>
                  getEditPencilBodyTemplate(multiplierId, () => {
                    setSelectedValues([])
                    setMultiplierInfo(multiplierId)
                    showUpdateModal()
                  })
                : undefined
            }
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingMultiplierConfig || isLoadingDeleteMultplierConfig,
                onFilter,
                onPage,
                onSort,
              }
            }
          />


          <UfinetModal
            size="lg"
            show={createModal}
            handleClose={hideCreateModal}
            title={translate('CREATE.MULTIPLIER.CONFIG')}
          >
            <CreateMultiplierConfigForm hideModal={hideCreateModal} refetch={refetch} />
          </UfinetModal>

          <UfinetModal
            size="lg"
            show={updateModal}
            handleClose={hideUpdateModal}
            title={translate('UPDATE.MULTIPLIER.CONFIG')}
          >
            <></>
            <UpdateMultiplierConfigForm updatedData={multiplierConfigInfo} hideModal={hideUpdateModal} refetch={refetch} />
          </UfinetModal>
        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}

export { MultiplierConfigPage }

