import axios from "axios"
import { useMutation, useQuery } from "react-query"
import { fetchGET2, fetchPATCH } from "utils/https/https"

export const UseQueryGetUsers = () => {
  return useQuery(['UseQueryGetUsers'], async () => {
    try {
      const response = await fetchGET2(`User/ListUsers`)
      return response.data
    } catch (error) {
      throw error
    }
  })
}




export const UseQueryGetData = (data: any) => {
  const year = data.year
  const username = data.username
  return useQuery(['UseQueryGetData', data], async () => {
    try {

      const searchParams = new URLSearchParams()

      if (year !== '') {
        searchParams.append('goalYear', year)
      }

      if (username !== '') {
        searchParams.append('email', username)
      }

      const response = await fetchGET2(`Goal/ListUserGoals?${searchParams.toString()}`)
      return response
    } catch (error) {
      throw error
    }
  },
    {
      enabled: !!year && !!username
    }
  )
}



const UsePostGoals = async (data: any) => {
  try {
    const response = await fetchPATCH(`/Goal/Update`, [data])
    return response
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const customErr = {
        errorCode: error.response.data.code,
        message: error.response.data.message,
      }
      throw customErr
    }
  }
}

// This is the request to send the goals
export const UsePatchGoals = (callbackSuccess: any) => {
  return useMutation(UsePostGoals, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};



const UseQueryGetTemplateBulkLoadGoals = async (data: any) => {

  try {
    const response = await fetchGET2(`/Goal/GetUserGoalTemplate?managerEmail=${data}`, { responseType: 'blob' })

    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'template.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    return response
  } catch (error) {
    throw error
  }

}

export const UseGetBulkLoadTemplate = (callbackSuccess: any) => {
  return useMutation(UseQueryGetTemplateBulkLoadGoals, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};
