import React, { useState, useEffect, useContext, useMemo, } from 'react'
import { useIntl } from 'react-intl'
import { Table, UfinetBox, UfinetButton, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection } from 'ufinet-web-components'
import { AuthContext, Authority, } from 'ufinet-web-functions'
import { colsRejectedAuthorizationSystem } from './AuhtorizationSystemRejectedColData'
import { UseQueryRejectedAuthorizationSystemCommissions, UseDownloadFileAuthorizatorRejectedSys } from 'request'
import { Translation } from 'utils/translation/Translation'
import { DataTablePageEvent } from 'primereact/datatable'
import { CommissionElement } from 'types/authorization'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { useFiltersAuthorizationSys } from 'hooks/useFiltersAuthorizationSys'
import { MultiSelectFiltersHeaders } from '../filterHeaders/MultiSelectFiltersHeaders'

const RejectedAuthorizationSystemPage = () => {
  const intl = useIntl()

  const colsRejectedAuthorizationSys = useMemo(() => colsRejectedAuthorizationSystem(intl), [intl])

  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  const permissions = Authority.getCommissionAuthorizationSysPermissions(roles)



  const [paging, setPaging] = useState<any>()
  const [filterData, setFilterData] = useState<any>()


  const {
    kamsSelect,
    isLoadingDataAuthorizator,
    clientsSelect,
    isLoadingDataClients,
    handleMultiSelect,
    multiSelectKam,
    multiSelectClient,
    cleanSelectKamAndClient,
    username,
    serviceClient,
    selectAllClient,
    selectAllKam,
    onSelectAll,
  } = useFiltersAuthorizationSys({ filterData, setFilterData })


  const { data: rejectedAuthorizationSystem, isLoading: isLoadingRejectedAuthorizationSystem } = UseQueryRejectedAuthorizationSystemCommissions(filterData)

  const cbSuccessAuthRejectedSys = ({ data }: any) => {
  }



  const { mutate: downloadFile, isLoading: isLoadingDownloadingFile } = UseDownloadFileAuthorizatorRejectedSys(cbSuccessAuthRejectedSys)





  useEffect(() => {
    if (rejectedAuthorizationSystem) {
      const parsePagination = JSON.parse(rejectedAuthorizationSystem?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [rejectedAuthorizationSystem])

  const onFilterClear = (): void => {
    cleanSelectKamAndClient()
    setFilterData({})
  }

  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)
    setFilterData({
      ...filterData,
      ...formattedFilters,
      username,
      serviceClient,
    })
  }

  const onPage = (e: DataTablePageEvent): void => {
    setFilterData({
      ...filterData,
      username,
      serviceClient,
      pageNumber: e.page,
      pageSize: e.rows
    })
  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilterData({
      ...filterData,
      username,
      serviceClient,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })
  }



  const downloadFileRejectedSys = () => {
    downloadFile(filterData)
  }

  const getHeadersButtons = () => (
    <>
      {permissions.canWrite && (
        <UfinetButton
          className="me-3"
          icon={faDownload}
          isDisabled={isLoadingDownloadingFile}
          onClick={() => downloadFileRejectedSys()}
          content={Translation('DOWNLOAD.FILE')}
        />
      )}
    </>
  )

  const filterButtons = () => {
    return (
      <>
        <MultiSelectFiltersHeaders
          // kamFilter={false}

          onSelectAll={onSelectAll}
          selectAllClient={selectAllClient}
          selectAllKam={selectAllKam}
          multiSelectClient={multiSelectClient}
          multiSelectKam={multiSelectKam}
          clientsSelect={clientsSelect}
          kamsSelect={kamsSelect}
          onChangeHandle={handleMultiSelect}
          isLoadingDataAuthorizator={isLoadingDataAuthorizator}
          isLoadingDataClients={isLoadingDataClients}
        />
      </>
    )

  }






  return (
    <>
      <UfinetBox>

        <UfinetSectionBox>
          <Table
            filterButtons={filterButtons()}
            enableSearchFilter={false}
            headerButtons={getHeadersButtons()}
            dataKey="id"
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            cols={colsRejectedAuthorizationSys}
            content={rejectedAuthorizationSystem?.data?.commissions?.map((item: CommissionElement) => ({
              ...item,
              username: item.user.username,
              idService: item.service.idService,
              saleMonth: item.service.saleMonth,
              commissionType: item.commission.commissionType,
              exchangeRate: item.commission.exchangeRate,
              authorizationState: item.authorization.authorizationState,
              id: item.commission.id,
              totalAmount: item.commission.totalAmount,
              backlog: item.service.backlog,
              percentageCommission: item.commission.percentageCommission,
              baseAmount: item.commission.baseAmount,
              achievementFactor: item.commission.achievementFactor,
              paymentYear: item.commission.paymentYear,
              commissionTypePercentage: item.commission.commissionTypePercentage,
              rejectedDate: item.authorization.rejectedDate,
              saleYear: item.service.saleYear,
              authorizatorName: item.authorization.authorizatorName,
              authorizatorLevel: item.authorization.authorizatorLevel,
              idServiceCRM: item.service.idServiceCRM,
              serviceName: item.service.serviceName,
              serviceClient: item.service.serviceClient,
              authorizationReason: item.authorization.authorizationReason,
            }))}
            actionBodyTemplate={<></>}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            emptyMessage={isLoadingRejectedAuthorizationSystem || isLoadingDownloadingFile ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            onFilterClear={onFilterClear}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingRejectedAuthorizationSystem || isLoadingDownloadingFile,
                onFilter,
                onPage,
                onSort,
              }
            }
          />
        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}

export { RejectedAuthorizationSystemPage }

